<template>
    <div class="row justify-content-center detalle row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h2>{{$t('message.total')}}</h2>
            <h3>$ {{ arrayCheckout.precioTotal }} {{coinConfig.coinSelected}}</h3>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 v-if="arrayCheckout.promo">$ {{ arrayCheckout.total }} {{coinConfig.coinSelected}}</h5>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject, provide } from 'vue';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"
    import axios from 'axios';
    import { useUsersStore } from "../../stores/user"
    import { useConfigStore } from "../../stores/config"
    import { useRoute, useRouter } from 'vue-router';

    const carts = useCartStore();
    const { cart, arrayCheckout } = storeToRefs(carts);
    const { setCheckout } = carts

    const coinConfig = useConfigStore();

    setCheckout()

    const route = useRoute()
    const router = useRouter()

    const users = useUsersStore();
    const { idUser } = storeToRefs(users);
    const {  } = users;

    let step = inject('step')

</script>



<style lang="scss" scoped>
.detalle{
    h2{
        font-weight: 600;
        font-size: 28px;
        color: var(--colorSecondary);
        padding-top: 15px;
        padding-bottom: 10px;
    }
    h3{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
        padding-bottom: 10px;
    }
    h5{
        color: #B8C1DB;
        font-weight: 500;
        font-size: 20px;
        text-decoration: line-through;
    }
}
</style>