<template>
    <div class="col-11 d-flex justify-content-center align-items-center px-0 settings">
        <div class="row justify-content-center px-lg-4 row__width" v-if="actualActivity && actualActivity.lessonType == 2">
            <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                <div class="row justify-content-start row__width ">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                        <h2>{{ $t('message.addingQuestionnaire') }}</h2>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start px-0">
                        <div class="row justify-content-start align-items-start row__width">
                            <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start px-0 pe-lg-3" ref="titleRef" id="title-input-empty">
                                <h4 class="mb-2">{{ $t('message.name') }}</h4>
                                <input type="text" v-model="actualActivity.title">
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-start align-items-center error d-none" ref="titleRefError" id="title-empty">
                                        <p>Por favor, complete el título del cuestionario</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 pe-lg-0 mt-3 mt-lg-0">
                                <div class="row row__width justify-content-between">
                                    <div class="col d-flex flex-column justify-content-center align-items-start px-0 pe-lg-0">
                                        <h4 class="mb-2">{{ $t('message.timeLimit') }}</h4>
                                        <input type="Number" v-model="actualActivity.limitTime">
                                    </div>
                                    <div class="col-3 d-flex justify-content-between justify-content-lg-around align-items-end mb-2 buttons ps-1 pe-0">
                                        <button type="button" @click="decrement">-</button>
                                        <button type="button" class="active" @click="increment">+</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3">
                                <div class="row justify-content-center row__width width">
                                    <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                        <h3>{{ $t('message.description') }}</h3>
                                        <p v-if="actualActivity.description">{{actualActivity.description.length}}/400</p>
                                        <p v-else>0/400</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0" ref="descRef" id="description-textarea-empty">
                                        <span class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <h6>B</h6>
                                            </div>
                                        </span>
                                        <textarea name="" id="" placeholder="Escribir aqui" cols="30" rows="10" maxlength="400" v-model="actualActivity.description"></textarea>
                                    </div>
                                    <div class="row px-0">
                                        <div class="col-12 d-flex justify-content-start align-items-center error d-none px-0" ref="descRefError" id="description-empty">
                                            <p class="ps-0">Por favor, complete la descripción del cuestionario</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 mt-4">
                        <h2>{{ $t('message.settingCount') }}</h2>
                        <h5>{{ $t('message.howToPassCourse') }}</h5>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch ps-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.mandatoryQuestionnaire') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="mandatory" v-model="actualActivity.config.mandatory">
                            <label class="form-check-label" for="mandatory">
                                <p v-if="actualActivity.config.mandatory">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch ps-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.takeQuestionnaireAgain') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="questionnaire" v-model="actualActivity.config.repeatable">
                            <label class="form-check-label" for="questionnaire">
                                <p v-if="actualActivity.config.repeatable">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch ps-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.seeResults') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="see" v-model="actualActivity.config.answersVisible">
                            <label class="form-check-label" for="see">
                                <p v-if="actualActivity.config.answersVisible">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch ps-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.requiredScore') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="qualification" v-model="actualActivity.config.checkMinimumGrade">
                            <label class="form-check-label" for="qualification">
                                <p v-if="actualActivity.config.checkMinimumGrade">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start px-0 margin " v-if="actualActivity.config.checkMinimumGrade">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center ofertas">
                                <div class="row row__width justify-content-between">
                                    <div class="col-5 d-flex flex-column justify-content-around align-items-start pe-0">
                                        <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                        <input type="number" v-model="actualActivity.config.minimumGrade" @input="validateNumber(actualActivity.config.minimumGrade)">
                                    </div>
                                    <div class="col-5 col-lg-2 d-flex justify-content-center align-items-center guardar">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-3 switch ps-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">Cantidad de preguntas a mostrar en el cuestionario</p>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start px-0 margin ">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center ofertas" ref="qShownRef" id="questionShown-input-empty">
                                <div class="row row__width justify-content-start">
                                    <div class="col-4 d-flex flex-column justify-content-around align-items-start pe-0">
                                        <input type="number" v-model="actualActivity.questionsShown">
                                    </div>
                                    <div class="col d-flex justify-content-start align-items-center">
                                        <p>Nota: mínimo 1 pregunta</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-start align-items-center error d-none" ref="qShownRefError" id="questionShown-empty">
                                    <p>Por favor, complete el numero de preguntas a mostrar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>


import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs


let actualActivity = inject('actualActivity')
let listNotes = inject('listNotes')

let titleRef = inject('titleRef')
let descRef = inject('descRef')
let qShownRef = inject('qShownRef')
let titleRefError = inject('titleRefError')
let descRefError = inject('descRefError')
let qShownRefError = inject('qShownRefError')


const showComponent = ref(false);
const closeComponent = () => {
  showComponent.value = false;
};

const increment = () =>{
    actualActivity.value.limitTime = actualActivity.value.limitTime + 15
}
const decrement = () =>{
    actualActivity.value.limitTime = actualActivity.value.limitTime - 15
    if(actualActivity.value.limitTime < 0){
        actualActivity.value.limitTime = 0
    }
}

const validateNumber = (newValue) => {
    if (newValue < listNotes.value.quizValue) {
        actualActivity.value.config.minimumGrade = listNotes.value.quizValue
    } else if (newValue > 100) {
        actualActivity.value.config.minimumGrade = 100
    }
}

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
.ofertas{
    background-color: #F5F5F5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
    .guardar{
        button{
            border: 2px solid var(--colorPrimary);
            background-color: var(--bgPrincipal);
            border-radius: 25px;
            height: 50px;
            font-weight: 600;
            font-size: 16px;
            color: var(--colorPrimary);
            width: 100%;
        }
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}
.upload{
    input{
        display: none;
    }
    label{
        background-color: var(--bgPrincipal);
        border-radius: 24px;
        height: 50px;
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        cursor: pointer;
        img{
            padding-right: 10px;
        }
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        font-size: 18px;
        color: var(--colorSecondary);
        font-weight: 500;
        padding-bottom: 30px;
        text-align: left;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            font-size: 16px;
            font-weight: 400;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        max-width: 55%;
    }
    .buttons{
        button{
            border: 2px solid var(--colorPrimary);
            background-color: var(--bgPrincipal);
            border-radius: 50%;
            height: 42px;
            width: 42px;
            font-weight: 600;
            font-size: 22px;
            color: var(--colorPrimary);
            display: flex;
            justify-content: center;
            align-items: center;
            &:focus, &:hover{
                background-color: var(--colorPrimary);
                color: var(--bgPrincipal);
            }
            @media (max-width: 991px) {
                height: 37px;
                width: 37px;
            }
        }
        .active{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            &:focus, &:hover{
                background-color: var(--bgPrincipal);
                color: var(--colorPrimary);
            }
        }
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 20vh;
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            
            color: #697891;
        }
        
    }
    span{
        background-color: #E4E4E4;
        height: 42px;
        width: 100%;
        position: absolute;
        max-width: 99.5%;
        top: 1.5px;
        border-radius: 8px 8px 0 0 ;
        padding: 0px 15px;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding-left: 5px;
        }
        img{
            height: 24px;
        }
    }
    .width{
        width: -webkit-fill-available;
    }
    .margin{
        margin-bottom: 20px;
    }
}

.error{
    p{
        color: #FF6A6A !important;
        padding-left: 0;
    }
    input, textarea{
        border: 2px solid #FF6A6A !important;
    }
}
.error-btn{
    color: var(--colorRed) !important;
}
</style>