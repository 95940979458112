<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none" />
        <div class="row justify-content-center ">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center max-width">
                <RolInicioRegistro/>
                <TituloYSubtituloRegistro :titulo="$t('message.titleLogin')" :subtitulo="$t('message.subtitleHome')" :desaparecerSubtitulo="true"/>
                <!--<h1>{{ tokenAccess }}</h1>-->
                <!-- <div class="row justify-content-center row__width">
                    <BTNFacebook :textoBTNFacebook="$t('message.loginFacebook')"/>
                    <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')"/>
                   <BTNTelefono :textoBTNTelefono="$t('message.loginPhone')" />
                </div> -->
                <FormLogin/>
                <div class="row justify-content-center row__width margin-top">
                    <RegistrarmeEnlace :cuenta="$t('message.dontHaveAccountYet')" :enlace="$t('message.registerMe')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Navbar from "../../components/Navs/Navbar.vue";
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import RolInicioRegistro from '../../components/Roles/RolInicioRegistro.vue';
import TituloYSubtituloRegistro from '../../components/Titulo/TituloYSubtituloRegistro.vue';
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import BTNTelefono from '../../components/Botones/Telefono.vue';
import FormLogin from '../../components/Forms/FormLogin.vue';
import RegistrarmeEnlace from "../../components/Enlaces/RegistrarmeEnlace.vue";
import { onMounted, onUnmounted, ref } from 'vue'
import axios from "axios"

import { useLanguageStore } from "../../stores/langs"
import { storeToRefs } from "pinia";

const langs = useLanguageStore();
const {textos} = storeToRefs(langs);
const {} = langs;

const url = process.env.VUE_APP_BASE_URL
const urlLanguage = '/api/lang/get/full'

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
/*const prueba2 = onMounted(async() =>{
    try {
        await axios.get(url+urlLanguage).then((result) => {
            console.log(result)
        })
    }
    catch (error) {
        console.log(error)
    }
})*/
</script>




<style lang="scss" scoped>
.max-width{
    @media (max-width: 768px) {
        width: 389px !important;
    }
    @media (min-width: 768px) {
        width: 420px !important;
    }
}
</style>