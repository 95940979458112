<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex justify-content-start align-items-center px-0">
            <button class="btn_none px-0 width" @click="$router.go(-1)">
                <img src="../../img/roles-icons/arrow-left-negro.svg" alt="">
                <h6>{{ $t('message.return') }}</h6>
            </button>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
h6{
    color: #1F1F39;
    font-size: 16px;
    font-weight: 700;
    padding-left: 5px;
}
.width{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: min-content;
}
</style>