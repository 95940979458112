<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3 px-0">
        <h3>{{ $t('message.namePrice') }}</h3>
        <input type="text" v-model="actualActivity.title">
        <!-- :placeholder="userData.full_name !== undefined ? userData.full_name : $t('message.user')" -->
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { inject } from "vue";
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";

let actualActivity = inject('actualActivity')
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
</style>