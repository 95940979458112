<template>
    <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center  align-items-lg-start span " :class="dLgFLex == true ? 'd-none d-lg-flex' : ''">
        <div class="row justify-content-center row__width">
            <div class="col d-flex justify-content-center align-items-center align-items-lg-start max-width">
                <span><img :src="src" alt=""></span>
            </div>
            <div class="col d-flex flex-column justify-content-center align-items-start pe-0 ps-3">
                <h6>{{titulo}}</h6>
                <h3>{{subtitulo}}</h3>
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        titulo:'',
        subtitulo:'',
        src: '',
        dLgFLex:{
            type: Boolean,
            default: false
        }
    })
</script>

<script>
import { mapState } from "vuex";
export default{
    computed: {
        ...mapState(['textos'], ),
    }
}
</script>

<style lang="scss" scoped>
.max-width{
    max-width: 17%;
    @media (min-width:992px) {
        padding-right: 20px;
    }
}
span{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
    min-height: 65px;
    min-width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width:992px) {
        min-height: 75px;
        min-width: 75px;
    }
}

.span{
    margin-bottom: 20px;
    @media (min-width:992px) {
        margin-bottom: 30px;
    }
}
h3{
    font-size: 16px;
    font-weight: 400;
    color: #7D93B2;
    text-align: left;
    padding: 5px 0;
    line-height: 23px;
    text-align: left;
}
h6{
    font-weight: 700;
    font-size: 18px;
    color: var(--colorSecondary);
    text-align: left;
}
</style>