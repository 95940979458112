<template>
    <div class="row justify-content-center margin-top row__width">
        <div class="col-9 col-lg d-flex justify-content-center align-items-center px-0 max-width-filtros" :class=" filtrosMapa ? 'filtrosDesplegados' : '' || mapaDesplegado ? 'max-width-mapa' : ''">
            <button class="contenedor" type="button" @click="funcionMostrarFiltros">
                <img src="../../img/home-icons/filtros.svg" alt="">
                <h3 v-if="filtrosDesplegados" >Ocultar Filtros</h3>
                <h3 v-else>{{$t('message.showFilters')}}</h3>
                
                <span><img src="../../img/home-icons/ver-mas.svg" alt=""></span>
            </button>
        </div>
        <div class="col-lg d-flex justify-content-center align-items-center d-none d-lg-flex" :class="filtrosMapa ? 'filtrosDesplegados2' : '' || mapaDesplegado ? 'col-lg justify-content-lg-start max-width-mapa2' : 'col-lg'">
            <div class="contenedor2 row__width">
                <h3>{{$t('message.search')}}</h3>
                <form class="w-100 d-flex justify-content-between" @submit.prevent v-on:keydown.enter="inputValue">
                    <input type="text" :placeholder="$t('message.placeholderSearch')" @submit.prevent v-model.trim="X">
                    <button type="button" @click="inputValue"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                </form>
            </div>
        </div>
        <div class="col-2 col-lg d-flex justify-content-center align-items-center desk-max-width">
            <button type="button" @click="funcionMapa"><img src="../../img/home-icons/ubicacion-icon.svg" alt=""></button>
        </div>
        <!------>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    import { ref } from 'vue';
    import axios from 'axios';
    
    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado, filtrosMapa, reSearch} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        funcionMostrarFiltros: '',
        funcionMapa:'',
        texto: ''
    })

    const searchValue = ref('')
    const X = ref('')

    if (sessionStorage.getItem('searchValue')){
        //console.log(sessionStorage.getItem('searchValue'))
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
    }

    //Seteo el parametro de busqueda
    const inputValue = () => {
        searchValue.value = X.value
        sessionStorage.setItem('searchValue', JSON.stringify(searchValue.value))
        reSearch.value = !reSearch.value
        return {reSearch}
    }
    
</script>

<style lang="scss" scoped>
.margin-top{
    margin-top: 95px;
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 40px;
    height: 66px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    h3{
        color: #374557;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 10px;
        
    }
    span{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 50px;
        width: 50px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bgPrincipal);
        img{
            max-height: 20px;
        }
    }
    .azul{
        position: absolute;
        left: 7px;
        height: 60px;
        width: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colorPrimary); 
    }
    .mapa{
        padding-left: 23%;
    }
    
}
.desk-max-width{
    button{
        border: 0;
        background-color: transparent;
        outline: 0;
        &:focus{
            outline: 0;
        }
    }
}
@media (min-width: 992px) {
    .max-width-filtros{
        max-width: 20%;
    }
    .desk-max-width{
        max-width: 5%;
    }
    .margin-top{
        margin-top: 35px;
    }
    .max-width-mapa{
        max-width: 28%;
    }
    .max-width-mapa2{
        max-width: 63%;
        margin-right: 10px;
    }
    .filtrosDesplegados{
        max-width: 23%;
    }
    .filtrosDesplegados2{
        max-width: 70%;
    }
}
.contenedor2{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 50px;
    min-height: 66px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
    h3{
        color: var(--colorSecondary);
        font-size: 18px;
        font-weight: 500;
        padding: 0px 0px;
        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 15px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
    }
    button{
        position: absolute;
        right: 10px;
        top: 6px;
        border: 0;
        min-height: 53px;
        width: 53px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
    
}

</style>