<template>
    <div class="row separador tipo__reunion">
        <div class="col-12 d-flex justify-content-start align-items-center">
            <h2>{{ $t('message.chooseMeeting') }}</h2>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <input type="checkbox" :checked="false" id="hola" v-model.trim="information.meeting">
            <label for="hola">
                <p class="left">{{ $t('message.individualMeeting') }}</p>
                <p class="right">{{ $t('message.groupMeeting') }}</p>
            </label>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'
const information = inject('information')
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 20px;
    color: var(--colorSecondary);
}
.tipo__reunion{
    label{
        height: 55px;
        width: 100%;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 20px 0px;
        cursor: pointer;
        &::before{
            content: '';
            position: absolute;
            border: 2px solid var(--colorPrimary);
            height: 80%;
            width: 52%;
            border-radius:  28px;
            left: 3%;
            transition: all 0.5s;
        }
        p{
            margin: 0 5%;
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
        }
        .left{
            color: var(--colorPrimary);
            font-weight: 600;
        }
        .right{
            color: #B8C1DB;
        }
    }
    input{
        display: none;
    }
    input:checked + label{
        &::before{
            left: 50%;
            width: 48%;
        }
        p{
            color: var(--colorPrimary);
            font-weight: 600;
        }
        .left{
            color: #B8C1DB;
            font-weight: 400;
        }
    }
    @media (min-width: 992px) {
        label{
            p{
                margin: 0 7%;
                font-size: 16px;
            }
        }
    }
}
</style>