<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center align-items-center flex-column">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <TituloYSubtituloRegistro :titulo="$t('message.confirmTelephone')" 
                    :subtitulo="$t('message.trainCode')" 
                    :variableAdiccional="$t('message.numCode')"
                    :desaparecerSubtitulo="true"
                    :color="true"/>
                <div class="row input-codigo justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-center row__width3">
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1" autofocus="true" >
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1">
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1">
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1">
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1">
                            </div>
                            <div class="col-2 d-flex justify-content-center align-items-center">
                                <input type="number" maxlength="1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-11 col-md-5 col-lg d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <div class="row input-codigo justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center no-codigo">
                        <a href="">{{ $t('message.receiveCode') }}</a>
                    </div>
                    <MasOpcionesCodigoListado/>
                </div>
                <div class="row justify-content-center mt-4 ">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <div class="row justify-content-center row__width row__width2">
                            <BTNFacebook :textoBTNFacebook="$t('message.loginFacebook')"/>
                            <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')" class="mb-2"/>
                        </div>
                    </div>
                    <IniciarEnlace :cuenta="$t('message.iHaveAccount')" :enlace="$t('message.login')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../../components/Navs/Navbar.vue';
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import IniciarEnlace from '../../components/Enlaces/IniciarEnlace.vue';
import MasOpcionesCodigoListado from "../../components/Iniciar/MasOpcionesCodigoListado.vue";
import { provide, ref, computed, onUnmounted, onMounted } from "vue";

const opciones = ref([
    {titulo: "Mensaje de texto (SMS)", subtitulo: 'Te vamos a enviar un código', check: true},
    {titulo: "Whatsapp", subtitulo: 'Te vamos a enviar un código', check: false},
    {titulo: "Llamada", subtitulo: 'Te vamos a llamar para darte un código', check: false},
])

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>
body{
    min-height: 100%;
}
.input-codigo{
    input[type=number]{
        border: 2px solid #E4E4E4;
        border-radius: 10px;
        width: 50px;
        height: 50px;
        outline: 0;
        background-color: transparent;
        text-align: center;
        &:focus{
            border-color: var(--colorPrimary);
        }
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] { appearance:textfield; }
}
.no-codigo{
    a{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-top: 20px;
        text-decoration: none;
    }
}
</style>