<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center py-4 px-0 asesorias__profesionales">
            <img src="../../img/home-icons/cursos-icons/consultoria-online-ejemplo.png" alt="">
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center pt-4">
            <div class="row justify-content-center row__width" >
                <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                    <h2 class="v2">{{$t('message.titleConsulting')}}</h2>
                    <h4>{{$t('message.subtitleConsultancies')}}<strong>{{$t('message.masterClassPhotographers')}}</strong></h4>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-start pt-4 lista">
                    <h5>{{$t('message.canGet')}}</h5>
                    <ul>
                        <li v-for="(item, id) in listado">{{listado[id].titulo}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import { mapState } from "vuex";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        funcionBTNVioleta: '',
        BTNVioletaTexto: '',
    })

    const listado = ref([
        {titulo: 'Consultorías individuales desde 15 minutos hasta 60 minutos.'},
        {titulo: 'Asesorías grupales desde 60 minutos hasta 120 minutos'},
    ])
</script>

<style lang="scss" scoped>
.asesorias__profesionales{
    h2{
        font-weight: 700;
        font-size: 28px;
        color: var(--colorSecondary);
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            border-radius: 5px;
            width: 15%;
            background-color: var(--colorSecondary);
        }
    }
    .v2, .v3{
        font-size: 26px;
        text-align: left;
        &::before{
            display: none;
        }
    }
    h4{
        font-size: 16px;
        font-weight: 400;
        color: #697891;
        text-align: left;
        padding: 10px 0;
        line-height: 27px;
        strong{
            color: inherit;
            font-size: inherit;
        }
    }
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary); 
        padding-bottom: 10px;
    }
    .lista{
        ul{
            padding: 0;
            li{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                position: relative;
                padding-left: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 10px;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/home-icons/cursos-icons/tick-listado-asesorias.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 15px;
                    width: 15px;
                    left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
    .nav-tabs{
        height: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 28px;
        button{
            border: 0;
            border-radius: 50%;
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            height: 85%;
            width: 90%;
            transition: color .1s ease-in-out;
            &:focus{
                outline: 0;
            }
        }
        .active{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 700;
            border-radius: 28px;
        }
    }
    .spanV2{
        margin-bottom: 10px;
    }
    p{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-left: 10px;
    }
}
</style>