<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4">
        <div class="row justify-content-center row__width width">
            <div class="col-12 d-flex justify-content-between align-items-center px-0" v-if="information">
                <h3>{{ $t('message.description') }}</h3>
                <p>{{information.description.length}}</p>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                <!-- <span>
                    <h6>B</h6>
                </span>
                <textarea name="" id="" placeholder="Escribir aqui" cols="30" rows="10" maxlength="400" v-model="information.description"></textarea> -->
                <div class="w-100 my-5">
                    <quill-editor 
                        class="w-100" 
                        contentType="html" 
                        :toolbar="editorModules" 
                        theme="snow" 
                        style="height: 300px; overflow-y: auto;"
                        content-style="height: 100%;"
                        :options="{ scrollingContainer: '.ql-editor' }" 
                        v-model:content="information.description">
                    </quill-editor>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"    
import { storeToRefs } from "pinia";
import { inject, createApp, ref, watch } from "vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp()
app.component('QuillEditor', QuillEditor)

const information = inject('information')
const variable = ref()

const editorModules = {
  toolbar: [
    [{ header: [] }], // Encabezados de h1 a h6
    ['bold', 'italic', 'underline'],  
    [{ list: 'ordered' }, { list: 'bullet' }], // Listas
    ['blockquote', 'code-block'],        // Bloque de cita y bloque de código
    [{ script: 'sub' }, { script: 'super' }], // Superíndice y subíndice
    [{ align: [] }],                     // Alineación
    ['link', 'image', 'video'],          // Insertar link, imagen y video
    [{ color: [] }, { background: [] }], // Colores de texto y fondo
    ['clean']                            // Limpiar formato
  ]
};



</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorPrimary);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
.width{
    width: -webkit-fill-available;
}
textarea{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 20vh;
    padding-top: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        
        color: #697891;
    }
    
}
span{
    background-color: #E4E4E4;
    height: 40px;
    width: 99.5%;
    position: absolute;
    top: 2px;
    border-radius: 10px 10px 0 0 ;
    padding: 10px 15px;
    h6{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
    }
}

</style>