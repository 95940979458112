<template>
    <!--- Configuración académica --->
    <div class="row justify-content-center contenedor">
        <div class="col-12 col-lg d-flex justify-content-start align-items-center px-0 filtrosMobile">
            <!-- <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="setView(0)">
                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
            </button> -->
            <h5>Configuracion del plan</h5>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center  px-0 mt-4">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg d-flex justify-content-start align-items-center mb-4 px-0 filtrosMobile">
                    <h4>Configuración</h4>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 academicas " >
                    <div class="row justify-content-start row__width">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center ofertas">
                            <div class="row row__width justify-content-between py-4">
                                <div class="col-6 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">Nombre del plan</h4>
                                    <input type="text">
                                </div>
                                <div class="col-6 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                    <input type="text">
                                </div>
                                <div class="col-6 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                    <input type="text">
                                </div>
                                <div class="col-6 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                    <input type="text">
                                </div>
                            </div>
                            <div class="row row__width justify-content-end pb-4">
                                <div class="col-5 col-lg-2 d-flex justify-content-center align-items-center guardar">
                                    <button type="button">{{ $t('message.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue"
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const urlFetchNotes = '/api/academic/get/config'
const urlEditNotes = '/api/academic/edit'

const quizValue = ref(null)
const certValue = ref(null)

const views = inject('views')
const buttons = inject('buttons')

const fetchNotes = async() => {
    try {
        await axios.get(url+urlFetchNotes).then((result) => {
            //console.log(result)
            if (result.status == 200){
                quizValue.value = result.data.quiz_min
                certValue.value = result.data.cert_min
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchNotes()

const editNotes = async(quiz, cert) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlEditNotes, {quiz_min: quiz, cert_min: cert}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchNotes()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = true
    return {buttons, tabs}
}
</script>

<style lang="scss" scoped>
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 20px;
        }
        h4{
            color: #2E4765;
            font-weight: 600;
            font-size: 18px;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        min-height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 400;
            font-size: 14px;
            padding-top: 10px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    } 
}
</style>