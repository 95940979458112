<template>
    <div class="col-12 d-flex justify-content-center align-items-center slide px-0">
        <div class="row justify-content-center align-items-start row__width py-3">
            <div class="col d-flex justify-content-center align-items-center position-relative imagenes pe-0">
                <img src="../../img/home-icons/ejemplo.png" alt="" class="ejemplo w-100 h-100">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <div class="row justify-content-center row__width descripcion">
                    <div class="col-12 d-flex justify-content-start align-items-center pt-3 pb-2 px-0">
                        <h3>Producto adicional</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center pb-3 pb-lg-1 px-0">
                        <h4>Lorem ipsum dolor sit amet.</h4>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <h5>$100</h5><h3>$80</h3>
                    </div>
                    <!-- <BTNPrimario url="/perfil-marca/" :slug="slug" :textoBTNPrimario="$t('message.addCart')" class="px-0"/> -->
                    <BTNPrimario :textoBTNPrimario="$t('message.addCart')" class="px-0"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    //Recibe los parametros de busqueda del componente padre
    const props = defineProps({
        rName: '',
        rDescription: '',
        rImage: '',
        rLocation: '',
        rSlug: '',
        rRates: '',
        rVerified: ''
    })

    //Setea las variables con los resultados para su uso
    const name = props.rName
    const description = props.rDescription
    const image = props.rImage
    const location = props.rLocation
    const slug = props.rSlug
    const rates = props.rRates
    const verified = props.rVerified
</script>

<style lang="scss" scoped>
.proveedores{
    .slide{
        height: 170px;
    }
    h6{
        font-weight: 700;
        font-size: 20px;
        color: var(--colorSecondary);
        text-align: left;
        @media (min-width: 992px) {
            padding-left: 3vw;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        text-decoration: line-through;
        text-align: left;
    }
    h3{
        font-size: 22px;
        font-weight: 400;
        color: var(--colorSecondary);
        text-align: left;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        text-align: left;
    }
    .imagenes{
        width: 158px;
        max-width: 158px;
        min-height: 150px;
        img{
            box-shadow: 0px 3px 3px #00000014;
            border-radius: 8px;
            height: 100%;
            min-height: 140px;
        }
    }
    a{
        max-height: 40px;
        margin: 10px 0;
    }
    @media (min-width: 992px) {
        .max-width{
            max-width: 35%;
        }
        .ejemplo{
            height: 85%;
        }
    }
}
</style>