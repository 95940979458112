

<template>
    <div class="row error justify-content-center">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
            <img src="../../img/error-icons/error-404.svg" alt="">
            <h2>ERROR 404</h2>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.error{
    margin-top: 40px;
    h2{
        color: #1F1F39;
        font-size: 30px;
        font-weight: 700;
        padding: 15px 0 30px 0;
    }
    @media (min-width: 992px) {
        h2{
            font-size: 45px;
            padding: 30px 0 10px 0;
        }
        img{
            height: 25vh;
        }
    }
}
</style>