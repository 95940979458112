<template>
    <div class="col-12 col-lg d-flex justify-content-center align-items-center caja">
        <div class="row justify-content-center">   
            <div class="col-12 d-flex justify-content-between align-items-center">
                <h2>PAGO MENSUAL</h2><img src="../../img/plus-icons/pago-unico.svg" alt="">
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                <h3>${{ formatCurrency(convertPrice(1), selectedCurrency) }}</h3>
                <h4> Suscríbete desde ${{ formatCurrency(convertPrice(0), selectedCurrency) }} por 14 días y después únete por ${{ formatCurrency(convertPrice(1), selectedCurrency) }} mensuales.</h4>
            </div>
            <BTNPrimario :route="'/pago-plus'" :textoBTNPrimario="$t('message.joinNow')"/>
        </div>
    </div>
    <div class="col-12 col-lg d-flex justify-content-center align-items-center caja">
        <div class="row justify-content-center">   
            <div class="col-12 d-flex justify-content-between align-items-center">
                <h2>PAGO ANUAL</h2><img src="../../img/plus-icons/pago-anual.svg" alt="">
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                <h3>${{ formatCurrency(convertPrice(1), selectedCurrency) }}</h3>
                <h4>Suscríbete desde ${{ formatCurrency(convertPrice(0), selectedCurrency) }} por 14 días y después únete por ${{ formatCurrency(convertPrice(1), selectedCurrency) }} anuales.</h4>
            </div>
            <BTNPrimario :route="'/pago-plus'" :textoBTNPrimario="$t('message.joinNow')"/>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, provide } from "vue";
import BTNPrimario from '../Botones/Primario.vue';

let formatCurrency = inject('formatCurrency')
let convertPrice = inject('convertPrice')
let selectedCurrency = inject('selectedCurrency')

const props = defineProps({
    titulo: "",
    src: "",
    precio: "",
    subtitulo: "",
})
</script>

<style lang="scss" scoped>
.planes{
    .caja{
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #E4E4E4;
        border-radius: 30px;
        min-height: 330px;
        margin-bottom: 30px;
        padding: 30px;
        &:first-child{
            margin: 30px 0;
        }
        h2{
            font-size: 30px;
            font-weight: 700;
            color: var(--colorPrimary);
        }
        h3{
            font-size: 42px;
            font-weight: 700;
            color: var(--colorSecondary);
            padding: 20px 0;
        }
        h4{
            font-size: 15px;
            font-weight: 400;
            color: #7D93B2;
            padding-bottom: 30px;
            text-align: left;
        }
        @media (min-width: 992px) {
            margin: 30px 0;
            max-width: 48%;
            h3{
                font-size: 50px;
            }
        }
    }
}
</style>