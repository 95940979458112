<template>
    <div class="row justify-content-center align-items-center my-0 row__width" v-if="sliderLength >= 1">
        <swiper :class="filtrosDesplegados == true ? 'd-none' : '' || mapaDesplegado == true ? 'd-none' : '' "
            :navigation="{
                nextEl: '#nextSliderAsesorias',
                prevEl: '#prevSliderAsesorias'
            }"
            :pagination="{
                el: '#pagAsesorias',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle',
            }"
            :slidesPerView="3"
            :slidesPerGroup="3"
            :modules="modules"
            :breakpoints="{
                375: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1080: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1366: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1920: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }" 
            @swiper="onSwiper"
            class="mySwiper swiper-asesores"  
            >
            <swiper-slide v-for="(slide, id) in sliderLength"  class="d-flex justify-content-center align-items-center pb-2">
                <div class="col-11 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center caption row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center filtro  px-0">
                            <img src="../../img/home-icons/cursos-icons/asesorias-ejemplo.svg" alt="" class="ejemplo">
                            <div class="row justify-content-center row__width position">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width row__slide">
                                        <div class="col-12 d-flex justify-content-center align-items-center arriba px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6 style="margin-top: 5px;">{{$t('message.rating')}}</h6>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width position-relative">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center creador">
                            <img :src="childProducts[id].image" alt="" class="ejemplo2">
                            <div class="d-flex flex-column justify-content-center align-items-center pt-2">
                                <h2>{{ childProducts[id].names[lang] }} {{ childProducts[id].lastName }} <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" v-if="verified == true"></h2>
                                <h4>{{ childProducts[id].category.description[lang] }}</h4>
                                <h5>{{ childProducts[id].description[lang] }}</h5>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-3 position2">
                            <AsesoriasReserva url="/reservar-asesor/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.reserve')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <swiper :class="filtrosDesplegados == true ? 'd-lg-flex mt-0' : '' || mapaDesplegado == true ? 'd-lg-flex mt-0' : '' "
            :slidesPerView="3"
            :slidesPerGroup="3"
            :navigation="{
                nextEl: '#nextSliderAsesorias',
                prevEl: '#prevSliderAsesorias'
            }"
            :pagination="{
                el: '#pagAsesorias',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :modules="modules"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerGroup: 2,
                },
                992: {
                    slidesPerGroup: 3,
                },
                1920: {
                    slidesPerGroup: 3,
                },
            }"
            class="mySwiper d-none" >
            <swiper-slide v-for="(slide, id) in sliderLength" class="d-flex justify-content-center align-items-center pb-2">
                <div class="col-11 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center caption row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center filtro  px-0">
                            <img src="../../img/home-icons/cursos-icons/asesorias-ejemplo.svg" alt="" class="ejemplo">
                            <div class="row justify-content-center row__width position">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width row__slide">
                                        <div class="col-12 d-flex justify-content-center align-items-center arriba px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <h6><img src="../../img/home-icons/cursos-icons/rate.svg" alt="">{{$t('message.rating')}}</h6>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center creador">
                            <img :src="childProducts[id].image" alt="" class="ejemplo2">
                            <div class="d-flex flex-column justify-content-center align-items-center pt-2">
                                <h2>{{ childProducts[id].names[lang] }} {{ childProducts[id].lastName }} <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" v-if="verified == true"></h2>
                                <h4>{{ childProducts[id].category.description[lang] }}</h4>
                                <h5>{{ childProducts[id].description[lang] }}</h5>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <AsesoriasReserva url="/reservar-asesor/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.reserve')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Navigation, Grid, Pagination, column, activeIndex } from "swiper";
    
    import AsesoriasReserva from "../Botones/AsesoriasReserva.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import { ref, watch, onMounted, nextTick, watchEffect} from 'vue';
    import { useI18n } from "vue-i18n";
    import axios from 'axios';

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
    const {} = general;

    let modules = ref([Navigation, Pagination])

    const {locale} = useI18n({ useScope: 'global' })
    const lang = ref()
    const X = ref('')

    const url = process.env.VUE_APP_MEILISEARCH_URL
    const urlApi = '/multi-search'

    //Setea parametros de busqueda para el post
    const search = ref({
        queries:[{
            indexUid: 'brands',
            q:''
        }]
    })
    //Precarga parametros de resultados de busqueda
    const childProducts = ref(0)
    //Setea las variables con los resultados para su uso
    // const name = childProducts 
    // const lastName = childProducts 
    // const description = childProducts 
    // const descriptionAdviser = childProducts
    // const image = childProducts 
    // const imageBanner = childProducts 
    // const slug = childProducts 
    // const rates = childProducts 
    const verified = childProducts 
    const sliderLength = ref(0)

    //Toma el elemento swiper
    const onSwiper = (swiper) => {
        //console.log(swiper)
        swiper.pagination.render()
    };

    //Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
    const setLang = () => {
        lang.value = locale.value
        //console.log(lang.value)
    }
    setLang()

    const fetchSearch = async () => {
        if (sessionStorage.getItem('searchValue')){
            //console.log(sessionStorage.getItem('searchValue'))
            X.value = JSON.parse(sessionStorage.getItem('searchValue'))
            search.value.queries[0].q = X.value
        }
        setLang()
        try {
            await axios.post(url+urlApi, search.value).then((result) => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                //console.log(result.data.results)
                sessionStorage.removeItem('searchValue')
            })
        }
        catch (error) {
        // console.log(error)
        }
    }
    fetchSearch()

    //Hago un post del usuario x
    watch(reSearch,async () => {
        if (sessionStorage.getItem('searchValue')){
            //console.log(sessionStorage.getItem('searchValue'))
            X.value = JSON.parse(sessionStorage.getItem('searchValue'))
            search.value.queries[0].q = X.value
        }
        setLang()
        try {
            await axios.post(url+urlApi, search.value).then((result) => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                //console.log(result.data.results)
                sessionStorage.removeItem('searchValue')
            })
        }
        catch (error) {
        // console.log(error)
        }
    })
</script>

<style lang="scss" scoped>
.mySwiper {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 10px 5px 0px 5px;
    margin: 0px 0;
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px 1px #00000029;
    border-radius: 15px;
    opacity: 1;
    max-width: 397.5px;
    height: 440px;
    padding-bottom: 20px;
    .caption{
        position: relative;
        margin: 10px 0px;
        img{
            max-width: 95%;
        }
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            z-index: 3;
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
                padding-left: 5px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                .arriba{
                    position: absolute;
                    top: 10%;
                }
                .abajo{
                    position: absolute;
                    bottom: 10%;
                }
            }
            
        }
        .filtro{
            position: absolute;
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                width: 95%;
                height: 40%;
                border-radius: 0px 0px 15px 15px;
                background: linear-gradient(to top,  #081026 , #252E4800);
                filter: opacity(1);
                z-index: 2;
            }
        }
    }
    .descripcion{
        position: relative;
        height: 100%;
        z-index: 2;
        h2{
            font-weight: 700;
            font-size: 22px;
            color: #374557;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 14px;
            padding: 10px 0;
            text-align: center;
            max-width: 70%;
            line-height: 23px;
        }
        h5{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            padding: 5px 0;
            text-align: center;
            line-height: 23px;
        }
        .position2{
            position: absolute;
            bottom: 0;
        }
    }
    .ejemplo{
        width: 100%;
        height: 165px;
        object-fit: cover;
    }
    .ejemplo2{
        width: 50%;
        height: 125px;
        border-radius: 50%;
        max-width: 125px;
        border: 2.5px solid #FFFFFF ;
    }
}
</style>