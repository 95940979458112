<template>
    <div class="row justify-content-center my-5 row__width">
        <div class="col-12 d-flex justify-content-start align-items-center panel">
            <div class="d-flex flex-column justify-content-center align-items-start contenedor_panel">
                <h2>{{ $t('message.discoverShops') }}</h2>
                <a href="">
                    {{ $t('message.visitShop') }}
                </a>
            </div>
            <img src="../../img/home-icons/panel-img/1.svg" alt="">
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.panel{
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    h2{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
        text-align: left;
    }
    a{
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 600;
        min-height: 50px;
        position: relative;
        text-decoration: none;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 70%;
        margin-top: 10px;
    }
    img{
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .contenedor_panel{
        max-width: 70%;
    }
    @media (min-width: 992px) {
        height: 250px;
        padding: 30px;
        h2{
            font-size: 35px;
        }
        h3{
            font-size: 15px;
            padding-bottom: 5%;
        }
        a{
            width: 60%;
            font-size: 16px;
            margin-left: 5%;
            &::before{
                left: -15%;
                top: -35%;
                width: 30%;
                height: 100%;
                background-size: 100% 100%;
            }
        }
        img{
            min-width: 28%;
        }
    }
}
.row__width{
    @media (min-width: 992px) {
        padding: 5% 0;
    }
}
</style>