<template>
    <div class="row justify-content-center detalle row__width">
        <div class="col-12 d-flex justify-content-start align-items-center pt-1 px-0">
            <h6> {{ $t('message.titlePurchasePlus') }} </h6>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-start pt-3 pb-4  px-0">
            <h5> {{ $t('message.subtitlePurchasePlus') }} </h5>
            <h5> {{ $t('message.subtitlePurchasePlus2') }} </h5>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center py-3 px-0 border_bottom">
            <h2>{{$t('message.total')}}</h2>
            <div class="d-flex flex-column justify-content-center align-items-end">
                <h4 class="d-none d-lg-flex">MX $15.000</h4>
                <h3>MX $10.000</h3>
            </div>
        </div>
        <BTNPrimario :textoBTNPrimario="$t('message.finishBuy')" class="mt-5" @click="pay(selectedMethod)"/>
        <CondicionesCheckoutPlus class=""/>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/Primario.vue";
    import CondicionesCheckoutPlus from "../Condiciones/CondicionesCheckoutPlus.vue";
    import { ref, inject, provide } from 'vue';
    import axios from 'axios';

    let selectedMethod = inject('selectedMethod')
    let pay = inject('pay')
</script>


<style lang="scss" scoped>
.detalle{
    background-color: var(--bgPrincipal);
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 20px;
    padding: 15px;
    h2{
        font-weight: 600;
        font-size: 28px;
        color: var(--colorSecondary);
        padding-top: 15px;
        padding-bottom: 10px;
        @media (min-width: 992px) {
            font-weight: 700;
        }
    }
    h3{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    h5{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        padding-bottom: 10px;
    }
    h6{
        font-weight: 600;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h4{
        font-size: 18px;
        font-weight: 500;
        color: #B8C1DB;
        text-decoration: line-through;
    }
    p{
        font-size: 20px;
        font-weight: 500;
        color: var(--colorSecondary);
    }
    .border_bottom{
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-top:1px solid rgba(0, 0, 0, 0.1) ;
    }
    @media (min-width: 992px) {
        padding: 30px;
        min-height: 73vh;
        h5{
            font-size: 18px;
        }
    }
}
</style>