import { ref } from 'vue';
import axios from 'axios';
<template>
    <div class="col-12 col-lg-5 d-flex justify-content-between align-items-center buttons px-0">
        <div class="contenedor__buscar">
            <form class="w-100" action="post" @submit.prevent="fetchSearch">
                <input type="text" placeholder="Buscar" v-model.trim="search.params">
                <button><img src="../../img/home-icons/lupa.svg" alt=""></button>
            </form>
        </div>        
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { ref } from 'vue';

    const url = ''
    const urlApi = ''

    const search = ref({
        params:'',
    })
    //Hago un post del usuario 
    async function fetchSearch() {
        try {
        await axios.post(url+urlApi, search.value).then((result) => {
            console.log()
        })
        router.push('/')
        }
        catch (error) {
        console.log(error)
        }
    }
</script>

<style lang="scss" scoped>
.contenedor__buscar{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    background-color: var(--bgPrincipal);
    height: 55px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
   
    @media (min-width: 992px) {
        border-radius: 50px;
        padding: 22px 15px;
        height: 50px;
    }
    .img{
        padding: 0 20px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 18px;
        font-weight: 600;
        padding: 5px 0px;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 5px;
        }
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        @media (min-width: 992px) {
            color: #697891;
            font-size: 14px;
            width: 80%;
        }
    }
    button{
        position: absolute;
        right: 7px;
        top: 3px;
        border: 0;
        height: 45px;
        width: 45px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
            height: 22px;
            margin-bottom: 2px;
        }
        @media (min-width: 992px) {
            right: 5px;
        }
    }
    .border-left{
        border-left: 1px solid rgba(184, 193, 219, 0.4);
    }
    
}
</style>