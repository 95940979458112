<template>
    <form action="post" @submit.prevent="fetchValidateMail" class="w-100">
        <UsuarioOMailRegistroMail/>
        <GrisInicioSubmit :textoBtn="$t('message.continue')"/>
    </form>
</template>

<script setup>
import GrisInicioSubmit from '../Botones/GrisInicioSubmit.vue';
import UsuarioOMailRegistroMail from '../Inputs/UsuarioOMailRegistroMail.vue';
import { useRouter } from 'vue-router';
import axios from "axios"
// Importo mi método
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { ref, provide, computed } from 'vue';

import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'

const { t }  = useI18n()
const msg = computed(() => t('message'))

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const users = useUsersStore();
const {cambioHeader, usuariosRegistro, mailValido} = storeToRefs(users);
const {validarMail} = users;

const router = useRouter();

const url = process.env.VUE_APP_BASE_URL
const validateEmail = '/api/validate/email/'; 

const validez = ref({
    validezMail: false,
})
provide('validez', validez)
const validarEmail = () => {
    if (usuariosRegistro.value.email !== '' && validarMail.test(usuariosRegistro.value.email)){
        validez.value.validezMail = 'input_valido'
    }
    else {
        validez.value.validezMail = 'input_invalido'
    }
}

async function fetchValidateMail(){
    //console.log(usuariosRegistro.value)
    validarEmail()
    if(validez.value.validezMail == 'input_valido'){
        let mail = usuariosRegistro.value.email
        await axios.get(url+validateEmail+mail).then((result) => {
            //console.log(url+validateEmail+mail)
            if(result.data.data === 'true'){
                //pusheo al registro 
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: t('message.validData')
                })
                setTimeout(() => {
                    router.push('/registro-terminar')
                }, 4000);
                mailValido.value = true
            }
            else{
                mailValido.value = false
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'error',
                    title: t('message.invalidData')
                })
            }
        })
    }else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: t('message.invalidData')
        })
    }
}

</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>