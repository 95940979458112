<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3 px-0">
        <h3>{{ $t('message.deadline') }}</h3>
        <div class="position">
            <select name="" id="" v-model="actualActivity.refund">
                <option :value="7">7 dias</option>
            </select>
            <i></i>
        </div>
        <p>En México, el mínimo es de 7 días</p>
        <!-- :placeholder="userData.full_name !== undefined ? userData.full_name : $t('message.user')" -->
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { inject } from "vue";
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";

let actualActivity = inject('actualActivity')

const user = useUsersStore();
const {datosUsuario, headers, idUser, beforeInfomation, userData} = storeToRefs(user);
const {} = user;


const general = useGeneralStore();
const {textos, inputs} = storeToRefs(general);
const {} = general;

const props = defineProps({
    titulo: {
        type: String,
        default: ''
    },
})
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: #000;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 7px 0px;
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    &:focus + i{
        transform: rotate(90deg);
        transition: all ease 0.2s;
    }
}
.position{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    i{
        width: 10px;
        height: 15px;
        background-image: url('../../../img/subir-productos-icons/cursos-icons/arrow-abajo-gris.svg');
        background-repeat: no-repeat;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 4%;
        transform: rotate(-90deg);
        transition: all ease 0.2s;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
</style>