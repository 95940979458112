<template>
    <button class="btn__primario" type="button" @click="stepMinus">
        <i></i> Anterior
    </button>
</template>

<script setup>
import { inject, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import axios from 'axios';
import { useRouter } from 'vue-router';
const router = useRouter();

const type = inject('type')
const modality = inject('modality')
const product = inject('product')
let certificate = inject('certificate')
let alert = inject('alert')

const users = useUsersStore();
const { } = storeToRefs(users);
const { url } = users;

const urlCreateProduct = '/api/product/create'
const urlConfigCertif = '/api/certificate/create/'

let step = inject('step')

const stepMinus = () => {
    if (step.value == 1) {
        router.back(-1)
    }
    else {
        step.value = step.value - 1
    }
    alert.value = false
}
</script>

<style lang="scss" scoped>
i {
    min-height: 25px;
    min-width: 27px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    background-image: url('../../img/subir-productos-icons/arrow-azul.svg');
    transform: rotate(360deg);
    margin-right: 10px;
}

button {
    padding-right: 25px;

    &:focus,
    &:hover {
        i {
            background-image: url('../../img/subir-productos-icons/arrow-blanco.svg');
            transform: rotate(180deg);
        }
    }
}</style>