<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center modulos px-0">
        <modulos v-for="(modulo, id) in modulosData" :titulo="modulosData[id].titulo" :id="id"/>
        <!-- <button class="btn_none row quiz row__width justify-content-center w-100 px-0" @click="cambiarPagina">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 div ">
                <div class="row justify-content-center row__width row__back ps-4">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                        <h3>Quizz</h3>
                        <h4>Cuestionario | de 10 min</h4>
                    </div>
                </div>
            </div>
        </button> -->
    </div>
</template>

<script setup>
import { ref } from "vue";
import modulos from "../Modulos/ModulosViendoMobile.vue";
const quiz = ref(false)
const cambiarPagina = () =>{
    quiz.value = true
}
const modulosData = ref([
    {titulo: 'Módulo 1: Recién nacido'},
    {titulo: 'Módulo 2: Recién nacido'},
    {titulo: 'Módulo 3: Recién nacido'}
])
</script>

<style lang="scss" scoped>
.modulos{
    .accordion{
        width: 100%;
        button{
            border-radius: 10px;
        }
    }
}
.quiz{
    text-decoration: none;
    .div{
        position: relative;
        border: 1px solid #E4E4E4;
        h3{
            padding: 5px 0;
            font-weight: 600;
            font-size: 18px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h4{
            font-weight: 400;
            font-size: 16px;
            color: #374557;
            text-align: left;
        }
        &:focus, &:hover{
            border: 1px solid #E4E4E4;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                height: 100%;
                width: 5px;
                background-color: var(--colorPrimary);
            }
            h5{
                color: var(--colorSecondary);
                font-weight: 600;
            }
            h6{
                color: #374557;
            }
            .row__back{
                background-color: #F5F5F5;
            }
            i{
                background-image: url('../../img/viendo-icons/play-azul.svg');
            }
        }
        .row__back{
            height: 90px;
        }
    }
    .div-active{
        border: 2px solid #E4E4E4;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 5px;
            background-color: var(--colorPrimary);
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
        }
        h6{
            color: #374557;
        }
        .row__back{
            background-color: #F5F5F5;
        }
    }
}
</style>