<template>
    <div class="row justify-content-center row__width position-relative">
        <swiper
            :slidesPerView="1"
            :navigation="{
                nextEl: '#nextSliderMarca',
                prevEl: '#prevSliderMarca'
            }"
            :pagination="true"
            :loop="false"
            :breakpoints="{
                375: {
                    spaceBetween: -15
                },
                992: {
                    spaceBetween: -320
                },
                1100: {
                    spaceBetween: -380
                },
                1300: {
                    spaceBetween: -500
                },
                1400: {
                    spaceBetween: -470
                },
                1800: {
                    spaceBetween: -550
                },
                1800: {
                    spaceBetween: -600
                },
            }"
            class="mySwiper" >
            <swiper-slide v-for="(slide, id) in services" class="d-flex justify-content-start align-items-center">
                <div class="col-11 col-lg-7 d-flex flex-column justify-content-start justify-content-lg-center align-items-center productos">
                    <div class="row justify-content-center row__width">
                        <div class="col-2  d-flex justify-content-center align-items-start px-0">
                            <img :src="services[id].img" alt="" class="img">
                        </div>
                        <div class="col-10 col-lg-6 d-flex flex-column justify-content-center justify-content-lg-start align-items-start px-0">
                            <h5>{{services[id].slug }}</h5>
                            <h4>{{ services[id].name }}</h4>
                        </div>
                        <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center  mt-3 mt-lg-0">
                            <BTNPrimario :textoBTNPrimario="$t('message.seeCoupons')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script setup>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from "swiper/vue";

    // import required modules
    import { Navigation } from "swiper";
    import BTNPrimario from "../Botones/Primario.vue";
    import { ref } from 'vue';

    const slider = ref([
        {modalidad: 0, botonProducto: 0},
        {modalidad: 3, botonProducto: 1},
        {modalidad: 3, botonProducto: 2},
    ])

    const props = defineProps({
        services: {
            type: Object
        }
    })
    let lang = ref(JSON.parse(localStorage.getItem('lang')))
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.productos{
    background-color: #fff;
    box-shadow: 0px 10px 30px #868E9633;
    border-radius: 20px;
    max-height: max-content;
    padding: 15px;
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        text-align: left;
        width: 100%;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        text-align: left;
        width: 100%;
        padding-top: 5px;
    }
    .img{
        object-fit: cover;
    }
    @media (min-width: 992px) {
        min-height: 15vh;
    }
}
</style>