<template>
    <ul class="nav nav-tabs row justify-content-center row__width " id="myTab" role="tablist">
        <div class="scroll">
            <linksTabsHomeCursos v-for="(link, id) in tabHomeCursosResultados"
            :key="id" 
            :id="id"
            :titulo="tabHomeCursosResultados[id].titulo" 
            :active="tabHomeCursosResultados[id].active" 
            :display="tabHomeCursosResultados[id].displayM"
            :src="tabHomeCursosResultados[id].src"
            />
        </div>
        <div class="tab-content justify-content-center row__width px-0" id="myTabContent">
            <TabpaneHomePreset/>
        </div>
    </ul>
</template>

<script setup>
    import linksTabsHomeCursos from "./LinksTabsHomeCursos.vue";
    import TabpaneHomePreset from "./TabpaneHomePreset.vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {tabHomeCursosResultados} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.tabs{
    .tab-pane{
        padding: 0;
    }
    .detalle-tabs{
        .descripcion{
            padding: 0px 0px;
        }
        .before{
            position: relative;
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 70px;
                background: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 23%));
                filter: opacity(0.5);
                z-index: 2;
            }
        }     
    }
}
.perfil{
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 20px;
    padding: 15px;
}
.scroll{
    min-width: 100%;
    max-width: max-content;
    overflow: scroll;
    overflow-y: hidden !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    .col{
        min-width: 42%;
    }
    @media (min-width: 992px) {
        overflow: auto;
        .col{
            min-width: auto !important;
        }
    }
}
</style>