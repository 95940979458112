<template>
    <div class="row row justify-content-center row__width footer__carrito py-lg-5">
        <div class="col-12 d-flex justify-content-center align-items-center py-4 d-lg-none">
            <img src="../../img/carrito-icons/pago-seguro.svg" alt="">
            <h6>{{$t('message.paymentSecure')}}</h6>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center tarjetas">
            <img src="../../img/carrito-icons/garantia_masterclass.png" alt="">
        </div>
        <div class="col-12 col-lg-9 d-flex justify-content-center align-items-center px-lg-0">
            <p> {{ $t('message.guaranteeMC') }} </p>
        </div>
    </div>
</template>

<script setup>
  
</script>

<style lang="scss" scoped>
.footer__carrito{
  h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-left: 10px;
  }
  p{
    font-weight: 500;
    font-size: 15px;
    color: #2A2C30;
    padding: 20px 0px;
    text-align: left;
    @media (min-width: 992px) {
      font-size: 18px;
      color: var(--colorSecondary);
    }
  }
}
</style>