<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0">
        <div class="row justify-content-center row__width send_opinion">
            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 pb-4">
                <div class="row justify-content-center justify-content-lg-start row__width">
                    <button class="col-12 btn-none btn__primario" @click="show()">
                        Escribe tu opinión
                    </button>
                </div>
            </div>
            <template v-if="showSendOpinion">
                <template v-if="!idUser">
                    <div class="col-6 pe-2 d-flex flex-column justify-content-center align-items-start contenedor px-0">
                        <input
                            type="text"
                            v-model="firstName"
                            placeholder="Nombre"
                            required
                        />
                    </div>
                    <div class="col-6 ps-2 d-flex flex-column justify-content-center align-items-start contenedor px-0">
                        <input
                            type="text"
                            v-model="lastName"
                            placeholder="Apellido"
                            required
                        />
                    </div>
                    <div class="col-6 pe-2 d-flex flex-column justify-content-center align-items-start contenedor px-0">
                        <input
                            type="email"
                            v-model="email"
                            placeholder="Correo electrónico"
                            required
                        />
                    </div>
                    <div class="col-6 ps-2 d-flex flex-column justify-content-center align-items-start contenedor px-0">
                        <MazSelect
                            class="row__width"
                            v-model="country"
                            :placeholder="$t('message.country')"
                            color="info"
                            :options="countries"
                            name="country"
                            @update:model-value="updateInput()"
                            search
                            search-placeholder="Buscar en la lista"
                        />
                    </div>
                </template>
                <div class="col-12 d-flex justify-content-start align-items-center px-0 py-3">
                    <div class="row justify-content-center row__width align-items-center">
                        <div v-for="(label, n) in starLabels" :key="n" class="col-2 col star-label d-flex flex-column align-items-center" @click="setScore(n + 1)">
                            <img :src="n + 1 <= score ? require('../../img/home-icons/cursos-icons/rateV2.svg') : require('../../img/home-icons/cursos-icons/rate-gris.svg')" alt="star" />
                            <p class="pt-2" :class="{'active-label': n + 1 === score}">{{ label }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between align-items-center contenedor px-0">
                    <input
                        type="text"
                        v-model="comment"
                        placeholder="Escribe tu comentario"
                        required
                    />
                    
                    <button @click="submitReview" class="btn-submit">
                        Enviar
                    </button>
                </div>
            </template>
        </div>
    </div>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center opinion px-0">
        <!-- <OpinionYRespuesta/> -->
        <div class="row justify-content-start align-items-center row__width pb-3" v-if="opinions">
            <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center">
                <OpinionIndividual/>
            </div>
        </div>
        <div class="row justify-content-center align-items-center row__width pb-3" v-if="opinions">
            <!-- <OpinionIndividual/> -->
            <!-- <ResponderOpinion/> -->
            <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                <button v-if="currentPage < lastPage" @click="loadMore" type="button" class="mostrar_mas d-flex justify-content-center align-items-center">
                    <p>{{ $t('message.ShowMore') }}</p>
                </button>
            </div>
        </div>
        
        <!-- <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex flex-column justify-content-start align-items-center py-1 px-0">
                <div class="before" id="filtro5">
                    <p>
                        Es impresionante la pasión con la que cuenta Mónica Olvera y el conocimiento que tiene para explicar y resolver las dudas durante el curso, 
                        aprendí demasiado. Lo recomiendo completamente, ideal para comenzar a 
                        prepararte profesionalmente en el mundo de la fotografía de newborn por profesionales reconocidos.
                    </p>
                    <div id="desaparecer5" class="d-none">
                        <p>
                            Lo recomiendo completamente, ideal para comenzar a 
                            prepararte profesionalmente en el mundo de la 
                            fotografía de newborn por profesionales reconocidos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                <h6>¿Te gusto este comentario?</h6>
                <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarTexto5" disabled/>
            </div>
        </div>
        <div class="row justify-content-center row__width d-none d-lg-flex">
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarOpiniones" disabled/>
            </div>
        </div> -->
    </div>
    
    

</template>

<script setup>
import BTNMostrarMas from "../Botones/MostrarMas.vue";
import OpinionIndividual from "../Cursos/OpinionIndividual.vue";
import ResponderOpinion from "../Perfil/ResponderOpinion.vue";
import OpinionYRespuesta from "../Cursos/OpinionYRespuesta.vue";
import { ref, onMounted, inject, provide, onBeforeMount } from 'vue'
import { useUsersStore } from "../../stores/user";
import { useConfigStore } from "../../stores/config";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router';

import axios from 'axios'
import Swal from 'sweetalert2'
import MazSelect from 'maz-ui/components/MazSelect'
import countriesJson from '../../assets/countries.json'


const users = useUsersStore();
const {cambioHeader, idUser } = storeToRefs(users);
const { url } = users

const config = useConfigStore()
const { countries, states, cities } = storeToRefs(config)
const { fetchCountries, fetchStates, fetchCities } = config

const router = useRouter();
const route = useRoute()

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const country = ref()
const countriesList = ref()


const comment = ref('');
const score = ref(0);
const starLabels = ["Pésimo", "Malo", "Regular", "Bueno", "Excelente"];

const opinions = ref([])
const currentPage = ref(1); 
const lastPage = ref(1); 
const showSendOpinion = ref(false); 



const professional = inject('professional')
provide("opinions", opinions)

const updateInput = () => {
    console.log(country.value)
    //country.value = ''
}

const loadMore = () => {
    if (currentPage.value < lastPage.value) {
        fetchReviews(currentPage.value + 1); 
    }
};

const fetchReviews = async (page) => {
    console.log(opinions.value.data)
    const body = {
        reviewable_type: "PROFESSIONAL",
        reviewable_slug: professional.value.slug
    }
    try {
        const response = await axios.post(`${url}/api/public/professionals/profile/reviews/index?page=${page}`, body);
        console.log(response)
        if (page === 1) {
            opinions.value = response.data.data;
        } else {
            opinions.value.data = [...opinions.value.data, ...response.data.data.data]; // Si es otra página, concatenar los datos
        }
        console.log(opinions.value.data)
        currentPage.value = page;
        lastPage.value = response.data.data.last_page;
    } catch (error) {
        console.error('Error fetching the reviews statistics:', error);
    }
}


const show = () => {
    console.log(showSendOpinion.value)
    showSendOpinion.value = !showSendOpinion.value
    console.log(showSendOpinion.value)
}

onMounted(() => {
    fetchCountries()
    fetchReviews(1)
})

const setScore = (n) => {
  score.value = n;
}

onBeforeMount(() => {
    countriesList.value = countriesJson.map(country => ({
        label: country.name.common,
        value: country.cca2
    }));
    //console.log('Listo los paises')
    //console.log(countriesList.value)
})


const submitReview = async () => {
  if (comment.value === '' || score.value === 0) {
    Swal.fire({
      icon: 'error',
      title: 'Puntee y comente',
      timer: 1500,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
    });
    return;
  }

  // Validar campos de usuario no logueado
  if (!idUser.value) {
    if (firstName.value === '' || lastName.value === '' || email.value === '') {
      Swal.fire({
        icon: 'error',
        title: 'Complete los campos de nombre, apellido y correo electrónico',
        timer: 1500,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      });
      return;
    }
  }

  // Construir el cuerpo del request
  const body = {
    reviewable_type: "PROFESSIONAL",
    reviewable_slug: professional.value.slug,
    comment: comment.value,
    score: score.value,
  };

  // Si no hay usuario logueado, incluir los campos de nombre, apellido y correo
  if (!idUser.value) {
    console.log('no hay usuario')
    const user = {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        country_id: country.value
    } 
    body.user = user
    console.log(body)
  } else {
    body.reviewer_id = idUser.value.id; // Si el usuario está logueado, agregar su ID
  }

  try {
    const response = await axios.post(`${url}/api/public/professionals/profile/reviews`, body);
    console.log(response)
    console.log(body)
    Swal.fire({
      icon: 'success',
      title: 'Opinión enviada correctamente',
      timer: 1500,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
    });

    // Limpiar los campos después de enviar la reseña
    comment.value = '';
    score.value = 0;
    if (!idUser) {
      firstName.value = '';
      lastName.value = '';
      email.value = '';
    }

    fetchReviews(1);

  } catch (error) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: error.response?.data?.message || 'Error al enviar la reseña',
      timer: 1500,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
    });
  }
};
</script>

<style lang="scss" scoped>
.opinion{ 
    h2{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-left: 10px;
    }
    .starts{
        img{
            padding-right: 3px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .perfil-foto{
        max-width: max-content;
        img{
            height: 68px;
            border-radius: 50%;
            max-width: 68px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 12px;
        color: #B8C1DB;
    }
    .botones{
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
    }
    .before_respuesta{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            border-radius: 50px;
            width: 2px;
            height: 110%;
            left: -5%;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    .contenedor{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        input{
            border: 2px solid #E9E3E3;
            background-color: #FCFCFC;
            border-radius: 10px;
            height: 50px;
            width: 100%;
            color: #697891;
            font-weight: 400;
            font-size: 15px;
            padding-left: 15px;
            &:hover, &:focus{
                outline: 0;
                border-color: var(--colorPrimary);
            }
        }
        img{
            position: absolute;
            margin-right: 20px;
        }

    }
    @media (min-width: 992px) {
        margin: 40px 0px;
        .perfil-foto{
           img{
                height: 70px;
                max-width: 78px;
           }
        }
        .flex-cambio{
            flex-direction: column;
        }
        .before{
            &::before{
                position: relative;
            }
        }
    }
}

.send_opinion {
  h4 {
    font-weight: 500;
    font-size: 22px;
    color: var(--colorSecondary);
  }

  .contenedor {
    //width: 100%;
    margin-bottom: 15px;
    input {
      border: 2px solid #E9E3E3;
      background-color: #FCFCFC;
      border-radius: 10px;
      height: 50px;
      width: 100%;
      padding-left: 15px;
      font-size: 15px;
      margin-right: 15px;
      &:hover, &:focus {
        outline: none;
        border-color: var(--colorPrimary);
      }
    }
  }

  .stars-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .star {
      cursor: pointer;
      margin: 0 5px;
    }
  }

  .btn-submit {
    background-color: var(--colorPrimary);
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 15px;
    width: 80px;
    height: 100%;
    cursor: pointer;
    &:hover {
      background-color: var(--colorPrimary);
    }
  }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}

.mostrar_mas{
    position: relative;
    height: 50px;
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorSecondary);
    border-radius: 26px;
    width: 100%;
    color: var(--colorSecondary);
    margin: 30px 0px;
    p{
        font-weight: 700;
        font-size: 16px;
    }
    img{
        padding: 0px 20px;
    }
}

.stars-selector {
    gap: 15px;
}
.star-label {
    cursor: pointer;
    text-align: center;
    @media (min-width: 992px) {
        max-width: 7vw
    }
}
.star-label img {
    width: 24px;
    height: 24px;
}
.star-label p {
    font-size: 1.1rem;
    font-family: 'Poppins';
    color: #888;
}
.star-label .active-label {
    font-weight: bold;
    color: #FFA000;
}

.btn__primario{
    width: 20vw;
}

</style>