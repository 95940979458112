<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center ">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <TituloYSubtituloRegistro :titulo="$t('message.welcomeBack')"/>
                <UsuarioIngresoAnterior/>
                <div class="row justify-content-center mt-4">
                    <ContinuarComoBTN titulo="Continua como Carla Cooper"/>
                    <NoEresIngresarCon/>
                    <ORegistrarseCon/>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')" class="mb-2"/>
                        <BTNMail/>
                        <!--<BTNTelefonoV2/> -->
                    </div>
                    <IniciarEnlace :cuenta="$t('message.dontHaveAccountYet')" :enlace="$t('message.registerMe')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import ORegistrarseCon from '../../components/Titulo/ORegistrarseCon.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import IniciarEnlace from '../../components/Enlaces/IniciarEnlace.vue';
import BTNTelefonoV2 from "../../components/Botones/TelefonoV2.vue";
import BTNMail from "../../components/Botones/Mail.vue";
import NoEresIngresarCon from "../../components/Enlaces/NoEresIngresarCon.vue";
import ContinuarComoBTN from "../../components/Botones/ContinuarComo.vue";
import UsuarioIngresoAnterior from "../../components/Usuario/UsuarioIngresoAnterior.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";


const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>

</style>