<template>
    <div class="col-12 d-flex justify-content-center align-items-center video" v-if="product.presentation.information">
        <template v-if="product.presentation.information.videoType == 1">
            <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="product.presentation.video" 
                :resetOnEnd="false" >
                <div class="plyr__video-embed">
                    <iframe
                    :src="product.presentation.video+'?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'"
                    allowfullscreen
                    allowtransparency
                    ></iframe>
                </div>
            </vue-plyr>
            <div class="div" v-else>
                No hay vídeo
            </div>    
        </template>
        <template v-else>
            <vue-plyr ref="player" @timeupdate="videoTimeUpdated" v-if="product.presentation.video" :resetOnEnd="false">
                <div class="plyr__video-embed">
                    <iframe
                    :src="product.presentation.video+'?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'"
                    allowfullscreen
                    allowtransparency
                    ></iframe>
                </div>
            </vue-plyr>
            <div class="div" v-else>
                No hay vídeo
            </div>
        </template>
    </div>
    <div class="col-12 d-flex justify-content-center align-items-center video" v-else>
        <img class="image" :src="product.presentation.image" alt="">
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useVideoStore } from "../../stores/video";
import { ref, provide, inject, computed, watch, onMounted, getCurrentInstance, onBeforeUnmount} from "vue";
    
let product = inject('product')

const video = useVideoStore();

const {videosDurations, totalTimeVideo} = storeToRefs(video);
const {verifiedPlataform} = video;

onMounted(async () => {
    // console.log(product.value.presentation)
    product.value.presentation.information = await verifiedPlataform(product.value.presentation.video)
    // console.log(product.value.presentation)
})

</script>

<style lang="scss" scoped>
.video{
    padding: 20px 0;
    video{
        min-height: 265px;
        max-width: 100%;
    }
}
.plyr--video{
    width: 100%;
}
.image{
    object-fit: cover;
    height: 350px;
    width: 100%;
    max-width: 100%
}
</style>