<template>
    <button class="btn_none px-0 d-lg-none" @click="$router.go(-1)">
        <img src="../../img/home-icons/arrow-left-negra.svg" alt="">
    </button>
</template>

<script setup>

</script>

<style lang="scss" scoped>
img{
    height: 15px;
}
button{
    margin-right: 10px;
}
</style>