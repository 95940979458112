<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0 ps-lg-0 pe-lg-3">
        <h3>{{ $t('message.location') }}</h3>
        <div class="contenedor">
            <input type="text" :placeholder="$t('message.location')" v-model="information.country">
            <i></i>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"    
import { storeToRefs } from "pinia";
import { inject } from "vue";

const general = useGeneralStore();
const {inputs} = storeToRefs(general);
const {} = general;
const information = inject('information')
const validez = inject('validez')
</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    &:focus + i, &:hover + i{
        background-image: url('../../../img/panel-icons/ubi-active.svg');
    }
}
.contenedor{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
        position: absolute;
        right: 10px;
        width: 20px;
        height: 25px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../../img/panel-icons/ubi.svg');
    }
}
</style>