<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center mt-3 max__width__login__Desk">
                <TituloRoles/>
                <div class="row justify-content-center align-items-center row__width">
                    <RolesInicio/>
                </div>
                <!-- <BTNUsuarioInvitado/> -->
                <BTNContinuarRoles :textoBTNPrimario="$t('message.continue')"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Navbar from "../../components/Navs/Navbar.vue";
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloRoles from '../../components/Titulo/TituloRoles.vue';
import RolesInicio from '../../components/Roles/RolesInicio.vue';
import BTNUsuarioInvitado from '../../components/Botones/UsuarioInvitado.vue';
import BTNContinuarRoles from "../../components/Botones/BTNContinuarRoles.vue";

import { provide, ref, computed, onUnmounted, onMounted } from "vue";


const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>
.margin-top{
    margin-top: 50%;
}
</style>