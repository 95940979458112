<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex flex-column  justify-content-center align-items-center">
            <h4>{{$t('message.roles')}}</h4>
            <h5>{{$t('message.yourRole')}}</h5>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
h4{
    color: var(--colorSecondary);
    font-size: 30px;
    font-weight: 700;
    padding: 30px 0px;
}
h5{
    color: #7D93B2;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 30px;
    overflow-wrap: break-word;
}
</style>