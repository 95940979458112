<template>
    <button class="btn__primario mt-3" :class="sinBack == true ? 'btn__primario__sinBackground' : '' " @click="validar" :disabled="disabled">
        {{textoBTNPrimario}}
    </button>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import axios from "axios"
import { computed, onMounted} from "vue";
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const users = useUsersStore();

const { idUser, datosUsuarioLogin, cambioHeader, tokenAccess, headers } = storeToRefs(users);
const { usuarioData, url, urlLogin } = users
const disabled = computed(() => {
    localStorage.setItem('idUser', JSON.stringify(idUser.value))
    return idUser.value.team === undefined ? true : false
    
})

const props = defineProps({
    textoBTNPrimario : String,
    sinBack : {
        type: Boolean,
        default: false
    }
})

// async function validar(){
//     try {
//         console.log(idUser.value)
//         let params = {
//             email: datosUsuarioLogin.value.email,
//             password: datosUsuarioLogin.value.password,
//             id: idUser.value.id,
//             team: idUser.value.team
//         }
//         await axios.post(url+urlLogin, params).then((result) => {
//             tokenAccess.value = result.data.data.token 
//             localStorage.setItem('token', JSON.stringify(tokenAccess.value))
//             headers.value = {'Authorization': `Bearer ${tokenAccess.value}`}
//             usuarioData()
//             JSON.parse(localStorage.getItem('idUser'))
//             //Pregunto si hay un usuario en LocalStorage, en caso afirmativo reemplazo mis variables vacías por esa info
//             let team = idUser.value.team
//             cambioHeader.value = false
//             if(team == 1 || team == 2){
//                 router.push('/ajustes-admin')
//             }else if(team == 3){
//                 router.push('/')
//             }else if(team == 4 || team == 5){
//                 if(route.params.route == 'checkout'){
//                     router.push('/carrito/4')
//                 } else {
//                     router.push('/cuenta')
//                 }
//             }else if(team == 6){
//                 //console.log('Me ejecuto 3')
//                 if(route.params.route == 'checkout'){
//                     router.push('/carrito/4')
//                 } else {
//                     router.push('/')
//                 }
//             }
//             return {headers, usuarioData}
//         })
        
//     }
//     catch (error) {
//         console.log(error)
//     }
// }
</script>

<style lang="scss" scoped>

</style>