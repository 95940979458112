<template>
    <!--- Configuración académica --->
    <div class="row justify-content-center contenedor">
        <div class="col-12 col-lg d-flex justify-content-start align-items-center px-0 filtrosMobile">
            <button class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" @click.prevent="setView(0)">
                <img src="../../../../img/home-icons/arrow-left-negra.svg" alt="">
            </button>
            <h5>Configuracion academica</h5>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center  px-0 mt-4">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg d-flex justify-content-start align-items-center mb-4 px-0">
                    <h4>Nota minima cuestionarios</h4>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 academicas " >
                    <div class="row justify-content-start row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center ofertas">
                            <div class="row row__width justify-content-between">
                                <div class="col-5 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                    <input type="text" v-model="quizValue">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center  px-0 mt-4">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg d-flex justify-content-start align-items-center mb-4 px-0">
                    <h4>Nota minima certificados</h4>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 academicas " >
                    <div class="row justify-content-start row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center ofertas">
                            <div class="row row__width justify-content-between">
                                <div class="col-5 d-flex flex-column justify-content-around align-items-start pe-0">
                                    <h4 class="mb-2">{{ $t('message.minumumNote') }}</h4>
                                    <input type="text" v-model="certValue">
                                </div>
                                <div class="col-5 col-lg-2 d-flex justify-content-center align-items-center guardar">
                                    <button type="button" @click="editNotes(quizValue, certValue)">{{ $t('message.save') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject} from "vue"
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const urlFetchNotes = '/api/academic/get/config'
const urlEditNotes = '/api/academic/edit'

const quizValue = ref(null)
const certValue = ref(null)

const views = inject('views')
const buttons = inject('buttons')

const fetchNotes = async() => {
    try {
        await axios.get(url+urlFetchNotes).then((result) => {
            //console.log(result)
            if (result.status == 200){
                quizValue.value = result.data.quiz_min
                certValue.value = result.data.cert_min
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchNotes()

const editNotes = async(quiz, cert) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlEditNotes, {quiz_min: quiz, cert_min: cert}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                fetchNotes()
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = true
    return {buttons}
}
</script>

<style lang="scss" scoped>
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 30px;
        border-bottom: 1px solid #70707023;
        box-shadow: 0px 3px 6px #00000016;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorPrimary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -10px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 90%;
                margin-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding:30px;
        min-height: auto;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                input{
                    width: 94%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                    @media (max-width: 991px) {
                        width: 83%;
                    }
                }
                .button{
                    background-color: #fff;
                    border: 2px solid #E9E3E3;
                    outline: 0;
                    border-radius: 25px;
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 15px;
                    }
                }
                h4{
                    color: #0E1133;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: left;
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                .accordion-button{
                    background-color: #F9F9F9;
                    padding: 10px;
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2%;
                    background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    background-color: transparent;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
            .eliminar{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 1px solid #B8BCC8;
            }
        }
        .padding{
            padding: 0 11%;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    }   
    .border-top{
        border-top: 2px solid #E4E4E4;
        padding-top: 20px;
    }
    .agregar-cursos-btn, .agregar-cursos-btn-3{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin: 20px 0;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .agregar-cursos-btn-4{
        max-width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-right: 0;
        }
    }
    .faq{
        .collapses{
            h3{
                margin-bottom: 5px !important;
                font-weight: 700;
                font-size: 18px;
            }
            .question{
                font-size: 30px;
                margin-bottom: 0 !important;
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: #B8BCC8;
                text-align: left;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--colorSecondary);
                    }
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: relative;
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: #F9F9F9;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            border: 2px solid #E9E3E3;
                            min-height: 50px;
                            min-width: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        @media (max-width: 991px) {
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 5%;
                        background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                        background-size: 100%;
                        background-position: center;
                        @media (max-width: 991px) {
                            margin-top: 5px;
                        }
                    }
                }
            }
            @media (min-width: 992px) {
                margin: 20px 0 60px 0;
            }
            .collapses2{
                h3{
                    margin-bottom: 0px !important;
                    font-size: 16px;
                }
                .accordion{
                    width: 100%;
                    .accordion-item{
                        margin: 15px 0px;
                        border: 0;
                        border-radius: 10px;
                    }
                    .accordion-item:last-of-type .accordion-button.collapsed{
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    .accordion-header{
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        background-color: var(--bgPrincipal);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        .accordion-button{
                            border-radius: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            background-color: var(--bgPrincipal);
                            &:focus{
                                border-color: inherit;
                                box-shadow: none;
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                min-height: 40px;
                                min-width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                        }
                        .accordion-button:not(.collapsed){
                            background-color: inherit;
                            box-shadow: none;
                        }
                        .accordion-button::after{
                            position: absolute;
                            right: 2.5%;
                            background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                            background-size: 50%;
                            background-position: center;
                            border: 2px solid #E9E3E3;
                            border-radius: 50%;
                            min-height: 40px;
                            min-width: 40px;
                        }
                        .accordion-body{
                            input,select{
                                width: 100%;
                                border: 2px solid #E9E3E3;
                                border-radius: 10px;
                                background-color: #FCFCFC;
                                height: 50px;
                                padding-left: 15px;
                                outline: 0;
                                color: #697891;
                                font-size: 15px;
                                font-weight: 400;
                                -webkit-appearance: none;
                                &:focus, &:hover{
                                    border: 2px solid #1679FC;
                                }
                                &::placeholder{
                                    color: #697891;
                                }
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: 2px solid #E4E4E4;
                                border-radius: 50%;
                                min-height: 40px;
                                min-width: 40px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                
                @media (min-width: 992px) {
                    margin: 20px 0 60px 0;
                }
            }
            
            .max-width{
                width: 7%;
            }
            .position-relative{
                .position{
                    position: absolute;
                    right: 8%;
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        
                    }
                }
            }
        }
    }
}
</style>