<template>
    <div class="offcanvas-body row justify-content-center mapa__seccion">
        <div class="col-12 d-flex flex-column justify-content-start align-items-center px-0  position-relative">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0168878894465!2d-58.38375908423284!3d-34.60373446500723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4aa9f0a6da5edb%3A0x11bead4e234e558b!2sObelisco!5e0!3m2!1ses-419!2sar!4v1668362752555!5m2!1ses-419!2sar" 
            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div class="row justify-content-center row__width position center botones">
                <div class="col-11 d-flex justify-content-center align-items-center position-relative">
                    <div class="pin">
                        <div class="position-relative">
                            <div class="num">10</div>
                            <img src="../../img/mapa-icons/pin-ejemplo.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row zoom botones">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                    <button><span>+</span></button>
                    <button><span>-</span></button>
                </div>
            </div>
            <div class="row justify-content-center row__width position end  botones">
                <div class="col-12 d-flex flex-column justify-content-center align-items-end my-3">
                    <button class=""><span><img src="../../img/mapa-icons/lock.svg" alt=""></span></button>
                </div>
                <div class="col-12 d-flex justify-content-end align-items-center">
                    <button class=""><span><img src="../../img/mapa-icons/arrow-left-negro.svg" alt=""></span></button>
                    <button class=""><span><img src="../../img/mapa-icons/arrow-right-negro.svg" alt=""></span></button>
                    <button class="azul"><span><img src="../../img/mapa-icons/position.svg" alt=""></span></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.mapa__seccion{
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    .position{
        position: absolute;
    }
    .top{
        top: 3%;
    }
    .center{
        top: 7%;
        min-height: 70vh;
    }
    .end{
        bottom: 2%;
        button{
            margin-left: 10px;
        }
    }
    .zoom{
        position: absolute;
        top: 50vh;
        right: 10px;
        button{
            margin: 0 0 10px 0px;
        }
    }
    .pin{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        .num{
            position: absolute;
            top: 0;
            right: 0px;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            font-size: 21px;
            font-weight: 600;
            color: #FAFAFA;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .botones{
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 10px #00000029;
            border-radius: 10px;
            border: 0;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                width: 27px;
                height: 27px;
                box-shadow: 0px 3px 6px #00000029;
                background-color: var(--bgPrincipal);
                border-radius: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000;
                font-weight: 600;
                font-size: 16px;
            }
            &:focus{
                outline: 0;
            }
        }
        .azul{
            background-color: var(--colorPrimary);
        }
    }
}
</style>