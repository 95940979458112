<template>
    <div class="row justify-content-center row__width inputs__carrito">
        <div class="col-12 d-flex justify-content-start align-items-center ">
            <h6>Detalles para facturación</h6>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.name')}}</h5>
            <input type="text" v-model="user.first_name">
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.surname')}}</h5>
            <input type="text" v-model="user.last_name">
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.email')}}</h5>
            <input type="text" v-model="user.email">
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center cont_input position-relative checkoutTelefono">
            <h5>{{$t('message.telephone')}}</h5>
            <MazPhoneNumberInput
                v-model="user.phone_number"
                v-model:country-code="user.country_code"
                show-code-on-list
                color="info"
                :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB']"
                :ignored-countries="['AC']"
                :translations="{
                    countrySelector: {
                        placeholder: '',
                        error: '',
                    },
                    phoneInput: {
                        placeholder: '',
                        example: '',
                        label: ''
                    },
                }"
                no-example
                fetch-country
                no-use-browser-locale
            />
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center cont_input position-relative checkoutTelefono">
            <h5>{{$t('message.country')}}</h5>
            <MazSelect
                class="row__width"
                v-model="user.country_id"
                :placeholder="$t('message.country')"
                color="info"
                :options="countries"
                name="country"
                search
                search-placeholder="Buscar en la lista"
            />
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.gender')}}</h5>
            <div class="col-12 d-flex justify-content-between align-items-start gender_label">
                <input type="radio" id="Male" value="MALE" name="gender" class="d-none" v-model.trim="user.gender">
                <label for="Male">
                    <div class="span"></div>
                    <i class="hombre"></i>
                    <p>{{ $t('message.man') }}</p>
                </label>
                <input type="radio" id="Female" value="FEMALE" name="gender" class="d-none" v-model.trim="user.gender">
                <label for="Female">
                    <div class="span"></div>
                    <i class="mujer"></i>
                    <p>{{ $t('message.woman') }}</p>
                </label>
                <input type="radio" id="None" value="UNDEFINED" name="gender" class="d-none" v-model.trim="user.gender">
                <label for="None">
                    <div class="span"></div>
                    <i class="ambos"></i>
                    <p>{{ $t('message.undefined') }}</p>
                </label>
            </div>
        </div>
        <div class="col-12 col-lg-12 d-flex flex-column justify-content-center align-items-start cont_input">
            <h5>{{$t('message.birthdate')}}</h5>
            <div class="position">
                <input
                    type="text"
                    name="reg-user-day"
                    id="reg-user-day"
                    v-model="day"
                    maxlength="2"
                    @input="validateDay"
                    @keydown.backspace="moveToPrevious($event, 'day')"
                    placeholder="Día"
                />-
                <input
                    type="text"
                    name="reg-user-month"
                    id="reg-user-month"
                    v-model="month"
                    maxlength="2"
                    @input="validateMonth"
                    @keydown.backspace="moveToPrevious($event, 'month')"
                    placeholder="Mes"
                />-
                <input
                    type="text"
                    name="reg-user-year"
                    id="reg-user-year"
                    v-model="year"
                    maxlength="4"
                    @input="validateYear"
                    @keydown.backspace="moveToPrevious($event, 'year')"
                    placeholder="Año"
                />
            </div>
        </div>
        <RecordarCheckout/>
        <!-- <IngresoRapidoCheckout class="mb-lg-4"/> -->
        <!-- <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center d-none">
            <ContinuarComoBTN :titulo="$t('message.loginFacebook')"/>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <Google :textoBTNGoogle="$t('message.loginGoogle')"/>
        </div> -->
    </div>
</template>

<script setup>
    import Google from "../Botones/Google.vue";
    import ContinuarComoBTN from "../Botones/ContinuarComo.vue";
    import IngresoRapidoCheckout from "../Titulo/IngresoRapidoCheckout.vue";
    import RecordarCheckout from "./RecordarCheckout.vue";
    import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
    import MazSelect from 'maz-ui/components/MazSelect'
    import { useGeneralStore } from "../../stores/general"    
    import { useConfigStore } from "../../stores/config"
    import { storeToRefs } from "pinia";
    import { ref, provide, inject, onBeforeMount, nextTick, computed, watch } from 'vue';
    import axios from 'axios';
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const config = useConfigStore()
    const { countries, states, cities } = storeToRefs(config)
    const { fetchCountries, fetchStates, fetchCities } = config

    let user = inject('user')

    fetchCountries()

    const day = ref('')
    const month = ref('')
    const year = ref('')
    const currentYear = new Date().getFullYear()

    const isValidDate = computed(() => {
        const dayNum = parseInt(day.value, 10)
        const monthNum = parseInt(month.value, 10)
        const yearNum = parseInt(year.value, 10)

        const validDay = dayNum >= 1 && dayNum <= 31
        const validMonth = monthNum >= 1 && monthNum <= 12
        const validYear = yearNum >= 0 && yearNum <= currentYear

        return validDay && validMonth && validYear
    })

    const validateDay = (event) => {
        const input = event.target
        const value = input.value.replace(/\D/g, '');
        if (!/^\d{0,2}$/.test(value)) {
            input.value = value.slice(0, -1);
        } else {
            let intValue = parseInt(value);
            if (value.length === 1 && intValue >= 4 && intValue <= 9) {
                input.value = '0' + value;
                day.value = '0' + value;
                setTimeout(() => {
                    moveToNext(event, 'day');
                }, 0);
            } else if (value.length === 2) {
                if (intValue > 31) {
                    input.value = '31';
                    day.value = '31';
                } else if (intValue < 1) {
                    input.value = '01';
                    day.value = '01';
                } else {
                    day.value = value;
                }
                moveToNext(event, 'day');
            } else {
                day.value = value;
            }
        }
    }

    const validateMonth = (event) => {
        const input = event.target
        const value = input.value.replace(/\D/g, '');
        if (!/^\d{0,2}$/.test(value)) {
            input.value = value.slice(0, -1);
        } else {
            let intValue = parseInt(value);
            if (value.length === 1 && intValue > 1 && intValue <= 9) {
                input.value = '0' + value;
                month.value = '0' + value;
                setTimeout(() => {
                    moveToNext(event, 'month');
                }, 0);
            } else if (value.length === 2) {
                if (intValue > 12) {
                    input.value = '12';
                    month.value = '12';
                } else if (intValue < 1) {
                    input.value = '01';
                    month.value = '01';
                } else {
                    month.value = value;
                }
                moveToNext(event, 'month');
            } else {
                month.value = value;
            }
        }
    }

    const validateYear = (event) => {
        const input = event.target
        const value = input.value.replace(/\D/g, '');
        if (!/^\d{0,4}$/.test(value)) {
            input.value = value.slice(0, -1);
        } else if (value.length === 4) {
            let intValue = parseInt(value);
            if (intValue > currentYear) {
                input.value = currentYear.toString();
                year.value = currentYear.toString();
            } else {
                year.value = value;
            }
            moveToNext(event, 'year');
        } else {
            year.value = value;
        }
    }

    const moveToNext = (event, field) => {
        const input = event.target
        if (input.value.length >= input.maxLength) {
            switch (field) {
            case 'day':
                input.nextElementSibling.focus()
                break
            case 'month':
                input.nextElementSibling.focus()
                break
            case 'year':
                break
            }
        }
    }

    const moveToPrevious = (event, field) => {
        const input = event.target
        if (input.value.length === 0 && event.key === 'Backspace') {
            switch (field) {
            case 'day':
                break
            case 'month':
                input.previousElementSibling.focus()
                break
            case 'year':
                input.previousElementSibling.focus()
                break
            }
        }
    }

    watch([day, month, year], () => {
        if (isValidDate.value) {
            user.value.birthdate = `${year.value.padStart(4, '0')}-${month.value.padStart(2, '0')}-${day.value.padStart(2, '0')}`
        } else {
            user.value.birthdate = ''
        }
    })

</script>

<style lang="scss" scoped>
h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding: 30px 0 10px 0;
}
h5{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
    padding: 10px 0px;
    width: 100%;
    text-align: left;
}
.cont_input{
    margin-bottom: 10px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.gender_label{
    label{
        font-size: 16px;
        color: #697891;
        font-weight: 400;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        height: 50px;
        width: 32.4%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        @media (min-width: 420px) {
            width: 32%;
        }
        i{
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
            display: block;
            @media (min-width: 420px) {
                width: 26px;
                height: 26px;
            }
            //margin-right: 10px;
        }
        .hombre{
            background-image: url('../../img/panel-icons/hombre.svg');
        }
        .mujer{
            background-image: url('../../img/panel-icons/mujer.svg');
        }
        .ambos{
            background-image: url('../../img/panel-icons/ambos.svg');
        }
    }
    input:checked + label{
        border-color: var(--colorPrimary);
        color: var(--colorPrimary);
        &::before{
            content: '';
            position: absolute;
            top: -10px;
            right: -7px;
            background-image: url('../../img/panel-icons/input-valido.svg');
            background-repeat: no-repeat;
            z-index: 1;
            display: block;
            width: 26px;
            height: 27px;
        }
        .hombre{
            background-image: url('../../img/panel-icons/hombre-active.svg');
        }
        .mujer{
            background-image: url('../../img/panel-icons/mujer-active.svg');
        }
        .ambos{
            background-image: url('../../img/panel-icons/ambos-active.svg');
        }
    }
    p{
        font-size: 13px;
        margin-left: 5px;
        @media (min-width: 420px) {
            font-size: 14px;
        }
    }
}

.position{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .calendar{
        width: 25px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../img/panel-icons/calendar.svg') ;
        background-size: 100% 100%;
        position: absolute;
        left: 9px;
    }
    input{
        width: 30%;
        padding-left: 15px;
        justify-content: center;
        align-items: center;
    }
}
</style>