<template>
    <a class="mostrar_mas d-flex justify-content-center align-items-center" id="mostrar">
        {{ $t('message.contactWpp') }}
        <img src="../../img/home-icons/cursos-icons/wpp.svg" alt="" >
    </a>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.mostrar_mas{
    position: relative;
    height: 50px;
    background-color: var(--bgPrincipal);
    border: 2px solid #000000;
    border-radius: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: #374557;
    margin: 30px 0px;
    text-decoration: none;
    cursor:pointer;
    img{
        padding: 0px 10px;
    }
}
</style>