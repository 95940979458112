<template>
   <div class="row justify-content-center justify-content-lg-around justify-content-xl-center">
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(1)">
            <div class="row justify-content-center  row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>{{ $t('message.frequentQuestions') }}</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(2)">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>Beneficios</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(3)">
            <div class="row justify-content-center row__width agregar_cursos" >
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>Propuesta de valor</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(4)">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>Configuracion academica</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 disabled" disabled @click.prevent="setView(5)">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>{{ $t('message.prices') }}</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(6)">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>{{ $t('message.categories') }}</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
        <button  class="col-12 col-md-6 col-xxl-4 d-flex justify-content-center align-items-center px-0 pe-md-3 " @click.prevent="setView(7)">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col d-flex flex-column justify-content-center align-items-center px-0">
                    <h4>Profesores</h4>
                </div>
                <div class="col d-flex justify-content-center align-items-center px-0 max-width">
                    <span class="mas">
                        <img src="../../../../img/panel-icons/mas.svg" alt="">
                    </span>
                </div>
            </div>
        </button>
   </div>
</template>

<script setup>
import { inject } from 'vue'
const views = inject('views')
const buttons = inject('buttons')
const tabs = inject('tabs')

const setView = (x) =>{
    //console.log('me ejecuto')
    views.value = x
    buttons.value = false
    tabs.value = false
    return {buttons, tabs}
}
</script>

<style lang="scss" scoped>
button{
    border: 0;
    background-color: transparent;
}
.agregar_cursos{
    box-shadow: 2px 2px 10px #00000026;
    border-radius: 43px;
    height: 85px;
    margin: 20px 0;
    position: relative;
    align-items: center;
    h4{
        font-weight: 500;
        font-size: 18px;
        color: #0E1133;
        padding-right: 20px;
    }
    button{
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid #E4E4E4;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
        width: 65px;
        outline: 0;
    }
    .mas{
        border: 2px solid #E4E4E4;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .max-width{
        max-width: 50px;
        position: absolute;
        right: 15px;
    }
    @media (max-width: 991px) {
        margin: 10px 0;
    }
}
.disabled{
    filter: grayscale(1);
    opacity: 50%;
}
</style>