<template>
    <div class="col-12 d-flex justify-content-start align-items-center py-2 px-0">
        <div class="row justify-content-center align-items-center row__width">
            <div class="col-12 d-flex justify-content-start align-items-center vendido pe-0">
                <img src="../../img/home-icons/cursos-icons/cursos-mas-vendidos-icon.svg" alt="">
                <a href="">{{ $t('message.bestSellingCourse') }}</a>
                <h5>| 8,545 {{ $t('message.sales') }} |</h5>
                <div class="row justify-content-center ms-2 d-none d-lg-flex">
                    <div class="col d-flex justify-content-start align-items-center py-2 rate px-lg-0">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <h6>(12k {{ $t('message.reviews') }})</h6>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center py-2 rate px-lg-0 d-lg-none">
                <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                <h6>(12k {{ $t('message.reviews') }})</h6>
            </div>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.vendido{
    img{
        padding-right: 10px;
    }
}
.rate{
    img{
        padding-right: 5px;
        height: 20px;
    }
}
a{
    font-weight: 600;
    font-size: 15px;
    color: var(--colorPrimary);
}
h5{
    font-weight: 400;
    font-size: 15px;
    color: #B8C1DB;
    padding: 0px 5px;
}
h6{
    font-weight: 500;
    font-size: 15px;
    color: #B8C1DB;
    padding: 0px 5px;
}
</style>