import { defineStore } from 'pinia'
import { ref } from 'vue'
import  axios from 'axios'

export const useConfigStore = defineStore('config', () => {
    const url = process.env.VUE_APP_BASE_URL

    const urlFetchCoins = '/api/payment/get/coin'
    const urlFetchRates = '/api/currency/rate'

    const countries = ref([])
    const states = ref([])
    const cities = ref([])

    const iso2ToCountryName = ref({})
    const countryNameToIso2 = ref({})

    let coins = ref()
    let rates = ref([])
    let ratesDate = ref()
    let coinSelected = ref('MXN')

    if(localStorage.getItem('coin')){
        coinSelected.value = JSON.parse(localStorage.getItem('coin'))
    }

    const fetchCountries = async() => {
        try {
            const response = await axios.get(`${url}/api/public/countries`)
            console.log(response)
            countries.value = await response.data.data
            countries.value.forEach((country) => {
                country.label = country.translation.name,
                country.value = country.id
            })
          } catch (error) {
            console.error('Error fetching countries:', error)
          }
    }

    const fetchStates = async(country_id) => {
        // if (!country_id) return
        // const selectedCountry = countries.value.find(country => country.value === country_id)
        // if (!selectedCountry) return

        try {
            states.value = []
            const response = await axios.get(`${url}/api/public/states?countryId=${country_id}`)
            console.log(response)
            states.value = await response.data.data
            states.value.forEach((state) => {
                state.label = state.translation.name,
                state.value = state.id
            })
            console.log(states.value)
        } catch (error) {
            console.error('Error fetching states:', error)
        }
    }

    const fetchCities = async(state_id) => {
        // if (!state_id) return
        // const selectedState = states.value.find(state => state.value === state_id)
        // if (!selectedState) return

        try {
            cities.value = []
            const response = await axios.get(`${url}/api/public/cities?stateId=${state_id}`)
            console.log(response)
            cities.value = await response.data.data
            cities.value.forEach((city) => {
                city.label = city.translation.name,
                city.value = city.id
            })
        } catch (error) {
            console.error('Error fetching cities:', error)
        }
    }

    const fetchCoins = async() => {
        try {
            axios.get(url+urlFetchCoins).then((result) => {
                console.log(result)
                coins.value = result.data
            })
        } catch (error) {
            console.log(error)
        }
    }
    // fetchCoins()

    const fetchRates = async() => {
        try {
            axios.get(url+urlFetchRates).then((result) => {
                console.log(result)
                if (result.data.length > 0){
                    ratesDate.value = result.data[0].date
                    result.data.forEach(element => {
                        delete element.date
                        rates.value.push(element)
                        localStorage.setItem('rates', JSON.stringify(rates.value))
                    })
                    console.log(rates.value)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    // fetchRates()

    return{
        url, fetchCountries, fetchStates, fetchCities, countries, states, cities, iso2ToCountryName, countryNameToIso2,
        coins, coinSelected
    }
})