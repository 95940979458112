<template>
    <div class="row justify-content-center my-5 row__width d-none">
        <div class="col-12 d-flex justify-content-start align-items-center panel">
            <div class="d-flex flex-column justify-content-center align-items-start contenedor_panel">
                <h2>{{$t('message.premiumAccess')}}</h2>
                <h3>{{$t('message.joinMCV2')}}</h3>
                <a href="">
                    {{$t('message.bePremium')}}
                </a>
            </div>
            <img src="../../img/home-icons/panel-img/1.svg" alt="">
        </div>
    </div>
</template>

<script setup>
	import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.panel{
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    h2{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
        text-align: center;
        width: 100%;
    }
    h3{
        font-size: 14px;
        font-weight: 400;
        color: #697891;
        padding: 15px 0px;
        line-height: 22px;
    }
    a{
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-size: 16px;
        font-weight: 700;
        height: 50px;
        position: relative;
        text-decoration: none;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        &::before{
            content: '';
            background-image: url('../../img/home-icons/premium-before.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            width: 44px;
            height: 44px;
            display: block;
            left: 0%;
            top: -30%;
        }
    }
    img{
        display: none;
    }
    @media (min-width: 992px) {
        height: 250px;
        padding: 30px;
        h2{
            font-size: 35px;
            text-align: left;
        }
        h3{
            font-size: 15px;
            padding-bottom: 5%;
            text-align: left;
        }
        a{
            width: 70%;
            font-size: 16px;
            margin-left: 5%;
            &::before{
                left: -15%;
                top: -35%;
                width: 30%;
                height: 100%;
                background-size: 100% 100%;
            }
        }
        img{
            position: absolute;
            right: 0;
            bottom: 0;
            display: block;
            height: 40vh;
        }
    }
}
.row__width{
    @media (min-width: 992px) {
        padding: 5% 0;
    }
}
</style>