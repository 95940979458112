<template>
    <div class="row justify-content-center justify-content-lg-between row__width">
        <div class="col-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center  align-items-start m-lg-0">
            <h2>Cursos</h2>
            <h3 v-if="professional.courses_uploaded" >Tiene {{ professional.courses_uploaded }} {{ $t('message.productsV2') }}</h3>
        </div>
        <!-- <FiltrosYBuscadorCursosV2 class="d-lg-none"/> -->
        <!-- <div class="col-lg-5 d-flex justify-content-center align-items-center px-0 d-none d-lg-flex">
            <div class="row justify-content-end row__width botones">
                <button id="prevSliderCursosboton"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                <div id="pagCursos" class="paginationStyle"></div>
                <button id="nextSliderCursosboton"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
            </div>
        </div> -->
        <div class="col col-lg-6 d-flex justify-content-center align-items-center contador__botones">
            <div class="row justify-content-end row__width">
                <div class="col col__perso d-flex justify-content-end align-items-center">
                    <div :id="pagination" class="paginationStyle"></div>
                </div>
                <div class="col col__perso d-flex justify-content-end align-items-center">
                    <button :id="idBackV2"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                    <button :id="idNextV2" class="me-0"><img src="../../img/home-icons/arrow-rigth-azul.svg" alt=""></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado} = storeToRefs(general);
const {} = general;

const props = defineProps({
    titulo: '',
    categoria:{
        type: Number,
        default: 1
    },
    lista:{
        type: Number,
        default: 1
    },
    idBackV2: {
        type: String,
    },
    idNextV2: {
        type: String,
    },
    pagination: '',
})

const professional = inject('professional')
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 25px;
    color: var(--colorSecondary);
    text-align: left;
}
h3{
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    text-align: left;
    padding-top: 10px;
}

.contador__botones{
    margin: 20px 0px;
    p, h3{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3px;
    }
    h3{
        font-weight: 400;
        padding-left: 3px;
    }
    button{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.col__perso{
    max-width: max-content;
}
.paginationStyle {
    font-weight: 700!important;
    font-size: 18px!important;
    color: var(--colorSecondary)!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding-right: 3px!important;
    span {
        font-weight: 400!important;
        padding-left: 3px!important;
    }
}
</style>