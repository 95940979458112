import { computed, createApp, mounted } from 'vue'

import App from './App.vue'

import './registerServiceWorker'

import router from './router'

import store from './store'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'

import 'plyr/dist/plyr.css'

import 'maz-ui/css/main.css'

import 'animate.css'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'

import '../src/assets/styles.css'

import { createPinia } from 'pinia'
const pinia = createPinia()

import vue3GoogleLogin from 'vue3-google-login'

import 'sweetalert2/src/sweetalert2.scss'

import { i18n } from '../src/stores/langs'

import VuePlyr from '@skjnldsv/vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// const app = createApp()
// app.component('QuillEditor', QuillEditor)


// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: 'app-key',
//   cluster: 'mt1',
//   wsHost: 'masterclass.knowdo.studio',
//   wsPort: '6001',
//   disableStats: true,
// });

import { register } from 'swiper/element/bundle';

import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

import VueApexCharts from "vue3-apexcharts";

// import Flicking from "@egjs/vue3-flicking";
// import "@egjs/vue3-flicking/dist/flicking.css";
// import "@egjs/vue3-flicking/dist/flicking-inline.css";

// import VueSplide from '@splidejs/vue-splide';
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
// import '@splidejs/vue-splide/css';

createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue)
    .use(pinia)
    .use(register)
    .use(VuePlyr, {
        // plyr: {
        //     // Aquí puedes agregar tus opciones
        //     fullscreen: {enabled: true, fallback: true, iosNative: false, container: null},
        //     controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
        //     settings: ['captions', 'quality', 'speed'],
        //     speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
        //     autoplay: false,
        //     resetOnEnd: false,
        // }
    })
    .use(vue3GoogleLogin, {
        clientId: '662097405904-a0oqv3dhj70n6jb5nu0v9r4cpne5l04r.apps.googleusercontent.com'
    })
    .use(i18n)
    .use(VueApexCharts)
    .use(QuillEditor)
    // .use(Flicking)
    // .use( VueSplide )
    .mount('#app');
