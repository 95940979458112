<template>
    <div class="row justify-content-center cartel">
        <div class="col-11 d-flex justify-content-start align-items-center px-0">
            <img src="../../img/home-icons/arrow-left-negra.svg" alt="" @click="router.go(-1)"><h2>{{tituloHeader}}</h2>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter()

const props = defineProps({
    tituloHeader: '',
})
</script>

<style lang="scss" scoped>
h2{
    font-weight: 400;
    font-size: 16px;
    color: #697891;
    padding: 15px 0px;
}
.cartel{
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000016;
    height: 72px;
    margin-top: 95px;
}
img{
    padding-right: 15px;
    cursor: pointer;
}
</style>