<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3 inputs">
        <h3>{{ titulo }}</h3>
        <div class="position">
            <input type="password" :class="validez.validezPassword" :placeholder="titulo" id="id3" v-model.trim="infoReset.password">
            <button class="btn_none d-flex justify-content-center align-items-center" @click="cambiar()"  type="button"><i class="ojo"></i></button>
        </div>
    </div>
</template>

<script setup>
import { inject } from "vue";

const infoReset = inject('infoReset')
const validez = inject('validez')

const props = defineProps({
    titulo: '',
    placeholder: '',
    id: '',
})

const cambiar = () => {
    if(document.getElementById('id3').getAttribute('type') == 'password'){
        document.getElementById('id3').setAttribute('type', 'text')
    }else{
        document.getElementById('id3').setAttribute('type','password')
    }
}

</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.position{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        position: absolute;
        right: 15px;
        padding: 0;
    }
    .ojo{
        background-image: url('../../../img/roles-icons/ojo.svg');
        background-repeat: no-repeat;
        display: block;
        min-height: 20px !important;
        min-width: 20px !important;
    }
}
</style>