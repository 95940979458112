<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4">
                <div class="row justify-content-start row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                        <h2>{{ $t('message.settingCount') }}</h2>
                    </div>
                    <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.enableAffilities') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="default" v-model="affiliates.enabled" @change="configAffiliates()">
                            <label class="form-check-label" for="default">
                                <p v-if="affiliates.enabled">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.onlyAfillitiesAproved') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="perso" v-model="affiliates.approved" @change="configAffiliates()">
                            <label class="form-check-label" for="perso">
                                <p v-if="affiliates.approved">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.commissionLimit') }}</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const urlConfigAffiliates = '/api/product/creating/add/affiliates'

let affiliates = inject('affiliates')
let product = inject('product')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

const configAffiliates = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    affiliates.value.id = product.value.id
    affiliates.value.owner_id = owner_id.value,
    affiliates.value.product_id = product_id.value
    console.log(affiliates.value)
    try {
        await axios.post(url+urlConfigAffiliates, affiliates.value,{headers: headers.value}).then((result) => {
            console.log(result)
            affiliates.value.enabled = result.data.enabled
            affiliates.value.approved = result.data.approved
        })
    } catch (error) {
        console.error(error)
    }
}
</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
h5{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
.switch{
    .form-switch .form-check-input{
        width: 48px;
        height: 23px;
        background-color: #E4E4E4;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
        border: 0;
        background-position-x: 2px;
    }
    .form-switch .form-check-input:focus{
        outline: 0;
        box-shadow: none;
        border: 0;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
    }
    .form-switch .form-check-input:checked{
        background-color: var(--colorPrimary);
        background-position-x: 27px;
    }
    p{
        font-weight: 500;
        padding-left: 10px;
        width: 25px;
    }
    .sin-padding{
        padding: 0;
        width: auto;
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-left: 10px;
    }
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
   
}
.col-perso{
    @media (min-width: 992px) {
        min-width: 55% !important;
        max-width: 55%;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    position: relative;
    p{
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 15px;
    }
}
.buttons{
    .btn__primario{
        height: 45px;
    }
}
.collapses{
    h3{
        margin-bottom: 0px !important;
        font-size: 16px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 100%;
                background-position: center;
            }
            .accordion-body{
                input,select{
                    width: 100%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: #FCFCFC;
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    -webkit-appearance: none;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
                
            }
        }
    }
    @media (min-width: 992px) {
        margin: 0px 0 60px 0;
    }
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
}
.checkboxes{
    input[type=radio]{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
        }
        .rojo{
            background-color: #EF4836;
        }
        .verde{
            background-color: #37BFA3;
        }
        .azul{
            background-color: #1679FC;
        }
        i{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            display: block;
        }
        .star{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/star.svg');
        }
        .flame{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/flame.svg');
        }
    }
    input:checked + label .span{
        border-color: #000;
        &::after{
            display: block;
        }
    }
    input:checked + label .star{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/star-active.svg');
    }
    input:checked + label .flame{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/flame-active.svg');
    }
    .col{
        max-width: 32px;
    }
}
</style>