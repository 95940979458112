<template>
    <div class="row justify-content-center justify-content-lg-start row__width mt-3 mt-lg-0">
        <div class="col-11 col-lg-3 d-flex justify-content-start  align-items-center px-0">
            <div class="dropdown">
                <a class="dropdown-toggle d-flex justify-content-start align-items-center" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../img/home-icons/ordenar-por.svg" alt="" class="me-2">
                    {{ $t('message.orderBy') }} <img src="../../img/home-icons/cursos-icons/arrow-abajo-negro.svg" alt="" class="ms-3">
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.dropdown{
    width: 100%;
}
.dropdown-toggle{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::after{
        border: 0;
    }
}
ul{
    border: 0;
    height: 100%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
    li{
        button{
            width: 100%;
            height: 100%;
            font-weight: 500;
            font-size: 16px;
            color: #707070;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: color .15s ease-in-out;
        }
        .active{
            color: var(--bgPrincipal);
            font-weight: 700;
            border-radius: 33px;
            background-color: var(--colorPrimary);
        }
        &:focus{
            outline: 0;
        }
    }
}
.dropdown-menu{
    z-index: 3;
    background-color: var(--bgPrincipal);
    box-shadow: 0px 3px 6px #00000029;
    height: auto;
}
</style>