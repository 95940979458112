<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-4">
                <div class="row justify-content-start row__width">
                    <!--- AFILIADOS --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                        <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                            <div class="accordion-item row row__width justify-content-center">
                                <h2 class="accordion-header mb-0 px-0" id="heading">
                                    <p class="accordion-button py-0 px-0 collapsed ps-lg-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                        <div class="col-9 col-lg-2 d-flex justify-content-start align-items-center ps-1 pe-0 ">
                                            <div class="row row__width justify-content-center justify-content-lg-start pt-3 pt-lg-0">
                                                <div class="col-4 col-lg-6 d-flex justify-content-center align-items-center img">
                                                    <img src="../../../img/perfil-icons/img-ejemplo.svg" alt="">
                                                </div>
                                                <div class="col col-lg-6 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.userAfilliate') }}</h5>
                                                    <h6>Carlos</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center px-0 seccion d-none d-lg-flex">
                                            <div class="row row__width justify-content-start align-items-center">
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.course') }}</h5>
                                                    <h6>Fotografía</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.applicationDate') }}</h5>
                                                    <h6>14/5/2020</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.commission') }}</h5>
                                                    <h6>80%</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.status') }}</h5>
                                                    <div class="row separador row__width tipo__reunion">
                                                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                            <input type="checkbox" id="hola">
                                                            <label for="hola" class="row">
                                                                <div class="col-6 d-flex justify-content-center align-items-center">
                                                                    <p class="left">Aprobado</p>
                                                                </div>
                                                                <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                                                    <p class="right">Pendiente</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </h2>
                                <div id="collapse" class="col-12 accordion-collapse collapse d-lg-none" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                    <div class="accordion-body row justify-content-end py-0 px-0">
                                        <div class="col-6 d-flex flex-column justify-content-center align-items-start ps-1 excepcion">
                                            <div class="row row__width justify-content-center">
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.course') }}</h5>
                                                    <h6>Fotografía</h6>
                                                </div>
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.applicationDate') }}</h5>
                                                    <h6>14/5/2020</h6>
                                                </div>
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.commission') }}</h5>
                                                    <h6>80%</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg- d-flex flex-column justify-content-center align-items-start px-0 py-3 excepcion d-none d-lg-flex">
                                                <h5>{{ $t('message.status') }}</h5>
                                                <div class="row separador row__width tipo__reunion">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <input type="checkbox" id="hola">
                                                        <label for="hola" class="row row__width">
                                                            <div class="col-6 d-flex justify-content-center align-items-center">
                                                                <p class="left">Aprobado</p>
                                                            </div>
                                                            <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                                                <p class="right">Pendiente</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-3 d-flex justify-content-center align-items-center eliminar">
                                            <button class="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-end row__width px-0 d-lg-none">
                                    <div class="col-6 d-flex flex-column justify-content-center align-items-start px-0 py-3 excepcion">
                                        <h5>{{ $t('message.status') }}</h5>
                                        <div class="row separador row__width tipo__reunion">
                                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                <input type="checkbox" id="hola">
                                                <label for="hola" class="row row__width">
                                                    <div class="col-6 d-flex justify-content-center align-items-center">
                                                        <p class="left">Aprobado</p>
                                                    </div>
                                                    <div class="col-6 d-flex justify-content-center align-items-center px-0">
                                                        <p class="right">Pendiente</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, onUnmounted } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const users = useUsersStore()
const {datosUsuario, professionalData, headers} = storeToRefs(users)
const {url, validarMail, usuarioData, userProfessionalData} = users

const showBanner = () => {
    // Analizar ancho de pantalla
    if(window.innerWidth >= 992) {
        document.querySelectorAll('.accordion-button').addEventListener('click', function(e) {
            e.stopPropagation();
        });
    }
}
onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border-radius: 50%;
            height: 40px;
            width: 40px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
}
.collapses{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
            margin-bottom: 15px;
            
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    height: 70px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
                @media (min-width: 992px) {
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    position: relative;
                    height: 100px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
                @media (min-width: 992px) {
                    background-color: var(--bgPrincipal);
                    box-shadow: inherit;
                }
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                @media (min-width: 992px) {
                    display: none;
                }
            }
            .accordion-body{
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
            .buttons{
                position: absolute;
                right: 17%;
                @media (min-width: 992px) {
                    right: 7%;
                }
            }
            
        }
        .dropdown{
            width: 100%;
            box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
            border-radius: 10px;
            button{
                width: 100%;
                background-color: var(--bgPrincipal);
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                border: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                border-radius: 10px;
                padding: 0 20px;
                h3{
                    padding: 20px 0;
                }
                .dropdown-menu{
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    border: 0;
                    padding: 20px 20px 0 20px;
                    height: auto;
                    border-radius: 0 0 10px 10px ;
                    a:hover, a:focus{
                        background-color: inherit;
                    }
                    a{
                        padding: 0 0 10px 0;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #2E4765;
                        font-size: 15px;
                        font-weight: 400;
                    }
                    span{
                        height: 40px;
                        width: 40px;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }
                    li{
                        width: 100%;
                    }
                }
            }
            .dropdown-toggle::after{
                display: none;
            }
            .arrow{
                width: 25px;
                height: 15px;
                background-repeat: no-repeat;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all ease 0.2s;
                background-size: 75%;
                background-position: center;
            }
        }
        .dropdown-toggle.show{
            .dropdown-menu{
                box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:after{
                    content: '';
                    position: absolute;
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    height: 10px;
                    top: -7px;
                }
            }
            .arrow{
                transform: rotate(180deg);
                transition: all ease 0.2s;
            }
        }
        .ofertas{
            border: 1px solid #E4E4E4;
            background-color: var(--bgPrincipal);
            box-shadow: 0 3px 6px #00000029;
            border-radius: 15px;
            min-height: 110px;
            h4{
                color: var(--colorSecondary);
                font-weight: 600;
                font-size: 16px;
            }
            h5{
                color: #2E4765;
                font-weight: 300;
                font-size: 14px;
            }
            .eliminar{
                border-left: 2px solid #E4E4E4;
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
                @media (max-width: 991px) {
                    flex-direction: column;
                    button{
                        margin: 5px 0;
                    }
                }
            }
            .ejemplo{
                border-radius: 5px;
                .img{
                    max-height: 100%;
                    max-width: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                    height: 83px;
                    width: 118px;
                    margin-left: 10px;
                    @media (max-width: 991px) {
                        margin-left: 5px;
                    }
                }
            }
            span{
                height: 40px;
                width: 40px;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                p{
                    font-size: 14px;
                    color: #2E4765;
                    font-weight: 300;
                }
            }
            .max-width{
                max-width: 18%;
                @media (min-width: 992px) {
                    max-width: 13%;
                }
            }
            .max-width-2{
                @media (min-width: 992px) {
                    max-width: 15%;
                }
            }
            .span-noti{
               position: relative;
               margin-right: 15px;
                .noti{
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: -5px;
                    right: -5px;
                    p{
                        color: var(--bgPrincipal);
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            .drag{
                height: 20px;
            }
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding-bottom: 10px;
        }
        h6{
            color: #B8C1DB;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            height: 30px;
        }
        .tipo__reunion{
            label{
                height: 30px;
                width: 100%;
                box-shadow: 0px 0px 6px #00000029;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
                background-color: #EBEEF9;
                &::before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #38E6A7;
                    border-radius:  28px;
                    left: 1%;
                    transition: all 0.5s;
                }
                p{
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--bgPrincipal);
                    z-index: 1;
                    transition: all 0.5s;
                    &:first-child{
                        margin-right: 0%;
                    }
                    &:last-child{
                        margin-left: 0%;
                    }
                }
                .left{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                }
                .right{
                    color: var(--colorSecondary);
                }
            }
            input{
                display: none;
            }
            input:checked + label{
                &::before{
                    left: 49%;
                    width: 50%;
                    background-color: #FEB842;
                }
                p{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                    
                }
                .left{
                    color: var(--colorSecondary);
                    font-weight: 500;
                }
            }
            @media (min-width: 992px) {
                label{
                    .right{
                        color: var(--colorSecondary);
                    }
                }
                input:checked + label{
                    .left{
                        color: var(--colorSecondary);
                    }
                }
            }
        }
        .max-width{
            max-width: 10%;
        }
        .eliminar{
            border-left: 2px solid #E4E4E4;
            button{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 0;
            }
        }
        .img{
            img{
                height: 58px;
                width: 58px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .seccion{
            .col{
                max-width: 13%;
            }
            .excepcion{
                max-width: 24%;
            }
        }
    }
    .padding{
        padding: 0 11%;
    }
    
}

</style>