<template>
   <div class="col-12 d-flex flex-column justify-content-start align-items-center mis_cursos">
        <div class="row justify-content-center justify-content-lg-between row__width">
            <div class=" col-12 col-lg d-flex justify-content-center align-items-center px-0 max-width">
                <div class="row justify-content-start justify-content-lg-start row__width">
                    <TituloYSubtituloPanelV2 :titulo="$t('message.myProducts')" :subtitulo=" $t('message.youHave')+' '+professionalProducts.length+' '+$t('message.products')" class="px-3"/>
                    <filtrosYBuscadorCursosV2 class="px-3 d-none"/>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas mt-5 px-0">
                        <div class="scroll d-flex justify-content-center align-items-center">
                            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                <li class="nav-item col-3 d-flex justify-content-center align-items-center" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{{ $t('message.all') }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col d-flex flex-column justify-content-center align-items-center tablas">
                        <div class="tab-content row justify-content-center  row__width" id="myTabContent">
                            <div class="col-12 tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <MisProductosProfesor/>
                            </div>
                            <div class="col-12 tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <!-- <MisProductosProfesor/> -->
                            </div>
                            <div class="col-12 tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <!-- <MisProductosProfesor/> -->
                            </div>
                            <div class="col-12 tab-pane fade" id="contact2" role="tabpanel" aria-labelledby="contact-tab2">
                                <!-- <MisProductosProfesor/> -->
                            </div>
                        </div>
                    </div>
                    <FiltrosCursos/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import FiltrosCursos from "../../../Filtros/FiltrosCursos.vue";
import SliderMisProductos from "../../../Sliders/MisProductos.vue";
import filtrosYBuscadorCursosV2 from "../../../Filtros/FiltrosYBuscadorCursosV2.vue";
import TituloYSubtituloPanelV2 from "../../../Titulo/TituloYSubtituloPanelV2.vue";
import MisProductosProfesor from '@/components/Sliders/MisProductosProfesor.vue';
const professionalProducts = inject('professionalProducts')
const productSheet = inject('productSheet')
</script>

<style lang="scss" scoped>
.mis_cursos{
    margin: 2vh 0;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 15px 0;
    @media (min-width: 992px) {
        margin: 0;
        padding: 40px 30px;
        border-radius: 28px;
        .max-width{
            max-width: 97%;
        }
    }
}

.tablas{  
    .nav-link{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        position: relative;
        padding: 10px 0;
        border: 0;
    }
    .nav-link.active{
        font-weight: 700;
        border: 0;
        color: var(--colorSecondary);
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 0;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        // overflow-x: scroll;
        // max-width: 100%;
        width: 100%;
        ul{
            width: 100%;
        }
        // @media (min-width: 992px) {
        //     overflow: hidden;
        //     max-width: none;
        //     width: 100%;
        //     ul{
        //         max-width: 100%;
        //         min-width: 1vw;
        //         width: 100%;
        //     }
        // }
    }
}
</style>