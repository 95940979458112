<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h3>{{$t('message.user')}}</h3>
            <input type="text" id="usuarioLogin" :class="validez.validezMail" :placeholder="$t('message.user')"  v-model.trim="usuario.email">
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const usuario = inject('usuario')
const validez = inject('validez')
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>