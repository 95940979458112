<template>
    <div class="col-11 d-flex flex-column justify-content-center align-items-center tablas px-0">
        <div class="scroll d-flex justify-content-start align-items-center">
            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link active" id="perfil-tab" data-bs-toggle="tab" data-bs-target="#perfil" type="button" role="tab" aria-controls="perfil" aria-selected="true">{{ $t('message.profile') }}</button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link " id="productos-tab" data-bs-toggle="tab" data-bs-target="#productos" type="button" role="tab" aria-controls="productos" aria-selected="false"><h3>{{ $t('message.productsV2') }}</h3> <i class="productos"></i></button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link" id="demos-tab" data-bs-toggle="tab" data-bs-target="#demos" type="button" role="tab" aria-controls="demos" aria-selected="false"><h3>{{ $t('message.demos') }}</h3> <i class="demos"></i></button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link" id="cupones-tab" data-bs-toggle="tab" data-bs-target="#cupones" type="button" role="tab" aria-controls="cupones" aria-selected="false"><h3>{{ $t('message.coupons') }}</h3><i class="cupones"></i></button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.tablas{  
    margin-top: 10px;
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid #70707023;
    ul{
        background-color: transparent;
        padding: 8px 0;
    }
    .nav-item{
        padding: 0;
    }
    .col{
        max-width: max-content;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-right: 1px solid #70707023;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        h3{
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
        }
        i{
            height: 25px;
            width: 30px;
            background-repeat: no-repeat;
            display: none;
            background-position: center;
        }
        @media (min-width: 992px) {
            color: #0E1133;
            h3{
                color: #0E1133;
            }
        }
    }
    .nav-link.active{
        font-weight: 700;
        border-color: transparent;
        color: var(--colorSecondary);
        background-color: transparent;
        border-radius: 0;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            left: 0;
            background-color: var(--colorPrimary);
            
        }
        h3{
            display: none;
        }
        i{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .productos{
            background-image: url('../../img/panel-icons/productos-icon.svg');
        }
        .demos{
            background-image: url('../../img/panel-icons/demos-icon.svg');
        }
        .cupones{
            background-image: url('../../img/panel-icons/cupones-icon.svg');
        }
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
        }
    }
    .scroll{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        ul{
            min-width: 150vw;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
    
}
</style>