<template>
    <div class="row justify-content-center row__width titulo">
        <div class="col-12 d-flex justify-content-between align-items-center flex-cambio">
            <h2>{{ $t('message.myAffilities') }}</h2>
            <h3>{{ $t('message.detailsAffilities') }}</h3>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    .contenedor{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 33px;
        background-color: var(--bgPrincipal);
        height: 55px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
        @media (min-width: 992px) {
            border-radius: 50px;
            padding: 22px 10px;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 18px;
            font-weight: 600;
            padding: 5px 0px;
            @media (min-width: 992px) {
                font-weight: 500;
                padding-top: 0;
                padding-bottom: 5px;
            }
        }
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            margin-left: 15px;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 14px;
                width: 80%;
            }
        }
        
        h4{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 2px;
        }
        h5{
            color: #B8C1DB;
            font-size: 14px;
            font-weight: 500;
        }
        .position{
            position: absolute;
            top: 7px;
            right: 15px;
            height: 7px;
        }
        
    }
}
.flex-cambio{
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: center;
        h3{
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 0;
        }
    }
}
</style>