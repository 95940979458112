<template>
    <div class="row justify-content-center resumen d-none" id="resumen">
        <div class="col-11 d-flex justify-content-center align-items-center py-3">
            <div class="row justify-content-center  row__width">
                <div class="col col-lg-4 d-flex justify-content-center align-items-center position-relative px-0 col-perso">
                    <img :src="product.presentation.image" alt="" class="img">
                    <div class="row justify-content-center row__width position">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <div class="row justify-content-center row__width row__slide">
                                <div class="col-12 d-flex justify-content-start align-items-start arriba">
                                    <a href=""><img src="../../img/home-icons/cursos-icons/verificado.svg" alt="" class="height-img"></a>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-7 d-flex justify-content-start align-items-center pe-0">
                                            <h4>{{$t('message.produced')}}</h4><h5>{{$t('message.mpc')}}</h5>
                                        </div>
                                        <div class="col d-flex justify-content-end align-items-center">
                                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="height-img2"><h6>{{product.reviews.average}}</h6><p >{{ product.reviews.count ? `(${product.reviews.count})` : $t('message.new') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-denter align-items-center ps-4">
                    <div class="row  justify-content-center row__width descripcion">
                        <div class="col-12 d-flex justify-content-start align-items-center">
                            <h2>{{product.name}}</h2>
                        </div>
                        <div class="col-12 d-flex justify-content-start align-items-center pt-3">
                            <div class="col-2 d-flex justify-content-start align-items-center colaboradores" :class="product.collabs.length > 0 ? 'creador' : ''">
                                <img :src="product.owner.image" alt="" style="border-radius: 50px; width: 40px; height: 40px; object-fit: cover;">
                                <h3>{{$t('message.creator')}}</h3>
                                <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ps-2 d-none">
                            </div>
                            <div class="d-flex justify-content-start align-items-center colaboradores ps-2" v-if="product.collabs && product.collabs.length > 0">
                                <img :src="product.collabs[0].image" alt="" style="border-radius: 50px; width: 40px; height: 40px; object-fit: cover;">
                                <div class="colaboran" v-if="product.collabs.length >=3"><p>+{{ product.collabs.length }}</p></div>
                                <h3 class="colaboran_h3">{{$t('message.collaborate')}}</h3>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center horarios pt-3">
                            <div class="row justify-content-center row__width py-2">
                                <div class="col-12 d-flex justify-content-start align-items-center">
                                    <div class="d-flex justify-content-center align-items-center border2 pe-3">
                                        <img src="../../img/home-icons/cursos-icons/participantes.svg" alt=""><h6>{{ product.participants.length }}</h6>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center px-2">
                                        <h3>{{product.type}}</h3>
                                    </div>
                                    <div class="d-flex justify-content-start align-items-center  px-2" v-if="product.totalTimeVideo">
                                        <img src="../../img/home-icons/cursos-icons/horario.svg" alt="">
                                        <h6>{{ product.totalTimeVideo[0] < 1 ? '~1 hora' : product.totalTimeVideo[0] + ' horas' }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 d-flex justify-content-denter align-items-center px-0">
                    <router-link to="/carrito/2" class="btn__primarioV2" @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">
                        Comprar ahora
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, onUnmounted, watch, inject } from 'vue';
import { storeToRefs } from "pinia"
import { useCartStore } from "../../stores/cart"
import { useRoute, useRouter } from 'vue-router';
const router = useRouter()
const route = useRoute()

const carts = useCartStore();
const {  } = storeToRefs(carts);
const { addtoCart } = carts

let product = inject('product')

const selectOption = (price) => {
    if(!product.value.acquired){
        product.value.selectedOption = price
    }
}

let checkFirst = (index) => computed(() => index === 0);

selectOption(product.value.payments[0])

const showBanner = () => {
    const resumen = document.getElementById('resumen')
    // Analizar ancho de pantalla
    if(window.innerWidth >= 992) {
        window.onscroll = function() {
            let y = window.scrollY;
            //console.log(y);
            if( y >= 95 ){
                resumen.classList.remove('d-none')
            }else{
                resumen.classList.add('d-none')
            }
        }
    }
    else {
        resumen.classList.add('d-none')
    }
}

onMounted(() => {
    window.addEventListener("scroll", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("scroll", showBanner)
})

</script>


<style lang="scss" scoped>
.resumen{
    background-color: var(--bgPrincipal);
    z-index: 5;
    box-shadow: 0px 3px 10px #00000045;
    border-radius: 0px 0px 15px 15px;
    position: fixed;
    top: 95px;
    width: 100%;
    transition: all 3s ease;
    h2{
        font-weight: 400;
        font-size: 16px;
        color: #697891;
    }
    .position{
        position: absolute;
        top: 0;
        min-height: 100%;
        min-width: 160px;
        h3{
            font-weight: 700;
            font-size: 16px;
            color: var(--bgPrincipal);
        }
        h4{
            font-weight: 400;
            font-size: 9px;
            color: var(--bgPrincipal);
        }
        h5{
            font-weight: 700;
            font-size: 9px;
            color: var(--bgPrincipal);
            padding-left: 5px;
        }
        h6{
            font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 11px;
            color: #FEB230;
            padding-top: 2px;
        }
        p{  font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 11px;
            color: var(--bgPrincipal);
            padding-left: 3px;
        }
        .row__slide{
            position: relative;
            min-height: 100% !important;
            min-height: 250px;
            z-index: 2;
            .arriba{
                position: absolute;
                top: 5%;
            }
            .abajo{
                position: absolute;
                bottom: 5%;
            }
        }
        &::before{
            content: '';
            position: absolute;
            background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
            border-radius: 0px 0px 15px 15px;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
        
    }
    .col-perso{
        max-width: max-content;
        .img{
            width: 100%;
            height: 140px;
            max-width: 240px;
            object-fit: cover;
            border-radius: 15px;
        }
        .height-img{
            height: 35px;
        }
        .height-img2{
            height: 10px;
            margin-bottom: 3px;
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 400;
            font-size: 15px;
            padding-left: 10px;
            text-align: left;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            img{
                margin-left: 5px;
            }
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
            position: relative;
            min-width: 120px;
            .colaboran{
                background-color: #697891;
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                min-width: 42px;
                min-height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 15%;
            }
            .colaboran_h3{
                padding-left: 35px;
            }
            .azul{
                background-color: var(--colorSecondary);
            }
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .horarios{
        h6{
            color: #7D93B2;
            font-weight: 500;
            font-size: 14px;
            padding-left: 5px;
        }
        .border2{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        h3{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 15px;
            padding: 0;
            line-height: 1.3em;
        }
    }
    .mirar{
        border: 2px solid var(--colorSecondary);
        border-radius: 25px;
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        height: 50px;
        width: 100%;
        position: relative;
        margin-top: 15%;
        img{
            padding-right: 10px;
        }
        span{
           position: absolute;
           text-transform: uppercase;
           color: var(--colorPrimary);
           font-weight: 600;
           font-size: 14px;
           background-color: var(--bgPrincipal);
           top: -10px;
           right: 15px;
           min-width: 40%;
        }
    }
}
@media (max-width: 991px){
    .resumen{
        display: none !important;
    }
    
}
</style>