<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
            <h3>{{$t('message.user')}}</h3>
            <input type="text" :placeholder="$t('message.user')" v-model.trim="usuariosRegistro.email" id="registroMail"
             :class="validez.validezMail">
        </div>
    </div>
</template>

<script setup>
// Importo mi método
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { inject } from "vue";

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
const validez = inject('validez')
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {cambioHeader, usuariosRegistro, mailValido} = storeToRefs(users);
const {} = users;
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>