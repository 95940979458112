<template>
    <div class="row justify-content-center servicios row__width">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center align-items-lg-start flex-cambio px-0" :class="center == true ? 'align-items-lg-center' : ''">
            <h2 class="d-none d-lg-flex" v-if="sinResultados">{{ $t('message.popularCategories') }}</h2>
            <div class="row justify-content-start align-items-start row__width" :class="center == true ? 'justify-content-center' : ''">
                <ActividadesServicios v-for="(servicio, id) in servicios" :key="id" :route="servicio.route"
                :titulo="servicio.nombre" :src="servicio.src" :id="id" :for="id" :check="servicio.check" @mouseover="servicio.check = true" @mouseleave="servicio.check = false"/>
                <!-- <VerMasActividadesServicios/> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import ActividadesServicios from "./ActividadesServicios.vue";
import VerMasActividadesServicios from "./VerMasActividadesServicios.vue";

const props = defineProps({
    center: Boolean,
    sinResultados: {
        default: true
    }
})
const servicios = ref([
    {nombre: "Cursos de fotografía", src: require('@/img/home-icons/fotografia.png'), route: 'ResultadosCursos', check: false},
    {nombre: "Directorio de profesionales", src: require('@/img/home-icons/clases-particulares.png'), route: 'ResultadosProfesores', check: false},
    {nombre: "Expo Photo Master Class", src: require('@/img/home-icons/expo-photo.png'), route: 'https://www.expophotomasterclass.com/', check: false},
    /* {nombre: "Workshops privados", src: require('@/img/home-icons/workshops.png'), check: false},
    {nombre: "Descarga Presets", src: require('@/img/home-icons/presets.png'), check: false}, */
])
</script>

<style lang="scss" scoped>
.servicios{
    margin-top: 30px;
    @media (min-width: 992px) {
        margin: 60px 0 0 0;
    }
    @media (min-width: 1920px) {
        margin: 3% 0 4% 0;
    }
}
h2{
    font-weight: 600;
    font-size: 18px;
    color: #697891;
    padding-bottom: 20px;
}
.flex-cambio{
    display: flex;
    flex-direction: initial;
    @media (min-width: 992px) {
        flex-direction: column;
    }
}
</style>