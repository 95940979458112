<template>
    <div class="row justify-content-center testimonios row__width position-relative">
        <div class="col-12 col-lg-11 d-flex flex-column justify-content-center align-items-center px-0">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-between justify-content-lg-start align-items-center">
                            <h2>{{$t('message.testimonials')}}</h2>
                            <div class="d-flex justify-content-end align-items-center position-relative imgs ps-lg-4">
                                <img src="../../img/home-icons/testimonios-1.svg" alt="" class="img1">
                                <img src="../../img/home-icons/cursos-icons/colaboran-ejemplo.svg" alt="" class="img2">
                                <img src="../../img/home-icons/cursos-icons/creador-ejemplo.png" alt="" class="img3">
                                <span>{{$t('message.plus12')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-start align-items-center">
                    <h4>{{$t('message.opinionMatters')}}</h4>
                </div>
            </div>
        </div>
        <swiper
            :slidesPerView="1"
            :navigation="{
                nextEl: '#nextSlider8boton',
                prevEl: '#prevSlider8boton'
            }"
            :pagination="false"
            :loop="true"
            :breakpoints="{
                375: {
                    spaceBetween: 20
                },
                992: {
                    spaceBetween: -320
                },
                1100: {
                    spaceBetween: -380
                },
                1300: {
                    spaceBetween: -430
                },
                1400: {
                    spaceBetween: -470
                },
                1800: {
                    spaceBetween: -550
                },
                1800: {
                    spaceBetween: -600
                },
            }"
            class="mySwiper" >
            <swiper-slide v-for="(slide, id) in slider" class="d-flex justify-content-center align-items-center">
                <div class="col-12 d-flex flex-column justify-content-start align-items-center testimonio">
                    <div class="row justify-content-center row__width pb-3 pb-lg-4">
                        <div class="col-9 d-flex justify-content-start align-items-center px-0">
                            <img src="../../img/home-icons/testimonios-1.svg" alt="" class="me-2">
                            <div class="d-flex flex-column justify-content-center align-items-start">
                                <h5>{{$t('message.creatorName')}}</h5>
                                <h6>{{$t('message.mexico')}}</h6>
                            </div>
                        </div>
                        <div class="col-3 d-flex justify-content-end align-items-start px-0">
                            <img src="../../img/home-icons/estrella.svg" alt="">
                            <img src="../../img/home-icons/estrella.svg" alt="">
                            <img src="../../img/home-icons/estrella.svg" alt="">
                            <img src="../../img/home-icons/estrella.svg" alt="">
                            <img src="../../img/home-icons/estrella.svg" alt="">
                        </div>
                    </div>
                    <div class="row justify-content-center row__width scroll">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos…
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos…
                            </p>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="col-12 d-flex justify-content-center align-items-center botones">
            <button id="prevSlider8boton" class="prev"><img src="../../img/home-icons/arrow-gris-left.svg" alt=""></button>
            <button id="nextSlider8boton" class="next"><img src="../../img/home-icons/arrow-rigth-gris.svg" alt=""></button>
        </div>
    </div>
</template>

<script setup>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from "swiper/vue";

    // import required modules
    import { Navigation } from "swiper";
    import { ref } from 'vue';
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const slider = ref([
        {modalidad: 0, botonProducto: 0},
        {modalidad: 3, botonProducto: 1},
        {modalidad: 3, botonProducto: 2},
    ])

</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 0 5px;
    .swiper-wrapper{
        margin: 20px 0;
    }
}
.testimonios{
    padding-top: 5%;
    h2{
        font-size: 28px;
        font-weight: 700;
        color: var(--colorSecondary);
        text-align: left;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            border-radius: 5px;
            width: 25%;
            height: 4px;
            background-color: var(--colorSecondary);
            bottom: -30%;
        }
    }
    h4{
        color: #7D93B2;
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        margin-top: 10%;
        @media (min-width: 992px) {
            margin: 2% 0 0.5% 0 !important;
        }
    }
    span{
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
        border-radius: 52px;
        width: 42px;
        height: 42px;
        background-color: var(--colorSecondary);
        font-weight: 500;
        font-size: 15px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
    
    .imgs{
        width: 55%;
        @media (min-width: 768px) {
            width: 20%;
        }
        @media (min-width: 1366px) {
            width: 14%;
        }
        @media (min-width: 1800px) {
            width: 11%;
        }
    }
    .img1{
        position: absolute;
        left: 29%;
        z-index: 3;
    }
    .img2{
        position: absolute;
        left: 44%;
        z-index: 2;
    }
    .img3{
        position: absolute;
        left: 60%;
    }
    .testimonio{
        background-color: #F5F5F5;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        min-height: 200px;
        padding: 20px 30px 20px 20px;
        margin: 20px 0px;
        
        h5{
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 5px;
            text-align: left;
        }
        h6{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            text-align: left;
        }
        .scroll{
            overflow-y: scroll;
            max-height: 100px;
            &::-webkit-scrollbar{
                background-color: rgba(219, 219, 219, 0.25);
                width: 8px;
                border-radius: 200px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #DBDBDB !important;
                border-radius: 20px;
            }
            &::-webkit-scrollbar-track{
                background-color: rgba(219, 219, 219, 0.25);
                border-radius: 20px;
            }
        }
        @media (min-width: 992px) {
            padding: 50px 40px;
            max-width: 60%;
            .scroll{
                max-height: 70px;
            }
        }
    }
    .botones{
        margin: 20px 0px;
        button{
            background-color: var(--bgPrincipal);
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #F5F5F5;
            border-radius: 21px;
            width: 42px;
            height: 42px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
@media (min-width: 992px) {
    .mySwiper{
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
    .swiper-slide{
        transition: 1s;
    }
    .swiper-slide-prev, .swiper-slide-next, .swiper-slide-duplicate{
        opacity: 0.5;
    }
    .swiper-slide-active{
        opacity: 1;
    }
    .botones{
        button{
            position: absolute;
            z-index: 5;
            top: calc(100% / 1.6);
        }
        .prev{
            left: 14.5%;
        }
        .next{
            right: 14.5%;
        }
    }
    .swiper-pagination{
        position: absolute;
        bottom: 0%;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .swiper-pagination-bullet{
            transition: 0.2s;
        }
        .swiper-pagination-bullet-active{
            width: 34px;
        }
    }
    
}
</style>