<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-start align-items-center">
                <h3>Añadir imagen como url</h3>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-center input-m-empty">
                <input type="text" placeholder="www.google.com/image" v-model="multimedia.image">
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

let multimedia = inject('multimedia')
let mediaTemp = inject('mediaTemp')

</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    @media (max-width: 991px) {
        width: 83%;
    }
}
button{
    background-color: #fff;
    border: 2px solid #E9E3E3;
    outline: 0;
    border-radius: 25px;
    height: 50px;
    width: 50px;
}
.error_input input{
    border-color: #FF6A6A;
    &:hover, &:focus{
        border-color: var(--colorPrimary);
    }
}
</style>