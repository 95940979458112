<template>
    <div class="row justify-content-center proveedores mt-4 row__width" v-if="sliderLength >= 1">
        <swiper
            :slidesPerView="3"
            :navigation="{
                nextEl: '#nextSliderFotosboton',
                prevEl: '#prevSliderFotosboton'
            }"
            :pagination="{
                el: '#pagMarcas',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :spaceBetween="20"
            :loop="false"
            :grid="{
                rows: 3,
                fill: 'column'
            }"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    grid: {
                        rows: 0,
                    },
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 2,
                    slidesPerGroup: 9,
                    grid: {
                        rows: 3,
                        columns: 3,
                        fill: 'column'
                    }
                },
                1420: {
                    slidesPerView: 3,
                    grid: {
                        rows: 3,
                        fill: 'column'
                    },
                },
            }"
            class="mySwiperDirectorio" :class="filtrosDesplegados == true ? 'd-none' : '' || mapaDesplegado == true ? 'd-none' : '' ">
                <swiper-slide v-for="(slide, id) in sliderLength">
                    <div class="col d-flex justify-content-center align-items-center slide px-0">
                        <div class="row justify-content-center row__width h-100">
                            <div class="col d-flex justify-content-center align-items-center position-relative imagenes pe-0">
                                <img :src="childProducts[id].image" alt="" class="ejemplo w-100">
                                <div class="filtro">
                                    <div class="col-12 d-flex justify-content-end align-items-center d-lg-none">
                                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6>{{$t('message.rating')}}</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 d-flex justify-content-center align-items-center">
                                <div class="row justify-content-center row__width descripcion">
                                    <div class="col-12 d-flex justify-content-start align-items-center pt-3 pb-2 px-0">
                                        <h5>{{ childProducts[id].names[lang] }}</h5><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2" v-if="childProducts[id].verified == true">
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center pb-2 px-0">
                                        <img src="../../img/home-icons/mexico-bandera.svg" alt="" class="me-2">
                                        <h3>{{ childProducts[id].location }}</h3>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center pb-3 pb-lg-1 px-0">
                                        <h4>{{ childProducts[id].description[lang] }}</h4>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center d-none d-lg-flex px-0 py-2">
                                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6 style="margin-top: 5px; padding-left: 3px;">{{$t('message.rating')}}</h6>
                                    </div>
                                    <BTNPrimario url="/perfil-marca/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.viewProfile')" class="px-0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
        </swiper>
        <swiper
            :slidesPerView="3"
            :navigation="{
                nextEl: '#nextSliderFotosboton',
                prevEl: '#prevSliderFotosboton'
            }"
            :pagination="{
                el: '#pagMarcas',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :spaceBetween="20"
            :loop="true"
            :grid="{
                rows: 2,
                fill: column
            }"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    grid: {
                        rows: 0,
                    },
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 2,
                    grid: {
                        rows: 3,
                    }
                },
                1920: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    grid: {
                        rows: 3,
                    },
                },
            }"
            class="mySwiperDirectorio d-none ps-2" :class="filtrosDesplegados == true ? 'd-lg-block' : '' || mapaDesplegado == true ? 'd-lg-block' : '' ">
                <swiper-slide v-for="(slide, id) in sliderLength" style="height: auto;">
                    <div class="col d-flex justify-content-center align-items-center slide px-0">
                            <div class="row justify-content-center row__width h-100">
                                <div class="col-3 d-flex justify-content-center align-items-center position-relative imagenes pe-0">
                                    <img :src="childProducts[id].image" alt="" class="ejemplo w-100">
                                    <div class="filtro">
                                        <div class="col-12 d-flex justify-content-end align-items-center d-lg-none">
                                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6>{{$t('message.rating')}}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width descripcion">
                                        <div class="col-12 d-flex justify-content-start align-items-center pt-3 pb-2 px-0">
                                            <h5>{{ childProducts[id].names[lang] }}</h5><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2" v-if="childProducts[id].verified == true">
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-2 px-0">
                                            <img src="../../img/home-icons/mexico-bandera.svg" alt="" class="me-2">
                                            <h3>{{ childProducts[id].location }}</h3>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center pb-3 pb-lg-1 px-0">
                                            <h4>{{ childProducts[id].description[lang] }}</h4>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center d-none d-lg-flex px-0 py-2">
                                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6>{{$t('message.rating')}}</h6>
                                        </div>
                                        <BTNPrimario url="/perfil-marca/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.viewProfile')" class="px-0"/>
                                    </div>
                                </div>
                            </div>
                    </div>
                </swiper-slide>
        </swiper>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>
import prueba from "./prueba.vue";


import BTNPrimario from "../Botones/PrimarioRutas.vue";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Grid, Pagination, column } from "swiper";

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const url = process.env.VUE_APP_MEILISEARCH_URL
const urlApi = '/multi-search'

//Setea parametros de busqueda para el post
const search = ref({
    queries:[{
        indexUid: 'brands',
        q:''
    }]
})

//Precarga parametros de resultados de busqueda
const childProducts = ref(0)
const sliderLength = ref(0)

//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
}
setLang()

//Hago un post del usuario x
const fetchSearch = async () => {
    if (sessionStorage.getItem('searchValue')){
        //console.log(sessionStorage.getItem('searchValue'))
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = result.data.results[0].hits
            sliderLength.value = result.data.results[0].hits.length
            //console.log(result.data.results)
            sessionStorage.removeItem('searchValue')
        })
    }
    catch (error) {
    // console.log(error)
    }
}
fetchSearch()

watch( reSearch, async () => {
    if (sessionStorage.getItem('searchValue')){
        //console.log(sessionStorage.getItem('searchValue'))
        X.value = JSON.parse(sessionStorage.getItem('searchValue'))
        search.value.queries[0].q = X.value
    }
    setLang()
    try {
        await axios.post(url+urlApi, search.value).then((result) => {
            childProducts.value = result.data.results[0].hits
            sliderLength.value = result.data.results[0].hits.length
            //console.log(result.data.results)
            sessionStorage.removeItem('searchValue')
        })
    }
    catch (error) {
    // console.log(error)
    }
})
</script>

<style lang="scss" scoped>
// .mySwiper{
//     padding: 20px 5px;
// }
// .swiper-slide{
//     height: calc((100% - 30px) / 2) !important;
// }
.proveedores{
    .slide{
        height: auto;
        height: 220px;
        margin: 0px 20px 20px 0px !important;
    }
    h6{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
    }
    h5{
        color: #374557;
        font-size: 22px;
        font-weight: 700;
        text-align: left;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        max-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .filtro{
        position: absolute;
        top: 15%;
        right: 20px;
    }
    .imagenes{
        min-width: 158px;
        max-width: 158px;
        padding-left: 0;
        img{
            box-shadow: 0px 3px 3px #00000014;
            border-radius: 8px;
        }
    }
    @media (min-width: 992px) {
        min-height: 210px;
        .imagenes{
            max-width: 35%;
            min-width: 35%;
        }
        .ejemplo{
            height: 85%;
            width: 100%;
        }
    }
}
.mySwiperDirectorio{
    display: flex;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 4) !important;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>