<template>
    <button class="btn__inicio" type="button">
        <template class="d-none d-lg-flex">
            {{$t('message.saveInformation')}} 
        </template>
        <img src="../../img/subir-productos-icons/cursos-icons/disk.svg" alt="" class="d-lg-none">
    </button>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.btn__inicio{
    border: 2px solid #B8C1DB;
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 29px;
    template{
        color: #B8C1DB;
        border-radius: 27px;
        font-size: 16px;
        font-weight: 600;
    }
    &:hover, &:focus{
        background-color: #B8C1DB;
        border-color: transparent;
        template{
            color: var(--colorBTNPrimario);
        }
    }
    @media (max-width: 991px) {
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }
}
</style>