<template>
  <a class="google" :href="urlPrueba">
    <img src="../../img/roles-icons/google.svg" alt="">
    {{textoBTNGoogle}}
  </a>
</template>

<script setup>
import axios from "axios"
import { ref, onMounted } from "vue";

const url = process.env.VUE_APP_BASE_URL
const urlGoogle = "/api/auth/google/url"



const props = defineProps({
  textoBTNGoogle: '',
})

const urlPrueba = ref('')

const prueba = onMounted( async() => {
  try {
    await axios.get(url+urlGoogle).then((result) => {
      //console.log(result)
      urlPrueba.value = result.data
    })
  }
  catch (error) {
    console.log(error)
  }
})

</script>

<style lang="scss" scoped>
.google{
    border: 2px solid var(--colorSecondary);
    color: var(--colorSecondary);
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px;
    text-decoration: none;
    img{
        margin-right: 10px;
    } 
}
</style>