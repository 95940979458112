import { defineStore } from 'pinia'
import { onBeforeMount, ref, watch } from 'vue'


export const useCartStore = defineStore('cart', () => {
  let cart = ref([])
  let totalPrice = ref(0)
  let totalDiscount = ref(0)
  let arrayCheckout = ref({
    total: 0,
    precio: 0,
    promo: 0,
    precioTotal: 0,
    promoTotal: 0
  })
  let coinSelected = ref(JSON.parse(localStorage.getItem('coin')))
  let exchangeUpdate = ref(false)

  onBeforeMount(() => {
    if (localStorage.getItem('Cart')){
        cart.value = JSON.parse(localStorage.getItem('Cart'))
        console.log(cart.value, 'cart')
        setCheckout()
    }
  })

  //Contador de precios - OPTIMIZAR
  const setCheckout = () => {
    if(localStorage.getItem('Cart')){
      cart.value = JSON.parse(localStorage.getItem('Cart'))
    }
    // console.log(arrayCheckout.value)
    localStorage.setItem('Cart', JSON.stringify(cart.value))
    // console.log(arrayCheckout.value)
  }

  const addtoCart = (product) => {
    console.log(product)
    if (localStorage.getItem('Cart')) {
      cart.value = JSON.parse(localStorage.getItem('Cart'))
    }
    console.log(product.selectedOption.payment_type)
    const partial_products = cart.value.some(item => item.price.payment_type == 'partial')
    console.log(partial_products)
    if (product.selectedOption.payment_type == 'partial' || partial_products) {
      cart.value = []
    }
    let params = {
      name: product.name,
      presentation: product.presentation,
      image: product.presentation[0].image,
      video: product.presentation[0].video,
      price: product.selectedOption,
      productId: product.product_id
    }
    if (cart.value.presentation) {
      const image_item = cart.value.presentation.filter(item => item.image)[0];
      const video_item = cart.value.presentation.filter(item => item.video)[0];
    
      params.image = image_item ? image_item.image : null;
      params.video = video_item ? video_item.video : null;
    }
    console.log(params)
    cart.value.push(params)
    localStorage.setItem('Cart', JSON.stringify(cart.value))
    setCheckout()
    managePricesCart()
  }

  const deleteCart = (id) => {
    if (localStorage.getItem('Cart')){
        cart.value = JSON.parse(localStorage.getItem('Cart'))
        cart.value.splice(id, 1);
        localStorage.setItem('Cart', JSON.stringify(cart.value))
        setCheckout()
    }
  }

  const managePricesCart = () => {
    if(cart.value.length > 0){
      arrayCheckout.value.total = null
      arrayCheckout.value.precioTotal = 0
      arrayCheckout.value.promoTotal = 0
      cart.value.forEach(prod => {
        if (prod.price.payment_type == "one_time") {
          prod.price.showPrice = prod.price.price
          if(prod.price.discount > 0){
            prod.price.realPrice = parseFloat((prod.price.showPrice - (prod.price.showPrice * (prod.price.discount / 100))).toFixed(2))
          } else{
            prod.price.realPrice = prod.price.showPrice
          }
        } else if (prod.price.payment_type == "partial") {
          prod.price.showPrice = prod.price.price
          prod.price.realPrice = prod.price.showPrice
        }
         else {
          // producto gratuito
          prod.price.realPrice = 'GRATIS'
          prod.price.showPrice = null
        }
        arrayCheckout.value.total = parseFloat((arrayCheckout.value.total + prod.price.showPrice).toFixed(2))
        arrayCheckout.value.precioTotal = parseFloat((arrayCheckout.value.precioTotal + prod.price.realPrice).toFixed(2))
        arrayCheckout.value.promoTotal = parseFloat((arrayCheckout.value.promoTotal + (prod.price.showPrice * prod.price.discount / 100)).toFixed(2))
      })
      localStorage.setItem('Cart', JSON.stringify(cart.value))
      console.log(arrayCheckout.value)
    }
    else {
      arrayCheckout.value = {
        total: 0,
        precio: 0,
        promo: 0,
        precioTotal: 0,
        promoTotal: 0
      }
    }
    exchangeUpdate.value = !exchangeUpdate.value
  }

  return { cart, totalPrice, totalDiscount, arrayCheckout, setCheckout, addtoCart, deleteCart, managePricesCart, coinSelected, exchangeUpdate }
})