<template>
    <div class="col-4 d-flex flex-column justify-content-center align-items-center" v-for="(rol, id) in rolesDisponibles" 
    :class="rol.name == 'Guest' ? 'd-none' : ''">
        <input type="radio" :id="rol.id" name="roles" :value="rol.id" v-model="idUser.team" :checked="false">
        <label :for="rol.id">
            <img src="../../img/roles-icons/estudiante.svg" alt="" v-if="rol.name == 'Student'">
            <img src="../../img/roles-icons/profesor.svg" alt="" v-else-if="rol.name == 'Professor'">
            <img src="../../img/roles-icons/admin.svg" alt="" v-else-if="rol.name == 'Brand'">
            <img src="../../img/roles-icons/admin.svg" alt="" v-else-if="rol.name == 'Master Class'">
            <img src="../../img/roles-icons/admin.svg" alt="" v-else-if="rol.name == 'Administrator'">
        </label>
        <h3>{{ rol.name }}</h3>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../stores/user";
import axios from "axios"
import { ref } from 'vue'
import { onMounted } from "vue";

const url = process.env.VUE_APP_BASE_URL
const urlUser = '/api/user/'
const urlTeams = '/teams'
const rolesDisponibles = ref([''])



// const roles = onMounted(async() => {   
//     idUser.value = JSON.parse(localStorage.getItem('idData'))
//     idUser.value.team = 0
//     let id = idUser.value.id
    
//     //console.log(idUser.value)
//     try {
//         await axios.get(url+urlUser+id+urlTeams).then((result) => {
//             console.log(result)
//             //let resultParse = JSON.parse(result)
//             rolesDisponibles.value =  result.data.data
//             console.log( rolesDisponibles.value)
//         })
//     }
//     catch (error) {
//         console.log(error)
//     }
//     //console.log(rolesDisponibles.value)
// })

const users = useUsersStore();

const { datosUsuario, idUser,  } = storeToRefs(users);
const { consoleLogPrueba, usuarioData } = users;
</script>


<style lang="scss" scoped>
input[type=radio]{
    display: none;
}
input[type=radio] + label{
    height: 86px;
    min-width: 86px;
    overflow: hidden;
    display: flex;
    justify-content: center;     
    align-items: flex-end;
    background-color: #F2F2F2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);  
    border-radius: 50%; 
    position: relative;
    cursor: pointer;
    img{
        width: 90%;
        height: 90%;
    }
}
input[type=radio]:checked + label{
    background-color: rgba(242, 242, 242, 0.5);
}
input[type=radio]:checked + label::before{
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid var(--colorPrimary);
    height: 100%;
    width: 100%;
}
input[type=radio]:checked ~ h3{
    color: var(--colorSecondary);
    font-weight: 700;
}
h3{
    color: #697891;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}
</style>