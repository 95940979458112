<template>
    <div class="offcanvas-body row justify-content-center row__width">
        <div class="col-11 d-flex justify-content-center align-items-start px-1">
            <div class="row justify-content-center row__width mt-2">
                <div class="col-12 div__blanco">
                    <div class="row justify-content-center">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h2 class="pt-0">{{$t('message.courses')}}</h2>
                            <p :class="cursos[id].active == true ? 'active' : ''" v-for="(curso, id) in cursos">{{cursos[id].titulo}}</p>
                            
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h2>{{$t('message.categories')}}</h2>
                            <div class="row px-3 before__texto" id="texto1">
                                <CategoriaResultadosCursos v-for="(categoria , id) in categoriasResultadosCursos" 
                                :key="id" :nombre="categoriasResultadosCursos[id].titulo" :index="id" :desaparecer="categoriasResultadosCursos[id].oculto"/>
                            </div>
                            <BTNVerMas :funcionTextoVerMas="verMas1"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item div__blanco">
                            <h2 class="accordion-header p-0" id="headingOne">
                                <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Ponentes
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row justify-content-center ponente" v-for="(ponente, id) in ponentes">
                                        <div class="col-9 d-flex justify-content-start align-items-center">
                                            <div class="position-relative">
                                                <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt=""  
                                                class="verificado" :class="ponentes[id].verificado == true ? '' : 'd-none'"> -->
                                                <img :src="ponentes[id].foto" alt="" class="perfil">
                                            </div>
                                            <h3>{{ponentes[id].nombre}}</h3>
                                        </div>
                                        <div class="col-3 d-flex justify-content-end align-items-center px-2">
                                            <button class="mas" :id="id">+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item div__blanco" v-for="(div , id) in divsEnBlanco">
                            <h2 class="accordion-header p-0" :id="`heading`+id">
                                <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+id" aria-expanded="false" :aria-controls="`collapse`+id">
                                    {{divsEnBlanco[id].titulo}}
                                </button>
                            </h2>
                            <div :id="`collapse`+id" class="accordion-collapse collapse" :aria-labelledby="`heading`+id" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    2
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item div__blanco">
                            <h2 class="accordion-header p-0" id="headingTwo">
                                <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {{$t('message.location')}}
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row justify-content-start">
                                        <div class="col-12 d-flex flex-column justify-content-start align-items-start before__texto scroll">
                                            <div class="div__contenedor" v-for="(ubicacion, id) in ubicaciones">
                                                <input type="checkbox" :id="`ubicacion`+id">
                                                <label :for="`ubicacion`+id">{{ubicaciones[id].titulo}}</label>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <BTNVerMas :funcionTextoVerMas="verMas2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                    <button class="filtrar" @click=""> 
                        <img src="../../img/home-icons/filtrar-blanco.svg" alt="">
                        {{$t('message.filter')}}
                    </button>
                    <button class="cancelar" @click="">{{$t('message.cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNVerMas from "../Botones/VerMas.vue";
    import CategoriaResultadosCursos from "../Categorias/CategoriaResultadosCursos.vue";
    import { ref } from "vue";

    const cursos = ref([
        {titulo : 'Cursos Online', active: true},
        {titulo : 'Curso Presencial', active: false},
        {titulo : 'Curso Presencial', active: false},
        {titulo : 'Curso Presencial', active: false},
        {titulo : 'Curso Presencial', active: false},
    ])
    const ponentes = ref([
        {nombre: 'Pablo Argán', foto:require('@/img/home-icons/cursos-icons/ejemplo-1.svg'), verificado: true,},
        {nombre: 'Pablo Argán', foto:require('@/img/home-icons/cursos-icons/ejemplo-1.svg'), verificado: false,},
        {nombre: 'Pablo Argán', foto:require('@/img/home-icons/cursos-icons/ejemplo-1.svg'), verificado: false,},
    ])
    const divsEnBlanco = ref([
        {titulo: 'Proveedores'},
        {titulo: 'Rango de precio'},
    ])
    const ubicaciones = ref([
        {titulo:'País'},
        {titulo:'Argentina'},
        {titulo:'España'},
        {titulo:'México'},
        {titulo:'Colombia'},
    ])

    const verMas1 = () => {
        console.log('hola 1')
        let ocultarChecks = document.querySelector('div.desaparecer')
        ocultarChecks.classList.toggle('d-none')
        document.getElementById('texto1').classList.toggle('before__texto')
    }

    const verMas2 = () => {
        console.log('hola')
    }

</script>


<style lang="scss" scoped>
.div__blanco{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 10px;
    min-height: 88px;
    margin: 0px 0px 25px 0px;
    padding: 30px;
    h2{
        font-weight: 600;
        font-size: 20px;
        color: #374557;
        padding: 15px 0px;
    }
    p, label{  
        font-weight: 400;
        font-size: 16px;
        padding: 0px 0px 7px 0px;
        color: #697891;
    }
    .active{
        font-weight: 700;
        color: var(--colorPrimary);
    }
    
}
.accordion{
    .accordion-item{
        .accordion-button{
            font-weight: 600;
            font-size: 20px;
            color: #374557;
            padding: 0px 0px;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            border-radius: 0;
            &:focus{
                box-shadow: none;
            }
        }
        .accordion-body{
            padding: 10px 0;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding-left: 5px;
            }
            .perfil{
                max-height: 41px;
            }
            .verificado{
                position: absolute;
                top: 0;
                right: 0;
                max-height: 14px;
            }
            .mas{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 28px;
                width: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--colorPrimary);
                font-size: 15px;
                font-weight: 600;
            }
            .scroll{
                max-height: 20vh;
                overflow-x: hidden;
                overflow-y: scroll;
                .div__contenedor{
                    margin-bottom: 10px;
                    width: 98%;
                }
                input{
                    display: none;
                }
                input + label{  
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0px 0px 7px 0px;
                    color: #697891;
                    width: 100%;
                    height: 44px;
                    padding-left: 15px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 0;
                }
                input:checked + label{
                    background-color: #F8F8F9;
                    border-radius: 22px;
                    font-weight: 600;
                    color: var(--colorPrimary);
                }
                &::-webkit-scrollbar{
                    background-color: rgba(219, 219, 219, 0.25);
                    width: 8px;
                    border-radius: 20px;
                }
                &::-webkit-scrollbar-thumb{
                    background-color: #DBDBDB;
                    border-radius: 50px;
                }
            }
        }
        .accordion-button::after{
            background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
            background-size: 80%;
            background-position: center;
        }
        .ponente{
            padding: 10px 0px;
            &:first-child{
                padding-top: 15px;
            }
        }
    }
}
.filtrar{
    background-color: var(--colorPrimary);
    color: #fff;
    width: 100%;
    height: 50px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 16px;
    color: #FAFAFA; 
    border: 0;
    img{
        padding: 0px 5px;
    }
}
.cancelar{
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #697891;
    border: 0;
    background-color: transparent;
    outline: 0;
    &:focus{
        outline: 0;
    }
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    border: 1px solid #ECECEC;
    border-radius: 40px;
    height: 66px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    h3{
        color: #374557;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 10px;
    }
    span{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 50px;
        width: 50px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bgPrincipal);
        img{
            max-height: 20px;
        }
    }
    .azul{
        position: absolute;
        left: 7px;
        height: 60px;
        width: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colorPrimary); 
    }
    .mapa{
        padding-left: 23%;
    }
}
</style>