<template>
    <form class="row justify-content-center align-items-start row__width">
        <Loader :loading="loading" :style="isSidebarOpen ? 'padding-left: 280px !important;'  : ''"/>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
            <div class="row justify-content-center row__width px-lg-5 pt-4 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 1" v-if="new_user">
                <div class="col col-lg-11">
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.password ? '' : 'pb-3'">
                            <h3>{{ $t('message.email') }} *</h3>
                            <input type="mail" placeholder="example@gmail.com" v-model="new_user.email">
                            <span v-if="errors.email">{{ errors.email }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start password mt-3 ps-lg-0" :class="errors.email ? '' : 'pb-3'">
                            <div class="d-flex justify-content-between w-100">
                                <h3>{{ $t('message.password') }}</h3>
                                <p class="weak" v-if="new_user.password.length <= 3 && new_user.password.length > 0">Debil</p>
                                <p class="moderate" v-if="new_user.password.length >= 4 && new_user.password.length <= 7">Moderada</p>
                                <p class="strong" v-if="new_user.password.length >= 8">Fuerte</p>
                            </div>
                            <input type="password" placeholder="******" id="editUserPassword" v-model="new_user.password">
                            <button class="btn_none d-flex justify-content-center align-items-center" @click="showPassword()" type="button"><i class="ojo"></i></button>
                            <span v-if="errors.password">{{ errors.password }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start ps-lg-0" :class="errors.plan.id ? '' : 'pb-3'">
                            <h3>{{ $t('message.language') }}</h3>
                            <div class="justify-content-center align-items-start row__width">
                                <div class="col d-flex justify-content-end align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.locale_setting"
                                        placeholder="Seleccionar lenguaje"
                                        color="info"
                                        :options="listLangs"
                                        name="team"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start ps-lg-0" :class="errors.plan.id ? '' : 'pb-3'">
                            <h3>{{ $t('message.plan') }}</h3>
                            <div class="justify-content-center align-items-start row__width">
                                <div class="col d-flex justify-content-end align-items-start position px-0" v-if="new_user.plan && new_user.plan.translation" >
                                    <input type="text" :placeholder="new_user.plan.translation.name" disabled>
                                    <router-link v-if="new_user.plan.id == 1" class="btn__primario ms-1" to="/planes-plus">{{$t('message.bePremium')}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 pt-4 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 2" v-if="new_user && new_user.personal_data">
                <div class="col col-lg-11">
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.last_name ? '' : 'pb-3'">
                            <h3>Nombre *</h3>
                            <input type="text" placeholder="Nombre" v-model="new_user.personal_data.first_name">
                            <span v-if="errors.first_name">{{ errors.first_name }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.first_name ? '' : 'pb-3'">
                            <h3>Apellido *</h3>
                            <input type="text" placeholder="Apellido" v-model="new_user.personal_data.last_name">
                            <span v-if="errors.last_name">{{ errors.last_name }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start mt-3 ps-lg-0 gender-inputs" :class="errors.country ? '' : 'pb-3'">
                            <h3>Telefono *</h3>
                            <MazPhoneNumberInput
                                name="phone_number"
                                show-code-on-list
                                color="info"
                                :preferred-countries="['MX']"
                                :ignored-countries="[]"
                                v-model="new_user.personal_data.phone_number"
                                v-model:country-code="new_user.personal_data.country_code"
                                @update="results_basic = $event"
                                :success="results_basic?.isValid"
                                :translations="{
                                    countrySelector: {
                                        placeholder: '',
                                        error: '',
                                    },
                                    phoneInput: {
                                        placeholder: 'Telefono',
                                        example: '',
                                        label: ''
                                    },
                                }"
                                no-example
                                fetch-country
                                no-use-browser-locale
                            />
                            <span v-if="errors.phone_number">{{ errors.phone_number }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.phone_number ? '' : 'pb-3'">
                            <h3>Pais *</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.personal_data.country.id"
                                        placeholder="Seleccionar pais"
                                        color="info"
                                        :options="countries"
                                        name="country"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors.country">{{ errors.country }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-start mt-3 ps-lg-0 gender-inputs" :class="errors.birthdate ? '' : 'pb-3'">
                            <h3>Género</h3>
                            <div class="col d-flex justify-content-between align-items-start position px-0">
                                <input type="radio" id="Male" value="MALE" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="Male">
                                    <div class="span"></div>
                                    <i class="hombre me-2"></i>
                                    <p>Male</p>
                                </label>
                                <input type="radio" id="Female" value="FEMALE" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="Female">
                                    <div class="span"></div>
                                    <i class="mujer me-2"></i>
                                    <p>Female</p>
                                </label>
                                <input type="radio" id="None" value="UNDEFINED" name="gender" v-model.trim="new_user.personal_data.gender">
                                <label for="None">
                                    <div class="span"></div>
                                    <i class="ambos me-2"></i>
                                    <p>None</p>
                                </label>
                            </div>
                            <span v-if="errors.gender">{{ errors.gender }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors.gender ? '' : 'pb-3'">
                            <h3>{{$t('message.birthdate')}} *</h3>
                            <div class="position">
                                <input
                                    type="text"
                                    name="reg-user-day"
                                    id="reg-user-day"
                                    v-model="day"
                                    maxlength="2"
                                    @input="validateDay"
                                    @keydown.backspace="moveToPrevious($event, 'day')"
                                    placeholder="Día"
                                    :class="errors.birthdate"
                                />-
                                <input
                                    type="text"
                                    name="reg-user-month"
                                    id="reg-user-month"
                                    v-model="month"
                                    maxlength="2"
                                    @input="validateMonth"
                                    @keydown.backspace="moveToPrevious($event, 'month')"
                                    placeholder="Mes"
                                    :class="errors.birthdate"
                                />-
                                <input
                                    type="text"
                                    name="reg-user-year"
                                    id="reg-user-year"
                                    v-model="year"
                                    maxlength="4"
                                    @input="validateYear"
                                    @keydown.backspace="moveToPrevious($event, 'year')"
                                    placeholder="Año"
                                    :class="errors.birthdate"
                                />
                            </div>
                            <span v-if="errors.birthdate">{{ errors.birthdate }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-7 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0 pb-3">
                            <h3>Imagen de perfil</h3>
                            <div class="row justify-content-center align-items-center row__width padding-left">
                                <div class="col px-0 profile align-items-start" v-if="avatar_blob">
                                    <button type="button" class="button-close" @click="eraseImage()"><img src="../../../img/subir-productos-icons/close-azul.svg" alt="" class="button-close-image"></button>
                                    <img :src="avatar_blob" alt="">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center position input-width px-lg-0" v-if="!avatar_blob">
                                    <label for="new_user_profile_pic" class="profile-plus">
                                        <img src="../../../img/panel-icons/plus.svg" alt="">
                                    </label>
                                    <input type="file" id="new_user_profile_pic" ref="fileInputAvatar" class="invisible wh-0" @change="handleFileChangeAvatar($event)">
                                </div>
                                <div class="col d-flex justify-content-start align-items-center position pe-0">
                                    <p class="ps-2 pb-1">Hasta 4 mb, formato jpg o png.</p>
                                </div>
                            </div>
                            <span v-if="errors.image">{{ errors.image }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 pt-4 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 3" v-if="new_user.professional && new_user.professional.professional_data">
                <div class="col col-lg-11">
                    <div class="row justify-content-start mt-3">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.specializations ? '' : 'pb-3'">
                            <h3>Profesiones</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professions_id"
                                        placeholder="Seleccionar profesión"
                                        color="info"
                                        :options="professions_list"
                                        name="profession"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchSpecializations($event)"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.professions">{{ errors_prof.professions }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.professions ? '' : 'pb-3'">
                            <h3>Especializaciones</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width select-bg-color-fix"
                                        v-model="new_user.professional.specializations_id"
                                        placeholder="Seleccionar especialización"
                                        color="info"
                                        :options="specializations_list"
                                        name="specialization"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        multiple
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.specializations">{{ errors_prof.specializations }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start mb-5 row_banner">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Banner</h3>
                            <button type="button" class="button-close-banner" @click="eraseBanner()" v-if="banner_blob"><img src="../../../img/subir-productos-icons/close-azul.svg" alt="" class="button-close-image"></button>
                            <label class="label blob d-flex justify-content-center align-items-center" v-if="banner_blob">
                                <img :src="banner_blob" alt="">
                            </label>
                            <label for="pa-prof-banner" class="label d-flex justify-content-center align-items-center" v-if="!banner_blob">
                                <input type="file" name="pa-prof-banner" id="pa-prof-banner" ref="fileInputBanner" placeholder="Nombre" class="d-none" @change="handleFileChangeBanner($event)">
                                <span><img src="../../../img/panel-icons/perfil-mas.svg" alt=""></span>
                            </label>
                            <span v-if="errors_prof.banner">{{ errors_prof.banner }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.slug ? '' : 'pb-3'">
                            <h3>Email profesional</h3>
                            <input type="mail" placeholder="example@gmail.com" v-model="new_user.professional.professional_data.email">
                            <span v-if="errors_prof.email">{{ errors_prof.email }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Acerca de</h3>
                            <textarea class="no-resize" placeholder="Breve descripción del profesional" v-model="new_user.professional.professional_data.about">
                            </textarea>
                            <span v-if="errors_prof.about">{{ errors_prof.about }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.country ? '' : 'pb-3'">
                            <h3>Telefono</h3>
                            <MazPhoneNumberInput
                                name="phone_number"
                                show-code-on-list
                                color="info"
                                :preferred-countries="['MX']"
                                :ignored-countries="[]"
                                v-model="new_user.professional.professional_data.phone_number"
                                v-model:country-code="new_user.professional.professional_data.country_code"
                                @update="results_prof = $event"
                                :success="results_prof?.isValid"
                                :translations="{
                                    countrySelector: {
                                        placeholder: '',
                                        error: '',
                                    },
                                    phoneInput: {
                                        placeholder: 'Telefono',
                                        example: '',
                                        label: ''
                                    },
                                }"
                                no-example
                                fetch-country
                                no-use-browser-locale
                            />
                            <span v-if="errors_prof.phone_number">{{ errors_prof.phone_number }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.phone_number ? '' : 'pb-3'">
                            <h3>Pais</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.country.id"
                                        placeholder="Seleccionar pais"
                                        color="info"
                                        :options="countries"
                                        name="country"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchStates($event); new_user.professional.professional_data.state = null"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.country">{{ errors_prof.country }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.city ? '' : 'pb-3'">
                            <h3>Estado</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.state.id"
                                        placeholder="Seleccionar estado"
                                        color="info"
                                        :options="states"
                                        name="state"
                                        search
                                        search-placeholder="Buscar en la lista"
                                        @update:model-value="fetchCities(new_user.professional.professional_data.country, $event); new_user.professional.professional_data.city = null"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.state">{{ errors_prof.state }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.state ? '' : 'pb-3'">
                            <h3>Ciudad</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="new_user.professional.professional_data.city.id"
                                        placeholder="Seleccionar ciudad"
                                        color="info"
                                        :options="cities"
                                        name="city"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <span v-if="errors_prof.city">{{ errors_prof.city }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.postal_code ? '' : 'pb-3'">
                            <h3>Direccion</h3>
                            <input type="text" placeholder="Direccion" v-model="new_user.professional.professional_data.address">
                            <span v-if="errors_prof.address">{{ errors_prof.address }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" :class="errors_prof.address ? '' : 'pb-3'">
                            <h3>Codigo postal</h3>
                            <input type="text" placeholder="Codigo postal" v-model="new_user.professional.professional_data.postal_code">
                            <span v-if="errors_prof.postal_code">{{ errors_prof.postal_code }}</span>
                        </div>
                    </div>
                    <div class="row justify-content-start mt-3">
                        <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0">
                            <h3>Redes sociales</h3>
                            <div class="d-flex justify-content-start row__width">
                                <div class="d-flex justify-content-start align-items-start px-0 pe-2" @click="selectSocialNetwork(social, index)" v-for="(social, index) in new_user.professional.professional_data.social_networks">
                                    <label class="profile-plus solid pointer" :class="actual_sn == social ? 'selected' : ''">
                                        <img src="../../../img/panel-icons/facebook-icon.svg" alt="" v-if="social.name.toLowerCase() == 'facebook'">
                                        <img src="../../../img/panel-icons/instagram.svg" alt="" v-if="social.name.toLowerCase() == 'instagram'">
                                        <img src="../../../img/panel-icons/twitter.svg" alt="" v-if="social.name.toLowerCase() == 'twitter'">
                                        <img src="../../../img/panel-icons/likedin.svg" alt="" v-if="social.name.toLowerCase() == 'linkedin'">
                                    </label>
                                </div>
                                <div class="d-flex justify-content-start align-items-start px-0" @click="newSocialNetwork()">
                                    <label class="profile-plus pointer">
                                        <img src="../../../img/panel-icons/plus.svg" alt="">
                                    </label>
                                </div>
                            </div>
                            <span v-if="errors_prof.social_networks">{{ errors_prof.social_networks }}</span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start mt-3 ps-lg-0" v-if="actual_sn">
                            <h3>Red social</h3>
                            <div class="justify-content-center row__width">
                                <div class="col d-flex justify-content-center align-items-start position px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="actual_sn.name"
                                        placeholder="Seleccionar red social"
                                        color="info"
                                        :options="social_network_list"
                                        name="social_network"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                            <h3>URL</h3>
                            <input type="text" placeholder="https://www.ejemplo.com/usuario" v-model="actual_sn.url" @input="updateSocialNetwork(actual_sn.index, 'url', $event.target.value)">
                            <span v-if="errors_prof.social_networks">{{ errors_prof.social_networks }}</span>
                            <div class="d-flex col justify-content-between align-items-center mt-3 px-0 w-100">
                                <button type="button" class="btn_save btn-valid me-3" @click="resetActualSN()">Guardar</button>
                                <button type="button" class="btn_save btn-error me-3" @click="deleteSocialNetwork(actual_sn.index)">Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-evenly row__width px-lg-5 mt-3 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 4" v-if="!actual_gallery">
                <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                    <h2>Galerías</h2>
                </div>
                <!-- <div class="col-3 d-flex flex-column justify-content-start align-items-start mt-3 gallery" @click="getGallery(0), actual_gallery = null">
                    <div class="d-flex justify-content-center row__width">
                        <h3>Galería general</h3>
                    </div>
                </div> -->
                <div class="col-6 col-lg-3 d-flex flex-column justify-content-start align-items-start mt-3 gallery" @click="getGallery(gallery), actual_gallery = null" v-for="(gallery, index) in user_gallery_list">
                    <div class="d-flex" v-if="gallery.temporary">
                        <img
                            :src="gallery.temporary[0].file"
                            alt="Lightbox Thumbnail"
                            class="lightbox-thumbnail"
                            @click="selectImage(image)"
                        />
                    </div>
                    <div class="d-flex justify-content-center row__width">
                        <h3>{{ gallery.translation.name }}</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-start row__width px-lg-5 pt-4 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 4" v-if="actual_gallery">
                <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 gallery-show">
                    
                    <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                        <h2>{{ actual_gallery.translation.name }}</h2>
                    </div>
                    <div class="d-flex justify-content-start row__width">
                        <div class="gallery-container">
                            <div class="thumbnail-list">
                                <div class="thumbnail-wrapper" v-for="(image, index) in actual_gallery.files" :key="image.id">
                                    <img
                                        :src="image.file"
                                        alt="Gallery Thumbnail"
                                        @click="openLightbox(image)"
                                        class="thumbnail"
                                    />
                                    <button type="button" class="delete-button" @click="openDeletePopup(image.id)">✖</button>
                                </div>
                                <label for="new_user_gallery_file" class="">
                                    <img src="../../../img/panel-icons/plus.svg" alt="" class="thumbnail thumbnail-new">
                                </label>
                                <input type="file" multiple id="new_user_gallery_file" ref="fileInputGallery" class="invisible d-none wh-0" @change="handleFileGallery($event)">
                            </div>
                            <div v-if="lightbox_open" class="lightbox">
                                <div class="backdrop" @click="closeLightbox()"></div>
                                <div class="lightbox-content">
                                    <div class="navigation">
                                        <span @click="previousImage" class="arrow left-arrow">❮</span>
                                        <img :src="selected_image?.file" alt="Expanded Image" class="expanded-image" />
                                        <span @click="nextImage" class="arrow right-arrow">❯</span>
                                    </div>
                                    <div class="lightbox-thumbnails" ref="thumbnail_container">
                                        <img
                                            v-for="image in actual_gallery.files"
                                            :key="image.id"
                                            :src="image.file"
                                            alt="Lightbox Thumbnail"
                                            class="lightbox-thumbnail"
                                            :class="{'lightbox-thumbnail': true, 'selected': image.id === selected_image?.id}"
                                            @click="selectImage(image)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="delete_popup_open" class="delete-popup-backdrop">
                                <div class="delete-popup">
                                    <p>Are you sure you want to delete this image?</p>
                                    <div class="">
                                        <button type="button" @click="confirmDeleteImage">Yes</button>
                                        <button type="button" @click="closeDeletePopup">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3 gallery-show" v-if="checkGalleriesTemporary()">
                    <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                        <h2>{{ $t('message.pendingApproval') }}</h2>
                    </div>
                    <div class="d-flex justify-content-start row__width">
                        <div class="gallery-container">
                            <div class="thumbnail-list">
                                <div class="thumbnail-wrapper" v-for="(image, index) in actual_gallery.temporary" :key="image.id">
                                    <img
                                        :src="image.file"
                                        alt="Gallery Thumbnail"
                                        @click="openLightboxTemporary(image)"
                                        class="thumbnail"
                                    />
                                </div>
                            </div>
                            <div v-if="lightbox_temporary_open" class="lightbox">
                                <div class="backdrop" @click="closeLightboxTemporary()"></div>
                                <div class="lightbox-content">
                                    <div class="navigation">
                                        <span @click="previousImageTemporary" class="arrow left-arrow">❮</span>
                                        <img :src="selected_image?.file" alt="Expanded Image" class="expanded-image" />
                                        <span @click="nextImageTemporary" class="arrow right-arrow">❯</span>
                                    </div>
                                    <div class="lightbox-thumbnails" ref="thumbnail_container">
                                        <img
                                            v-for="image in actual_gallery.temporary"
                                            :key="image.id"
                                            :src="image.file"
                                            alt="Lightbox Thumbnail"
                                            class="lightbox-thumbnail"
                                            :class="{'lightbox-thumbnail': true, 'selected': image.id === selected_image?.id}"
                                            @click="selectImage(image)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 5">
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 6">
            </div>
            <div class="row justify-content-center row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 7">
            </div>
            <div class="row justify-content-start row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 8" v-if="!actual_payment?.pay">
                <div class="d-flex justify-content-between ps-3 mt-3 mb-4 row__width">
                    <h2>{{ $t('message.paymentsPending') }}</h2>
                </div>
                <div class="col col-md-5 d-flex flex-column justify-content-start align-items-start mt-3 payments" v-for="(partial, index) in user_partial_list">
                    <div class="d-flex justify-content-between row__width">
                        <h2>{{ $t('message.order') }}: {{ partial.id }}</h2>
                        <h3>{{ partial.status }}</h3>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <h2>$ {{ partial.amount_payed }} / {{ partial.amount }} {{ partial.currency }}</h2>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <h3>{{ $t('message.payments') }}: {{ partial.payments_payed }} / {{ partial.payments }}</h3>
                        <button type="button" class="btn btn-primary" @click="paymentModal(partial)">{{ $t('message.seeDetails') }}</button>
                    </div>
                </div>
            </div>
            <div class="row justify-content-start row__width px-lg-5 addReferrals" :class="loading ? 'loader' : ''" v-show="actual_page == 8" v-if="actual_payment?.pay">
                <div class="col-12 d-flex flex-column justify-content-start align-items-start mt-3">
                    <div class="d-flex justify-content-between row__width">
                        <h2>{{ $t('message.completePayment') }}</h2>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <p>{{ $t('message.order') }}: {{ actual_payment.id }}</p>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <h5>{{ $t('message.amountDue') }}</h5>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <p>$ {{ actual_payment.complete ? actual_payment.complete.amount : actual_payment.next.amount }} {{ actual_payment.currency }}</p>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <h5>{{ $t('message.paymentMethod') }}</h5>
                    </div>
                    <div class="row d-flex justify-content-center align-items-center row__width methods">
                        <!-- STRIPE -->
                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                            <input type="radio" id="credit-debit" value="1" name="credit-debit" v-model="selectedMethod">
                            <label for="credit-debit" @click="mountStripe()">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5> {{ $t('message.cards') }} </h5>
                                        <div class="col d-flex justify-content-end align-items-center position2">
                                            <span class="chiquitos"><img src="../../../img/carrito-icons/visa.svg" alt=""></span>
                                            <span class="chiquitos"><img src="../../../img/carrito-icons/mastercard.svg" alt="" ></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center row__width" v-show="selectedMethod == 1">
                                    <form class="col-12 justify-content-center align-items-center px-0">
                                        <div id="card_element">
                                            <div id="card_number_element"></div>
                                            <div id="card_expiry_element"></div>
                                            <div id="card_cvc_element"></div>
                                        </div>
                                    </form>
                                </div>
                            </label>
                        </div>
                        <!-- PAYPAL -->
                        <div class="col-12 d-flex justify-content-start align-items-center px-0">
                            <input type="radio" id="paypal" value="2" name="paypal" v-model="selectedMethod">
                            <label for="paypal">
                                <div class="padding row justify-content-center row__width">
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <div class="span"></div>
                                        <h5> {{ $t('message.paypal') }} </h5>
                                        <div class="col d-flex justify-content-end align-items-center position2">
                                            <img src="../../../img/carrito-icons/paypal.svg" alt="" class="paypal">
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between row__width">
                        <button type="button" class="btn btn-primary" @click="actual_payment = null">{{ $t('message.cancel') }}</button>
                        <button type="button" class="btn btn-primary" @click="pay(selectedMethod)">{{ $t('message.pay') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
            <div class="row justify-content-center justify-content-lg-between px-lg-5 row__width">
                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center ps-lg-0">
                    <button id="save-button" ref="buttonRef" type="button" class="btn__inicio"
                        @click="actual_gallery = null"
                        v-if="actual_page == 4 && actual_gallery?.files"
                    >
                        {{$t('message.return')}}
                    </button>
                </div>
                <div class="col-6 col-lg-3 d-flex justify-content-center align-items-center pe-0">
                    <button id="edit-button" ref="buttonRefEdit" type="button" class="btn_save btn-valid"
                        @click="saveAccountInfo()"
                        v-show="actual_page == 1"
                    >
                        {{$t('message.save')}}
                    </button>
                    <button id="edit-button" ref="buttonRefEdit" type="button" class="btn_save btn-valid"
                        @click="saveBasicInfo()"
                        v-show="actual_page == 2"
                    >
                        {{$t('message.save')}}
                    </button>
                    <button id="edit-button" ref="buttonRefEdit" type="button" class="btn_save btn-valid"
                        @click="saveProfessionalInfo()"
                        v-show="actual_page == 3"
                    >
                        {{$t('message.save')}}
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column justify-content-evenly align-items-center payments-modal" v-if="actual_payment && !actual_payment.pay">
            <div class="d-flex justify-content-between row__width">
                <h5>{{ $t('message.paymentDetails') }}</h5>
            </div>
            <div class="d-flex justify-content-between row__width">
                <p>Orden: {{ actual_payment.id }}</p>
            </div>
            <div class="d-flex justify-content-between row__width">
                <h5>{{ $t('message.paymentProgress') }}</h5>
            </div>
            <div class="d-flex justify-content-between row__width">
                <p>$ {{ actual_payment.amount_payed }} / {{ actual_payment.amount }} {{ actual_payment.currency }}</p>
                <p>{{ actual_payment.status }}</p>
            </div>
            <div class="d-flex justify-content-between row__width">
                <h5>{{ $t('message.paymentHistory') }}</h5>
            </div>
            <div class="d-flex justify-content-between row__width">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>{{ $t('message.date2') }}</th>
                            <th>{{ $t('message.amount') }}</th>
                            <th>{{ $t('message.status') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in actual_payment.transactions" :key="transaction.id">
                            <td>{{ transaction.date }}</td>
                            <td>{{ transaction.amount }} {{ transaction.currency }}</td>
                            <td>{{ transaction.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-between row__width">
                <button type="button" class="btn btn-primary" @click="actual_payment.pay = true">{{ $t('message.pay') }}</button>
                <button type="button" class="btn btn-primary" @click="paymentModal(null)">{{ $t('message.cancel') }}</button>
            </div>
        </div>
    </form>
</template>

<script setup>
import TituloPanelAdmin from "../../../components/Titulo/TituloPanelAdmin.vue"
import Loader from "../../../components/Filtros/Loader.vue"
import Cancelar from "../../../components/Botones/Cancelar.vue"
import MazSelect from 'maz-ui/components/MazSelect'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill"
import { ref, provide, onBeforeMount, computed, inject, watch, nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useConfigStore } from "../../../stores/config"
import { useLanguageStore } from "../../../stores/langs"
import axios from 'axios'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { parsePhoneNumberFromString, getPhoneCode } from 'libphonenumber-js'
import { loadStripe } from '@stripe/stripe-js';

polyfillCountryFlagEmojis()

let props = defineProps({
    isSidebarOpen: Boolean
})

const { t }  = useI18n()

const users = useUsersStore()
const { headers } = storeToRefs(users)
const { url } = users

const config = useConfigStore()
const { countries, states, cities } = storeToRefs(config)
const { fetchCountries, fetchStates, fetchCities } = config

const langs = useLanguageStore()
const { listLangs } = storeToRefs(langs)
const {  } = langs

const router = useRouter()

const urlUser = '/api/user'
const urlTeams = '/api/public/teams'
const urlPlans = '/api/public/plans'
const urlProfessions = '/api/public/professions'
const urlSpecialization = '/api/public/specializations'
const urlPartials = '/api/user/orders/partials'

const actual_page = inject('actual_page')
const changePage = inject('changePage')

const loading = ref(false);

let results_basic = ref()
let results_prof = ref()

const new_user = inject('new_user')

const original_user = ref({
    id: 0,
    personal_data: {
        first_name: '',
        last_name: '',
        country_code: '',
        phone_number: '',
        birthdate: '',
        country: {
            id: ''
        },
        nickname: ''
    },
    email: '',
    plan: {
        id: ''
    },
    team: {
        id: ''
    },
    avatar: null,
    password: '',
    status: false,
    professional: {
        id: '',
        active: '',
        professional_data: {
            id: '',
            email: '',
            banner: '',
            country: {
                id: ''
            },
            state: {
                id: ''
            },
            city: {
                id: ''
            },
            address: '',
            postal_code: '',
            country_code: '',
            phone_number: '',
            social_networks: [],
            about: '',
            slug: '',
            temporary: ''
        },
        professions: [
            {
                specializations: []
            }
        ],
        portfolio: null,
        reviews: null
    }
})

const errors = ref({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    country: '',
    country_code: '',
    phone_number: '',
    birthdate: '',
    plan: '',
    team: '',
    avatar: null,
    password: ''
})

const errors_prof = ref({
    email: '',
    banner: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    country_code: '',
    phone_number: '',
    social_networks: '',
    about: '',
    professions: '',
    specializations: '',
    slug: ''
})

const plans_list = ref([])

const teams_list = ref([])

const professions_list = ref([])
const specializations_list = ref([])

const user_gallery_list = ref([])

const user_partial_list = ref([])

const actual_payment = ref(null)
const selectedMethod = ref()

const fileInputAvatar = ref(null)
const fileInputBanner = ref(null)
const fileInputGallery = ref(null)

const actual_gallery = ref(null)
const selected_image = ref(null)
const lightbox_open = ref(false)
const thumbnail_container = ref(null)
const delete_popup_open = ref(false)
const image_to_delete = ref(null)

const lightbox_temporary_open = ref(false)

const social_network_list = ref([
    {
        label: 'Facebook',
        value: 'facebook'
    },
    {
        label: 'Instagram',
        value: 'instagram'
    },
    {
        label: 'LinkedIn',
        value: 'linkedIn'
    }
])

const avatar_blob = ref(null)
const banner_blob = ref(null)

const actual_sn = ref()

const day = ref('')
const month = ref('')
const year = ref('')
const currentYear = new Date().getFullYear()

const payment_method = {
    1: "STRIPE",
    2: "PAYPAL"
}

let stripe_pk = process.env.VUE_APP_STRIPE_PK
let stripe = ref(null)
let elements = null
let card_element = ref(null)
let is_processing = ref(false);
let error_message = ref(null);
let payment_intention = ref(false)
let payment_data = ref()

const style = {
    base: {
        color: '#000000',
        fontFamily: '"Roboto", "Helvetica Neue", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',  // Puedes ajustarlo si lo ves necesario
        '::placeholder': {
            color: '#aab7c4',
        },
        padding: '12px',  // Ajuste del padding para hacer el campo más amplio verticalmente
        backgroundColor: '#ffffff',  // Fondo blanco como en la imagen
        border: '1px solid #ced4da',  // Color de borde similar al que se ve en la imagen
        borderRadius: '6px',  // Bordes más redondeados
        marginBottom: '15px',  // Añadir espacio entre los campos
        boxShadow: 'none',  // Eliminar sombras
        height: '45px',  // Controlar la altura del campo
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        borderColor: '#e3342f',  // Bordes rojos si es inválido
    },
};

let mountStripe = async () => {
  try {
    // Cargar Stripe
    stripe.value = await loadStripe(stripe_pk);
    
    let elements = stripe.value.elements();

    // card_element.value = elements.create('card', { style });
    // card_element.value.mount('#card_element');

    // Crear el campo de número de tarjeta
    card_element.value = elements.create('cardNumber', { style });
    card_element.value.mount('#card_number_element');

    // Crear el campo de expiración
    let cardExpiry = elements.create('cardExpiry', { style });
    cardExpiry.mount('#card_expiry_element');

    // Crear el campo de CVC
    let cardCvc = elements.create('cardCvc', { style });
    cardCvc.mount('#card_cvc_element');

    // console.log(cardNumber)
    console.log(card_element.value)
    // card_element.value.cardNumber = cardNumber
    console.log(card_element.value)

  } catch (error) {
    console.error('Error al inicializar Stripe:', error);
    error_message.value = 'No se pudo cargar el formulario de pago.';
  }
};

const isValidDate = computed(() => {
    const dayNum = parseInt(day.value, 10)
    const monthNum = parseInt(month.value, 10)
    const yearNum = parseInt(year.value, 10)

    const validDay = dayNum >= 1 && dayNum <= 31
    const validMonth = monthNum >= 1 && monthNum <= 12
    const validYear = yearNum >= 0 && yearNum <= currentYear

    return validDay && validMonth && validYear
})

const isValid = computed(() => {
    const user = new_user.value
    let professionalEmailValid = true
    let professionalCountryValid = true
    let professionalPhoneValid = true
    let professionalProfessionValid = true
    let professionalSpecializationValid = true
    let professionalStateValid = true
    let professionalCityValid = true
    let professionalSlugValid = true
    let professionalAboutValid = true
    let professionalSocialMediaValid = true

    const emailValid = user.email && user.email.trim() !== ''
    const firstNameValid = user.first_name && user.first_name.trim() !== ''
    const lastNameValid = user.last_name && user.last_name.trim() !== ''
    const teamValid = user.team.id === null || typeof user.team.id === 'number'
    const planValid = user.plan.id === null || typeof user.plan.id === 'number'
    const phoneValid = (user.country_code && user.phone_number) && results_basic.value.isValid

    if (user.team.id == 3 || user.plan.id == 2) {
        professionalEmailValid = user.professional.professional_data.email && user.professional.professional_data.email.trim() !== ''

        professionalCountryValid = user.professional.professional_data.country.id && typeof user.professional.professional_data.country.id === 'number'
        professionalStateValid = user.professional.professional_data.state.id && typeof user.professional.professional_data.state.id === 'number'
        professionalCityValid = user.professional.professional_data.city.id && typeof user.professional.professional_data.city.id === 'number'
        professionalSlugValid = user.professional.professional_data.slug && user.professional.professional_data.slug.trim() !== ''

        professionalPhoneValid = (user.professional.professional_data.country_code && user.professional.professional_data.phone_number) && results_prof.value.isValid
        
        professionalProfessionValid = user.professional.professions_id && user.professional.professions_id > 0
        professionalSpecializationValid = Array.isArray(user.professional.specializations_id) && user.professional.specializations_id.length > 0

        professionalAboutValid = user.professional.professional_data.about && user.professional.professional_data.about.trim() !== ''

        professionalSocialMediaValid = Array.isArray(user.professional.professional_data.social_networks) && user.professional.professional_data.social_networks.length > 0 && user.professional.professional_data.social_networks.every(social => social.url.trim() !== '')
    }

    return emailValid &&
           firstNameValid &&
           lastNameValid &&
           teamValid &&
           planValid &&
           phoneValid &&
           professionalEmailValid &&
           professionalCountryValid &&
           professionalPhoneValid &&
           professionalProfessionValid &&
           professionalSpecializationValid &&
           professionalStateValid &&
           professionalCityValid &&
           professionalSlugValid &&
           professionalAboutValid &&
           professionalSocialMediaValid
})

const restructureParams = (data) => {
    if (data.personal_data) {
        const { personal_data, ...rest } = data
        return { ...rest, ...personal_data }
    }
    return data
}

const convertDateFormat = (dateString) => {
    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        throw new Error('Formato de fecha no válido. Debe ser YYYY-MM-DD.')
    }
    const [yearValue, monthValue, dayValue] = dateString.split('-')
    day.value = dayValue
    month.value = monthValue
    year.value = yearValue
}

const showPassword = () => {
    if(document.getElementById('editUserProfilePassword').getAttribute('type') == 'password'){
        document.getElementById('editUserProfilePassword').setAttribute('type', 'text')
    }else{
        document.getElementById('editUserProfilePassword').setAttribute('type','password')
    }
}

const handleFileChangeAvatar = (event) => {
    const file = event.target.files[0]
    if (file) {
        new_user.value.avatar = file
        avatar_blob.value = URL.createObjectURL(file)
        fileInputAvatar.value.value = ''
    }
}

const handleFileChangeBanner = (event) => {
    const file = event.target.files[0]
    if (file) {
        new_user.value.professional.professional_data.banner = file
        banner_blob.value = URL.createObjectURL(file)
        fileInputBanner.value.value = ''
    }
}

const handleFileGallery = (event) => {
    const files = event.target.files
    if (files) {
        createFileGallery(files)
        fileInputGallery.value.value = ''
    }
}

const openLightbox = (image) => {
  selected_image.value = image
  lightbox_open.value = true
}

const closeLightbox = () => {
  lightbox_open.value = false
  selected_image.value = null
}

const selectImage = (image) => {
  selected_image.value = image
}

const previousImage = () => {
  const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
  const previous_index = (current_index - 1 + actual_gallery.value.files.length) % actual_gallery.value.files.length
  selected_image.value = actual_gallery.value.files[previous_index]
}

const nextImage = () => {
  const current_index = actual_gallery.value.files.findIndex(image => image.id === selected_image.value.id)
  const next_index = (current_index + 1) % actual_gallery.value.files.length
  selected_image.value = actual_gallery.value.files[next_index]
}

const scrollToSelectedThumbnail = () => {
    if (thumbnail_container.value) {
        const selected_thumbnail = thumbnail_container.value.querySelector('.selected')
        if (selected_thumbnail) {
            const container_width = thumbnail_container.value.offsetWidth
            const thumbnail_width = selected_thumbnail.offsetWidth
            const scroll_position = selected_thumbnail.offsetLeft - (container_width / 2) + (thumbnail_width / 2)
            thumbnail_container.value.scrollTo({
                left: scroll_position,
                behavior: 'smooth'
            })
        }
    }
}

const openLightboxTemporary = (image) => {
  selected_image.value = image
  lightbox_temporary_open.value = true
}

const closeLightboxTemporary = () => {
  lightbox_temporary_open.value = false
  selected_image.value = null
}

const previousImageTemporary = () => {
  const current_index = actual_gallery.value.temporary.findIndex(image => image.id === selected_image.value.id)
  const previous_index = (current_index - 1 + actual_gallery.value.temporary.length) % actual_gallery.value.temporary.length
  selected_image.value = actual_gallery.value.temporary[previous_index]
}

const nextImageTemporary = () => {
  const current_index = actual_gallery.value.temporary.findIndex(image => image.id === selected_image.value.id)
  const next_index = (current_index + 1) % actual_gallery.value.temporary.length
  selected_image.value = actual_gallery.value.temporary[next_index]
}

const checkGalleriesTemporary = () => {
    console.log(actual_gallery.value)
    if (actual_gallery.value.temporary) { return true }
    else { return false }
}

const eraseImage = () => {
    new_user.value.avatar = null
    avatar_blob.value = null
}

const eraseBanner = () => {
    new_user.value.professional.professional_data.banner = null
    banner_blob.value = null
}

const resetErrors = () => {
    errors.value = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        plan: '',
        team: '',
        image: null,
        password: '',
        professional: {
            id: '',
            active: '',
            professional_data: {
                id: '',
                email: '',
                banner: '',
                country: '',
                state: '',
                city: '',
                province: '',
                locality: '',
                address: '',
                postal_code: '',
                country_code: '',
                phone_number: '',
                social_networks: '',
                about: '',
                slug: ''
            },
            professions: '',
            portfolio: '',
            reviews: ''
        }
    }
}

const setProfessionalMembership = () => {
    new_user.value.professional = {
        id: '',
        active: '',
        professional_data: {
            id: '',
            email: '',
            banner: '',
            country: '',
            state: '',
            city: '',
            province: '',
            locality: '',
            address: '',
            postal_code: '',
            country_code: '',
            phone_number: '',
            social_networks: [],
            about: '',
            slug: ''
        },
        professions: [],
        portfolio: null,
        reviews: null
    }
}

const handleServerErrors = (server_errors) => {
    if (server_errors) {
        Object.keys(server_errors).forEach((key) => {
            const error = server_errors[key];
            if (Array.isArray(error) && error.length > 0) {
                const keys = key.split('.');
                let current_error_object = errors.value;

                keys.forEach((nested_key, index) => {
                    if (index === keys.length - 1) {
                        current_error_object[nested_key] = error[0];
                    } else {
                        if (!current_error_object[nested_key]) {
                            current_error_object[nested_key] = {};
                        }
                        current_error_object = current_error_object[nested_key];
                    }
                })
            }
        })
    }
}

const handleServerErrorsProf = (server_errors) => {
    if (server_errors) {
        if (server_errors.first_name) {
            errors_prof.value.first_name = server_errors.first_name[0]
        }

        if (server_errors.email) {
            errors_prof.value.email = server_errors.email[0]
        }

        if (server_errors.password) {
            errors_prof.value.password = server_errors.password[0]
        }

        if (server_errors.country) {
            errors_prof.value.country = server_errors.country[0]
        }

        if (server_errors.plan.id) {
            errors_prof.value.plan.id = server_errors.plan.id[0]
        }

        if (server_errors.team.id) {
            errors_prof.value.team.id = server_errors.team.id[0]
        }
    }
}

const loadPhoneNumber = (phoneNumber, countryCode) => {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, countryCode)

    if (phoneNumberObject) {
        return {
        countryCode: phoneNumberObject.countryCallingCode,
        localNumber: phoneNumberObject.nationalNumber
        }
    } else {
        throw new Error('Número de teléfono no válido')
    }
}

const convertNumericCodeToString = (countryCode, phoneNumber) => {
    const testPhoneNumber = `+${countryCode}${phoneNumber}`
    
    const phoneNumberObject = parsePhoneNumberFromString(testPhoneNumber)

    if (!phoneNumberObject) {
        throw new Error(`No se encontró un código de país para el código numérico ${countryCode}`)
    }

    return {
        countryCode: phoneNumberObject.country,
        phoneNumber: phoneNumberObject.nationalNumber
    }
}

const updateSocialNetwork = (index, key, value) => {
    const social_network_key = new_user.value.professional.professional_data.social_networks[index].name
    new_user.value.professional.professional_data.social_networks[social_network_key] = value
}

const newSocialNetwork = () => {
    console.log(new_user.value.professional.professional_data)
    let index = new_user.value.professional.professional_data.social_networks.length
    actual_sn.value = {
        name: '',
        url: '',
        index: index,
        new: true
    }
    new_user.value.professional.professional_data.social_networks.push(actual_sn.value)
}

const selectSocialNetwork = (social, index) => {
    if (actual_sn.value == social) {
        actual_sn.value = null
    }
    else {
        actual_sn.value = social
        actual_sn.value.index = index
        actual_sn.value.new = false
    }
}

const deleteSocialNetwork = (index) => {
    new_user.value.professional.professional_data.social_networks.splice(index, 1)
    resetActualSN()
}

const resetActualSN = () => {
    actual_sn.value = null
}

const validateDay = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue >= 4 && intValue <= 9) {
            value = '0' + value;
            day.value = value;
            setTimeout(() => {
                moveToNext(event, 'day');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 31) {
                value = '31';
                day.value = value;
            } else if (intValue < 1) {
                value = '01';
                day.value = value;
            } else {
                day.value = value;
            }
            moveToNext(event, 'day');
        } else {
            day.value = value;
        }
    }
}

const validateMonth = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue > 1 && intValue <= 9) {
            value = '0' + value;
            month.value = value;
            setTimeout(() => {
                moveToNext(event, 'month');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 12) {
                value = '12';
                month.value = value;
            } else if (intValue < 1) {
                value = '01';
                month.value = value;
            } else {
                month.value = value;
            }
            moveToNext(event, 'month');
        } else {
            month.value = value;
        }
    }
}

const validateYear = (event) => {
    let value = event.target.value;
    if (!/^\d{0,4}$/.test(value)) {
        value = value.slice(0, -1);
    } else if (value.length === 4) {
        let intValue = parseInt(value);
        if (intValue > currentYear) {
            value = currentYear.toString();
            year.value = value;
        } else {
            year.value = value;
        }
        moveToNext(event, 'year');
    } else {
        year.value = value;
    }
}

const moveToNext = (event, field) => {
    const input = event.target
    if (input.value.length >= input.maxLength) {
        switch (field) {
        case 'day':
            input.nextElementSibling.focus()
            break
        case 'month':
            input.nextElementSibling.focus()
            break
        case 'year':
            break
        }
    }
}

const moveToPrevious = (event, field) => {
    const input = event.target
    if (input.value.length === 0 && event.key === 'Backspace') {
        switch (field) {
        case 'day':
            break
        case 'month':
            input.previousElementSibling.focus()
            break
        case 'year':
            input.previousElementSibling.focus()
            break
        }
    }
}

const fetchTeams = async () => {
    try {
        const response = await axios.get(url+urlTeams, { headers: headers.value })
        console.log(response)
        teams_list.value = response.data.data
        teams_list.value.forEach((team) => {
            team.label = team.translation.name,
            team.value = team.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchPlans = async () => {
    try {
        const response = await axios.get(url+urlPlans, { headers: headers.value })
        console.log(response)
        plans_list.value = response.data.data
        plans_list.value.forEach((plan) => {
            plan.label = plan.translation.name,
            plan.value = plan.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchProfessions = async () => {
    try {
        const response = await axios.get(`${url}${urlProfessions}`, {headers: headers.value})
        console.log(response)
        professions_list.value = response.data.data
        professions_list.value.forEach((prof) => {
            prof.label = prof.translation.name,
            prof.value = prof.id
        })
    } catch (error) {
        console.log(error)
    }
}

const fetchSpecializations = async (prof_id) => {
    try {
        const response = await axios.get(`${url}${urlSpecialization}?professionId=${prof_id}`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            new_user.value.professional.specializations_id = []
            specializations_list.value = response.data.data
            specializations_list.value.forEach((spec) => {
                spec.label = spec.translation.name,
                spec.value = spec.id
                new_user.value.professional.specializations_id.push(spec.id)
            })
        }
    } catch (error) {
        console.log(error)
    }
}

const fetchUser = async (id) => {
    try {
        avatar_blob.value = null
        banner_blob.value = null
        const response = await axios.get(url+urlUser, { headers: headers.value })
        console.log(response)
        new_user.value = response.data.data
        new_user.value.password = ''
        new_user.value.status = !response.data.data.active
        convertDateFormat(new_user.value.personal_data.birthdate)
        if(new_user.value.personal_data.country_code.startsWith('+')) {
            new_user.value.personal_data.country_code = new_user.value.personal_data.country_code.slice(1)
            let formattedNumber = convertNumericCodeToString(new_user.value.personal_data.country_code, new_user.value.personal_data.phone_number)
            new_user.value.personal_data.country_code = formattedNumber.countryCode
            new_user.value.personal_data.phone_number = formattedNumber.phoneNumber
        }
        if (new_user.value.avatar) {
            avatar_blob.value = new_user.value.avatar
        }
        if (new_user.value.professional) {
            if (new_user.value.professional.professional_data.banner) {
                banner_blob.value = new_user.value.professional.professional_data.banner
            }
            new_user.value.professional.status = !response.data.data.professional.active
            new_user.value.professional.professional_data.social_networks = Object.entries(new_user.value.professional.professional_data.social_networks).map(([key, value]) => ({
                name: key,
                url: value
            }))
            console.log(new_user.value.professional.professional_data.social_networks)
            if(new_user.value.professional.professional_data.country_code.startsWith('+')) {
                new_user.value.professional.professional_data.country_code = new_user.value.professional.professional_data.country_code.slice(1)
                let formattedNumber = convertNumericCodeToString(new_user.value.professional.professional_data.country_code, new_user.value.professional.professional_data.phone_number)
                new_user.value.professional.professional_data.country_code = formattedNumber.countryCode
                new_user.value.professional.professional_data.phone_number = formattedNumber.phoneNumber
            }
            new_user.value.professional.professions_id = new_user.value.professional.professions[0].id
            await fetchSpecializations(new_user.value.professional.professions_id)
            new_user.value.professional.specializations_id = new_user.value.professional.professions[0].specializations.map(prof => prof.id)
            await fetchStates(new_user.value.professional.professional_data.country.id)
            await fetchCities(new_user.value.professional.professional_data.state.id)
        }
        else {
            setProfessionalMembership()
        }
        original_user.value = structuredClone(new_user.value)
        loading.value = false
        console.log(new_user.value)
        await nextTick()
    } catch (error) {
        console.log(error)
        loading.value = false
    }
}

const saveAccountInfo = async () => {
    let endpoint = `${url}${urlUser}`

    const form_data = new FormData()

    form_data.append('email', new_user.value.email)
    form_data.append('password', new_user.value.password)

    if (new_user.value.avatar && new_user.value.avatar instanceof File) {
        form_data.append('avatar', new_user.value.avatar)
    }

    form_data.append('_method', 'PATCH')

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    try {
        const response = await axios.post(endpoint, form_data, { headers: headers.value })
        console.log(response)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: response.data.message
        })
    } catch (error) {
        console.log(error)
        handleServerErrors(error.response.data.data)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const saveBasicInfo = async () => {
    let endpoint = `${url}${urlUser}/personal_data`

    let formattedNumber = loadPhoneNumber(new_user.value.personal_data.phone_number, new_user.value.personal_data.country_code)

    const form_data = new FormData()

    form_data.append('first_name', new_user.value.personal_data.first_name)
    form_data.append('last_name', new_user.value.personal_data.last_name)
    form_data.append('birthdate', new_user.value.personal_data.birthdate)
    form_data.append('country_code', '+'+formattedNumber.countryCode)
    form_data.append('phone_number', formattedNumber.localNumber)
    form_data.append('gender', new_user.value.personal_data.gender)
    form_data.append('country_id', new_user.value.personal_data.country.id)

    form_data.append('_method', 'PATCH')

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    try {
        const response = await axios.post(endpoint, form_data, { headers: headers.value })
        console.log(response)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: response.data.message
        })
    } catch (error) {
        console.log(error)
        handleServerErrors(error.response.data.data)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const saveProfessionalInfo = async (userId) => {
    let endpoint = `${url}${urlUser}/professional/data`

    let formattedNumber = loadPhoneNumber(new_user.value.professional.professional_data.phone_number, new_user.value.professional.professional_data.country_code)

    console.log(formattedNumber)

    const transformedSocialNetworks = {}

    const form_data = new FormData()

    form_data.append('email', new_user.value.professional.professional_data.email)
    form_data.append('country', new_user.value.professional.professional_data.country)
    form_data.append('state', new_user.value.professional.professional_data.state)
    form_data.append('city', new_user.value.professional.professional_data.city)
    form_data.append('address', new_user.value.professional.professional_data.address)
    form_data.append('postal_code', new_user.value.professional.professional_data.postal_code)
    form_data.append('country_code', '+'+formattedNumber.countryCode)
    form_data.append('phone_number', formattedNumber.localNumber)
    form_data.append('slug', new_user.value.professional.professional_data.slug)
    for (var i = 0; i < new_user.value.professional.professional_data.social_networks.length; i++) {
        const network = new_user.value.professional.professional_data.social_networks[i]
        transformedSocialNetworks[network.name.toLowerCase()] = network.url
    }
    form_data.append('social_networks[]', JSON.stringify(transformedSocialNetworks))
    form_data.append('about', new_user.value.professional.professional_data.about)

    if (!new_user.value.professional.id) {
        console.log(new_user.value.professional)
        form_data.append('profession_id', new_user.value.professional.professions_id)
        for (var i = 0; i < new_user.value.professional.specializations_id.length; i++) {
            form_data.append('specialization_ids[]', JSON.stringify(new_user.value.professional.specializations_id[i]))
        }
    }

    if (new_user.value.professional.professional_data.banner && new_user.value.professional.professional_data.banner instanceof File) {
        form_data.append('_method', 'PATCH')
        form_data.append('banner', new_user.value.professional.professional_data.banner)
    }
    form_data.append('_method', 'PATCH')

    for (let [key, value] of form_data.entries()) {
        console.log(`${key}: ${value}`);
    }

    try {
        const response = await axios.post(endpoint, form_data, { headers: headers.value })
        console.log(response)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: response.data.message
        })
    } catch (error) {
        console.log(error)
        handleServerErrors(error.response.data.data)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const getUserGallery = async () => {
    try {
        const response = await axios.get(`${url}${urlUser}/professional/specializations/gallery`, {headers: headers.value})
        console.log(response)
        user_gallery_list.value = response.data.data
    } catch (error) {
        console.log(error)
    }
}

const getGallery = async (gallery) => {
    try {
        const response = await axios.get(`${url}${urlUser}/professional/specializations/${gallery.id}/gallery`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            actual_gallery.value = response.data.data
            console.log(actual_gallery.value)
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const createFileGallery = async (files) => {
    console.log(files)
    try {
        const form_data = new FormData()

        for (var i = 0; i < files.length; i++) {
            form_data.append('files[]', files[i])
        }

        const response = await axios.post(`${url}${urlUser}/professional/specializations/${actual_gallery.value.id}/gallery`, form_data, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            console.log(actual_gallery.value)
            getGallery(actual_gallery.value)
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const deleteImageGallery = async (imageId) => {
    try {
        const response = await axios.delete(`${url}${urlUser}/professional/specializations/${actual_gallery.value.id}/gallery/${imageId}`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            actual_gallery.value.gallery = actual_gallery.value.gallery.filter(image => image.id !== imageId);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: response.data.message
            })
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}

const openDeletePopup = (imageId) => {
    image_to_delete.value = imageId
    delete_popup_open.value = true
}

const closeDeletePopup = () => {
    delete_popup_open.value = false
    image_to_delete.value = null
}

const confirmDeleteImage = async () => {
    try {
        deleteImageGallery(image_to_delete.value)
        if (selected_image.value?.id === image_to_delete.value) {
            closeLightbox()
        }
        closeDeletePopup()
    } catch (error) {
        console.error('Error deleting image:', error)
    }
}

const getPartialPayments = async () => {
    try {
        const response = await axios.get(`${url}${urlPartials}`, {headers: headers.value})
        console.log(response)
        if (response.data.status == 'Success') {
            user_partial_list.value = response.data.data
            console.log(user_partial_list.value)
        }
    } catch (error) {
        console.log(error)
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'error',
            title: error.response.data.message
        })
    }
}
getPartialPayments()

const pay = async(method) => {

const token = JSON.parse(localStorage.getItem('token'))
const headers = ref({'Authorization': `Bearer ${token}`})

let partial_type = actual_payment.value.complete ? 'complete' : 'next'

let params = {
    payment_gateway: payment_method[method],
    amount: actual_payment.value.complete ? actual_payment.value.complete.amount : actual_payment.value.next.amount,
    order_id: actual_payment.value.id,
}

console.log(params)
console.log(partial_type)
try {
    await axios.post(`${url}${urlPartials}/${partial_type}`, params, {headers: headers.value}).then(async (result) => {
        console.log(result)
        // Stripe
        if (method == 1) {
            payment_intention.value = true
            payment_data.value = result.data.data

            const clientSecret = result.data.data.clientSecret

            console.log(clientSecret)

            console.log(stripe.value)

            const response = await stripe.value.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: card_element.value,
                },
            });

            console.log(response)

            if (response.error) {
                console.log(response.error.message)
            } else if (response.paymentIntent.status === 'succeeded') {
                actual_payment.value = null
                router.push({ name: 'Pedido', query: {
                        order_id: result.data.data.order.order_id,
                        payment_intent: response.paymentIntent.id
                    }
                })
            }
        }
        // PayPal
        else if (method == 2) {
            payment_data.value = result.data.data
            const payerActionUrl = result.data.data.links.find(link => link.rel === 'payer-action').href
            window.location.href = payerActionUrl
        }
    })
} catch (error) {
    console.log(error)
}
}

const paymentModal = (partial = null) => {
    actual_payment.value = partial
}

onBeforeMount(async () => {
    loading.value = true
    await fetchUser()
    getUserGallery()
    fetchTeams()
    fetchPlans()
    fetchProfessions()
    fetchCountries()
    if (new_user.value.professional.professions_id == 1) {
        // getUserSpecializations()
    }
    watch(selected_image, () => {
        if (lightbox_open.value) {
            nextTick(() => scrollToSelectedThumbnail())
        }
    })
    watch([day, month, year], () => {
        if (isValidDate.value) {
            new_user.value.birthdate = `${year.value.padStart(4, '0')}-${month.value.padStart(2, '0')}-${day.value.padStart(2, '0')}`
        } else {
            new_user.value.birthdate = ''
        }
    })
})
</script>

<style lang="scss" scoped>
.contenedor{

    .addReferrals{
        input{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        
        textarea{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 150px;
            padding-top: 5px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        .label{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            height: 100px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            cursor: pointer;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
        .blob {
            img:not(.button-close-image){
                width: 100%;
                max-height: 146px;
                object-fit: cover;
                border-radius: 8px;
            }
        }
        span{
            color: #FF576E;
            padding-top: 5px;
        }
        .user{
            p{
                display: flex;
                align-self: start;
                color: #FF576E;
                padding-top: 5px;
            }
        }
        .password{
            p{
                display: flex;
                align-self: start;
                padding-top: 7px;
            }
            button{
                position: relative;
                right: -89%;
                top: -32%;
                padding: 0;
            }
            .ojo{
                background-image: url('../../../img/roles-icons/ojo.svg');
                background-repeat: no-repeat;
                display: block;
                min-height: 20px !important;
                min-width: 20px !important;
            }
            .weak{
                color: #FF576E;
                padding-right: 5px;
            }
            .moderate{
                color: #FEB230;
                padding-right: 5px;
            }
            .strong{
                color: #38E6A7;
                padding-right: 5px;
            }
        }
        p{
            display: flex;
            color: var(--grisPrimario);
            padding-top: 5px;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            padding: 7px 0px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
        }
        h5{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            padding-top: 30px;
        }
        .rol{
            max-width: 57px;
            max-height: 57px;
            min-width: 57px;
            min-height: 57px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid var(--colorPrimary);
            }
        }
        .position{
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
            position: relative;
            i{
                width: 10px;
                height: 15px;
                background-image: url('../../../img/subir-productos-icons/cursos-icons/arrow-abajo-gris.svg');
                background-repeat: no-repeat;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 5%;
                top: 30%;
                transform: rotate(-90deg);
                transition: all ease 0.2s;
            }
        }
        select{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            &:focus + i{
                transform: rotate(90deg);
                transition: all ease 0.2s;
            }
        }
        .profile{
            max-width: 47px;
            max-height: 47px;
            min-width: 47px;
            min-height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img:not(.button-close-image){
                max-width: 47px;
                max-height: 47px;
                min-width: 47px;
                min-height: 47px;
                object-fit: cover;
                border-radius: 50%;
                // border: 2px solid #E9E3E3;
                // padding: 1px;
            }
        }
        .profile-plus{
            max-width: 47px;
            max-height: 47px;
            min-width: 47px;
            min-height: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px dashed #00000029;
            box-shadow: 0px 3px 5px #c7c7c7;
        }
        .pointer{
            cursor: pointer;
        }
        .solid{
            border: 1px solid #00000029;
        }
        .selected{
            border: 1px solid var(--colorPrimary);
        }
        .margin{
            margin-top: 3px;
        }
        .padding-left{
            padding-left: 10px;
        }
        .switch {
            label{
                height: 40px;
                width: 100%;
                box-shadow: 0px 0px 6px #00000029;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-right: 5px;
                cursor: pointer;
                background-color: #EBEEF9;
                &::before{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #38E6A7;
                    border-radius:  28px;
                    left: 1%;
                    transition: all 0.5s;
                }
                p{
                    font-weight: 500;
                    font-size: 14px;
                    padding-top: 0px;
                    color: var(--bgPrincipal);
                    z-index: 1;
                    transition: all 0.5s;
                    &:first-child{
                        margin-right: 0%;
                    }
                    &:last-child{
                        margin-left: 0%;
                    }
                }
                .left{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                }
                .right{
                    color: var(--colorSecondary);
                }
            }
            input{
                display: none;
            }
            input:checked + label{
                &::before{
                    left: 49%;
                    width: 50%;
                    background-color: #FEB230;
                }
                p{
                    color: var(--bgPrincipal);
                    font-weight: 600;
                    
                }
                .left{
                    color: var(--colorSecondary);
                    font-weight: 500;
                }
            }
            @media (min-width: 992px) {
                label{
                    .right{
                        color: var(--colorSecondary);
                    }
                }
                input:checked + label{
                    .left{
                        color: var(--colorSecondary);
                    }
                }
            }
        }
        .button-close{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            margin-left: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }
        .button-close-banner{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 88%;
            top: 53%;
            @media (min-width: 992px){
                left: 95%;
            }
        }
        .input-width{
            max-width: 47px;
            max-height: 47px;
        }
        .gender-inputs{
            .inputs{
                margin: 10px 0px !important;
            }
            input{
                display: none;
            }
            label{
                font-size: 16px;
                color: #697891;
                font-weight: 400;
                border: 2px solid #E9E3E3;
                border-radius: 10px;
                height: 50px;
                width: 32%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                i{
                    position: relative;
                    background-repeat: no-repeat;
                    background-size: cover;
                    right: 0;
                    top: 0;
                    width: 26px;
                    height: 26px;
                    display: block;
                    transform: rotate(0deg);
                    margin-right: 10px;
                }
                .hombre{
                    width: 26px;
                    height: 26px;
                    background-image: url('../../../img/panel-icons/hombre.svg');
                }
                .mujer{
                    width: 26px;
                    height: 26px;
                    margin: 0 0;
                    background-image: url('../../../img/panel-icons/mujer.svg');
                }
                .ambos{
                    width: 25px;
                    height: 26px;
                    background-image: url('../../../img/panel-icons/ambos.svg');
                }
            }
            label:hover {
                cursor: pointer;
            }
            input:checked + label{
                border-color: var(--colorPrimary);
                color: var(--colorPrimary);
                p{
                    color: var(--colorPrimary);
                }
                &::before{
                    content: '';
                    position: absolute;
                    top: -10px;
                    right: -7px;
                    background-image: url('../../../img/panel-icons/input-valido.svg');
                    background-repeat: no-repeat;
                    z-index: 1;
                    display: block;
                    width: 25px;
                    height: 25px;
                }
                .hombre{
                    background-image: url('../../../img/panel-icons/hombre-active.svg');
                }
                .mujer{
                    background-image: url('../../../img/panel-icons/mujer-active.svg');
                }
                .ambos{
                    background-image: url('../../../img/panel-icons/ambos-active.svg');
                }
            }
        }
    }  
    .loader{
        background-color: alpha($color: #ffffff21);
        filter: blur(3px);
        width: 100%;
        height: 100%;
    }
    .pa-users-tabs{
        .tab-element{
            p{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
                white-space: nowrap;
                overflow: visible;
            }
            cursor: pointer;
        }
        .active{
            p{
                font-weight: 700;
                border-color: transparent;
                color: var(--colorPrimary);
                background-color: transparent;
                border-radius: 0;
                &::before{
                    content: '';
                    display: flex;
                    position: relative;
                    width: 100%;
                    height: 5px;
                    top: 41px;
                    left: 0;
                    background-color: var(--colorPrimary);
                    border-radius: 10px;
                }
            }
        }
    } 
}
.btn__inicio{
    border: 2px solid #B8C1DB;
    color: #B8C1DB;
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 600;
    &:hover, &:focus{
        background-color: #B8C1DB;
        color: var(--colorBTNPrimario);
        border-color: transparent;
    }
}
.btn_save{
    background-color: #B8C1DB;
    color: var(--colorBTNPrimario);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    width: 100%;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    cursor: default !important;
}
.btn_save_no-w{
    background-color: #B8C1DB;
    color: var(--colorBTNPrimario);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    cursor: default !important;
}
.btn-valid{
    background-color: var(--colorPrimary);
    color: var(--colorBTNPrimario);
    border-color: transparent;
    cursor: pointer !important;
    &:hover{
        background-color: transparent;
        color: var(--colorPrimary);
        border: 2px solid var(--colorPrimary);
    }
}
.btn-error{
    background-color: var(--colorRed);
    color: var(--colorBTNPrimario);
    border-color: transparent;
    cursor: pointer !important;
    &:hover{
        background-color: transparent;
        color: var(--colorRed);
        border: 2px solid var(--colorRed);
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.no-resize {
    resize: none;
    width: 100%;
    height: 100px;
}

.inactive {
    color: #E9E3E3 !important;
    text-decoration: line-through;
    cursor: default !important;
    &:hover {
        border-color: #E9E3E3 !important;
    }
    h3 {
        color: #E9E3E3 !important;
    }
}

.gallery {
    flex-wrap: wrap;
    box-sizing: border-box;
    border: 2px solid #E9E3E3;
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    width: max-content;
    &:hover {
        border-color: var(--colorPrimary);
    }
    h3 {
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;

    }
    img{
        height: 150px;
        width: 150px;
        border-radius: 15px;
        margin: 5px;
        object-fit: cover;
        &:hover, &:focus{
            transform: none;
        }
    }
}

.gallery-show {
    h2 {
        font-weight: 600;
        font-size: 24px;
    }
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ECECEC;
    border-radius: 15px;
}

.thumbnail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    @media(min-width: 992px){
        gap: 8px;
    }
}

.thumbnail-wrapper {
    position: relative;
}

.thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
}

.thumbnail-new {
    border: 2px dotted #E9E3E3;
    padding-left: 5px;
}

.thumbnail:hover {
    transform: scale(1.1);
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.8);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: none;
    justify-content: center;
    align-items: center;
}

.thumbnail-wrapper:hover .delete-button {
    display: flex;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.lightbox-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10001;
}

.expanded-image {
    max-width: 90%;
    max-height: 70vh;
    margin-bottom: 20px;
    border-radius: 15px;
}

.lightbox-thumbnails {
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10002;
}

.lightbox-thumbnail {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid transparent;
}

.lightbox-thumbnail .delete-button {
    display: none;
}

.lightbox-thumbnail:hover {
    transform: scale(1.1);
}

.lightbox-thumbnail.selected {
    border-color: var(--colorPrimary);
}

.delete-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10003;
}

.delete-popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-popup p {
    margin-bottom: 20px;
}

.delete-popup button {
    margin: 5px;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.delete-popup button:first-of-type {
    background: red;
    color: white;
}

.delete-popup button:last-of-type {
    background: grey;
    color: white;
}

.navigation {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    font-size: 2rem;
    color: white !important;
    cursor: pointer;
    user-select: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 10%;
    z-index: 10002;
    &:hover {
        color: var(--colorPrimary) !important;
    }
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.row_banner{
    height: 100px;
    position: relative;
    @media (min-width: 992px){
        height: 140px;
    }
}

.payments {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}

.payments-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001;
    background: white;
    border: 1px solid #ddd;
    max-width: 500px;
    height: 500px;
    padding: 0px 50px;
    border-radius: 15px;
    h5{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        padding-top: 30px;
    }
}

.methods {
    margin: 20px 0 5px 0;
    span{
      width: 50px;
      height: auto;
      min-height: 30px;
      box-shadow: 0px 3px 6px #00000029;
      background-color: var(--bgPrincipal);
      border-radius: 8px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img{
        height: 13px;
      }
      .grande{
        height: 20px;
      }
    }
    .paypal{
        height: 25px;
    }
    .mercadopago{
        height: 90px;
    }
    .chiquitos{
       width: 40px;
       min-height: 0px !important;
       margin-right: 5px;
       padding: 5px;
       &:last-child{
        margin-right: 0;
       }
       img{
        height: 13px;
       }
    }
    input[type=radio]{
        display: none;
    }
    input[type=radio] + label{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #FCFCFC;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #E4E4E4;
        border-radius: 10px;
        min-height: 77px;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #B8C1DB;
            width: 28px;
            height: 28px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 14px;
                height: 14px;
                display: none;
            }
            @media (max-width: 425px) {
                width: 22px;
                height: 22px;
            }
        }
        .informacion{
            display: none;
            p{
                font-weight: 400;
                font-size: 14px;
                color: var(--colorSecondary);
                padding: 10px 0px;
            }
            h6, a{
                font-weight: 400;
                font-size: 14px;
                color: #697891;
            }
            a{
                padding: 0 2px ;
            }
        }
    }
    input[type=radio]:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: block;
        }
    }
    input[type=radio]:checked + label .informacion{
        display: flex;
    }
    input[type=radio]:checked + label{
        border: 2px solid var(--colorPrimary);
        border-radius: 20px;
        box-shadow: 0px 3px 6px #00000029;
    }
    .position2{
        position: absolute;
        right: 15px;
    }
    .padding{
        padding: 15px 0;
    }
    h5 {
        padding-top: 3px !important;
        padding-left: 10px !important;
    }
}
#card_number_element, #card_expiry_element, #card_cvc_element {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    background-color: #ffffff;
    width: 100%;  
    height: auto; 
    margin-bottom: 15px;  
}
</style>