<template>
    <div class="col-12 d-flex justify-content-start align-items-center recordar ">
      <div class="d-flex justify-content-center align-items-center">
      <input type="checkbox" id="recordar">
      <label for="recordar" class="d-flex justify-content-start align-items-center"></label>
      <p>Acepto crear una cuenta con estos datos</p>
      </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.recordar{
    padding-top: 10px;
    p{
      color: #212529;
      font-size: 16px;
      font-weight: 500;
      margin-left: 10px;
      text-align: left;
    }
    input{
      display: none;
    }
    label{
      height: 20px;
      width: 20px;
      border: 1px solid #697891;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      p{
        color: #212529;
        font-size: 16px;
        font-weight: 500;
        margin-left: 30px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
    }
    input:checked + label::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #1679FC;
    }
  }
</style>