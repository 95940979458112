<template>
    <form action="post" @submit.prevent="validarTelefono" class="w-100">
        <SelectTelefono/>
        <GrisInicioSubmit :textoBtn="$t('message.continue')"/>
    </form>
</template>

<script setup>
    import SelectTelefono from '../Inputs/SelectTelefono.vue';
    import GrisInicioSubmit from '../Botones/GrisInicioSubmit.vue';

    import { useRouter } from 'vue-router';
    import axios from "axios"
    import { provide, ref } from 'vue'
    // Importo mi método
    // Llamo a mi tienda de Pinia en forma de Setup 
    import { useUsersStore } from "../../stores/user";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

const router = useRouter();

const url = process.env.VUE_APP_BASE_URL
const validatePhone = '/api/auth/login/phone'

//-----//
const selectPhone = ref('')
provide("selectPhone", selectPhone)
//-----//
const phoneNumber = ref('')
provide("phoneNumber", phoneNumber)
//-----//
async function validarTelefono(){
    if(phoneNumber.value !== '' || selectPhone.value !== ''){
        let phone = selectPhone.value.label+phoneNumber.value
        //console.log(phone)
        //console.log(selectPhone.value.label)
        try{
            await axios.post(url+validatePhone, phone).then((result) => {
                console.log(result)
                //token
                tokenAccess.value = result.data.data.token 
                //localStorage
                localStorage.setItem('token', JSON.stringify(tokenAccess.value))
                //
                cambioHeader.value = false
                //
                console.log(cambioHeader.value)
            })
            //Pregunto si hay un usuario en LocalStorage, en caso afirmativo reemplazo mis variables vacías por esa info
            if(localStorage.getItem('token')){
                tokenAccess.value = JSON.parse(localStorage.getItem('token'))
            }
            //pusheo al index 
            router.push('/')
        }catch(error){
            console.log(error)
        }
    }
    else{
        //console.log('vacío')
    }
    
}
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {cambioHeader, tokenAccess} = storeToRefs(users);
const {} = users;
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 0 0px 10px 0;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>