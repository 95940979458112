<template>
    <div class="col-12 d-flex justify-content-center align-items-center mas-opciones px-0">
        <div class="accordion row row__width" id="accordionExample">
            <div class="accordion-item col-12 d-flex flex-column justify-content-center align-items-center">
                <h2 class="accordion-header d-flex justify-content-center align-items-center" id="headingTwo">
                    <button class="accordion-button collapsed row__width d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        {{ $t('message.moreOptions') }}
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <MasOpcionesCodigo v-for="(opcion, index) in opciones" :key="index" 
                            :titulo="opciones[index].titulo" :subtitulo="opciones[index].subtitulo" :index="index" :check="opciones[index].check"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import MasOpcionesCodigo from "../Inputs/MasOpcionesCodigo.vue";
const opciones = ref([
    {tiutlo: 'prueba', subtitulo: 'prueba', check: false},
    {tiutlo: 'prueba', subtitulo: 'prueba', check: false},
    {tiutlo: 'prueba', subtitulo: 'prueba', check: false},
])
</script>

<style lang="scss" scoped>
.mas-opciones{
    .accordion-item{
        padding: 0;
        background-color: transparent;
        border: 0;
        margin: 20px 0px 5px 0px;
        h2{
            background-color: #FCFCFC;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            width: 100%;
        }
        button{
            width: 100%;
            height: 63px;
            border-radius: 10px;
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
            &:focus{
                outline: 0;
                box-shadow: none;
            }
        }
        .accordion-button:not(.collapsed) {
            color: inherit;
            background-color: #FCFCFC;
            box-shadow: none;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-body{
            box-shadow: 0px 3px 10px #00000029;
            border-radius: 20px;
            margin-top: 10px;
            padding: 10px 30px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-button::after{
            background-image: url('../../img/home-icons/cursos-icons/arrow-abajo-negro.svg');
            background-size: 70%;
            background-position: center;
        }
        .accordion-collapse{
            width: 100%;
            max-width: 100%;
        }
    }
    .accordion-item:last-of-type .accordion-button.collapsed{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
</style>