<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center profesor">
        <div class="row justify-content-center row__width">
            <div class="col-3 col-lg d-flex justify-content-center align-items-start px-2 max-width">
                <img src="../../img/home-icons/cursos-icons/perfil-foto.png" alt="" class="perfil">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <h2>Salem McBunny {{ $t('message.teacherUppercase') }}</h2><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ps-4" >
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/ubi-negro.svg" alt="" class="pe-2"><h3>{{$t('message.fromMXCity')}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/camara-gris.svg" alt="" class="pe-2"><h4>{{$t('message.photographNewborn')}}</h4>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="">
                        <h5>{{$t('message.rating')}}</h5>
                        <p>{{$t('message.opinions')}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center pt-3" :class=" texto == false ? 'texto_masBefore' : ''">
                <p>
                    Mónica Olvera, su especialidad es la fotografía newborn en Ciudad de México y se destaca por ser de los mejores fotógrafos en esta área.
                    <p class="p-0" v-if="texto">Dedicada desde 2005 a capturar los momentos más importantes de las familias a través de diversos 
                    géneros fotográficos: embarazo, recién nacidos, smash cake, familias y subacuático. Embajadora Nikon.</p>
                </p>
            </div>
            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                <BTNVerPrerfil :funcionTextoV2="mostrarTexto4" style="max-height: 40px;"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNVerPrerfil from "../Botones/VerPrerfil.vue";
    import { ref } from "vue";

    const texto = ref(false)

    const mostrarTexto4 = () => {
        if(texto == false){
            texto = true
        }else{
            texto = false
        }
    }
</script>


<style lang="scss" scoped>
 .profesor{ 
    padding: 20px 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 20px 5px 20px 15px;
    margin: 20px 0px;
    h2{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
    }
    h3{
        font-weight: 400;
        font-size: 14px;
        color: var(--colorSecondary);
        padding: 0;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        padding: 0;
    }
    h5{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
        padding: 0px 5px;
    }
    p{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #697891;
        text-align: left;
    }
    .perfil{
        height: 75px;
        border-radius: 50%;
        object-fit: cover;
    }
    .max-width{
        max-width: 80px;
    }
    .texto_mas{
        p{  
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
            padding: 20px 0 10px 0;
        }
    }
    .texto_masBefore{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 70px;
            background: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 23%));
            filter: opacity(0.5);
            z-index: 2;
        }
    }
    .grandes{
        max-width: 100%;
        width: 100%;
        height: 100%;
        height: 170px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
    }
    .chiquitas{
        max-width: 100%;
        width: 100%;
        height: 80px;
        max-width: 92px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        
    }
}

.before__texto{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        background: linear-gradient(to top, rgb(255 255 255), rgb(255 255 255 / 23%));
        filter: opacity(0.5);
        z-index: 2;
    }
} 
</style>