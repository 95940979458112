<template>
    <div class="row justify-content-start align-items-center galeries-container">
        <div class="d-flex flex-column justify-content-center align-items-center galeries pt-3 pb-3 px-1 mb-3 mb-md-0 me-md-2" @click="actual_gallery = gallery, actual_tab = 2" v-for="(gallery, id) in gallery.specializations">
            <img :src="gallery.files[0].file" alt="" class="img">
            <div class="row row__width justify-content-center align-items-center sub-info pt-2">
                <div class="col-11 d-flex flex-column justify-content-center align-items-center max-change px-0">
                    <div class="row row__width d-flex justify-content-start align-items-center">
                        <div class="col-12 d-flex justify-content-start align-items-center">
                            <h5 class="title pt-1 mb-2">{{ gallery.translation.name }}</h5>
                        </div>
                        <!-- <div class="col-6 d-flex justify-content-start align-items-center">
                            <img src="../../img/perfil-icons/galeria-icons/like-red.svg" alt="">
                            <h5 class="mt-1 ms-2">{{ gallery.files[0].likes }}</h5>
                        </div>
                        <div class="col-6 d-flex justify-content-start align-items-center">
                            <img src="../../img/perfil-icons/galeria-icons/visualizaciones.svg" alt="" class="icon">
                            <h5 class="mt-1 ms-2">{{ gallery.files[0].views }}</h5>
                        </div> -->
                        <div class="col-12 d-flex justify-content-end align-items-center pt-3">
                            <h6>Ver más</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="gallery.showMore" class="col-lg-3 d-flex justify-content-start align-items-center">
            <span class="col d-flex justify-content-center align-items-center button-gallery" @click="actual_tab = 2" >
                <img :src="gallery.showMore.files[0].file" alt="">
                <img src="../../img/perfil-icons/mas.svg" class="img" alt="">
            </span>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';

const props = defineProps({
    avatar: '',
    gallery:{
        type: Object
    }
})

const actual_tab = inject('actual_tab')
const actual_gallery = inject('actual_gallery')

const onSwiper = (swiper) => {
    console.log(swiper, 'swiper')
    swiper.pagination.render()
}
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
    margin: 20px 0;
}
div {
  height: auto !important;
}
@media (max-width: 991px){
    .mySwiper3 div{
        padding-bottom: 10px;
    }
    .mySwiper{
        margin: 0 0 20px 0;
    }
}

.sub-info{
    .title{
        font-weight: 700;
    }
    img{
        width: 20px;
        height: 20px;
    }
    .icon{
        width: 22px;
        height: 22px;
    }
    .avatar{
        min-height: 50px;
        min-width: 50px;
        max-height: 50px;
        max-width: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.galeries-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Espacio entre elementos */
}

.galeries {
    background-color: transparent;
    border-radius: 20px;
    height: auto;
    width: 100%; /* Por defecto, ocupan todo el ancho */
    max-width: 300px; /* Máximo ancho para pantallas más grandes */
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 3px 10px #00000029;
    padding: 20px;
    transition: box-shadow 0.3s ease; /* Transición suave al hacer hover */
    
    &:hover {
        box-shadow: 0px 3px 6px #00000029;
        background-color: #fff;
        cursor: pointer;
    }

    .img {
        height: 200px;
        width: 90%;
        object-fit: cover;
        border-radius: 20px;
    }

    h5 {
        font-weight: 700;
        font-size: 1.2rem;
        text-align: left;
        line-height: 1.2;
        overflow: hidden;
    }

    h6 {
        font-weight: 500;
        font-size: 1rem;
        color: var(--colorSecondary);
        text-align: left;

        &:hover, &:focus {
            color: var(--colorPrimary);
        }
    }
}

/* Media queries */

/* Mostrar de 1 en 1 en pantallas pequeñas (móviles) */
@media (max-width: 576px) {
    .galeries {
        width: 100%; /* Ocupa todo el ancho en móviles */
    }
}

/* Mostrar de 2 en 2 en pantallas medianas (tablets) */
@media (min-width: 577px) and (max-width: 992px) {
    .galeries {
        width: 48%; /* Ocupa casi la mitad del ancho en tablets */
    }
}

/* Mostrar de 3 en 3 en pantallas grandes (desktops) */
@media (min-width: 993px) {
    .galeries {
        width: 30%; /* Ocupa el 30% del ancho en pantallas grandes */
    }
}

.button-gallery {
    width: 100%;
    max-width: 130px; /* Limitar ancho en pantallas grandes */
    height: 320px;
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 65px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(180deg, #ffffff00 0%, var(--colorSecondary) 100%) 0% 0% no-repeat padding-box;
        filter: opacity(1);
        border-radius: 65px;
        z-index: 1;
    }

    .img {
        height: 50px;
        width: 50px;
        position: absolute;
        top: 40%;
        z-index: 2;
    }
}

</style>