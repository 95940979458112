<template>
    <div class="row justify-content-center asesorias__profesionales pb-lg-5 pt-5">
        <div class="col-11 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center row__width">
                <div class="col-12 d-flex justify-content-center align-items-center pb-lg-5">
                    <h2>{{$t('message.titleConsultancies')}}</h2>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 py-3 py-lg-5">
                    <div class="row justify-content-center row__width">
                        <AsesoriasPuntosExplicativos v-for="(punto, id) in puntos" 
                            :titulo="puntos[id].titulo"
                            :subtitulo="puntos[id].subtitulo" 
                            :src="puntos[id].img"
                        />
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0 ">
                    <consultoriasSeccion class="py-lg-5"/>
                    <TabsAsesorias class="py-lg-5"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { mapState } from "vuex";
    import AsesoriasPuntosExplicativos from "../Asesorias/AsesoriasPuntosExplicativos.vue";
    import consultoriasSeccion from "../../components/Asesorias/ConsultoriasSeccion.vue";
    import TabsAsesorias from "../../components/Asesorias/TabsAsesorias.vue";
    import { ref } from 'vue'

    const puntos = ref([
        {
            titulo: 'Asesorías con líderes del sector', 
            subtitulo: 'Recibe consejos, apoyo y consultoría profesional con los mejores en su rubro.', 
            img:require('@/img/home-icons/cursos-icons/asesoriasProfesionales.svg')
        },
        {
            titulo: 'Adaptado a tu disponibilidad', 
            subtitulo: 'En diferentes días y espacios de tiempo, recibe consultorías en tu tiempo disponible.', 
            img:require('@/img/home-icons/cursos-icons/disponibilidad-asesorias.svg')
        },
        {
            titulo: 'Consultoría Online', 
            subtitulo: 'No importa donde te encuentres, consulta con los expertos desde un dispositivo con internet.', 
            img:require('@/img/home-icons/cursos-icons/consultoria-online.svg')
        },
    ])
</script>


<style lang="scss" scoped>
.asesorias__profesionales{
    h2{
        font-weight: 700;
        font-size: 28px;
        color: var(--colorSecondary);
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            border-radius: 5px;
            width: 15%;
            background-color: var(--colorSecondary);
        }
    }
    .v2, .v3{
        font-size: 26px;
        text-align: left;
        &::before{
            display: none;
        }
    }
    h4{
        font-size: 16px;
        font-weight: 400;
        color: #697891;
        text-align: left;
        padding: 10px 0;
        line-height: 27px;
        strong{
            color: inherit;
            font-size: inherit;
        }
    }
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary); 
        padding-bottom: 10px;
    }
    .lista{
        ul{
            padding: 0;
            li{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                position: relative;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 10px;
                text-align: left;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/home-icons/cursos-icons/tick-listado-asesorias.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 15px;
                    width: 15px;
                    left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
    .nav-tabs{
        height: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 28px;
        button{
            border: 0;
            border-radius: 50%;
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            height: 85%;
            width: 90%;
            transition: color .1s ease-in-out;
            &:focus{
                outline: 0;
            }
        }
        .active{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 700;
            border-radius: 28px;
        }
    }
    .spanV2{
        margin-bottom: 10px;
    }
    p{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-left: 10px;
    }
}
</style>