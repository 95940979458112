<template>
    <div class="row justify-content-center inputs px-3 row__width">
        <div class="col-12 d-flex justify-content-between align-items-center recordar px-0">
            <div class="d-flex justify-content-center align-items-center">
                <input type="checkbox" :placeholder="$t('message.password')" id="recordar">
                <label for="recordar" class="d-flex justify-content-start align-items-center"><p>{{$t('message.remember')}}</p></label>
            </div>
            <a href="">{{$t('message.forget')}}</a>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.recordar{
    padding-top: 10px;
    a{
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 400;
       
    }
    input{
        display: none;
    }
    label{
        height: 15px;
        width: 15px;
        border: 1px solid #697891;
        border-radius: 5px;
        position: relative;
        p{
            font-size: 14px;
            font-weight: 400;
            color: #697891;
            padding-left: 25px;
            min-width: max-content;
        }
    }
    input:checked + label::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #1679FC;
        background-image: url('../../img/inicio-icons/tick.svg');
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
        margin-right: 10px;
        margin-top: 0;
    }
    h3 + a{
        color: #1679FC;
        font-size: 16px;
        font-weight: 600;
    }
    @media (min-width: 992px) {
        label{
            width: 18px;
            height: 18px;
        }
        label p{
            font-size: 15px;
        }
        a{
            font-size: 15px;
        }
    }
}
</style>