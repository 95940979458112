<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.courses')"/>
        <div  class="col d-flex justify-content-center align-items-start classSpecial">
            <!--- INDEX --->
            <div class="row justify-content-center justify-content-lg-start row__width contenedor">
                <div class="col-12 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 caja">
                    <div class="row justify-content-center row__width">
                        <ProductosPanel/>
                    </div>
                </div>
            </div>
        </div>
        <FooterCertificados :site="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import ProductosPanel from "../../../components/Panel/Admin/Productos/ProductosPanel.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";

// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access} = storeToRefs(users);
const {} = users;
const {validate} = users;

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
}
.contenedor{
    // @media (min-width: 992px) {
    //     background-color: #fff;
    //     box-shadow: 0px 3px 6px #00000029;
    //     border-radius: 20px;
    //     padding:20px 0 30px 0;
    //     min-height: 80vh;
    // }
    @media (min-width: 992px) {
        padding-top: 80px;
    }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}
.classSpecial{
    width: 70vw !important;
}
</style>