<template>
    <div class="row row justify-content-center row__width footer__carrito py-lg-5">
        <div class="col-12 d-flex justify-content-start align-items-center px-0 pt-3">
            <img src="../../img/carrito-icons/pago-seguro.svg" alt="">
            <h6>{{$t('message.paymentSecure')}}</h6>
        </div>
        <div class="col-12 d-flex justify-content-start align-items-center tarjetas px-0">
            <span><img src="../../img/carrito-icons/visa.svg" alt=""></span>
            <span><img src="../../img/carrito-icons/mastercard.svg" alt="" class="grande"></span>
            <img src="../../img/carrito-icons/american.svg" alt="" class="american">
            <span><img src="../../img/carrito-icons/paypal.svg" alt=""></span>
        </div>
        <div class="col-12 d-flex justify-content-start align-items-center px-0">
            <p>{{$t('message.paymentSecureText')}}</p>
        </div>
    </div>
</template>

<script setup>
  
</script>

<style lang="scss" scoped>
.footer__carrito{
  h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-left: 10px;
  }
  .tarjetas{
    margin: 20px 0 5px 0;
    span{
      width: 50px;
      height: auto;
      min-height: 30px;
      box-shadow: 0px 3px 6px #00000029;
      background-color: var(--bgPrincipal);
      border-radius: 8px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img{
        height: 13px;
      }
      .grande{
        height: 17px;
      }
    }
    .american{
      margin-right: 10px;
      height: 22px;
    }
  }
  p{
    font-weight: 400;
    font-size: 15px;
    color: #7D93B2;
    padding: 10px 0px;
    text-align: left;
  }
}
</style>