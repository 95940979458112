<template>
    <div class="col-12 d-flex justify-content-center align-items-center content">
        <form action="" class="row__width" v-if="actualActivity != null && actualActivity.lessonType == 1">
            <div class="row justify-content-center px-xxl-4">
                <div class="col-12 col-lg-8 col-xxl-9 d-flex flex-column justify-content-start align-items-center px-0 mt-4">
                    <div class="row justify-content-start row__width d-lg-none">
                        <button class="agregar-cursos-btn text-bold" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAddItems" aria-controls="offcanvasAddItems">
                            <span class="">
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                            {{ $t('message.addItems') }}
                        </button>
                    </div>
                    <div class="row justify-content-start row__width  mb-3">
                        <div class="col-12 d-flex flex-column justify-content-center input align-items-start px-0">
                            <h4 class="mb-3">Titulo de la clase</h4>
                            <input type="text" v-model="actualActivity.title" id="input-title-lesson" @focusout="setTitle(actualActivity.lessonId, actualActivity.title, actualActivity.translationId)">
                        </div>
                        <div class="col-12 d-flex justify-content-start align-items-center mt-3 mb-1 title_prov px-0">
                            <h3>Video principal</h3>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center collapses my-0">
                            <div class="row row__width justify-content-center">
                                <!-- VIDEO PRINCIPAL DE LA CLASE -->
                                <div class="col-12 d-flex justify-content-center align-items-center px-0 new_video">
                                    <div class="row row__width justify-content-start">
                                        <div class="col-12 col-lg-4 max-width-2 px-0 d-flex justify-content-center align-items-center ejemplo  mb-3 mb-lg-0" v-if="videoIDRef" :key="videoIDRef.videoID">
                                            <div class="d-flex justify-content-center align-items-center" v-if="videoIDRef.videoType == 1">
                                                <div class="custom-loader" v-if="videoLoader && videoIDRef"></div>
                                                <img class="video-preview" :src="youtubeThumbnailUrl" alt="" v-else-if="youtubeThumbnailUrl">
                                                <div class="div" v-else>
                                                    No hay vídeo
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center align-items-center" v-else>
                                                <div class="custom-loader" v-if="videoLoader && videoIDRef"></div>
                                                <img class="video-preview" :src="vimeoThumbnailUrl" alt="" v-else-if="vimeoThumbnailUrl">
                                                <div class="div" v-else>
                                                    No hay vídeo
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 max-width-2 px-0 d-flex justify-content-start align-items-center ejemplo example-video-none mb-3 mb-lg-0" v-else></div>
                                        <div class="col-12 col-lg d-flex flex-column justify-content-around align-items-start">
                                            <h4 class="mb-2">{{ $t('message.title') }}</h4>
                                            <input type="text" placeholder="www.ejemplo.com/watch" v-model="actualActivity.video" @focusout="setMainVideo(actualActivity.lessonId, actualActivity.video)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-start align-items-center mt-3 mb-1 title_prov px-0">
                            <h3>Recursos</h3>
                        </div>
                        <Container @drop="onDrop($event)" drag-handle-selector=".yes-draggable" class="collapses d-flex flex-column justify-content-center align-items-start my-0">
                            <Draggable v-for="(item, id) in actualActivity.content" :key="item.type" class="d-flex flex-column justify-content-center align-items-center">
                                <div class="draggable-item row row__width justify-content-center" v-if="item.type == 1">
                                    <!-- VIDEO -->
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas draggable-item">
                                        <div class="row row__width justify-content-center">
                                            <div class="col d-flex justify-content-center align-items-center pe-0 py-3 py-lg-0">
                                                <div class="d-flex justify-content-start align-items-center yes-draggable">
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" alt="" draggable="false" class="me-2">
                                                </div>
                                                <div class="row row__width justify-content-start">
                                                    <div class="col-12 d-flex flex-column justify-content-around align-items-start pe-0">
                                                        <h4 class="mb-2">Url del video</h4>
                                                        <input type="text" placeholder="www.ejemplo.com/watch" v-model="item.itemUrl" @focusout="addVideo(item.type, actualActivity.lessonId, item.itemUrl, item.resourceId)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col max-width d-flex justify-content-center align-items-center eliminar">
                                                <button type="button" @click="deleteResource(actualActivity.content, id, item.id)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="draggable-item row row__width justify-content-center" v-else-if="item.type == 2">
                                    <!-- TEXTO -->
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start textarea">
                                        <div class="row justify-content-center row__width width">
                                            <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0">
                                                <span class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex justify-content-start align-items-center yes-draggable">
                                                        <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" alt="" draggable="false" class="me-2">
                                                        <h6>B</h6>
                                                    </div>
                                                    <div class="d-flex justify-content-start align-items-center eliminar">
                                                        <p>{{ item.text ? item.text.length : '0' }}</p>
                                                        <button class="ms-2 " type="button" @click="deleteResource(actualActivity.content, id, item.resourceId)">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="" class="py-1">
                                                        </button>
                                                    </div>
                                                </span>
                                                <div class="w-100 my-5">
                                                    <quill-editor 
                                                        class="w-100" 
                                                        contentType="html" 
                                                        :toolbar="editorModules" 
                                                        theme="snow" 
                                                        style="height: 300px; overflow-y: auto;"
                                                        content-style="height: 100%;"
                                                        :options="{ scrollingContainer: '.ql-editor' }" 
                                                        v-model:content="item.text"
                                                        @focusout="addText(item.type, actualActivity.lessonId, item.text, item.resourceId, item.transId)"
                                                    >
                                                    </quill-editor>
                                                </div>
                                                <!-- <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="item.text" @focusout="addText(item.type, actualActivity.lessonId, item.text, item.resourceId, item.transId)"></textarea> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="draggable-item row row__width justify-content-center" v-else-if="item.type == 3">
                                    <!-- DESCARGABLE -->
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <h3>{{ $t('message.downloadbleRecourse') }}</h3>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas2 draggable-item">
                                        <div class="row row__width justify-content-center">
                                            <div class="col d-flex justify-content-center align-items-center">
                                                <div class="row row__width justify-content-start">
                                                    <div class="col px-0 d-flex justify-content-start align-items-center ejemplo" >
                                                        <div class="yes-draggable">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" draggable="false" alt="">
                                                        </div>
                                                        <img src="../../../img/subir-productos-icons/archivos-icons/pdf.svg" alt="" class="ms-2 ms-lg-3">
                                                        <p v-if="item.name">{{ item.name }}</p>
                                                        <p v-else-if="item.files">{{ item.files ? JSON.parse(item.files)[0].split('/').pop() : '' }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col max-width d-flex justify-content-center align-items-center eliminar">
                                                <button type="button" @click="deleteResource(actualActivity.content, id, item.resourceId)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="draggable-item row row__width justify-content-center" v-else-if="item.type == 4">
                                    <!-- TAREA -->
                                    <div class="col-12 d-flex justify-content-center align-items-center collapses collapses2">
                                        <div class="row row__width justify-content-center">
                                            <div class="col-1 d-flex justify-content-center align-items-start yes-draggable">
                                                <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" draggable="false" alt="">
                                            </div>
                                            <div class="col col-lg-10 d-flex justify-content-center align-items-center pe-0 pe-lg-auto">
                                                <div class="row row__width justify-content-center">
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                        <div class="row row__width justify-content-center">
                                                            <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                                <h3>Titulo de la tarea</h3>
                                                                <input type="text" v-model="item.name" @input="activeButton(item)">
                                                            </div>
                                                            <div class="col-3 col-md-1 d-flex justify-content-end align-items-end d-lg-none">
                                                                <div class="eliminar mb-2">
                                                                    <button class="m-0" type="button" @click="deleteResource(actualActivity.content, id, item.resourceId)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3">
                                                        <div class="row justify-content-center row__width width">
                                                            <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                                <h3>{{ $t('message.description') }}</h3>
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0">
                                                                <span class="d-flex justify-content-between align-items-center">
                                                                    <div class="d-flex justify-content-start align-items-center">
                                                                        <h6>B</h6>
                                                                    </div>
                                                                    <p v-if="item.description">{{ item.description.length }}/400</p>
                                                                </span>
                                                                <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="item.description" @input="activeButton(item)"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center align-items-center px-0  mt-3">
                                                        <div class="row row__width justify-content-center">
                                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                                <h3>Guía para el estudiante</h3>
                                                                <h4>{{ $t('message.uploadGuide') }}</h4>
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-start align-items-center px-0 upload my-3">
                                                                <label :for="`task-file`+id" v-if="file">
                                                                    <img src="../../../img/viendo-icons/tick-verde.svg" alt="" class="">
                                                                    Subido!
                                                                </label>
                                                                <label :for="`task-file`+id" v-else>
                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/upload.svg" alt="">
                                                                    {{ $t('message.uploadAFile') }}
                                                                </label>
                                                                <input type="file" name="upload-file" :id="`task-file`+id" @change="handleFileChange($event), activeButton(item)">
                                                            </div>
                                                            <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas2 mb-3" v-if="item.fileName">
                                                                <div class="row row__width justify-content-center">
                                                                    <div class="col-9 col-lg d-flex justify-content-center align-items-center">
                                                                        <div class="row row__width justify-content-start">
                                                                            <div class="col-12 col-lg px-0 d-flex justify-content-start align-items-center ejemplo" >
                                                                                <img src="../../../img/subir-productos-icons/archivos-icons/pdf.svg" alt="" class="ms-2 ms-lg-3">
                                                                                <p>{{ item.fileName.split('/').pop() }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col max-width d-flex justify-content-center align-items-center eliminar">
                                                                        <button type="button" @click="eraseFile(item, id)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 d-flex flex-column justify-content-center align-items-end px-0">
                                                                <button type="button" class="button" @click="addTask(item.type, actualActivity.lessonId, item.name, item.description, item.fileName, item.resourceId, item.transId, id, item)" v-if="item.isActive">GUARDAR</button>
                                                                <h3 class="saved" v-else>Guardado!</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1 d-flex justify-content-center align-items-start d-none d-lg-flex">
                                                <div class="eliminar">
                                                    <button class="m-0" type="button" @click="deleteResource(actualActivity.content, id, item.resourceId)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="draggable-item row row__width justify-content-center" v-if="item.type == 5">
                                    <!-- ENLACE EXTERNO -->
                                    <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                        <h3>{{ $t('message.externalLink') }}</h3>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 ofertas3 draggable-item">
                                        <div class="row row__width justify-content-center">
                                            <div class="col d-flex justify-content-center align-items-center pe-0">
                                                <div class="row row__width justify-content-start">
                                                    <div class="col-1 mx-0 px-0 d-flex justify-content-start align-items-center ejemplo" style="width: 2.333333%;">
                                                        <div class="yes-draggable">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/drag.svg" draggable="false" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="col d-flex flex-column justify-content-around align-items-start pe-0">
                                                        <input type="text" placeholder="www.ejemplo.com" v-model="item.itemUrl" @focusout="addLink(item.type, actualActivity.lessonId, item.itemUrl, item.resourceId)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col max-width d-flex justify-content-center align-items-center eliminar">
                                                <button type="button" @click="deleteActivity(actualActivity.content, id)"><img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt=""></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Draggable>
                        </Container>
                    </div>
                </div>
                <div class="col-4 col-lg-4 col-xxl-3 d-flex justify-content-center align-items-start px-0 mt-4 d-none d-lg-flex">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" @click="addVideo(1, actualActivity.lessonId)">
                                <div class="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img src="../../../img/subir-productos-icons/cursos-icons/video.svg" alt="">
                                    </span>
                                    {{ $t('message.video') }}
                                </div>
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                            </button>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" @click="addLink(5, actualActivity.lessonId)">
                                <div class="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img src="../../../img/subir-productos-icons/cursos-icons/enlace.svg" alt="">
                                    </span>
                                    {{ $t('message.externalLink') }}
                                </div>
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                            </button>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="button" class="agregar-cursos-btn d-flex justify-content-center align-items-center">
                                <label for="resource-file" class="d-flex justify-content-between align-items-center w-100">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <span>
                                            <img src="../../../img/subir-productos-icons/cursos-icons/download.svg" alt="">
                                        </span>
                                        {{ $t('message.downloadable') }}
                                    </div>
                                    <span>
                                        <img src="../../../img/panel-icons/mas.svg" alt="">
                                    </span>
                                </label>
                                <input type="file" name="upload-file" id="resource-file"  class="d-none" @change="addDownloadable(3, actualActivity.lessonId, $event)">
                            </button>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" @click="addText(2, actualActivity.lessonId)">
                                <div class="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img src="../../../img/subir-productos-icons/cursos-icons/texto.svg" alt="">
                                    </span>
                                    {{ $t('message.text') }}
                                </div>
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                            </button>
                        </div>
                        <!-- <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" @click="addTask(4, actualActivity.lessonId)">
                                <div class="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img src="../../../img/subir-productos-icons/cursos-icons/tarea.svg" alt="">
                                    </span>
                                    {{ $t('message.asignature') }}
                                </div>
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>
        <div class="offcanvas offcanvas-start offcanvas-items" tabindex="-1" id="offcanvasAddItems" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel">{{ $t('message.addItems') }}</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body px-0">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" data-bs-dismiss="offcanvas" @click="addVideo(1, actualActivity.lessonId)">
                            <div class="d-flex justify-content-start align-items-center">
                                <span>
                                    <img src="../../../img/subir-productos-icons/cursos-icons/video.svg" alt="">
                                </span>
                                {{ $t('message.video') }}
                            </div>
                            <span>
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" data-bs-dismiss="offcanvas" @click="addLink(5, actualActivity.lessonId)">
                            <div class="d-flex justify-content-start align-items-center">
                                <span>
                                    <img src="../../../img/subir-productos-icons/cursos-icons/enlace.svg" alt="">
                                </span>
                                {{ $t('message.externalLink') }}
                            </div>
                            <span>
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" class="agregar-cursos-btn d-flex justify-content-center align-items-center">
                            <label for="resource-file" class="d-flex justify-content-between align-items-center w-100">
                                <div class="d-flex justify-content-start align-items-center">
                                    <span>
                                        <img src="../../../img/subir-productos-icons/cursos-icons/download.svg" alt="">
                                    </span>
                                    {{ $t('message.downloadable') }}
                                </div>
                                <span>
                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                </span>
                            </label>
                            <input type="file" name="upload-file" id="resource-file" class="d-none" @change="addDownloadable(3, actualActivity.lessonId, $event)">
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" data-bs-dismiss="offcanvas" @click="addText(2, actualActivity.lessonId)">
                            <div class="d-flex justify-content-start align-items-center">
                                <span>
                                    <img src="../../../img/subir-productos-icons/cursos-icons/texto.svg" alt="">
                                </span>
                                {{ $t('message.text') }}
                            </div>
                            <span>
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                        </button>
                    </div>
                    <!-- <div class="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" class="agregar-cursos-btn d-flex justify-content-between align-items-center" data-bs-dismiss="offcanvas" @click="addTask(4, actualActivity.lessonId)">
                            <div class="d-flex justify-content-start align-items-center">
                                <span>
                                    <img src="../../../img/subir-productos-icons/cursos-icons/tarea.svg" alt="">
                                </span>
                                {{ $t('message.asignature') }}
                            </div>
                            <span>
                                <img src="../../../img/panel-icons/mas.svg" alt="">
                            </span>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Nombre from '../../../components/Inputs/AgregarTarea/Nombre.vue';

import { onMounted, provide, ref, watch, inject, nextTick, watchEffect, computed, createApp } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useVideoStore } from "../../../stores/video";
import { useLanguageStore } from "../../../stores/langs"
import { Container, Draggable } from "vue-dndrop";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp()
app.component('QuillEditor', QuillEditor)

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const video = useVideoStore();
const { } = storeToRefs(video);
const { verifiedPlataform } = video;

const editorModules = {
  toolbar: [
    [{ header: [] }], // Encabezados de h1 a h6
    ['bold', 'italic', 'underline'],  
    [{ list: 'ordered' }, { list: 'bullet' }], // Listas
    ['blockquote', 'code-block'],        // Bloque de cita y bloque de código
    [{ script: 'sub' }, { script: 'super' }], // Superíndice y subíndice
    [{ align: [] }],                     // Alineación
    ['link', 'image', 'video'],          // Insertar link, imagen y video
    [{ color: [] }, { background: [] }], // Colores de texto y fondo
    ['clean']                            // Limpiar formato
  ]
};


let urlReorderResources = '/api/product/creating/module/lesson/resources/reorder'

let urlDeleteResource = '/api/product/creating/module/lesson/resources/delete/'

let urlSetTitle = '/api/product/creating/add/module/lesson/translation'
let urlSetVideo = '/api/product/creating/module/lesson/main/video/'

let urlAddVideo = '/api/product/creating/module/lesson/resources/video'
let urlAddLink = '/api/product/creating/module/lesson/resources/link'
let urlAddDown = '/api/product/creating/module/lesson/resources/downloadable'
let urlAddText = '/api/product/creating/module/lesson/resources/text'

let urlAddTask = '/api/product/creating/module/lesson/resources/task'

let file = ref()

let product = inject('product')
let information = inject('information')
let actualActivity = inject('actualActivity')

let videoIDRef = inject('videoIDRef')
let videoData = ref()

let youtubeThumbnailUrl = ref()
let vimeoThumbnailUrl = ref()

let owner_id = inject('owner_id')
let product_id = inject('product_id')

let videoLoader = ref(true)

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const handleFileChange = (event) => {
  file.value = event.target.files[0];
  console.log(file.value)
};

const eraseFile = (item, id) => {
    item.fileName = ''
    let inputElement = document.getElementById("task-file"+id);
    inputElement.value = null
}

const activeButton = (item) => {
    item.isActive = true
}

const setNewResPos = async(resPositions, copy) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlReorderResources, {resPositions: resPositions}, {headers: headers.value}).then((result) => {
            console.log(result)
            console.log(actualActivity.value.content)
        })
    } catch (error) {
        console.error(error)
        actualActivity.value.content = copy
    }
}

const onDrop = (dropResult) => {
    let copy = actualActivity.value.content
    actualActivity.value.content = applyDrag(actualActivity.value.content, dropResult);
    let resPositions = []
    console.log(actualActivity.value.content)
    for(let i = 0; i < actualActivity.value.content.length; i++){
        resPositions.push([actualActivity.value.content[i].resourceId, i])
    }
    setNewResPos(resPositions, copy)
};

const addItem = (type) => {
    if (type == 1){
        //VIDEO
        actualActivity.value.content.push(
            {
                type: 1,
                itemUrl: ''
            }
        )
    }
    else if (type == 2){
        //TEXTO
        actualActivity.value.content.push(
            {
                type: 2,
                text: ''
            }
        )
    }
    else if (type == 3){
        //DESCARGABLE
        actualActivity.value.content.push(
            {
                type: 3,
                name: ''
            }
        )
    }
    else if (type == 4){
        //TAREA
        actualActivity.value.content.push(
            {
                type: 4,
                name: '',
                description: '',
                files: []
            }
        )
    }
    else if (type == 5){
        //ENLACE EXTERNO
        actualActivity.value.content.push(
            {
                type: 5,
                itemUrl: ''
            }
        )
    }
}

const deleteActivity = (item, id) => {
    item.splice(id, 1);
}

const setTitle = async(lessonId, title, translationId) => {
    let lang = information.value.lang
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlSetTitle, {lessonId: lessonId, lang: lang, title: title, translationId: translationId, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            actualActivity.value.translationId = result.data.translationId
        })
    } catch (error) {
        console.log(error)
    }
}

const setMainVideo = async(lessonId, videoUrl) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    if(videoUrl.includes('vimeo')){
        const segments = videoUrl.split('/');
        const lastSegment = segments[segments.length - 1];
        videoUrl = `https://player.vimeo.com/video/${lastSegment}`;
        console.log(videoUrl)
    }
    try {
        await axios.post(url+urlSetVideo+lessonId, {video: videoUrl}, {headers: headers.value}).then((result) => {
            console.log(result)
        })
        if(videoUrl){
            console.log(videoIDRef)
            videoIDRef.value = ''
            videoIDRef.value = await verifiedPlataform(videoUrl)
            // console.log(videoIDRef.value)
            videoIDRef.value.url = videoUrl
            if(videoIDRef.value.time){
                console.log(videoIDRef.value.time)
                setTimeout(() => {
                    saveVideoDuration(videoIDRef.value.time)
                }, 2000)
            }
            youtubeThumbnailUrl.value = null
            vimeoThumbnailUrl.value = null

            // console.log(videoIDRef.value)
        }else{
            videoIDRef.value = ''
        }
    } catch (error) {
        console.log(error)
    }
}

const deleteResource = async(array, id, resourceId) => {
    console.log()
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.delete(url+urlDeleteResource+resourceId, {headers: headers.value}).then((result) => {
            console.log(result)
            let copy = actualActivity.value.content
            let resPositions = []
            for(let i = 0; i < actualActivity.value.content.length; i++){
                resPositions.push([actualActivity.value.content[i].resourceId, i])
            }
            deleteActivity(array, id)
            setNewResPos(resPositions, copy)
        })
    } catch (error) {
        console.error(error)
    }
}

const addVideo = async(type, lessonId, videoUrl, resourceId) => {
    let resourcePos = actualActivity.value.content.length
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlAddVideo, {lessonId: lessonId, type: type, url: videoUrl, resourceId: resourceId, resourcePos: resourcePos, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                if (!resourceId){
                    actualActivity.value.content.push(
                        {
                            type: 1,
                            itemUrl: '',
                            resourceId: result.data.resourceId,
                            resourcePos: resourcePos
                        }
                    )
                }
                else {

                }
            }
        })
    } catch (error) {
        console.log(error)
    }
}

const addLink = async(type, lessonId, linkUrl, resourceId) => {
    let resourcePos = actualActivity.value.content.length
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlAddLink, {lessonId: lessonId, type: type, url: linkUrl, resourceId: resourceId, resourcePos: resourcePos, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                if (!resourceId){
                    actualActivity.value.content.push(
                        {
                            type: 5,
                            itemUrl: '',
                            resourceId: result.data.resourceId,
                            resourcePos: resourcePos
                        }
                    )
                }
                else {
                    
                }
            }
        })
    } catch (error) {
        console.log(error)
    }
}

const addDownloadable = async(type, lessonId, event, resourceId) => {
    //mostrar preloader
    let file = event.target.files[0]
    let resourcePos = actualActivity.value.content.length
    const formData = new FormData()
    formData.append('id', product.value.id)
    formData.append('lessonId', lessonId)
    formData.append('resourcePos', resourcePos)
    formData.append('userId', product.value.ownerId)
    formData.append('type', type)
    formData.append('file', file)
    formData.append('owner_id', owner_id.value)
    formData.append('product_id', product_id.value)
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(product.value.ownerId)
    try {
        await axios.post(url+urlAddDown, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                if (!resourceId){
                    let name = result.data.name.substring(result.data.name.lastIndexOf("/") + 1);
                    actualActivity.value.content.push(
                        {
                            type: 3,
                            name: name,
                            resourceId: result.data.resourceId,
                            resourcePos: resourcePos
                        }
                    )
                }
                else {
                    
                }
            }
        })
    } catch (error) {
        console.log(error)
    }
    //quitar preloader
}

const addText = async(type, lessonId, text, resourceId, transId) => {
    let resourcePos = actualActivity.value.content.length
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlAddText, {lessonId: lessonId, type: type, text: text, resourceId: resourceId, transId: transId, resourcePos: resourcePos, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                if (!resourceId){
                    actualActivity.value.content.push(
                        {
                            type: 2,
                            text: '',
                            resourceId: result.data.resourceId,
                            transId: result.data.transId,
                            resourcePos: resourcePos
                        }
                    )
                }
                else {
                    
                }
            }
        })
    } catch (error) {
        console.log(error)
    }
}

const addTask = async(type, lessonId, name, description, fileName, resourceId = '', transId = '', id, item) => {
    let fileTask = null
    let inputElement = document.getElementById("task-file"+id);
    if (inputElement){
        console.log(inputElement.files)
        if (inputElement.files.length > 0) {
            fileTask = inputElement.files[0]
            console.log(fileTask)
        }
    }
    let resourcePos = actualActivity.value.content.length
    const formData = new FormData()
    formData.append('id', product.value.id)
    formData.append('lessonId', lessonId)
    formData.append('resourceId', resourceId)
    formData.append('transId', transId)
    if(product.value.ownerId){
        formData.append('userId', product.value.ownerId)
    }
    else {
        formData.append('userId', idUser.value.id)
    }
    formData.append('resourcePos', resourcePos)
    formData.append('type', type)
    formData.append('name', name)
    formData.append('description', description)
    if (fileTask){
        formData.append('file', fileTask)
    }
    formData.append('fileName', fileName)
    formData.append('owner_id', owner_id.value)
    formData.append('product_id', product_id.value)
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlAddTask, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            if (!resourceId){
                console.log(fileName)
                actualActivity.value.content.push(
                    {
                        type: 4,
                        name: '',
                        description: '',
                        fileName: fileName,
                        resourcePos: resourcePos,
                        resourceId: result.data.resourceId,
                        transId: result.data.transId,
                        isActive: true
                    }
                )
            }
            else{
                if(result.data.fileName){
                    actualActivity.value.content[id].fileName = result.data.fileName.split('/').pop()
                }
                item.isActive = false
            }
        })
    } catch (error) {
        console.log(error)
    }
}

const saveVideoDuration = async(duration) => {
    let lessonId = actualActivity.value.lessonId
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    console.log(duration)
    try {
        await axios.post(url+'/api/product/creating/module/lesson/config/save/video/duration', {lessonId: lessonId, duration: duration}, { headers: headers.value }).then(result => {
            console.log(result)
        })
        if(videoIDRef.value.youtube){
            videoIDRef.value.update = 1
            youtubeThumbnailUrl.value = videoIDRef.value?.youtube?.data?.items?.[0]?.snippet?.thumbnails?.medium?.url || null
        } else if(videoIDRef.value.vimeo){
            videoIDRef.value.update = 1
            vimeoThumbnailUrl.value = videoIDRef.value?.vimeo?.thumbnail_url || null
        }
        videoLoader.value = false
    } catch (error) {
        console.log(error)
    }
}

watch(videoIDRef, () => {
    videoLoader.value = true
    console.log(videoIDRef.value)
    setTimeout(() => {
        saveVideoDuration(videoIDRef.value.time)
    }, 2000)
})

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
.yes-draggable{
    display: flex;
    user-select: none;
    height: 100%;
    justify-content: center !important;
    align-items: center !important;
}
.collapses, .collapses2{
    user-select: none;
    background-color: #FCFCFC;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px 10px;
    @media (min-width: 992px) {
        padding: 15px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #F9F9F9;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            min-height: 77px;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
            }
            .accordion-button::after{
                position: absolute;
                right: 2%;
                background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border: 2px solid #E4E4E4;
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
            .accordion-body{
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    min-height: 40px;
                    min-width: 40px;
                }
            }
            .buttons{
                position: absolute;
                right: 7%;
            }
            
        }
        .dropdown{
            width: 100%;
            box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
            border-radius: 10px;
            button{
                width: 100%;
                background-color: var(--bgPrincipal);
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                border: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                border-radius: 10px;
                padding: 0 20px;
                h3{
                    padding: 20px 0;
                }
                .dropdown-menu{
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    border: 0;
                    padding: 20px 20px 0 20px;
                    height: auto;
                    border-radius: 0 0 10px 10px ;
                    a:hover, a:focus{
                        background-color: inherit;
                    }
                    a{
                        padding: 0 0 10px 0;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #2E4765;
                        font-size: 15px;
                        font-weight: 400;
                    }
                    span{
                        height: 40px;
                        width: 40px;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }
                    li{
                        width: 100%;
                    }
                }
            }
            .dropdown-toggle::after{
                display: none;
            }
            .arrow{
                width: 25px;
                height: 15px;
                background-repeat: no-repeat;
                background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all ease 0.2s;
                background-size: 75%;
                background-position: center;
            }
        }
        .dropdown-toggle.show{
            .dropdown-menu{
                box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.15);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:after{
                    content: '';
                    position: absolute;
                    background-color: var(--bgPrincipal);
                    width: 100%;
                    height: 10px;
                    top: -7px;
                }
            }
            .arrow{
                transform: rotate(180deg);
                transition: all ease 0.2s;
            }
        }
        
    }
    .eliminar{
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
        }
    }
}
.ofertas, .ofertas2, .ofertas3{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    box-shadow: 0 3px 6px #00000029;
    border-radius: 15px;
    @media (min-width: 992px) {
        height: 110px;
    }
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
        @media (max-width: 991px) {
            font-size: 14px;
        }
    }
    .eliminar{
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            min-height: 40px;
            min-width: 40px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        .img{
            max-height: 100%;
            max-width: 100%;
            margin-left: 5px;
            min-width: 118px;
            height: 83px;
            @media (min-width: 992px) {
                object-fit: cover;
                border-radius: 5px;
                height: 83px;
                width: 118px;
                margin-left: 10px;
            }
        }
    }
    span{
        min-height: 40px;
        min-width: 40px;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            font-size: 14px;
            color: #2E4765;
            font-weight: 300;
        }
    }
    .max-width{
        max-width: 15%;
        border-left: 1px solid #bebdbd5b;
        margin-left: 10px;
        @media (min-width: 992px) {
            max-width: 10%;
            margin-left: 0px;
            border-left: 0;
        }
    }
    .max-width-2{
        @media (min-width: 992px) {
            max-width: 13vw;
        }
    }
    .span-noti{
        position: relative;
        margin-right: 15px;
        .noti{
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--colorPrimary);
            display: flex;
            justify-content: center;
            align-items: center;
            top: -5px;
            right: -5px;
            p{
                color: var(--bgPrincipal);
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}
.input{
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}

.collapses2{
    @media (min-width: 992px) {
        padding: 30px 0px;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        padding: 0px 0px 10px 0 !important;
    }
    h4{
        color: #BEBDBD;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        padding: 0px 0px 10px 0 !important;
    }
    p{
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
    }
    .width{
        width: -webkit-fill-available;
    }
    textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 20vh;
        padding-left: 15px;
        outline: 0;
        padding-top: 50px;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            
            color: #697891;
        }
        
    }
    span{
        background-color: #E4E4E4;
        height: 42px;
        width: 100%;
        position: absolute;
        max-width: 99.5%;
        top: 1.5px;
        border-radius: 8px 8px 0 0 ;
        padding: 0px 15px;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding-left: 5px;
        }
        img{
            height: 24px;
        }
    }
    .inputs{
        margin: 10px 0px !important;
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}
.ofertas2{
    background-color: #F9F9F9;
    height: 57px;
   
    .ejemplo{
        .img{
            max-height: 30px;
        }
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
        max-width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (max-width: 991px) {
            font-size: 14px;
        }
    }
}
.ofertas3{
    background-color: #F9F9F9;
    height: 70px;
    .ejemplo{
        .img{
            max-height: 30px;
        }
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
}
.checkboxes{
    input[type=radio]{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
        }
        .rojo{
            background-color: #EF4836;
        }
        .verde{
            background-color: #37BFA3;
        }
        .azul{
            background-color: #1679FC;
        }
        i{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            display: block;
        }
        .star{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/star.svg');
        }
        .flame{
            background-image: url('../../../img/subir-productos-icons/cursos-icons/flame.svg');
        }
    }
    input:checked + label .span{
        border-color: #000;
        &::after{
            display: block;
        }
    }
    input:checked + label .star{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/star-active.svg');
    }
    input:checked + label .flame{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/flame-active.svg');
    }
    .col{
        max-width: 32px;
    }
    .col-perso{
        max-width: 45px;
    }
    .bg-azul{
        background-color: rgba(22, 122, 252, 0.17);
        padding: 15px;
        border-radius: 5px;
        margin: 30px 0;
        h5{
            font-weight: 500;
            color: #242134;
            font-size: 15px;
            padding: 5px 0;
        }
    }
    .time{
        .span{
            border: 2px solid var(--colorPrimary);
            height: 44px;
            width: 44px;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 12px;
            color: var(--colorPrimary);
            padding: 10px;
        }
        input:checked + label .span{
            background-color: var(--colorPrimary);
            border-color: #E4E4E4;
            color: var(--bgPrincipal);
            &::after{
                display: block;
            }
        }
        .margin{
            margin-top: 30px;
        }
    }
}
.agregar-cursos-btn{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 43px;
    height: 60px;
    margin: 0 0 15px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0px;
    width: 100%;
    max-width: 300px;
    text-align: left;
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }
}
.dndrop-draggable-wrapper{
    width: 100%;
}
.draggable-item{
    margin-bottom: 10px;
}
.upload{
    input{
        display: none;
    }
    label{
        background-color: transparent;
        border-radius: 24px;
        height: 50px;
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 200px;
        cursor: pointer;
        img{
            padding-right: 10px;
        }
    }
}
.uploaded{
    input{
        display: none;
    }
    label{
        background-color: transparent;
        border-radius: 24px;
        height: 50px;
        border: 2px solid var(--colorGreen);
        color: var(--colorGreen);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 200px;
        cursor: pointer;
        img{
            padding-right: 10px;
        }
    }
}
.offcanvas-items{
    h5{
        font-size: 20px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    .offcanvas-header{
        border-bottom: 1px solid #00000029;
        padding: 20px 30px;
    }
    .btn-close{
        background-image: url('../../../img/panel-icons/close.svg');
        height: 18px;
        width: 18px;
        background-size: 100%;
    }
    .agregar-cursos-btn{
        max-width: 90%;
    }
}
.textarea {
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        padding: 7px 0px;
    }
    p{
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
    }
    .width{
        width: -webkit-fill-available;
    }
    textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 20vh;
        padding-top: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            
            color: #697891;
        }
        
    }
    span{
        background-color: #E4E4E4;
        height: 42px;
        width: 100%;
        position: absolute;
        max-width: 99.5%;
        top: 1.5px;
        border-radius: 8px 8px 0 0 ;
        padding: 0px 15px;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding-left: 5px;
        }
        img{
            height: 24px;
        }
    }
}
.text-bold{
    font-weight: 700;
    max-width: 200px;
}

.title_prov{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
}
.new_video{
    .ejemplo{
        border-radius: 10px;
        max-height: 120px;
        .img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    .example-video-none{
        border-radius: 10px;
        height: 120px;
        background-color: #b8bcc833;
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
}
.button{
    background-color: transparent;
    border-radius: 24px;
    height: 50px;
    border: 2px solid var(--colorPrimary);
    color: var(--colorPrimary);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}
.plyr--video{
    width: 100%;
}
.plyr{
    min-width: 100px !important;
}
.video-preview{
    border-radius: 15px;
    width: 175px;
    height: 100px;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#1679FC 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#1679FC);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
</style>