<template>
    <div class="container-fluid">
        <NavbarBack class="" v-if="cambioHeader"/>
        <NavbarBuscadorBack v-else/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
        <ResumenScroll/>
        <div class="row justify-content-center mt-lg-5">
            <div class="col-11 d-flex justify-content-center align-items-center px-0">
                <div class="row justify-content-center justify-content-lg-between row__width">
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-center px-0 max-width-contenido">
                        <div class="row justify-content-center row__width">
                            <TituloHomeCursos :tituloCurso="$t('message.courseTitle')" :tituloGrande="true" class=""/>
                            <ProfesorHomeCursos class="d-none d-lg-flex"/>
                            <RateProfesorCurso class="d-none d-lg-flex"/>
                            <HomeCursosVideo/>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center tabs">
                                <TabsV3/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 justify-content-center align-items-start d-none d-lg-flex">
                        <div class="row justify-content-center align-items-center row__width">
                            <PagosV2/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DespliegueCarro class="d-lg-none"/>
        <Footer class="d-lg-none"/>
        <FooterHome/>
    </div>
</template>

<script setup>
    import Footer from '../../components/Footer/Footer.vue';
    import NavbarBuscadorBack from '../../components/Navs/NavbarBuscadorBack.vue';
    import FooterHome from '../../components/Footer/FooterHome.vue';
    import DespliegueCarro from '../../components/Carrito/DespliegueCarro.vue';
    import HomeCursosVideo from '../../components/Cursos/Video.vue';
    import TituloHomeCursos from '../../components/Titulo/TituloHomeCursos.vue';
    import ProfesorHomeCursos from '../../components/Cursos/Profesor.vue';
    import RateProfesorCurso from '../../components/Cursos/RateProfesorCurso.vue';
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import ResumenScroll from "../../components/Cursos/ResumenScroll.vue";
    import PagosV2 from "../../components/Cursos/PagosV2.vue";
    import TabsV3 from "../../components/Cursos/TabsV3.vue";
    import NavbarBack from "../../components/Navs/Navbar.vue";
    import { useUsersStore } from "../../stores/user";
    import { storeToRefs } from "pinia";
    
    const users = useUsersStore();
    const {cambioHeader} = storeToRefs(users);

</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
    .max-width-contenido{
        max-width: 55%;
    }
}


</style>