<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0 pe-lg-0 skills specialization">
        <h3>{{ $t('message.selectSpecialization') }}</h3>
        <multiselect2 
        v-model="information.specialities"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="false"
        :options="options"
        label="name" trackBy="name" valueProp="id" :multipleLabel="multipleLabel" :value="habilities" :placeholder="$t('message.select')" :noResultsText="$t('message.withoutResults')"/>
    </div>
</template>

<script setup>
import axios from "axios"
import { useI18n } from "vue-i18n"
import Multiselect from 'vue-multiselect'
import Multiselect2 from '@vueform/multiselect'
import { ref, inject, onMounted, watch } from 'vue';
import { storeToRefs } from "pinia";
import { useLanguageStore } from "../../../stores/langs"  
import { useUsersStore } from "../../../stores/user";

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {langWeb} = storeToRefs(langs)
const {} = langs


const validez = inject('validez')
const habilities = inject('habilities')
const options = inject('options')

const users = useUsersStore()
const {} = storeToRefs(users)
const {url} = users
const information = inject('information')


const urlHabilities = '/api/categories/professions/select/'

const setHabilities = async() =>{
    let lang = JSON.parse(localStorage.getItem('lang'))
    try {
        await axios.get(url+urlHabilities+lang).then((result) => {
            //console.log(result)
            options.value = result.data
            //console.log(options.value)
            //console.log(habilities.value)
        })
    }
    catch (error) {
        //console.log(error)
    }
}
setHabilities()
//Actualiza el idioma de los textos de las habilidades
watch(locale, () => {
    setHabilities()
})

const multipleLabel = (values) => {
  return values.map((value) => {
    const option = options.find((option) => option.id === value)
    return option ? option.name : value
  }).join(', ')
}
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input,select{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: #FCFCFC;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    -webkit-appearance: none;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.valido{
    background-image: url('../../../img/panel-icons/input-valido.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
}
</style>