<template>
    <div class="row acordeon__detalles">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item col-12">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"></button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body row justify-content-center py-0">
                        <div class="col-12 d-flex justify-content-start align-items-center px-0" v-for="(price, index) in product.payments" :key="index">
                            <div v-if="price.payment_type == 'one_time'" class="w-100">
                                <input type="radio" :id="'index'+price.id" :value="price" v-model="product.selectedOption" name="opciones4">
                                <label :for="'index'+price.id">
                                    <div class="span"></div>
                                    <div class="padding row justify-content-center">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <h5>{{ price.coin.abbr }} ${{price.price}}</h5>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p>{{ price.title }}</p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div v-else-if="price.payment_type == 'suscripción'">
                                <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                    <input type="radio" :id="'button'+price.id" name="opciones">
                                    <label :for="'button'+price.id">
                                        <div class="span"></div>
                                        <div class="padding row justify-content-center">
                                            <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0 pb-2">
                                                <div class="row justify-content-between row__width">
                                                    <div class="col d-flex justify-content-start align-items-center">
                                                        <h5>MX ${{priceMember}}</h5>
                                                    </div>
                                                    <div class="col-5 d-flex justify-content-center align-items-center px-0 d-lg-none">
                                                        <span>50% Dto.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-between align-items-center pe-0">
                                                <p class="descuento">Con tu membresía, obtén acceso a este descuento y otros beneficios exclusivos.</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center my-3">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-start align-items-center px-0">
                                    <!-- <button class="button">
                                        <img src="../../img/home-icons/cursos-icons/fav.svg" alt="">
                                    </button> -->
                                    <button class="button" @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">
                                        <img src="../../img/home-icons/cursos-icons/comprar.svg" alt="">
                                    </button>
                                </div>
                                <div class="col-8 d-flex justify-content-center align-items-center pe-0">
                                    <router-link to="/carrito/4" class="btn__primarioV2" @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">{{ $t('message.buyNow') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, inject } from 'vue';
import { storeToRefs } from "pinia"
import { useCartStore } from "../../stores/cart"
import PrimarioV2 from "../Botones/PrimarioV2.vue";

const carts = useCartStore();
const { cart, arrayCheckout } = storeToRefs(carts);
const { addtoCart } = carts

const props = defineProps({
    price: '',
    priceMember: '',
    modality: '',
    seats: '',
    participants: '',
    offer: ''
})

let product = inject('product')



const iconoImg = ref(true)

const mostrarTexto6 = () => {
    document.getElementById("desaparecer6").classList.toggle("d-none");
    document.getElementById("filtro6").classList.toggle("before");
    if(iconoImg.value == false){
        iconoImg.value =true
    }else{
        iconoImg.value =false
    }
}

</script>

<style lang="scss" scoped>
.acordeon__detalles{
    position: fixed;
    bottom: 85px;
    width: 100%;
    z-index: 50;
    .accordion{
        padding: 0;
        .accordion-item{
            box-shadow: 0px 3px 15px #00000042;
            border-radius: 20px 20px 0px 0px;
        }
        .accordion-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .accordion-button::after{
            margin-left: 0;
        }
        .accordion-button:focus{
            color: inherit;
            background-color: transparent;
            border-color: inherit;
            box-shadow: none;
        }
        input{
            display: none;
        }
        input + label{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;
            .span{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #E4E4E4;
                min-width: 28px;
                min-height: 28px;
                left: 0;
                &::after{
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: var(--colorPrimary);
                    width: 14px;
                    height: 14px;
                    display: none;
                }
            }
        }
        input:checked + label .span{
            border-color: var(--colorPrimary);
            &::after{
                display: block;
            }
        }
        input:checked + label{
            border: 2px solid var(--colorPrimary);
            border-radius: 20px;
            box-shadow: 0px 3px 6px #00000029;
        }
        .accordion-button:not(.collapsed){
            color: inherit;
            background-color: inherit;
            box-shadow: none;
        }
        h5{
            font-weight: 700;
            font-size: 25px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h4{
            color: #F85C5C;
            font-size: 15px;
            font-weight: 400;
            text-decoration: line-through;
            text-align: left;
        }
        p{
            font-weight: 600;
            font-size: 14px;
            color: var(--colorSecondary);
            text-align: left;
            width: max-content;
            @media (max-width: 375px) {
                font-size: 13px;
            }
        }
        .upper{
            text-transform: uppercase;
        }
        .padding{
            padding-left: 3%;
        }
        .button{
            border: 0;
            background-color: transparent;
            outline: 0;
            border-radius: 50%;
            border: 2px solid var(--colorSecondary);
            height: 50px;
            width: 50px;
            margin-right: 7px;
        }
    }
}
</style>