<template>
    <div class="row justify-content-center row__width titulo py-3">
        <div class="col-12 d-flex justify-content-start align-items-center ms-lg-4 px-0 px-lg-auto">
            <!-- <router-link class="btn_none px-0 d-flex justify-content-center align-items-center pe-3" to="/dashboard">
                <img src="../../img/home-icons/arrow-left-negra.svg" alt="">
            </router-link> -->
            <h2>{{title}}</h2>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue'
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();

const props = defineProps({
    title: ''
})
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       margin-bottom: 0;
    }
    h3{
        font-size: 16px;
        font-weight: 600;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    img{
        height: 20px;
    }
    button{
        margin-right: 10px;
    }
}
</style>