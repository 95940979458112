<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <RolInicioRegistro/>
                <TituloYSubtituloRegistro :titulo="$t('message.registerMe')" :subtitulo="$t('message.subtitleRegister')" :desaparecerSubtitulo="true"/>
                <!-- <div class="row justify-content-center row__width">
                    <BTNFacebook :textoBTNFacebook="$t('message.registrationFacebook')"/>
                    <BTNGoogle :textoBTNGoogle="$t('message.registrationGoogle')"/>
                    <BTNTelefono :textoBTNTelefono="$t('message.loginPhone')" class="d-none"/>
                </div> -->
                <RegistrarMail :enlaceRegistro="$t('message.registrationlink')"/>
                <TerminosYCondiciones :acepto="$t('message.iAgree')" :terminos="$t('message.terms')"/>
                <div class="row justify-content-center row__width margin-top">
                    <div class="col-12 d-flex justify-content-center align-items-center px-0">
                        <BTNGrisInicio :textoBtn="$t('message.continue')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import RolInicioRegistro from '../../components/Roles/RolInicioRegistro.vue';
import TituloYSubtituloRegistro from '../../components/Titulo/TituloYSubtituloRegistro.vue';
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import BTNTelefono from '../../components/Botones/Telefono.vue';
import RegistrarMail from "../../components/Enlaces/RegistrarMail.vue";
import TerminosYCondiciones from "../../components/TerminosYCondiciones/TerminosYCondiciones.vue";
import BTNGrisInicio from "../../components/Botones/GrisInicio.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";


const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>

</style>