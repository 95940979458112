<template>
    <div class="col-12 d-flex justify-content-start justify-content-lg-center align-items-center ingreso__rapido ">
      <h5>{{$t('message.loginWithSocialAcoount')}}</h5>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.ingreso__rapido{
  h5{
    font-weight: 400;
    font-size: 16px;
    color: #7D93B2;
    text-align: center;
    padding: 50px 0 10px 0px;
    width: 100%;
    @media (min-width: 992px) {
      padding: 20px 0;
      text-align: left;
    }
  }
}
</style>