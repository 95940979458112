<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center cont_input position-relative selectTelefono">
        <div class="row justify-content-center row__width">
            <div class="col-4 d-flex justify-content-center align-items-center px-0 numeros">
				<Dropdown v-model="information.phone.label" :options="groupedCities" optionLabel="label" :filter="true" :placeholder="$t('message.select') " class="multiselect" 
					filterPlaceholder="Buscar" dropdownIcon="src" filterIcon="search" emptyFilterMessage="No hay resulados">
					<template #value="slotProps">
						<div class="p-dropdown-car-value" v-if="slotProps.value != null">
							<img  src="../../img/home-icons/mexico-bandera.svg" />
							<span>{{slotProps.value.label}}</span>
						</div>
						<span v-else>
							{{slotProps.placeholder}}
						</span>
					</template>
					<template #option="slotProps">
						<div class="p-dropdown-car-option">
							<img  src="../../img/home-icons/mexico-bandera.svg" />
							<span>{{slotProps.option.label}}</span>
						</div>
					</template>
				</Dropdown>
			</div>
			<div class="col-8 px-0 telefono">
				<input type="number" :placeholder="$t('message.telephone')" id="inputTelefono" v-model="information.phone.number">
			</div>
        </div>
    </div>
</template>

<script setup>
import Dropdown from 'primevue/dropdown';
import { ref, inject } from 'vue'

const groupedCities = ref([
	{label: '+1'},
	{label: '+93'},
	{label: '+80'},
	{label: '+54'},
	{label: '+33'},
	{label: '+22'},
	{label: '+11'},
	{label: '+9'},
	{label: '+30'},
	{label: '+10'},
	{label: '+50'},
	{label: '+45'}
])
const information = inject('information')
</script>

<style lang="scss" scoped>
h5{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
    padding: 10px 0px;
    text-align: left;
    width: 100%;
}
.telefono input{
    width: 100%;
    border: 2px solid #E4E4E4;
    border-radius:  0 10px 10px 0;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
.numeros{
	input{
		display: none;
	}
}

</style>