<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start duration">
            <h3>{{$t('message.birthdate')}}</h3>
            <div class="position">
                <!-- <input id="calendar-input-6" type="date" name="birthdate" placeholder="" :class="estados.birthdate" v-model="usuariosRegistro.birthdate"/>
                <div id="calendar-input-div"></div>
                <i class="calendar"></i> -->
                <input
                    type="text"
                    name="reg-user-day"
                    id="reg-user-day"
                    v-model="day"
                    maxlength="2"
                    @input="validateDay"
                    @keydown.backspace="moveToPrevious($event, 'day')"
                    placeholder="Día"
                    :class="estados.birthdate"
                />-
                <input
                    type="text"
                    name="reg-user-month"
                    id="reg-user-month"
                    v-model="month"
                    maxlength="2"
                    @input="validateMonth"
                    @keydown.backspace="moveToPrevious($event, 'month')"
                    placeholder="Mes"
                    :class="estados.birthdate"
                />-
                <input
                    type="text"
                    name="reg-user-year"
                    id="reg-user-year"
                    v-model="year"
                    maxlength="4"
                    @input="validateYear"
                    @keydown.backspace="moveToPrevious($event, 'year')"
                    placeholder="Año"
                    :class="estados.birthdate"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref, computed, onMounted, watch, watchEffect } from 'vue';
import { storeToRefs } from "pinia";
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';
import 'v-calendar/style.css';
import { useI18n } from 'vue-i18n'

import { useUsersStore } from "../../stores/user"
import { useGeneralStore } from "../../stores/general"
    
const general = useGeneralStore()
const {} = storeToRefs(general)
const {} = general

const estados = inject('estados')
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore()
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {usuariosRegistro} = storeToRefs(users)
const {} = users

// const { t }  = useI18n()

// const {locale} = useI18n({ useScope: 'global' })

const day = ref('')
const month = ref('')
const year = ref('')
const currentYear = new Date().getFullYear()

const isValidDate = computed(() => {
    const dayNum = parseInt(day.value, 10)
    const monthNum = parseInt(month.value, 10)
    const yearNum = parseInt(year.value, 10)

    const validDay = dayNum >= 1 && dayNum <= 31
    const validMonth = monthNum >= 1 && monthNum <= 12
    const validYear = yearNum >= 0 && yearNum <= currentYear

    return validDay && validMonth && validYear
})

// const calendar = ref({})

// const options = ref({
//   input: true,
//   actions: {
//     changeToInput(e, HTMLInputElement, dates, time, hours, minutes, keeping) {
//       if (dates[0]) {
//         estados.value.birthdate = ''
//         usuariosRegistro.value.birthdate = dates[0]
//         HTMLInputElement.value = dates[0];
//         //console.log(calendar.value)
//         // if you want to hide the calendar after picking a date
//         //console.log(calendar.value.HTMLInputElement)
//         calendar.value.HTMLElement.classList.add('vanilla-calendar_hidden');
//         //console.log(calendar.value.HTMLElement, 'es lo que desaparece')
//       } else {
//         HTMLInputElement.value = '';
//       }
//     },
//   },
//   settings: {
//     lang: 'define',
//   },
//   locale: {
//     months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
//     weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
//   }
// })

// const loadCalendar = onMounted(() => {
//     calendar.value = new VanillaCalendar('#calendar-input-6', options.value)
//     calendar.value.init()
//     console.log(calendar)
//     //console.log(calendar.HTMLElement)
// })

const validateDay = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue >= 4 && intValue <= 9) {
            value = '0' + value;
            day.value = value;
            setTimeout(() => {
                moveToNext(event, 'day');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 31) {
                value = '31';
                day.value = value;
            } else if (intValue < 1) {
                value = '01';
                day.value = value;
            } else {
                day.value = value;
            }
            moveToNext(event, 'day');
        } else {
            day.value = value;
        }
    }
}

const validateMonth = (event) => {
    let value = event.target.value;
    if (!/^\d{0,2}$/.test(value)) {
        value = value.slice(0, -1);
    } else {
        let intValue = parseInt(value);
        if (value.length === 1 && intValue > 1 && intValue <= 9) {
            value = '0' + value;
            month.value = value;
            setTimeout(() => {
                moveToNext(event, 'month');
            }, 0);
        } else if (value.length === 2) {
            if (intValue > 12) {
                value = '12';
                month.value = value;
            } else if (intValue < 1) {
                value = '01';
                month.value = value;
            } else {
                month.value = value;
            }
            moveToNext(event, 'month');
        } else {
            month.value = value;
        }
    }
}

const validateYear = (event) => {
    let value = event.target.value;
    if (!/^\d{0,4}$/.test(value)) {
        value = value.slice(0, -1);
    } else if (value.length === 4) {
        let intValue = parseInt(value);
        if (intValue > currentYear) {
            value = currentYear.toString();
            year.value = value;
        } else {
            year.value = value;
        }
        moveToNext(event, 'year');
    } else {
        year.value = value;
    }
}

const moveToNext = (event, field) => {
    const input = event.target
    if (input.value.length >= input.maxLength) {
        switch (field) {
        case 'day':
            input.nextElementSibling.focus()
            break
        case 'month':
            input.nextElementSibling.focus()
            break
        case 'year':
            break
        }
    }
}

const moveToPrevious = (event, field) => {
    const input = event.target
    if (input.value.length === 0 && event.key === 'Backspace') {
        switch (field) {
        case 'day':
            break
        case 'month':
            input.previousElementSibling.focus()
            break
        case 'year':
            input.previousElementSibling.focus()
            break
        }
    }
}

watch([day, month, year], () => {
    if (isValidDate.value) {
        usuariosRegistro.value.birthdate = `${year.value.padStart(4, '0')}-${month.value.padStart(2, '0')}-${day.value.padStart(2, '0')}`
    } else {
        usuariosRegistro.value.birthdate = ''
    }
})

// watch(locale, () => {
//     //console.log(locale.value)
//     const options = ref({
//         input: true,
//         actions: {
//             changeToInput(e, HTMLInputElement, dates, time, hours, minutes, keeping) {
//                 if (dates[0]) {
//                     estados.value.birthdate = ''
//                     usuariosRegistro.value.birthdate = dates[0]
//                     HTMLInputElement.value = dates[0]
//                     console.log(calendar.value)
//                     // if you want to hide the calendar after picking a date
//                     console.log(calendar.value.HTMLInputElement)
//                     calendar.value.HTMLElement.classList.add('vanilla-calendar_hidden')
//                     console.log(calendar.value.HTMLElement, 'es lo que desaparece')
//                 } else {
//                     HTMLInputElement.value = ''
//                 }
//             },
//         },
//         settings: {
//             lang: 'define',
//         },
//         locale: {
//             months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
//             weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
//         }
//     })
//     loadCalendar()
// })
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
    z-index: 5;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 35px;
    outline: 0;
    position: relative;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
        font-size: 15px;
        font-weight: 400;
    }
}
input[type="date"]::-webkit-calendar-picker-indicator{
    background: url('../../img/panel-icons/calendar.svg');
    background-size: 100% 100%;
    width: 25px;
    position: absolute;
    left: 5px;
}
.position{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .calendar{
        width: 25px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../img/panel-icons/calendar.svg') ;
        background-size: 100% 100%;
        position: absolute;
        left: 9px;
    }
    input{
        width: 30%;
        padding-left: 15px;
        justify-content: center;
        align-items: center;
    }
}
</style>