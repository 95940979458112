<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <RolInicioRegistro class="d-none d-lg-flex"/>
                <TituloYSubtituloRegistro :titulo="$t('message.titleRegister')" />
                <FormRegistroTelefono/>
                <div class="row justify-content-center row__width mt-2">
                    <ORegistrarseCon/>
                </div>
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <BTNFacebook :textoBTNFacebook="$t('message.loginFacebook')"/>
                        <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')"/>
                    </div>
                    <IniciarEnlace :cuenta="$t('message.iHaveAccount')" :enlace="$t('message.login')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import ORegistrarseCon from '../../components/Titulo/ORegistrarseCon.vue';
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import IniciarEnlace from '../../components/Enlaces/IniciarEnlace.vue';
import RolInicioRegistro from '../../components/Roles/RolInicioRegistro.vue';
import FormRegistroTelefono from "../../components/Forms/FormRegistroTelefono.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";


const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>

</style>