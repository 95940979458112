<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start cont_input">
        <h5>{{$t('message.name')}}</h5>
        <input type="text">
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
h5{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorSecondary);
    padding: 10px 0px;
    text-align: left;
}
.cont_input{
    margin-bottom: 10px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>