<template>
    <div class="col-12 d-flex justify-content-center align-items-center">
        <h5>{{$t('message.registerOthers')}}</h5>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
h5{
    color: #B8C1DB;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0px;
}
</style>