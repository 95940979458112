<template>
    <div class="row separador reserva_div">
        <div class="col-12 d-flex justify-content-start align-items-center mb-lg-3">
            <h2>{{ $t('message.chooseReservation') }}</h2>
        </div>
        <div class="col-12 col-lg-7 d-flex justify-content-center align-items-center" v-for="(reserva, id) in reservas">
            <input type="radio" :id="`index`+id" :value="reservas[id].titulo" name="opciones" v-model.trim="information.booking">
            <label :for="`index`+id">
                <div class="span"></div>
                <div class="padding row justify-content-between">
                    <p>{{reservas[id].titulo}}</p>
                </div>
                <button type="button" class="btn btn-secondary" :class="(reservas[id].info == false ? 'd-none' : '')" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                    <img src="../../img/home-icons/info-icon.svg" alt="">
                    <h3>100 usd</h3>
                </button>
            </label>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const reservas = ref([
    {titulo: 'Reunión comercial', info: false},
    {titulo: 'Entrevista de acceso programas eductivos', info: false},
    {titulo: 'Asesoria personalizada (One & One)', info: true},
])
const information = inject('information')
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 20px;
    color: var(--colorSecondary);
}
.reserva_div{
    input{
        display: none;
    }
    input + label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0px 5px 7px;
        border-radius: 25px;
        margin: 10px 0px;
        border: 2px solid #F5F5F5;
        height: 47px;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 23px;
            min-height: 23px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 11px;
                height: 11px;
                display: none;
                justify-content: center;
                align-items: center;
            }
        }
        p{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
            max-width: 100%;
        }
        button{
            position: absolute;
            right: 15px;
            background-color: transparent;
            border: 0;
            width: 20px;
            padding: 0;
            h3{
                display: none;
            }
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: flex;
        }
    }
    input:checked + label{
        border: 2px solid var(--colorPrimary);
        box-shadow: 0px 3px 6px #00000029;
        p{
            color: #374557;
            font-weight: 700;
        }
    }
    .padding{
        padding-left: 1%;
        width: 90%;
    }
    @media (min-width: 992px) {
        input + label{
            p{
                font-size: 16px;
                text-align: left;
            }
            img{
                display: none;
            }
            button{
                border: 2px solid var(--colorPrimary);
                border-radius: 30px;
                background-color: var(--colorPrimary);
                width: 90px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 5px;
                h3{
                    color: #FAFAFA;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                }
            }
        }
    }
}
</style>