<template>
    <div class="row justify-content-center error">
        <CondicionesErrores v-for="(condicion, index) in condiciones" :key="index" :texto="condiciones[index].texto"/>
    </div>
</template>

<script setup>
    import CondicionesErrores from "./CondicionesErrores.vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {condiciones} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>

</style>