<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start ps-lg-0">
        <NavbarCertificados :seccion="$t('message.uploadProducts')"/>
        <!-- <NavLateralPanel :upload="true" v-if="idUser.team == 3 || idUser.team == 4"/>
        <NavLateralPanelAdmin :upload="true" v-else-if="idUser.team == 1"/> -->
        <div class="col-lg d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial">
            <div class="row row__width mb-4">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <button @click="setModalidad">Modalidad</button>
                    <button @click="setInformacion">Informacion</button>
                    <button @click="setCrearPrecio">Crear precio</button>
                    <button @click="setModulos">Modulos</button>
                    <button @click="setEditarModulos">Editar módulos</button>
                    <button @click="setCertificados">Certificados</button>
                    <button @click="setCuestionario">Cuestionario</button>
                </div>
            </div>
            <!--- BARRA DE PROCESO --->
            <div class="row justify-content-center justify-content-lg-start row__width proceso" v-if="proceso">
                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center row__width">
                        <div class="col d-flex justify-content-center align-items-center px-0 borde-right opacity">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.product') }}</h3>
                                    <h4>{{ $t('message.courses') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-center align-items-center px-0 borde-right active">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span>
                                        2
                                    </span>
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.modality') }}</h3>
                                    <h4>{{ $t('message.chooseModalityV2') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-center align-items-center px-0 borde-right">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span>
                                        3
                                    </span>
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.information') }}</h3>
                                    <h4>{{ $t('message.defineProduct') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-center align-items-center px-0 borde-right">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span>
                                        4
                                    </span>
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.content') }}</h3>
                                    <h4>{{ $t('message.addModules') }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--- MODALIDAD --->
            <form class="row justify-content-center row__width contenedor" v-if="modalidad">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloModaliad/>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5">
                    <ModalidadPreset/>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
                    <div class="row justify-content-between row__width">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width">
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <Anterior/>
                                </div>
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <Cancelar/>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-end row__width">
                                <div class="col-6 d-flex justify-content-center align-items-center">
                                    <GuardarInformacion/>
                                </div>
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <Siguiente/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor" v-if="informacion">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloModaliad/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-start justify-content-lg-center align-items-center">
                                <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="multimedia-tab" data-bs-toggle="tab" data-bs-target="#multimedia" type="button" role="tab" aria-controls="multimedia" aria-selected="false">{{ $t('message.multimedia') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="precio-tab" data-bs-toggle="tab" data-bs-target="#precio" type="button" role="tab" aria-controls="precio" aria-selected="false">{{ $t('message.precifification') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="preguntas-tab" data-bs-toggle="tab" data-bs-target="#preguntas" type="button" role="tab" aria-controls="preguntas" aria-selected="false">{{ $t('message.frequentQuestions') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="afiliados-tab" data-bs-toggle="tab" data-bs-target="#afiliados" type="button" role="tab" aria-controls="afiliados" aria-selected="false">{{ $t('message.myAffiliates') }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                <!--- INFORMACIÓN DEL PRODUCTO --->
                                <div class="col-12 tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <div class="col-12 d-flex justify-content-center align-items-start div-gris">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-5 d-flex flex-column justify-content-center align-items-start">
                                                    <div class="row justify-content-center row__width">
                                                        <h3>Seleccionar idioma del producto</h3>
                                                    </div>
                                                    <div class="row justify-content-center row__width">
                                                        <select name="" id="">
                                                            <option value="">Español</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-3 d-flex flex-column justify-content-center align-items-start agregar-idioma">
                                                    <div class="row justify-content-center row__width">
                                                        <h3>Idiomas agregados</h3>
                                                    </div>
                                                    <div class="row justify-content-start row__width">
                                                        <div class="col d-flex justify-content-center align-items-center position-relative">
                                                            <button><img src="../../../img/subir-productos-icons/close-azul.svg" alt=""></button>
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/bandera.svg" alt="">
                                                        </div>
                                                        <div class="col d-flex justify-content-center align-items-center position-relative">
                                                            <button><img src="../../../img/subir-productos-icons/close-azul.svg" alt=""></button>
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/spanish.svg" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-flex justify-content-end align-items-center">
                                                    <button type="button" class="agregar-cursos-btn">
                                                        <span>
                                                            <img src="../../../img/panel-icons/mas.svg" alt="">
                                                        </span>
                                                        Agregar en otro idioma
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- INFORMACIÓN DEL PRODUCTO --->
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                                            <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                                                <div class="accordion-item row row__width justify-content-center">
                                                    <h2 class="accordion-header px-0" id="heading">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                                            <span>
                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/carpeta.svg" alt="">
                                                                </span>
                                                            <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                                                <h3>{{ $t('message.productInformation') }}</h3>
                                                                <h4>{{ $t('message.subtitleProductInformation') }}</h4>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="collapse" class="col-12 accordion-collapse collapse" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body row justify-content-center">
                                                            <FormInformacion/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item row row__width justify-content-center">
                                                    <h2 class="accordion-header px-0" id="heading2">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse2" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                            <span>
                                                                    <img src="../../../img/subir-productos-icons/cursos-icons/beneficios.svg" alt="">
                                                                </span>
                                                            <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                                                <h3>{{ $t('message.productBenefits') }}</h3>
                                                                <h4>{{ $t('message.subtitleProductBenefits') }}</h4>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="collapse2" class="col-12 accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body row justify-content-center">
                                                            <FormInformacion/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- MULTIMEDIA --->
                                <div class="col-12 tab-pane fade px-0" id="multimedia" role="tabpanel" aria-labelledby="multimedia-tab">
                                    <div class="row justify-content-center">
                                        <div class="col-12 d-flex justify-content-center align-items-start px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                    <div class="row justify-content-center justify-content-lg-between row__width">
                                                        <FormMultimedia/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- PRECIOS --->
                                <div class="col-12 tab-pane fade" id="precio" role="tabpanel" aria-labelledby="precio-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormPrecio/>
                                    </div>
                                </div>
                                <!--- PREGUNTAS --->
                                <div class="col-12 tab-pane fade" id="preguntas" role="tabpanel" aria-labelledby="preguntas-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormPreguntas/>
                                    </div>
                                </div>
                                <!--- AFILIADOS --->
                                <div class="col-12 tab-pane fade" id="afiliados" role="tabpanel" aria-labelledby="afiliados-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormAfiliados/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
                    <div class="row justify-content-between row__width">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width">
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <Anterior/>
                                </div>
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <Cancelar/>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-end row__width">
                                <div class="col-6 d-flex justify-content-center align-items-center">
                                    <GuardarInformacion/>
                                </div>
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <Siguiente/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--- CREAR PRECIO --->
            <form class="row justify-content-center row__width contenedor" v-if="crearPrecio">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloAgregarPrecio/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <!--- ELEGIR IDIOMA --->
                               <FormAgregarPrecio/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
             <!--- MÓDULOS --->
            <form class="row justify-content-center row__width contenedor" v-if="modulos">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloTarea/>
                        </div>
                    </div>
                    <!--- CON MÓDULOS --->
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <!------>
                               <FormModulos/>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center settings">
                            <div class="row justify-content-start row__width">
                                <div class="col-4 d-flex justify-content-start align-items-center px-0">
                                    <button type="button " class="agregar-cursos-btn-3">
                                        Crear nuevo módulo
                                        <span>
                                            <img src="../../../img/panel-icons/mas.svg" alt="">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 d-flex justify-content-center align-items-center mt-3">
                                    <div class="row justify-content-start row__width">
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                            <h2>{{ $t('message.settingCount') }}</h2>
                                        </div>
                                        <div class="col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <p class="sin-padding">Activar foro para este curso</p>
                                            </div>
                                            <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                <input class="form-check-input" type="checkbox" role="switch" id="default" v-model="information.forum">
                                                <label class="form-check-label" for="default">
                                                    <p v-if="information.forum">SI</p>
                                                    <p v-else>NO</p>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <p class="sin-padding">Activar comentarios para este curso</p>
                                            </div>
                                            <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                <input class="form-check-input" type="checkbox" role="switch" id="perso" v-model="information.comments">
                                                <label class="form-check-label" for="perso">
                                                    <p v-if="information.comments">SI</p>
                                                    <p v-else>NO</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--- SIN MÓDULOS --->
                    <div class="row justify-content-center row__width px-4 border-top d-none">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width bg-gris">
                               <!--- ELEGIR IDIOMA --->
                               <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                                    <h3>Aun no tienes módulos creados</h3>
                                    <h5>Dale clic al botón "Crear  nuevo módulo" para agregar tu primer módulo </h5>
                                    <div class="row justify-content-center row__width">
                                        <div class="col-4 d-flex justify-content-center align-items-center">
                                            <button type="button" class="agregar-cursos-btn">
                                                <span>
                                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                                </span>
                                                Agregar en otro idioma
                                            </button>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--- EDITANDO MÓDULOS --->
            <form class="row justify-content-center row__width contenedor" v-if="editarModulos">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloTarea/>
                        </div>
                    </div>
                    <!--- MÓDULOS --->
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <FormAgregarTareas/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
                    <div class="row justify-content-between row__width">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <Cancelar/>
                                </div>
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <PrimarioV2 textoBTNPrimarioV2="$t('message.saveAndBack')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--- CERTIFICADOS --->
            <form class="row justify-content-center row__width contenedor" v-if="certificados">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center align-items-center row__width padding h-100 mb-4">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloCertificados/>
                        </div>
                    </div>
                    <!--- CERTIFICADOS --->
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <FormCertificados/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
                    <div class="row justify-content-between row__width">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <Cancelar/>
                                </div>
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <PrimarioV2 textoBTNPrimarioV2="$t('message.saveAndBack')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--- CUESTIONARIO --->
            <form class="row justify-content-center row__width contenedor" v-if="cuestionario">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mb-4">
                            <TituloModaliad/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-start align-items-center">
                                <ul class="nav nav-tabs row justify-content-start" id="myTab2" role="tablist">
                                    <li class="nav-item col-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="info2-tab" data-bs-toggle="tab" data-bs-target="#info2" type="button" role="tab" aria-controls="info2" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <li class="nav-item col-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="question-tab" data-bs-toggle="tab" data-bs-target="#question" type="button" role="tab" aria-controls="question" aria-selected="false">Preguntas</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent2">
                                <!--- INFORMACIÓN BÁSICA --->
                                <div class="col-12 tab-pane fade show active" id="info2" role="tabpanel" aria-labelledby="info2-tab">
                                    <div class="row justify-content-center">
                                        <FormCuestionario/>
                                    </div>
                                </div>
                                <!--- PREGUNTAS --->
                                <div class="col-12 tab-pane fade" id="question" role="tabpanel" aria-labelledby="question-tab">
                                    <div class="row justify-content-center">
                                        <FormCuestionarioPreguntas/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center mt-5 border-top">
                    <div class="row justify-content-between row__width">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <div class="row justify-content-start row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <Cancelar/>
                                </div>
                                <div class="col-5 d-flex justify-content-center align-items-center">
                                    <PrimarioV2 textoBTNPrimarioV2="$t('message.saveAndBack')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import atrasBTNCertificados from "../../../components/Botones/Atras.vue";
import TituloSubirProductos from "../../../components/Titulo/TituloSubirProductos.vue";
import AgregarApartadosPerfil from "../../../components/Panel/Perfil/AgregarApartadosPerfil.vue";
import TituloModaliad from "../../../components/Titulo/TituloModalidad.vue";
import Modalidad from "../../../components/Panel/Perfil/Modalidad.vue";
import GuardarInformacion from "../../../components/Botones/GuardarInformacion.vue";
import Siguiente from "../../../components/Botones/Siguiente.vue";
import Anterior from "../../../components/Botones/Anterior.vue";
import FormInformacion from "../../../components/Panel/SubirProductos/FormInformacion.vue";
import Subir from "../../../components/Inputs/MultimediaSubirCurso/Subir.vue";
import Cancelar from "../../../components/Botones/Cancelar.vue";
import FormMultimedia from "../../../components/Panel/SubirProductos/FormMultimedia.vue";
import FormPrecio from "../../../components/Panel/SubirProductos/FormPrecio.vue";
import FormPreguntas from "../../../components/Panel/SubirProductos/FormPreguntas.vue";
import FormAfiliados from "../../../components/Panel/SubirProductos/FormAfiliados.vue";
import TituloAgregarPrecio from "../../../components/Titulo/TituloAgregarPrecio.vue";
import FormAgregarPrecio from "../../../components/Panel/SubirProductos/FormAgregarPrecio.vue";
import TituloTarea from "../../../components/Titulo/TituloTarea.vue";
import FormModulos from "../../../components/Panel/SubirProductos/FormModulos.vue";
import FormAgregarTareas from "../../../components/Panel/SubirProductos/FormAgregarTareas.vue";
import Primario from "../../../components/Botones/Primario.vue";
import PrimarioV2 from "../../../components/Botones/PrimarioV2.vue";
import TituloCertificados from "../../../components/Titulo/TituloCertificados.vue";
import FormCertificados from "../../../components/Panel/SubirProductos/FormCertificados.vue";
import FormCuestionario from "../../../components/Panel/SubirProductos/FormCuestionario.vue";

// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"
import FormCuestionarioPreguntas from "../../../components/Panel/SubirProductos/FormCuestionarioPreguntas.vue";
import ModalidadPreset from "../../../components/Panel/Perfil/ModalidadPreset.vue";


// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access, idUser} = storeToRefs(users);
const {} = users;
const {validate} = users;

const algo = onBeforeMount( () => {
    access.value = [1, 4]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const information = ref({

})
const index = ref(false)
const modalidad = ref(false)
const informacion = ref(false)
const crearPrecio = ref(false)
const modulos = ref(false)
const editarModulos = ref(false)
const certificados = ref(false)
const cuestionario = ref(false)
const proceso = ref(false)

const setIndex = () =>{
    index.value = true
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = false
    proceso.value = false
}
const setModalidad = () =>{
    index.value = false
    modalidad.value = true
    informacion.value = false
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = false
    proceso.value = true
}
const setInformacion = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = true
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = false
    proceso.value = true
}
const setCrearPrecio = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = true
    modulos.value = false
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = false
    proceso.value = true
}
const setModulos = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = false
    modulos.value = true
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = false
    proceso.value = true
}
const setEditarModulos = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = true
    certificados.value = false
    cuestionario.value = false
    proceso.value = true
}
const setCertificados = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = false
    certificados.value = true
    cuestionario.value = false
    proceso.value = true
}
const setCuestionario = () =>{
    index.value = false
    modalidad.value = false
    informacion.value = false
    crearPrecio.value = false
    modulos.value = false
    editarModulos.value = false
    certificados.value = false
    cuestionario.value = true
    proceso.value = true
}
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}

.classSpecial{
   @media (min-width: 992px) {
        width: 70vw !important;
   }
}
.proceso{
    padding: 20px 0;
    border-bottom: 2px solid #E4E4E4;
    margin-bottom:30px;
    h3{
        font-weight: 600;
        color: #B8C1DB;
        font-size: 18px;
        text-align: left;
    }
    h4{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 16px;
        text-align: left;
    }
    .borde-right{
        border-right: 2px solid #E4E4E4;
    }
    .opacity{
        h3, h4{
            opacity: 0.5;
        }
    }
    span{
        background-color: #B8C1DB;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active{
        span{
            background-color: var(--colorPrimary);
        }
        h3, h4{
            color: var(--colorSecondary);
        }
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.tablas{  
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid #70707023;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 10px;
    ul{
        background-color: transparent;
        padding: 8px 0;
    }
    .nav-item{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        h3{
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
        }
        @media (min-width: 992px) {
            color: #0E1133;
            h3{
                color: #0E1133;
            }
        }
    }
    .nav-link.active{
        font-weight: 700;
        border-color: transparent;
        color: var(--colorPrimary);
        background-color: transparent;
        border-radius: 0;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            left: 0;
            background-color: var(--colorPrimary);
            border-radius: 10px;
        }
    }
    .scroll{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        ul{
            min-width: 150vw;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
    
}
.contenido{
    .div-gris{
        background-color: #F5F5F5;
        height: auto;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #0000001f;
    }
    h3{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px !important;
    }
    
    input[type=text], select{
        background-color: #FCFCFC;
        font-weight: 400;
        color: #697891;
        font-size: 16px;
        border-radius: 10px;
        border: 2px solid #E9E3E3;
        height: 50px;
        -webkit-appearance: none;
    }
    .agregar-idioma{
        .col{
            max-width: 40px;
            margin-right: 15px;
        }
        button{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -7px;
            top: -5px;
        }
    }
    

    .collapses{
        h3{
            margin-bottom: 5px !important;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8BCC8;
            text-align: left;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 15px 0px;
                border: 0;
                border-radius: 10px;
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 5%;
                    background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                    background-size: 100%;
                    background-position: center;
                }
            }
        }
        @media (min-width: 992px) {
            margin: 20px 0 60px 0;
        }
    }
    .bg-gris{
        background-color: #F5F5F5;
        padding: 30px 20px 20px 20px;
        border-radius: 10px;
        h4{
            font-weight: 600;
            color: #343232;
            font-size: 20px;
            text-align: center;
            margin-bottom: 10px !important;
        }
        h5{
            font-weight: 400;
            color: #BEBDBD;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px !important;
            max-width: 54%;
            line-height: 27px;
        }
    }
}
.agregar-cursos-btn, .agregar-cursos-btn-3{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.agregar-cursos-btn-3{
    display: flex;
    justify-content: space-between;
    span{
        margin: 0;
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            color: #2E4765;
            font-size: 14px;
            font-weight: 300;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        max-width: 55%;
    }
}
</style>