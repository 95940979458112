<template>
   <div class="row justify-content-center justify-content-lg-around justify-content-xl-start row__width">
        <router-link :to="`/subir-curso/${tipe.id}`" class="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-center px-0 pe-md-3" v-for="(tipe, id) in listTypes">
            <div class="row justify-content-center row__width agregar_cursos">
                <div class="col pe-0 d-flex justify-content-center align-items-center">
                    <span>
                        <img :src="tipe.icon" alt="" class="camara">
                    </span>
                </div>
                <div class="col-6 d-flex flex-column justify-content-center align-items-start pe-0">
                    <h4>{{ tipe.name }}</h4>
                    <h5>{{ tipe.description }}</h5>
                </div>
                <div class="col d-flex justify-content-center align-items-center ps-0">
                    <button class="mas">
                        <img src="../../../img/panel-icons/mas.svg" alt="">
                    </button>
                </div>
            </div>
        </router-link>
   </div>
</template>

<script setup>
import { inject } from 'vue';
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import axios from 'axios';

const users = useUsersStore();
const { access } = storeToRefs(users);
const { url, validate } = users;

const listTypes = inject('listTypes')

</script>

<style lang="scss" scoped>
.agregar_cursos{
    box-shadow: 2px 2px 10px #00000026;
    border-radius: 43px;
    height: 85px;
    margin: 20px 0;
    h4{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
    }
    h5{
        font-weight: 500;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    button{
        border-radius: 50%;
        background-color: transparent;
        border: 2px solid #E4E4E4;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
        width: 65px;
        outline: 0;
    }
    .mas{
        border: 2px solid #E4E4E4;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        .camara{
            width: 55px;
            height: 55px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    @media (max-width: 991px) {
        margin: 10px 0;
    }
}
</style>