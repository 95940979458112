
<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="row d-flex flex-column justify-content-center align-items-center">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center max__width__title">
                    <TituloYSubtituloRegistro :titulo="$t('message.titleRecover')" :subtitulo="$t('message.subtitleRecover')" desaparecerSubtitulo="false"/>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center max__width__mail">
                    <form class="w-100" action="">
                        <MailReestablecer/>
                        <Primario :textoBTNPrimario="$t('message.send')" @click="fetchMail()" class="mt-5"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import MailReestablecer from "../../components/Inputs/Reestablecer/MailReestablecer.vue";
import { useUsersStore } from "../../stores/user"    
import { storeToRefs } from "pinia";
import { provide, ref, computed, onUnmounted, onMounted } from "vue";
import axios from "axios"
import Primario from '../../components/Botones/Primario.vue';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import Navbar from "../../components/Navs/Navbar.vue";

const { t }  = useI18n()
const msg = computed(() => t('message'))

const router = useRouter();

const user = useUsersStore();
const {headers, infoMail} = storeToRefs(user);
const {validarMail} = user;

const validez = ref({
    validezMail : '',
})
provide("validez", validez)

// Cambio de navbar

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

//-------------//
const validateMail = () => {
    if (infoMail.value.email !== '' && validarMail.test(infoMail.value.email)){
        validez.value.validezMail = 'input_valido'
    }
    else {
        validez.value.validezMail = 'input_invalido'
    }
}
//-------------//
const url = process.env.VUE_APP_BASE_URL
const urlReset = '/api/auth/reset/mail'

//Hago un post del usuario 
async function fetchMail() {
    validateMail()
    console.log(infoMail.value.email, validarMail.test(infoMail.value.email))
    if( validez.value.validezMail == 'input_valido'){
        try {
            const response = await axios.post(url+urlReset, infoMail.value)
            if (response.data.status == 'Success') {
                Swal.fire({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: 'OK'
                })
            }
        }
        catch (error) {
            console.log(error)
            Swal.fire({
                text: error.response.data.message,
                icon: "error",
                confirmButtonText: 'OK'
            })
        }
    }else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
        })
        Toast.fire({
            icon: 'error',
            title: t('message.error')
        })
    }
}
</script>

<style lang="scss" scoped>

.max__width__title{
    max-width: 520px;
}
.max__width__mail{
    max-width: 420px;
}
.max__width__button{
    max-width: 420px;
}

</style>