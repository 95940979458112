<template>
    <div class="row justify-content-center justify-content-lg-start row__width noCertificados">
        <!--- D-NONE --->
        <div class="col-12 d-flex justify-content-start align-items-center mb-3 d-none" v-for="(input, id) in inputs">
            <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                <input class="form-check-input" type="checkbox" role="switch" :id="id" :checked="inputs[id].disable">
                <label class="form-check-label" :for="id">{{ inputs[id].titulo }}</label>
            </div>
        </div>
        <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-4">
            <h6>{{ $t('message.accountLanguage') }}</h6>
            <select class="form-select" aria-label="Default select example" disabled v-model="abbr" @change="setLang(abbr)">
                <option v-for="(language, id) in listLangs" :key="id" :value="language.abbr">{{ language.name }}</option>
            </select>   
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia"
import { useLanguageStore } from "../../../stores/langs"

const langs = useLanguageStore()
const {listLangs} = storeToRefs(langs)
const {setLang} = langs

const abbr = ref(JSON.parse(localStorage.getItem('lang')))

const inputs = ref([
    {titulo: 'Recibir email con novedades de curso en Masterclass Photographers', disable: true},
    {titulo: 'Notificarme cuando ', disable: true},
    {titulo: 'Notificarme cuando ', disable: false},
])
</script>

<style lang="scss" scoped>
.noCertificados{
    padding-top: 20px;
    label{
        font-size: 16px;
        color: var(--colorSecondary);
        font-weight: 400;
        padding-left: 15px;
        text-align: left;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .form-switch .form-check-input{
        min-width: 48px;
        min-height: 23px;
        background-color: #E4E4E4;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
        border: 0;
        background-position-x: 2px;
    }
    .form-switch .form-check-input:focus{
        outline: 0;
        box-shadow: none;
        border: 0;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
    }
    .form-switch .form-check-input:checked{
        background-color: var(--colorPrimary);
        background-position-x: 27px;
    }
    h6{
        font-size: 16px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 15px;
    }
    .form-select{
        border-color: #E4E4E4;
        color: #697891;
        font-weight: 400;
        font-size: 15px;
        height: 50px;
        background-image: url('../../../img/panel-icons/arrow-gris.svg');
        background-size: 50%;
        background-size: 4% 17%;
        &:focus{
            border-color: none;
            box-shadow: none;
            outline: 0;
        }
        @media (min-width: 992px) {
            max-width: 40%;
        }
    }
}
</style>