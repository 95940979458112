<template>
    <div class="col-12 d-flex justify-content-center align-items-center input__file input-m-empty">
        <input type="file" name="" id="file" accept="image/*" ref="tempFile" @input="uploadImage">
        <label :class="{ 'dragging': isDragging }" for="file" ref="dropArea" @dragover.prevent="dragOver" @dragenter.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile">
            <h4>{{ $t('message.dragFiles') }}</h4>
            <span v-if="isDragging">{{ $t('message.uploadFile') }}</span>
            <span v-else><img src="../../../img/panel-icons/perfil-mas.svg" alt="">{{ $t('message.selectFile') }}</span>
            <h5>{{ $t('message.maximumSize') }}</h5>
            <img class="image" v-bind:src="multimedia.image + (multimedia.image.includes('?') ? '&' : '?') + 't=' + new Date().getTime()" alt="" v-if="multimedia.image">
        </label>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import axios from 'axios'
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const urlUploadImage = '/api/product/creating/add/multimedia/file'

let multimedia = inject('multimedia')
let product = inject('product')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

let tempFile = ref(null)
let dropArea = ref(null)
let isDragging = ref(false)

const uploadImage = async() => {
    const formData = new FormData()
    formData.append('image', tempFile.value.files[0])
    formData.append('id', product.value.id)
    formData.append('owner_id', owner_id.value)
    formData.append('product_id', product_id.value)
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlUploadImage, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            multimedia.value.image = ''
            setTimeout(() => {
                multimedia.value.image = result.data
            }, 1)
            tempFile.value.value = null
        })
    } catch (error) {
        console.error(error)
    }
}

const dragOver = (event) => {
    event.preventDefault()
};

const dragEnter = (event) => {
    event.preventDefault()
    isDragging.value = true
};

const dragLeave = (event) => {
    event.preventDefault()
    if(event.currentTarget.contains(event.relatedTarget)){
        return
    }
    isDragging.value = false
};

const dropFile = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        tempFile.value.files = files;
        uploadImage()
        isDragging.value = false
    }
}
</script>

<style lang="scss" scoped>
.input__file{
    label{
        border: 2px dashed #E4E4E4;
        border-radius: 15px;    
        background-color: #FAFAFA;
        min-height: 20vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover, &:focus{
            border-color: var(--colorPrimary);
        }
    }
    input{
        display: none;
    }
    h4{
        font-weight: 500;
        color: #697891;
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
    }
    h5{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
    }
    span{
        cursor: pointer;
        border: 2px dashed var(--colorPrimary);
        height: 50px;
        width: 80%;
        color: var(--colorPrimary);
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        img{
            margin-right: 10px;
        }
    }
    @media (max-width: 991px) {
        span{
            font-size: 14px;
            img{
                max-height: 15px;
            }
        }
    }
}
.image {
    width: 400px;
    height: 250px;
    max-width: 80%;
    margin-bottom: 10px;
    object-fit: cover;
}
label.dragging {
    border-color: var(--colorPrimary)
}
</style>