<template>
    <div class="offcanvas offcanvas-carrito row justify-content-start align-items-start hide offcanvas-end" tabindex="-1" id="offcanvasCarrito" aria-labelledby="offcanvasCarrito" data-bs-scroll="true" >
        <div v-if="cart">
            <!--- CARRITO --->
            <div v-if="cart.length > 0">
                <div class="offcanvas-header modal-carrito col-12" >
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h4>{{$t('message.cart')}}</h4>
                            <p v-if="cart.length == 1">{{cart.length}} producto en la cesta</p>
                            <p v-else>{{cart.length}} productos en la cesta</p>
                        </div>
                    </div>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <span class="borde"></span>
                <div class="offcanvas-body modal-carrito col-12 d-flex justify-content-center align-items-start">
                    <div class="row h-100" v-if="cart">
                        <ListadoItemsCarrito/>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <Detalles/>
                        </div>
                        <router-link to="/carrito/2"><GrisCarrito textoBtn="Ir a checkout" class="mt-4 mb-2"/></router-link>
                        <router-link to="/carrito/4"><PrimarioV2 textoBTNPrimarioV2="Pagar ahora" class="btn"/></router-link>
                    </div>
                </div>
            </div>
            <!--- SIN CARRITO --->
            <div v-else>
                <div class="offcanvas-header mb-3 col-12" >
                    <h5 class="offcanvas-title" id="offcanvasLabel4">{{$t('message.cart')}}</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body col-12">
                    <div class="row h-100">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center div">
                            <img src="../../img/carrito-icons/vacio.svg" alt="">
                            <h2>Carrito vacío</h2>
                            <h3>Busca lo que necesites en nuestro Marketplace y aprende con los mejores cursos.</h3>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center mt-3">
                            <Primario textoBTNPrimario="Ir al marketplace"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import GrisCarrito from '../Botones/GrisCarrito.vue';
    import Primario from '../Botones/Primario.vue';
    import PrimarioV2 from '../Botones/PrimarioV2.vue';
    import ListadoItemsCarrito from "../Carrito/ListadoItems.vue";
    import Detalles from "./Detalles.vue";

    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"
    import { useConfigStore } from "../../stores/config"
    import { inject, watch, onMounted, ref, provide } from 'vue';

    const carts = useCartStore();
    const { cart, arrayCheckout, exchangeUpdate } = storeToRefs(carts);
    const { managePricesCart } = carts

    const config = useConfigStore();
    const { coinSelected } = storeToRefs(config);

    /* let cart = ref([])
    provide('cart', cart) */

    watch( cart, async () => {
        managePricesCart()
        console.log('CARRITO CAMBIO DE VALOR')
    }, {deep: true})

    onMounted(() => {
        if (localStorage.getItem('Cart')){
            cart.value = JSON.parse(localStorage.getItem('Cart'))
            managePricesCart()
        }
    })

</script>

<style lang="scss" scoped>
nav{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        p{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }
        @media (min-width: 992px) {
            font-size: 30px;
            p{
                font-size: 30px;
            }
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
        }
    }
    .paises{
        border-radius: 20px;
        background-color: var(--bgPrincipal);
        right: 10%;
        padding: 10px 20px;
        width: 250px;
        li{
            width: 100%;
            padding: 10px 0px 10px 0px;
            img{
                margin-left: 10px;
            }
            .nav-link{
                &:focus, &:hover{
                    color: var(--enlacesNav);
                    &::before{
                        display: none;
                    }
                }
            }
        }
        .borde{
            border-top: 1px solid #c6bacc7c;
            padding: 15px 0px;
            img{
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        li{
            a{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
            
        }
        .nav-link{
            &:focus, &:hover{
                color: var(--colorPrimary);
                font-weight: 700;
                &::before{
                    display: none;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    @media (min-width: 992px) {
        height: 95px;
    }
    

    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    .buscador{
        border: 2px solid #37405B;
        border-radius: 23px;
        min-height: 45px;
        min-width: 70%;
        padding: 0 7px 0 15px;
        margin-right: 5px;
        transition: all ease 3s;
        input{
            border: 0;
            outline: 0;
            font-weight: 400;
            color: #B8C1DB;
            font-size: 16px;
        }
    }
    
}
.navbar-expand-lg .navbar-toggler {
    @media (min-width: 992px){
        display: flex !important;
    }
}

.offcanvas{
    padding: 10px 10px;
    max-width: 95vw !important;
    border-radius: 0px 0px 15px 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 100vh;
    flex-direction: row;
    .offcanvas-header{
        align-items: flex-start;
        padding: 0;
    }
    .btn-close{
        background-image: url('../../img/menu-icons/cerrar.svg');
        &:focus{
            outline: 0;
            border: 0;
            box-shadow: none;
        }
    }
    .offcanvas-body{
        //padding: 10px 25px;
    }
    @media (min-width: 992px) {
        background-color: var(--bgPrincipal) !important;
        box-shadow: 0px 3px 6px #00000029;
        top: 95px;
    }
}


.offcanvas-carrito{
    padding: 20px;
    padding-bottom: 0;
    .offcanvas-title{
        font-size: 20px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    .div{
        min-height: 80%;
        padding: 20px;
        border-radius: 10px;
        background-color: #FCFCFC;
        h2{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        img{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        h3{
            font-size: 16px;
            font-weight: 400;
            color: #697891;
            text-align: center;
            padding: 10px 0px;
        }
    }
    .offcanvas .btn-close{
        background-image: url('../../img/carrito-icons/cerrar-modal.svg');
        opacity: 1;
    }
    .borde{
        border-bottom: 1px solid rgba(184, 193, 219, 0.5);
        height: 1px;
    }
    .modal-carrito{
        .offcanvas-header{
            margin-bottom: 10px !important;
        }
        h4{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: #697891;
            padding: 10px 0px;
            padding-bottom: 0;
        }
    }
}
</style>