<template>
    <button class="btn__primario" :class="sinBack == true ? 'btn__primario__sinBackground' : '' " type="button">
        {{textoBTNPrimario}}
    </button>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter()

    const props = defineProps({
        textoBTNPrimario : String,
        sinBack : {
            type: Boolean,
            default: false
        },
        slug: String,
        url: ''
    })
</script>

<style lang="scss" scoped>

</style>