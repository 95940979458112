<template>
    <div class="row separador">
        <div class="col-12 d-flex justify-content-start align-items-center">
            <h2>{{ $t('message.complete') }}</h2>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <div class="row justify-content-center row__width ">
                <div class="col-12 contenedor__input">
                    <img src="../../img/inicio-icons/mail.svg" alt="">
                    <input type="text" placeholder="Email" v-model="information.email">
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <SelectTelefonoAsesor/>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import SelectTelefonoAsesor from "./SelectTelefonoAsesor.vue";
const information = inject('information')
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 20px;
    color: var(--colorSecondary);
}
.separador{
    padding: 30px 0px 10px 0px;
}

.contenedor__input{
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    img{
        padding: 0 10px;
    }
    input{
        border: 0;
        border-left: 1px solid #cacaca;
        height: 80%;
        width: 90%;
        margin-left: 10px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #697891;
        &:focus{
            outline: 0;
        }
    }
}
</style>