<template>
    <div class="row justify-content-center justify-content-lg-start  separador">
        <div class="col-12 d-flex justify-content-start align-items-center mb-lg-4">
            <h2>{{ $t('message.dateAndTime') }}</h2>
        </div>
        <div class="col-lg-6">
            <div class="vanilla-calendar" id="calendar"></div>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center justify-content-lg-start align-items-center">
            <div class="row justify-content-center  separador">
                <div class="col-12 d-flex justify-content-start align-items-center">
                    <h2>{{ $t('message.availableHours') }}</h2>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center px-0 pt-3">
                    <div class="row scroll row__width">
                        <div class="col-12 d-flex justify-content-center align-items-center" v-for="(horario, id) in horarioDisponible">
                            <input type="radio" :id="`algo`+id" :value="horario.titulo" name="horarios-dispo" v-model.trim="information.time">
                            <label :for="`algo`+id">
                                <div class="span"></div>
                                <div class="padding row justify-content-between">
                                    <p>{{horario.titulo}}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <Primario :textoBTNPrimario="$t('message.continueV2')" class="px-0 d-lg-none"/>
            </div>
        </div>
        <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center my-4">
            <Primario :textoBTNPrimario="$t('message.continueV2')" class="px-0"/>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, watch, inject } from 'vue'
import Primario from '../Botones/Primario.vue';
import VCalendar from 'v-calendar';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';
import 'v-calendar/style.css';

import { useI18n } from 'vue-i18n'

const { t }  = useI18n()
const msg = computed(() => t('message'))

const date = ref(new Date())

const horarioDisponible = ref([
    {titulo: '08.00 AM'},
    {titulo: '09.00 AM / 10 AM'},
    {titulo: '10.00 AM'},
    {titulo: '11.00 AM'},
    {titulo: '12.00 PM'},
    {titulo: '13.00 PM / 14 PM'},
    {titulo: '14.00 PM'},
    {titulo: '15.00 PM'},
])
// const dateConsole = ref(null)

let options = ref({
    actions: {
        clickDay(event, dates) {
            
            information.value.date = dates
            //console.log(information.value.date);
        },
    },
    settings: {
        lang: 'define',
    },
    locale: {
        months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
        weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
    }
})

const loadCalendar = onMounted(() => {
    const calendar = new VanillaCalendar('#calendar', options.value)
    calendar.init()
    //console.log(options.value)
})

const {locale} = useI18n({ useScope: 'global' })
watch(locale, () => {
    //console.log(locale.value)
    options = ref({
        actions: {
            clickDay(event, dates) {
                information.value.date = dates
                //console.log(information.value.date);
            },
        },
        settings: {
            lang: 'define',
        },
        locale: {
            months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
            weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
        }
    })
    loadCalendar()
})
const information = inject('information')
</script>

<script>

</script>


<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 20px;
    color: var(--colorSecondary);
}
.separador{
    padding: 30px 0px 10px 0px;
}

.contenedor__input{
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    img{
        padding: 0 10px;
    }
    input{
        border: 0;
        border-left: 1px solid #cacaca;
        height: 80%;
        width: 90%;
        margin-left: 10px;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #697891;
        &:focus{
            outline: 0;
        }
    }
}

.scroll{
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        border-radius: 200px;
        background-color: rgba(219, 219, 219, 0.25);
        width: 8px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #DBDBDB;
        border-radius: 50px;
    }
    input{
        display: none;
    }
    input + label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 0px 5px 7px;
        border-radius: 25px;
        margin: 10px 0px;
        border: 2px solid #F5F5F5;
        height: 44px;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 23px;
            min-height: 23px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 11px;
                height: 11px;
                display: none;
                justify-content: center;
                align-items: center;
            }
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: #A098AE;
            max-width: 100%;
        }
        button{
            position: absolute;
            right: 15px;
            background-color: transparent;
            border: 0;
            width: 20px;
            padding: 0;
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: flex;
        }
    }
    input:checked + label{
        border: 2px solid var(--colorPrimary);
        box-shadow: 0px 3px 6px #00000029;
    }
    .padding{
        padding-left: 3%;
    }
}

</style>