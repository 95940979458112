<template>
    <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomeCarrito')"/>
    <div class="row justify-content-center">
        <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center justify-content-lg-between row__width">
                <div class="col-12 col-lg-7 d-flex flex-column justify-content-center align-items-center px-0  position-relative">
                    <ProgresoCarrito :uno='true' class=""/>
                    <div class="row justify-content-center row__width">
                        <div class="col-11 col-lg-12 d-flex flex-column justify-content-center align-items-center px-0 mt-2">
                            <TituloCarrito :titulo="$t('message.cart')" :oculto="true"/>
                            <ResumenCestaCarrito class="d-lg-none"/>
                            <div class="row justify-content-center row__width d-none d-lg-flex" v-if="cart">
                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                    <p>{{ cart.length }} {{ $t('message.coursesCart') }}</p>
                                </div>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center items px-0">
                                    <ItemsCarritoV3 v-for="(item, index) in cart"
                                        :titulo="item.name"
                                        :price="item.price"
                                        :image="item.image"
                                        :id="index"
                                        :key="exchangeUpdate"
                                    />
                                </div>
                                
                            </div>
                            <DetallesCarritoV2 class="d-lg-none"/>
                            <BTNPrimario :textoBTNPrimario="$t('message.checkout')" class="mt-5 d-lg-none" @click="checkoutButton()"/>
                            <FooterCarrito/>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-xxl d-none d-lg-flex flex-column justify-content-start align-items-center mt-5 pb-5 max-width">
                    <div class="box-shadow sticky-container">
                        <DetallesCarritoV2 class=" d-none d-lg-flex"/>
                        <BTNPrimario :textoBTNPrimario="$t('message.checkout')" class="mt-5 d-none d-lg-flex" @click="checkoutButton()"/>
                        <div class="row justify-content-center px-3 mt-5 mb-3">
                            <CodigoDescuentoInput class="d-none"/>
                            <div class="col d-flex justify-content-center align-items-center px-0 d-none">
                                <div class="row row__width">
                                    <BTNVioleta :BTNVioletaTexto="$t('message.apply')" class="my-0"/>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 d-flex justify-content-start align-items-center">
                                <p>{{ $t('message.termsComplete') }}<br><a href="">{{ $t('message.conditionsOfUse') }}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNPrimario from "../../components/Botones/PrimarioCheckout.vue";
    import DetallesCarritoV2 from "../../components/Carrito/DetallesV2.vue";
    import ProgresoCarrito from "../../components/Carrito/Progreso.vue";
    import BTNVioleta from "../../components/Botones/Violeta.vue";
    import CodigoDescuentoInput from "../../components/Inputs/CodigoDescuento.vue";
    import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
    import FooterCarrito from "../../components/Footer/FooterCarrito.vue";
    import ContinuarComprandoEnlace from "../../components/Enlaces/ContinuarComprando.vue";
    import ResumenCestaCarrito from "../../components/Carrito/ResumenCesta.vue";
    import TituloCarrito from "../../components/Titulo/TituloCarrito.vue";
    import ItemsCarritoV3 from "../../components/Carrito/ItemsV3.vue";
    import { ref, inject, provide } from 'vue';
    import axios from 'axios';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"
    import { useUsersStore } from "../../stores/user"
    import { useRoute, useRouter } from 'vue-router';

    const route = useRoute()
    const router = useRouter()

    const carts = useCartStore();
    const { cart, exchangeUpdate } = storeToRefs(carts);
    const {  } = carts

    const users = useUsersStore();
    const { idUser } = storeToRefs(users);
    const {  } = users;

    let step = inject('step')

    const checkoutButton = () => {
        if(idUser.value){
            router.replace('/carrito/4')
            step.value = 4
        } else{
            router.replace('/carrito/3')
            step.value = 3
        }
    }
</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
.btn{
    max-width: 90%;
}
.sticky-container {
  position: sticky;
  top: 110px;
}
@media (min-width: 992px) {
    .box-shadow{
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        position: sticky;
        top: 110px;
        a{
            font-weight: 400;
            font-size: 16px;
            color: #0C5FD4;
        }
        p{
            color: #697891;
            font-weight: 400;
            font-size: 16px;
            text-align: left;
        }
    }
}
@media (min-width: 1024px){
    .max-width{
        max-width: 33%;
    }
}
@media (min-width: 1440px){
    .max-width{
        max-width: 34%;
    }
}
</style>