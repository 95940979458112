<template>
    <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0 banner mt-lg-4">
        <div class="row justify-content-center row__width h-100">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 h-100 marca">
                <img src="../../img/marca-icons/marca-ejemplo.png" alt="" class="marca__img px-0">
                <div class="redes">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 d-flex justify-content-center justify-content-lg-end align-items-center mb-lg-4">
                            <a href=""><img src="../../img/marca-icons/facebook.svg" alt=""></a>
                            <a href="" class="box"><img src="../../img/marca-icons/twitter.svg" alt=""></a>
                            <a href="" class="box"><img src="../../img/marca-icons/linkedin.svg" alt=""></a>
                            <a href="" class="box"><img src="../../img/marca-icons/pinterest.svg" alt=""></a>
                            <a href="" class="box d-lg-none"><img src="../../img/marca-icons/computadora.svg" alt=""></a>
                            <a href="" class="box d-lg-none"><img src="../../img/marca-icons/web.svg" alt=""></a>
                        </div>
                        <div class="col-12 justify-content-center align-items-center d-none d-lg-flex px-0">
                            <div class="row justify-content-end row__width">
                                <div class="col-9 d-flex justify-content-end align-items-center">
                                    <div class="gris"><a href="" class=""><img src="../../img/marca-icons/computadora.svg" alt=""> Monitores para Fotografía</a></div>
                                </div>
                                <div class="col d-flex justify-content-end align-items-center min-width">
                                    <div class="gris">
                                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h4>3.0</h4><h5>(12)</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.banner{
    position: relative;
    height: 18vh;
    .marca__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .redes{
        position: absolute;
        bottom: 10%;
        right: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
        a{
            margin-left: 7px;
            border: 1px solid #707070;
            border-radius: 50%;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    @media (min-width: 992px) {
        border-radius: 20px 20px 0 0 ;
        height: 26vh;
        .marca__img{
            border-radius: 20px 20px 0 0 ;
        }
        .marca{
            position: relative;
            &:before{
                content: '';
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 25%;
                background: transparent linear-gradient(359deg, #000000 0%, #575F750E 49%, #5B637900 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                opacity: 0.84;
                bottom: 0;
            }
        }
        .redes{
            right: 3%;
            a{
                height: 40px;
                width: 40px;
                border: 0;
            }
            .min-width{
                max-width: min-content;
            }
        }
        .gris{
            background-color: #F5F5F5;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: min-content;
            padding: 0 10px;
            min-height: 40px;
            h4{
                color: #FEB230;
                font-weight: 500;
                font-family: 'Proxima Nova';
                font-size: 17px;
                padding-top: 3px;
                padding-left: 5px;
            }
            h5{
                color: #707070;
                font-weight: 400;
                font-family: 'Proxima Nova';
                font-size: 17px;
                padding-top: 3px;
                padding-left: 5px;
            }
            img{
                height: 20px;
            }
            a{
                display: flex;
                justify-content: center;
                align-items:center ;
                border: 0;
                background-color: transparent;
                width: max-content;
                color: var(--colorSecondary);
                font-weight: 500;
                font-size: 16px;
                text-decoration: none;
                margin-left: 0;
                img{
                    max-width: max-content;
                    
                }
            }
        }
    }
    
}
</style>