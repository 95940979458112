<template>
    <swiper-container
        :slidesPerView="3"
        :navigation="{
            nextEl: '#nextSliderBeneficios',
            prevEl: '#prevSliderBeneficios'
        }"
        :pagination="true"
        :spaceBetween="20"
        :loop="false"
        :breakpoints="{
            375: {
                slidesPerView: 1,
                spaceBetween: -50
            },
            425: {
                slidesPerView: 1,
                spaceBetween: -70
            },
            500: {
                slidesPerView: 1,
                spaceBetween: -90
            },
            600: {
                slidesPerView: 1,
                spaceBetween: 110
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: -300
            },
        }"
        class="mySwiper" >
        <swiper-slide v-for="(slide, id) in slider" class="d-flex justify-content-start align-items-center">
            <BeneficiosSinProductosPanel/>
        </swiper-slide>
    </swiper-container>
</template>

<script setup>
    import BeneficiosSinProductosPanel from "../BeneficiosSinProductosPanel.vue";
    import { ref } from "vue";

    const slider = ref([
        {modalidad: 0, botonProducto: 0},
        {modalidad: 3, botonProducto: 1},
        {modalidad: 3, botonProducto: 2},
        {modalidad: 0, botonProducto: 0},
        {modalidad: 3, botonProducto: 1},
        {modalidad: 3, botonProducto: 2},
    ])
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 10px 5px;
}
</style>