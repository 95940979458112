<template>
    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0 padding">
        <h2>{{titulo}}</h2>
    </div>
    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center modulos2">
        <div class="accordion d-flex justify-content-center align-items-center" id="accordionExample">
            <div class="row__width">
                <div class="accordion-item row row__width justify-content-center" v-for="(pregunta, id) in preguntas">
                    <h2 class="accordion-header px-0" :id="`heading`+id">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+id" aria-expanded="false" :aria-controls="`collapse`+id">
                            <h3>{{preguntas[id].titulo}}</h3>
                        </button>
                        <div :id="`collapse`+id" class="accordion-collapse collapse" :aria-labelledby="`heading`+id" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <h6>
                                    {{preguntas[id].respuesta}}
                                </h6>
                            </div>
                        </div>
                    </h2>
                </div>
            </div>
        </div>
        <!-- <BTNMostrarMas :funcionTexto="mostrarTexto7" :img="iconoImg" class="d-lg-none"/> -->
        <!-- <div class="accordion d-flex justify-content-center align-items-center d-none d-lg-flex" id="accordionExample">
            <div class="row__width">
                <div class="accordion-item row row__width justify-content-center" v-for="(pregunta, id) in preguntas">
                    <h2 class="accordion-header px-0" :id="`heading`+id">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+id" aria-expanded="false" :aria-controls="`collapse`+id">
                            <h3>{{preguntas[id].titulo}}</h3>
                        </button>
                        <div :id="`collapse`+id" class="accordion-collapse collapse" :aria-labelledby="`heading`+id" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <h6>
                                    {{preguntas[id].respuesta}}
                                </h6>
                            </div>
                        </div>
                    </h2>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup>
    import BTNMostrarMas from '../Botones/MostrarMas.vue';
    import { ref } from "vue";

    const props = defineProps({
        titulo: ''
    })

    const preguntas = ref([
        {   titulo: '¿Por qué unirte a la membresía de Master Class Photographers?', 
            respuesta: 'Si eres un amante de la fotografía, un aspirante a fotógrafo profesional o alguien que busca mejorar sus habilidades en fotografía, edición, marketing digital, y negocio nuestra membresía es la puerta de entrada a un mundo de oportunidades y desarrollo personal y profesional.', 
        },
        {   titulo: '¿Qué obtengo con la membresía?', 
            respuesta: 'Con la membresía, obtienes acceso ilimitado a una amplia variedad de cursos de fotografía, video y marketing digital. También disfrutas de actualizaciones semanales de contenido, un 30% de descuento en todos los cursos y productos a partir del primer mes, y la posibilidad de desarrollar tus habilidades y conocimientos de manera continua.', 
        },
        {   titulo: ' ¿Los cursos están disponibles en línea?', 
            respuesta: 'Sí, todos nuestros cursos están disponibles en línea. Puedes acceder a ellos desde cualquier dispositivo con conexión a Internet y estudiar a tu propio ritmo.', 
        },
        {   titulo: '¿Los cursos son para profesionales?', 
            respuesta: 'Los cursos de Master Class Photographers son pensados para cualquier persona que tenga o no conocimientos básicos de fotografía. No importa si tienes mucha o poca experiencia, estamos seguros que los cursos que tenemos para ti te ayudarán a ampliar tus conocimientos y a seguirte preparando profesionalmente.', 
        },
        {   titulo: '¿Tengo acceso al año del directorio fotográfico?', 
            respuesta: 'Sí, pero tu perfil debe estar optimizado con tu portafolio para ser revisado y posteriormente aprobado para tu acceso.', 
        },
        {   titulo: '¿Puedo cancelar mi membresía en cualquier momento?', 
            respuesta: 'Sí, puedes cancelar tu membresía en cualquier momento sin compromisos a largo plazo. No hay penalizaciones por cancelar, y puedes hacerlo directamente desde tu banco en línea o contactarnos directamente para la cancelación 1 día antes de tu fecha de cobro, con un WhatsApp al 5546490297.', 
        },
    ])
    const iconoImg = ref(false)

    const mostrarTexto7 = () => {
        document.querySelector('div.desaparecer').classList.toggle('d-none')
        document.getElementById('filtro7').classList.toggle('before')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }
</script>

<style lang="scss" scoped>
.modulos2{
    .accordion{
        width: 100%;
    }
    .accordion-item{
        margin: 15px 0px;
        border: 0;
        border-radius: 10px;
    }
    .accordion-header{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        background-color: rgba(247, 249, 250, 0.57);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        
    }
    .accordion-button{
        padding-left: 15%;
        border-radius: 10px;
        &:focus{
            border-color: inherit;
            box-shadow: none;
        }
        @media (min-width: 992px) {
            padding-left: 9%;
        }
    }
    .accordion-button:not(.collapsed){
        background-color: inherit;
        box-shadow: none;
    }
    .accordion-button::after{
        position: absolute;
        left: 4%;
        top: 28%;
        background-image: url('../../img/home-icons/cursos-icons/arrow-azul-abajo.svg');
        background-size: 70%;
        background-position: center;
    }
    h3{
        padding: 5px 0;
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    .accordion-body{
        padding: 0px 20px 30px 20px;
        h5{
            padding: 5px 0px;
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
        }
        .active{
            color: var(--colorPrimary);
        }
        h6{
            color: #374557;
            font-weight: 400;
            font-size: 16px;
            padding: 0;
        }
        .img{
            max-width: 40px;
            padding: 0;
        }
        @media (min-width: 992px) {
            padding: 0 35px 20px 35px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    @media (min-width: 992px) {
        margin: 20px 0 60px 0;
    }
}
h2{
    font-size: 22px;
    font-weight: 700;
    color: var(--colorSecondary);
    text-align: left;
    position: relative;
    @media (min-width: 992px) {
        color: #374557;
        font-size: 22px;
    }
}
.padding{
    padding: 20px 0;
    @media (min-width: 992px) {
        padding: 12vh 0 5vh 0;
    }
}
</style>