<template>
    <div class="col-12 d-flex justify-content-center align-items-center">
        <button class="mail">
            <img src="../../img/inicio-icons/mail.svg" alt="">{{ $t('message.continueMail') }}
        </button>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.mail, .telefonoV2{
    border: 2px solid #707070;
    color: #707070;
    font-weight: 500;
    font-size: 16px;
    border-radius: 27px;
    height: 50px;
    background-color: transparent;
    margin-bottom: 20px;
    width: 100%;
    img{
        padding-right: 15px;
    }
}
</style>