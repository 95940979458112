<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-6 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <TituloYSubtituloRegistro :titulo="$t('message.finishRegistration')"/>
                <UsuarioTerminarRegistro/>
                <FormTerminarRegistro/>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import TituloYSubtituloRegistro from '../../components/Titulo/TituloYSubtituloRegistro.vue';
import UsuarioTerminarRegistro from "../../components/Usuario/UsuarioTerminarRegistro.vue";
import FormTerminarRegistro from "../../components/Forms/FormTerminarRegistro.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";


const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>
.max__width__login__Desk{
    @media (min-width: 992px) {
        max-width: 420px !important;
    }
}
</style>