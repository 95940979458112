<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center items px-0" v-if="cart">
        <ItemsCarrito v-for="(item, index) in cart"
                        :name="item.name"
                        :price="item.price"
                        :image="item.image"
                        :id="item.name+index"
                    />
    </div>
</template>

<script setup>
    import ItemsCarrito from "./Items.vue";
    import { inject } from 'vue';
    import { storeToRefs } from "pinia"
    import { useCartStore } from "../../stores/cart"

    const carts = useCartStore();
    const { cart, totalPrice, totalDiscount } = storeToRefs(carts);
    const {  } = carts

    console.log(cart.value)
</script>

<style lang="scss" scoped>

</style>