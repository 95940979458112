<template>
    <button class="btn__inicio" :class="props.color" type="button">
        {{props.text ? props.text : $t('message.cancel')}}
    </button>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        text: String,
        color: String
    })
</script>

<style lang="scss" scoped>
.btn__inicio{
    border: 2px solid #B8C1DB;
    color: #B8C1DB;
    background-color: var(--bgPrincipal);
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 600;
    &:hover, &:focus{
        background-color: #B8C1DB;
        color: var(--colorBTNPrimario);
        border-color: transparent;
    }
}
.red {
    color: var(--colorRed) !important;
    border-color: var(--colorRed) !important;
    &:hover, &:focus{
        background-color: var(--colorRed) !important;
        color: var(--colorBTNPrimario) !important;
    }
}
</style>