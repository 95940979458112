<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start px-0">
        <NavbarCertificados :seccion="$t('message.uploadProducts')"/>
        <!-- <NavLateralPanel :upload="true" v-if="idUser.team == 3 || idUser.team == 4"/>
        <NavLateralPanelAdmin :upload="true" v-else-if="idUser.team == 1"/> -->
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial">
            <!--- BARRA DE PROCESO --->
            <div class="row justify-content-center justify-content-start row__width proceso">
                <div class="col-12 d-flex justify-content-start justify-content-xxl-center align-items-center px-lg-0 scroll">
                    <div class="row justify-content-center  row_scroll">
                        <div class="col col-lg d-flex justify-content-center align-items-center px-0 borde-right opacity">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.product') }}</h3>
                                    <h4>{{ $t('message.courses') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 1 ? 'active' : 'opacity'">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span v-if="step == 1">
                                        2
                                    </span>
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 2">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.modality') }}</h3>
                                    <h4 class="d-none d-lg-flex">{{ $t('message.chooseModalityV2') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 2 ? 'active' : 'opacity'">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span v-if="step <= 2">
                                        3
                                    </span>
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 3">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.information') }}</h3>
                                    <h4>{{ $t('message.defineProduct') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg d-flex justify-content-center align-items-center px-0 borde-right" :class="step == 3 ? 'active' : 'opacity'">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span v-if="step <= 3">
                                        4
                                    </span>
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 4">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.content') }}</h3>
                                    <h4>{{ $t('message.addModules') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col col-lg d-flex justify-content-center align-items-center px-0" :class="step == 4 ? 'active' : 'opacity'">
                            <div class="row justify-content-center row__width">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <span v-if="step <= 4">
                                        5
                                    </span>
                                    <img src="../../../img/subir-productos-icons/tick-sleccion.svg" alt="" v-else-if="step >= 5">
                                </div>
                                <div class="col-8 d-flex flex-column justify-content-center align-items-start ps-0">
                                    <h3>{{ $t('message.questionnaire') }}</h3>
                                    <h4>{{ $t('message.addQuestions') }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--- MODALIDAD --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 1">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 pb-lg-4">
                    <div class="row justify-content-center row__width padding h-100">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloModaliad/>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-start align-items-center error px-4 pt-0 d-none" id="modality-empty">
                    <p>Por favor, seleccione una modalidad</p>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                    <Modalidad/>
                </div>
                <FooterSubirCursoBotones/>
            </form>
            <!--- INFORMACIÓN --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 2 && viewEditPrice == false">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100  pb-4">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloInformacion/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-0 px-lg-4 border-top">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-start justify-content-xxl-center align-items-center">
                                <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" @click="saveData(1)" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " @click="saveData(2)" id="multimedia-tab" data-bs-toggle="tab" data-bs-target="#multimedia" type="button" role="tab" aria-controls="multimedia" aria-selected="false">{{ $t('message.multimedia') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" @click="saveData(3)" id="precio-tab" data-bs-toggle="tab" data-bs-target="#precio" type="button" role="tab" aria-controls="precio" aria-selected="false">{{ $t('message.precifification') }}</button>
                                    </li>
                                    <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" @click="saveData(4)" id="preguntas-tab" data-bs-toggle="tab" data-bs-target="#preguntas" type="button" role="tab" aria-controls="preguntas" aria-selected="false">{{ $t('message.frequentQuestions') }}</button>
                                    </li>
                                    <!-- <li class="nav-item col d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" @click="saveData(5)" id="afiliados-tab" data-bs-toggle="tab" data-bs-target="#afiliados" type="button" role="tab" aria-controls="afiliados" aria-selected="false">{{ $t('message.myAffiliates') }}</button>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                <!--- INFORMACIÓN DEL PRODUCTO --->
                                <div class="col-12 tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <div class="col-12 d-flex justify-content-center align-items-start div-gris">
                                            <div class="row justify-content-start row__width">
                                                <div class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start">
                                                    <div class="row justify-content-center row__width">
                                                        <h3 class="px-0">{{ $t('message.selectProductLanguage') }}</h3>
                                                    </div>
                                                    <div class="row justify-content-center row__width">
                                                        <select name="language" id="language" v-model="information.lang">
                                                            <option value="" disabled selected hidden>{{ $t('message.selectLang') }}</option>
                                                            <option value="es">Español</option>
                                                            <!-- <option :value="lang.abbr" v-for="(lang, id) in listLangs">{{ lang.name }}</option> -->
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-start align-items-center error d-none" id="lang-empty"> <!-- v-if="!information.lang" -->
                                                    <p>Por favor, seleccione un idioma</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!--- INFORMACIÓN DEL PRODUCTO --->
                                        <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                                            <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                                                <div class="accordion-item row row__width justify-content-center">
                                                    <h2 class="accordion-header px-0" id="heading">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                                            <span>
                                                                <img src="../../../img/subir-productos-icons/cursos-icons/carpeta.svg" alt="">
                                                            </span>
                                                            <div class=" d-flex flex-column justify-content-center align-items-start ps-2">
                                                                <h3>{{ $t('message.productInformation') }}</h3>
                                                                <h4>{{ $t('message.subtitleProductInformation') }}</h4>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id="collapse" class="col-12 accordion-collapse collapse" aria-labelledby="heading">
                                                        <div class="accordion-body row justify-content-center">
                                                            <FormInformacion/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="accordion-item row row__width justify-content-center">
                                                    <h2 class="accordion-header px-0" id="heading2">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse">
                                                            <span>
                                                                <img src="../../../img/subir-productos-icons/cursos-icons/beneficios.svg" alt="">
                                                            </span>
                                                            <div class=" d-flex flex-column justify-content-center align-items-start ps-3">
                                                                <h3>Beneficios y propuestas de valor</h3>
                                                                <h4>Aquí podrás configurar los beneficios y carácteristicas que ofrece tu producto</h4>
                                                            </div>
                                                        </button>
                                                        <div id="collapse2" class="col-12 accordion-collapse collapse" aria-labelledby="heading2">
                                                            <div class="accordion-body row justify-content-center">
                                                                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                    <div class="row justify-content-start bg row__width" v-if="listBenefits">
                                                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                                                            <h3>Beneficios</h3>
                                                                        </div>
                                                                        <div class="col-12 d-flex justify-content-start align-items-center" v-for="(benefit, index) in listBenefits" :key="benefit">
                                                                            <input class="me-2 image-radio d-none" type="checkbox" :id="'benefits'+benefit.id" :name="'benefit-icon'" :checked="checkBenefits(benefit.id)" :value="benefit.id" v-model="information.benefits"/>
                                                                            <label :for="'benefits'+benefit.id" class="d-flex justify-content-start align-items-center mt-2 image-label non-selectable">
                                                                                <img :src="benefit.icon" class="icons me-2">
                                                                                <h5>{{ benefit.translations.ES }}</h5>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row justify-content-start bg" v-if="listValues">
                                                                        <div class="col-12 d-flex justify-content-start align-items-center">
                                                                            <h3>Propuestas de valor</h3>
                                                                        </div>
                                                                        <div class="col-12 d-flex justify-content-start align-items-center" v-for="(value, index) in listValues" :key="value">
                                                                            <input class="me-2 image-radio d-none" type="checkbox" :id="'benefits'+value.id+'propVal'" :name="'prop-icon'" :value="value.id" v-model="information.values"/>
                                                                            <label :for="'benefits'+value.id+'propVal'" class="d-flex justify-content-start align-items-center mt-2 py-3 px-3 image-label non-selectable">
                                                                                <h5 class="" >{{ value.translations.ES }}</h5>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- MULTIMEDIA --->
                                <div class="col-12 tab-pane fade px-0" id="multimedia" role="tabpanel" aria-labelledby="multimedia-tab">
                                    <div class="row justify-content-center">
                                        <div class="col-12 d-flex justify-content-center align-items-start px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                                    <div class="row justify-content-center justify-content-lg-between row__width">
                                                        <FormMultimedia/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--- PRECIOS --->
                                <div class="col-12 tab-pane fade" id="precio" role="tabpanel" aria-labelledby="precio-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormPrecio/>
                                    </div>
                                </div>
                                <!--- PREGUNTAS --->
                                <div class="col-12 tab-pane fade" id="preguntas" role="tabpanel" aria-labelledby="preguntas-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormPreguntas/>
                                    </div>
                                </div>
                                <!--- AFILIADOS --->
                                <div class="col-12 tab-pane fade" id="afiliados" role="tabpanel" aria-labelledby="afiliados-tab">
                                    <div class="row justify-content-center">
                                        <!--- ELEGIR IDIOMA --->
                                        <FormAfiliados/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSubirCursoBotones/>
            </form>
            <!--- CREAR PRECIO --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 2 && viewEditPrice == true">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100 pb-4">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloAgregarPrecio/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-lg-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <!--- EDITAR PRECIO --->
                               <FormAgregarPrecio/>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterGuardarSubirCurso/>
            </form>
             <!--- MÓDULOS --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 3 && editTask == false && editQuest == false">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100 pb-4">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloModulo/>
                        </div>
                    </div>
                    <!--- CON MÓDULOS --->
                    <div class="row justify-content-center row__width px-lg-4 border-top" >
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width bg-gris  bg-gris-2 mb-3 ">
                               <!--- PRESENTACION --->
                               <Presentacion/>
                            </div>
                            <div class="row justify-content-center row__width px-2" v-if="modules.content.length >= 1">
                               <!------>
                               <FormModulos />
                               <!------>
                               <div class="col-12 d-flex justify-content-center align-items-center settings px-0">
                                    <div class="row justify-content-start row__width">
                                        <div class="col-11 col-lg-4 d-flex justify-content-start align-items-center px-0">
                                            <button type="button" class="agregar-cursos-btn-3" @click="createModule()">
                                                Crear nuevo módulo
                                                <span>
                                                    <img src="../../../img/panel-icons/mas.svg" alt="">
                                                </span>
                                            </button>
                                        </div>
                                        <!-- <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                                            <div class="row justify-content-start row__width">
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h2>{{ $t('message.settingCount') }}</h2>
                                                </div>
                                                <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <p class="sin-padding">Activar foro para este curso</p>
                                                    </div>
                                                    <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                        <input class="form-check-input" type="checkbox" role="switch" id="default" v-model="modules.config.forum" @change="setModulesConfig()">
                                                        <label class="form-check-label" for="default">
                                                            <p v-if="modules.config.forum">SI</p>
                                                            <p v-else>NO</p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <p class="sin-padding">Activar comentarios para este curso</p>
                                                    </div>
                                                    <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                                                        <input class="form-check-input" type="checkbox" role="switch" id="perso" v-model="modules.config.comments">
                                                        <label class="form-check-label" for="perso">
                                                            <p v-if="modules.config.comments">SI</p>
                                                            <p v-else>NO</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!--- SIN MÓDULOS --->
                            <div class="row justify-content-center row__width " v-else>
                                <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                                    <div class="row justify-content-center row__width bg-gris">
                                    <!--- AÑADIR MÓDULO --->
                                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                                            <h3>{{ $t('message.dontHaveModules') }}</h3>
                                            <h5>{{ $t('message.clickCreate') }}</h5>
                                            <div class="row justify-content-center row__width">
                                                <div class="col-10 col-lg-4 d-flex justify-content-center align-items-center">
                                                    <button type="button" class="agregar-cursos-btn" @click="createModule()">
                                                        <span class="">
                                                            <img src="../../../img/panel-icons/mas.svg" alt="">
                                                        </span>
                                                        {{ $t('message.addModule') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSubirCursoBotones/>
            </form>
            <!--- EDITANDO TAREAS --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 3 && editTask == true && editQuest == false">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100  pb-4">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloTarea/>
                        </div>
                    </div>
                    <!--- TAREAS --->
                    <div class="row justify-content-center row__width px-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <FormAgregarTareas/>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterGuardarSubirCurso/>
            </form>
            <!--- CUESTIONARIO --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 3 && editQuest == true && editTask == false">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center row__width padding h-100  pb-4">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloCuestionario/>
                        </div>
                    </div>
                    <div class="row justify-content-center row__width px-lg-4 border-top tabs-fix">
                        <div class="col-11 col-lg-12  d-flex flex-column justify-content-center align-items-center tablas">
                            <div class="scroll d-flex justify-content-start align-items-center">
                                <ul class="nav nav-tabs row justify-content-start" id="myTab2" role="tablist">
                                    <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="info2-tab" data-bs-toggle="tab" data-bs-target="#info2" type="button" role="tab" aria-controls="info2" aria-selected="true">{{ $t('message.basicInformation') }}</button>
                                    </li>
                                    <li class="nav-item col-6 col-lg-3 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link " id="question-tab" data-bs-toggle="tab" data-bs-target="#question" type="button" role="tab" aria-controls="question" aria-selected="false">Preguntas</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="tab-content row justify-content-center row__width" id="myTabContent2">
                                <!--- INFORMACIÓN BÁSICA --->
                                <div class="col-12 tab-pane fade show active" id="info2" role="tabpanel" aria-labelledby="info2-tab">
                                    <div class="row justify-content-center">
                                        <FormCuestionario/>
                                    </div>
                                </div>
                                <!--- PREGUNTAS --->
                                <div class="col-12 tab-pane fade" id="question" role="tabpanel" aria-labelledby="question-tab">
                                    <div class="row justify-content-center">
                                        <FormCuestionarioPreguntas/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterGuardarSubirCurso/>
            </form>
            <!--- CERTIFICADOS --->
            <form class="row justify-content-center row__width contenedor" v-show="step == 4">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <div class="row justify-content-center align-items-center row__width padding h-100 mb-4">
                        <atrasBTNCertificados/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloCertificados/>
                        </div>
                    </div>
                    <!--- CERTIFICADOS --->
                    <div class="row justify-content-center row__width px-lg-4 border-top">
                        <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido">
                            <div class="row justify-content-center row__width">
                               <FormCertificados/>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSubirCursoBotones/>
            </form>
            <!--- CURSO CREADO CON ÉXITO --->
            <form class="row justify-content-center row__width contenedor exito" v-show="step == 5">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div class="row justify-content-center row__width">
                        <div class="col-11 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                            <img src="../../../img/subir-productos-icons/curso-creado.svg" alt="">
                            <h2>¡Curso creado con éxito!</h2>
                            <h3>Tu curso nuevo fue creado con exito</h3>
                            <button type="button" class="btn__primario" @click="router.push({name: 'ViendoCurso', id: product.id})">Ver curso</button>
                            <router-link :to="{name: 'SubirCurso'}">Crear otro curso</router-link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import atrasBTNCertificados from "../../../components/Botones/Atras.vue";
import TituloModaliad from "../../../components/Titulo/TituloModalidad.vue";
import Modalidad from "../../../components/Panel/Perfil/Modalidad.vue";
import FormInformacion from "../../../components/Panel/SubirProductos/FormInformacion.vue";
import FormMultimedia from "../../../components/Panel/SubirProductos/FormMultimedia.vue";
import FormPrecio from "../../../components/Panel/SubirProductos/FormPrecio.vue";
import FormPreguntas from "../../../components/Panel/SubirProductos/FormPreguntas.vue";
import FormAfiliados from "../../../components/Panel/SubirProductos/FormAfiliados.vue";
import TituloAgregarPrecio from "../../../components/Titulo/TituloAgregarPrecio.vue";
import FormAgregarPrecio from "../../../components/Panel/SubirProductos/FormAgregarPrecio.vue";
import TituloModulo from "../../../components/Titulo/TituloModulo.vue";
import TituloInformacion from "../../../components/Titulo/TituloInformacion.vue";
import TituloTarea from "../../../components/Titulo/TituloTarea.vue";
import TituloCuestionario from "../../../components/Titulo/TituloCuestionario.vue";
import FormModulos from "../../../components/Panel/SubirProductos/FormModulos.vue";
import FormAgregarTareas from "../../../components/Panel/SubirProductos/FormAgregarTareas.vue";
import TituloCertificados from "../../../components/Titulo/TituloCertificados.vue";
import FormCertificados from "../../../components/Panel/SubirProductos/FormCertificados.vue";
import FormCuestionario from "../../../components/Panel/SubirProductos/FormCuestionario.vue";
import FormCuestionarioPreguntas from "../../../components/Panel/SubirProductos/FormCuestionarioPreguntas.vue";
import FooterSubirCursoBotones from "../../../components/Botones/FooterSubirCursoBotones.vue";
import FooterGuardarSubirCurso from "../../../components/Botones/FooterGuardarSubirCurso.vue";
import Presentacion from "../../../components/Panel/SubirProductos/Presentacion.vue";

import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch, inject } from 'vue'
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter();
const route = useRoute();

const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const editCourse = ref(route.params.id)

console.log(editCourse.value, 'soy el id de la ruta')

//Profesiones y especialidades
const urlFetchLinked = '/api/categories/linked/'
//Notas minimas cuestionarios y certificados
const urlFetchNotes = '/api/academic/get/config'
//Beneficios
const urlFetchBenefits = '/api/profits/get/all/'
//Propuestas de valor
const urlFetchValue = '/api/values/get/all/'
//Preguntas frecuentes
const urlFetchFaqs = '/api/faq/get/all/'

//Guarda la informacion de la pestaña 1 - Informacion Basica
const urlSaveInfo = '/api/product/creating/add/info'
//Guarda la informacion de la pestaña 2 - Multimedia
const urlSaveMulti = '/api/product/creating/add/multimedia'

//Crea el modulo vacio
const urlCreateModule = '/api/product/creating/add/module'

//Setea configuraciones de un curso en Viendo Curso
const setConfigModule = '/api/product/config/update/' //pId

//Query de curso
const urlQueryCourse = '/api/course/'

const listProfs = ref([])
const listSpecs = ref([])
const listModalities = ref([])
const listBenefits = ref([])
const listValues = ref([])
const listFaqs = ref([])
const listNotes = ref({})
const listProducers = ref({})
let videoIDRef = ref()

let owner_id = ref()
let product_id = ref()

provide('owner_id', owner_id)
provide('product_id', product_id)

const courseID = ref(route.params.id);

provide('listProfs', listProfs)
provide('listSpecs', listSpecs)
provide('listModalities', listModalities)
provide('listBenefits', listBenefits)
provide('listValues', listValues)
provide('listFaqs', listFaqs)
provide('listNotes', listNotes)
provide('listProducers', listProducers)
provide('videoIDRef', videoIDRef)

const algo = onBeforeMount( () => {
    access.value = [1, 4]
    //console.log(access.value) 
    validate();
}) 

const isSidebarOpen = ref(true)
//provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

//Setea el paso actual
let step = ref(1)
//Setea el sub paso
let subStep = ref(1)
//Setea el paso cacheado
let cacheSubStep = ref(null)
//Activa o desactiva las vistas
let viewEditPrice = ref(false)
let editTask = ref(false)
let editQuest = ref(false)
let actualActivity = ref(null)
let copyActualActivity = ref(null)
let currentItem = ref(null)
let currentId = ref(null)

let selectedLang = ref('')

let alert = ref()

//Setea el tipo de producto
let type = ref(null)

//Setea la modalidad
let modality = ref(null)

//Setea el contenido de INFORMACION
let information = ref({
    lang: null,
    title: '',
    description: '',
    producer: 2,
    profession: [],
    country: '',
    time: '',
    creator: [],
    collabs: [],
    specialities: [],
    benefits: [],
    values: [],
    dateStart: null,
    dateEnd: null
})
//Setea el contenido de MULTIMEDIA
let multimedia = ref({
    image: null,
    video: null,
    studentPf: false
})
let mediaTemp = ref({
    inputImg: false,
    inputFile: false,
    tempUrl: null
})

//Setea el contenido de PRECIFICACION
let precification = ref({
    coin: '',
    prices: [],
    products: []
})

//Setea el contenido de PREGUNTAS FRECUENTES
let faq = ref({
    showDefault: false,
    showQuestions: false,
    questions: []
})

//Setea el contenido de MIS AFILIADOS
let affiliates = ref({
    enabled: false,
    approved: false
})

//Setea el contenido de CERTIFICADO
let certificate = ref({
    sendCertificate: false,
    requiredGrade: false,
    minimumGrade: 0,
    hasFinalProject: false,
    certId: null
})

let finalProject = ref({
    title: '',
    description: '',
    pId: '',
    guide: null,
    fProjId: null
})

//Setea el contenido de MODULOS
let modules = ref({
    content: [],
    config: {
        forum: false,
        comments: false
    }
})

//Setea la variable del producto
let product = ref({
    id: null,
    config: null,
    information: {
        trans_id: null,
        detail_id: null
    },
    multimedia: {
        image_id: null,
        video_id: null
    },
    titleIntro: '',
    videoIntro: ''
})

//VARIABLES PASOS
provide('step', step)
provide('subStep', subStep)
provide('cacheSubStep', cacheSubStep)
//VARIABLES VISTAS
provide('viewEditPrice', viewEditPrice)
provide('editTask', editTask)
provide('editQuest', editQuest)
//TIPO DE PRODUCTO
provide('type', type)
//MODALIDAD
provide('modality', modality)
//INFORMACION
provide('information', information)
provide('multimedia', multimedia)
provide('mediaTemp', mediaTemp)
provide('precification', precification)
provide('faq', faq)
provide('affiliates', affiliates)
//MODULOS
provide('modules', modules)
//CERTIFICADO
provide('certificate', certificate)
provide('finalProject', finalProject)
//PRODUCTO
provide('product', product)
//VARIABLE ACTIVIDAD ACTUAL Y COPIA DE SEGURIDAD
provide('actualActivity', actualActivity)
provide('copyActualActivity', copyActualActivity)
provide('currentItem', currentItem)
provide('currentId', currentId)

provide('alert', alert)

// //Funcion añadir lenguaje
// const addLang = (lang) => {
//     if (!information.value.lang.includes(lang)) {
//         information.value.lang.push(lang)
//     }
// }

// //Funcion eliminar lenguaje
// const deleteLang = (item, id) => {
//     item.splice(id, 1);
// }

const checkBenefits = (id) => {
    information.value.benefits.forEach(element => {
        if(element.id == id){
            return true
        }
        else{
            return false
        }
    })
}

//Recibe los profesionales y sus especialidades
const fetchLinked = async() => {
    try {
        await axios.get(url+urlFetchLinked+'ES').then((result) => {
            //console.log(result)
            listProfs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listProfs.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchLinked()

//Recibe las notas minimas de cuestionarios y certificados
const fetchNotes = async() => {
    try {
        await axios.get(url+urlFetchNotes).then((result) => {
            console.log(result)
            if (result.status == 200){
                listNotes.value.quizValue = result.data.quiz_min
                listNotes.value.certValue = result.data.cert_min
                certificate.value.minimumGrade = listNotes.value.certValue
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchNotes()

//Recibe los beneficios
const fetchBenefits = async() => {
    try {
        await axios.get(url+urlFetchBenefits+'ES').then((result) => {
            //console.log(result)
            listBenefits.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listBenefits.value.push( result.data[i] )
                }
            }
            //console.log(listBenefits.value)
        })
    } catch (error) {
        console.error(error)
    }
}
fetchBenefits()

//Recibe las propuestas de valor
const fetchValues = async() => {
    try {
        await axios.get(url+urlFetchValue+'ES').then((result) => {
            //console.log(result)
            listValues.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listValues.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchValues()

//Recibe las preguntas frecuentes
const fetchFaqs = async() => {
    try {
        await axios.get(url+urlFetchFaqs+'ES').then((result) => {
            //console.log(result)
            listFaqs.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listFaqs.value.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchFaqs()

const saveData = (x) => {
    cacheSubStep.value = subStep.value
    subStep.value = x
    //Guarda la pestaña INFORMACION BASICA
    if(cacheSubStep.value == 1){
        const saveInfo = async(id, trans_id) => {
            const token = JSON.parse(localStorage.getItem('token'))
            const headers = ref({'Authorization': `Bearer ${token}`})
            information.value.lang = 'ES'
            information.value.id = id
            information.value.trans_id = trans_id
            information.value.owner_id = owner_id.value
            information.value.product_id = product_id.value
            console.log(information.value)
            try {
                await axios.post(url+urlSaveInfo, information.value,{headers: headers.value}).then((result) => {
                    console.log(result)
                    product.value.information.trans_id = result.data.trans_id
                    product.value.information.detail_id = result.data.detail_id
                })
            } catch (error) {
                console.error(error)
            }
        }
        saveInfo(product.value.id, information.value.trans_id)
    }
    //Guarda la pestaña MULTIMEDIA
    else if(cacheSubStep.value == 2){
        const saveInfo = async(id, image_id, video_id) => {
            const token = JSON.parse(localStorage.getItem('token'))
            const headers = ref({'Authorization': `Bearer ${token}`})
            multimedia.value.id = id
            multimedia.value.image_id = image_id
            multimedia.value.video_id = video_id
            multimedia.value.owner_id = owner_id.value
            multimedia.value.product_id = product_id.value
            console.log(information.value)
            try {
                await axios.post(url+urlSaveMulti, multimedia.value, {headers: headers.value}).then((result) => {
                    console.log(result)
                    product.value.multimedia.image_id = result.data.image_id
                    product.value.multimedia.video_id = result.data.video_id
                })
            } catch (error) {
                console.error(error)
            }
        }
        saveInfo(product.value.id, multimedia.value.image_id, multimedia.value.video_id)
    }
}

//Funcion añadir modulo
const addModule = () => {
    console.log(modules.value)
    modules.value.content.push([{ title: '', lessons: [] }])
    console.log(modules.value)
}

const createModule = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let pId = product.value.id
    let modPos = modules.value.content.length
    try {
        await axios.post(url+urlCreateModule, {id: pId, modPos: modPos, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            modules.value.content.push([{ title: '', lessons: [], modId: result.data.modId, modPos: result.data.modPos }])
        })
    } catch (error) {
        console.error(error)
    }
}

//Setea las configuraciones de un curso en Viendo Curso
const setModulesConfig = async() =>{
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    if(modules.value.config.forum || modules.value.config.comments){
        let config = {
            has_forum: modules.value.config.forum,
            has_comments: modules.value.config.comments,
            owner_id: owner_id.value,
            product_id: product_id.value
        } 
        console.log(config) 
        try {
            await axios.post(url+setConfigModule+product_id.value, config, {headers: headers.value}).then((result) => {
                console.log(result)
            })
        } catch (error) {
            console.error(error)
        }
    }
}

// const setforumComments = async() => {
//     const token = JSON.parse(localStorage.getItem('token'))
//     const headers = ref({'Authorization': `Bearer ${token}`})
//     try {
//         await axios.post(url+urlCreateModule, multimedia.value, {headers: headers.value}).then((result) => {
//             console.log(result)
//         })
//     } catch (error) {
//         console.error(error)
//     }
// }

// Preguntar si el curso ya existe en el listado de cursos
// const queryCourseList = async() => {
//     if(editCourse.value){
//         let lang = 'ES'
//         try {
//             await axios.get(url+urlQueryCourse+lang+'/asd/'+editCourse.value).then((result) => {
//                 console.log(result)
//                 modality.value = result.data.type
//             })
//         } catch (error) {
//             console.error(error)
//         }
//     }
// }
// queryCourseList()

// console.log(information.value)

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #FAFAFA;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
        background-color: #fff;
    }
}
.contenedor{
   @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
   }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}

.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
    
}

.proceso{
    border-bottom: 2px solid #E4E4E4;
    margin-bottom:30px;
    h3{
        font-weight: 600;
        color: #B8C1DB;
        font-size: 18px;
        text-align: left;
    }
    h4{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 16px;
        text-align: left;
    }
    .borde-right{
        border-right: 2px solid #E4E4E4;
    }
    .opacity{
        h3, h4{
            opacity: 0.5;
        }
    }
    span{
        background-color: #B8C1DB;
        min-height: 38px;
        min-width: 38px;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active{
        background-color: var(--bgPrincipal);
        span{
            background-color: var(--colorPrimary);
        }
        h3, h4{
            color: var(--colorSecondary);
        }
    }
    .col{
        z-index: 1;
    }
    @media (max-width: 991px) { 
        width: 100%; 
        box-shadow: 0px 3px 6px #00000029;
        background-color: var(--bgPrincipal);
        align-items: center;
        h3, h4{
          display: none;
        }
        .col{
            max-width: max-content;
        }
        .active{
            h3, h4{
                display: flex;
            }
        }
        .borde-right{
            border-right: 0;
        }
        .col{
            margin-right: 7px;
        }
        &:before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            width: 100%;
            height: 2px;
        }
    }
    .scroll{
        overflow-x: visible;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        min-width: 100%;
        padding: 20px 0;
        .row_scroll{
            min-width: 100vw;
            height: 100%;
        }
        @media (min-width: 1300px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            .row_scroll{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #272d3b0c;
            border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #272d3b18;
            border-radius: 100px;
        }
    }
}
.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.tablas{  
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid #70707023;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 10px;
    ul{
        background-color: transparent;
        padding: 8px 0;
        min-width: calc(100% + 200vw);
        @media (min-width: 768px) {
            min-width: calc(100% + 70vw);
        }
        @media (min-width: 992px) {
            min-width: calc(100% + 30vw);
        }
        @media (min-width: 1300px) {
            min-width: 100%;
        }
    }
    .nav-item{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        h3{
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
        }
        @media (min-width: 992px) {
            color: #0E1133;
            h3{
                color: #0E1133;
            }
        }
    }
    .nav-link.active{
        font-weight: 700;
        border-color: transparent;
        color: var(--colorPrimary)!important;
        background-color: transparent;
        border-radius: 0;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            left: 0;
            background-color: var(--colorPrimary);
            border-radius: 10px;
        }
    }
    .scroll{
        overflow-x: visible;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        min-width: 100%;
        .row_scroll{
            min-width: 100%;
            height: 100%;
        }
        @media (min-width: 1300px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            .row_scroll{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background-color: #272d3b0c;
            border-radius: 100px;
            border: 0;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #272d3b27;
            border-radius: 100px;
        }
    }
    @media (max-width: 991px) {
        border-radius: 0px;
        margin-bottom: 10px;
    }
    
}
.contenido{
    .div-gris{
        background-color: #F5F5F5;
        height: auto;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #0000001f;
    }
    h3{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px !important;
    }
    
    input[type=text], select{
        background-color: #FCFCFC;
        font-weight: 400;
        color: #697891;
        font-size: 16px;
        border-radius: 10px;
        border: 2px solid #E9E3E3;
        height: 50px;
        -webkit-appearance: none;
    }
    .agregar-idioma{
        .col{
            max-width: 40px;
            margin-right: 15px;
        }
        button{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -7px;
            top: -5px;
        }
    }
    

    .collapses{
        h3{
            margin-bottom: 5px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: break-spaces;
            max-width: 90%;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8BCC8;
            text-align: left;
            .bg{
                background-color: var(--bgPrincipal);
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                margin: 10px 20px;
                width: 100%;
                border-radius: 8px;
                padding: 20px 10px;
                margin-bottom: 10px;
            }
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 15px 0px;
                border: 0;
                border-radius: 10px;
                .bg{
                    background-color: var(--bgPrincipal);
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                    margin: 10px 20px;
                    border-radius: 8px;
                    padding: 20px 10px;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        min-height: 50px;
                        min-width: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    @media (max-width: 991px) {
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 5%;
                    background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                    background-size: 100%;
                    background-position: center;
                    @media (max-width: 991px) {
                        margin-top: 5px;
                    }
                }
            }
        }
        @media (min-width: 992px) {
            margin: 20px 0 60px 0;
        }
    }
    .bg-gris, .bg-gris-2{
        background-color: #F5F5F5;
        padding: 30px 20px 20px 20px;
        border-radius: 10px;
        h4{
            font-weight: 600;
            color: #343232;
            font-size: 20px;
            text-align: center;
            margin-bottom: 10px !important;
        }
        h5{
            font-weight: 400;
            color: #BEBDBD;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px !important;
            max-width: 54%;
            line-height: 27px;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        input{
            width: 100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: transparent;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
            @media (max-width: 991px) {
                width: 83%;
            }
        }
        @media (max-width: 991px) {
            box-shadow: 0px 3px 10px #00000029;
            h5{
                max-width: 100%;
                font-size: 16px;
            }
        }
    }
    .bg-gris-2{
        background-color: #fff;
        padding: 30px 13px 13px 13px;
        h4{
            color: var(--colorSecondary);
        }
        .collapses{
            h3{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                padding: 7px 0px;
            }
            h6{
                color:#B8BCC8;
                font-size: 15px;
                font-weight: 400;
                text-align: left;
                padding: 5px 0px;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: relative;
                    min-height: 70px;
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: #F9F9F9;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            min-height: 40px;
                            min-width: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        h3{
                            text-align: center;
                            font-size: 22px;
                        }
                        button{
                            background-color: transparent;
                            border: 2px solid #E4E4E4;
                            border-radius: 50%;
                            min-height: 40px;
                            min-width: 40px;
                            margin-left: 10px;
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 2%;
                        background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                        background-size: 50%;
                        background-position: center;
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                    }
                    .accordion-body{
                        span{
                            border: 2px solid #E9E3E3;
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h3{
                                text-align: center;
                            }
                        }
                        button{
                            background-color: transparent;
                            border: 2px solid #E4E4E4;
                            border-radius: 50%;
                            height: 40px;
                            width: 40px;
                            margin-left: 10px;
                        }
                    }
                    
                }
            }
        }

    }
}
.agregar-cursos-btn, .agregar-cursos-btn-3{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.agregar-cursos-btn-3{
    display: flex;
    justify-content: space-between;
    span{
        margin: 0;
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            color: #2E4765;
            font-size: 14px;
            font-weight: 300;
            text-align: left;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        @media (min-width: 992px) {
            min-width: 55% !important;
            max-width: 55%;
        }
    }
}
.exito{
    h2{
        font-weight: 600;
        font-size: 32px;
        color: var(--colorSecondary);
        padding: 20px 0;
    }
    h3{
        color: #BEBDBD;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 20%;
    }
    button{
        margin: 10% 0 15% 0;
    }
    a{
        color: var(--colorSecondary);
        font-weight: 700;
        font-size: 16px;
        text-decoration: underline;
    }
}
.dndrop-container.vertical{
    padding: 0 !important;
}

.icons{
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}

.non-selectable {
  user-select: none;
}

.image-label {
    padding: 2px;
    border: 2px solid #2b2b2b1c;
    border-radius: 25px;
    padding: 5px;
    width: 100%;
    h5{
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
        white-space: nowrap;
        word-break: break-all;
    }
}

.tabs-fix{
    .tablas ul{
        background-color: transparent;
        padding: 8px 0;
        min-width: calc(100% + 30vw);
    }
}

.image-radio:checked + .image-label {
    border: 2px solid #1679FC;
    
}

.error{
    padding: 10px 0;
    p{
        color: #FF6A6A;
        font-size: 16px;
        font-weight: 400;
    }
}
.error-color{
    color: #FF6A6A !important;
}
</style>