<template>
    <div class="col-12 d-flex justify-content-center align-items-center box px-0">
        <div class="row justify-content-center row__width">
            <div class="col-8 col-lg-3 d-flex justify-content-start align-items-center my-3 my-lg-0 mx-lg-2">
                <div class="d-flex justify-content-center align-items-center img">
                    <img :src="props.user.avatar" alt="avatar">
                    <div class="crown" v-if="props.user.plan && props.user.plan.id == 2">👑</div>
                </div>
                <div class="d-flex flex-column justify-content-center align-items-start excepcion ms-3 titles">
                    <h5>{{ $t('message.name') }}</h5>
                    <h6 v-if="props.user.personal_data">{{ props.user.personal_data.first_name}}</h6>
                    <h6 v-else-if="props.user.name">{{ props.user.name}}</h6>
                    <h6 v-else>{{  props.user.email }}</h6>
                </div>
            </div>
            <div class="col-4 col-lg-1 d-flex justify-content-end justify-content-lg-center align-items-center eliminar ps-0 d-lg-none">
                <button class="me-2" @click="edit_user(props.user.id)">
                    <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="edit">
                </button>
                <button type="button" class="arrow me-2" :id="'arrow'+props.user.id" @click="openUser(props.user.id)"></button>
            </div>
            <div class="col-12 col-lg d-flex justify-content-center align-items-center seccion d-none d-lg-flex body" :id="'collapse'+props.user.id">
                <div class="row row__width justify-content-between align-items-start">
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-start excepcion">
                        <h5>{{ $t('message.email') }}</h5>
                        <h6 class="inactive email-hover">{{ props.user.email }}</h6>
                    </div>
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-start"  v-if="props.user.team">
                        <h5>{{ $t('message.role') }}</h5>
                        <h6 class="inactive">{{ props.user.team.translation.name }}</h6>
                    </div>
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-start hide-plan"  v-if="props.user.plan">
                        <h5>{{ $t('message.plan') }}</h5>
                        <h6 class="inactive">{{ props.user.plan.translation.name }}</h6>
                    </div>
                    <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-start"  v-if="props.user.active">
                        <h5 class="mb-2 mb-lg-0">{{ $t('message.status') }}</h5>
                        <span class="active" v-if="props.user.active">{{ $t('message.active') }}</span>
                        <span class="inactive" v-else>{{ $t('message.inactive') }}</span>
                    </div>
                </div>
            </div>
            <div class="col-4 col-lg-1 d-flex justify-content-end justify-content-lg-center align-items-center eliminar d-none d-lg-flex" v-if="props.user.id" >
                <button class="me-2" @click="edit_user(props.user.id)">
                    <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="edit">
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, onUnmounted, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../../stores/user";
import { useLanguageStore } from "../../../../stores/langs"

const router = useRouter()

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const users = useUsersStore()
const {datosUsuario, professionalData, headers} = storeToRefs(users)
const {url, validarMail, usuarioData, userProfessionalData} = users

const props = defineProps({
    user: '',
    index: ''
})

let page_id = inject('page_id')
let user_id = inject('user_id')
let state = inject('state')

const edit_user = (id) => {
    page_id.value = 2
    user_id.value = id
    state.value = 'e'
    console.log(page_id.value, user_id.value, state.value)
}

const showBanner = () => {
    // Analizar ancho de pantalla
    if(window.innerWidth >= 992) {
        document.querySelectorAll('.accordion-button').addEventListener('click', function(e) {
            e.stopPropagation();
        });
    }
}
const openUser = (id) => {
    let element = document.getElementById('collapse'+id)
    let arrow = document.getElementById('arrow'+id)
    element.classList.toggle('d-none')
    arrow.classList.toggle('arrow-toggle')
}

// onMounted(() => {
//     window.addEventListener("resize", showBanner)
// })

// onUnmounted(() => {
//     window.removeEventListener("resize", showBanner)
// })

</script>

<style lang="scss" scoped>
.arrow-toggle{
    rotate: 180deg;
    transition: all ease 0.3s;
}

.box{
    border: 0;
    border-radius: 10px;
    //box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: #F9F9F9;
    margin-bottom: 15px;
    min-height: 90px;
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        position: relative;
    }
    .img{
        position: relative;
        min-height: 58px;
        min-width: 58px;
        max-height: 58px;
        max-width: 58px;
        img{
            min-height: 58px;
            min-width: 58px;
            max-height: 58px;
            max-width: 58px;
            border-radius: 50%;
            object-fit: cover;
        }
        .crown{
            position: absolute;
            top: -6px;
            right: 0;
            transform: rotate(17deg);
        }
    }
    .titles{
        max-width: 100%;
        overflow: hidden;
        h6{
            color: #B8C1DB;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
    .body{
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
            white-space: break-spaces; 
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        span{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            padding: 5px 10px;
            min-width: 110px;
        }
        .inactive{
            background-color: #F5F5F5;
            color: #707070;
        }
        .active{
            background-color: #38E6A7;
            color: #FFFFFF;
        }
        .pause{
            background-color: #FEB230;
            color: #FFFFFF;
        }
        .email-hover{
            // &:hover{
            //     position: absolute;
            //     bottom: 20px;
            //     border: 1px solid #E5E5E5;
            // }
        }
        
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    button.arrow::after{
        content: '';
        position: absolute;
        background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        background-color: transparent;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: block;
        @media (min-width: 992px) {
            display: none;
        }
    }
    
    @media (max-width: 992px) {
        h5{
            padding-bottom: 0;
        }
    }
    .seccion{
        .col-lg{
            margin-bottom: 15px;
        }
        @media (min-width: 992px) {
            .col-lg{
                max-width: 14vw;
            }
            .excepcion{
                max-width: 10vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .excepcion{
                max-width: 8vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .hide-plan{
                display: none !important;
            }
        }
        @media (min-width: 1100px) {
            .excepcion{
                max-width: 12vw;
            }
        }
        @media (min-width: 1200px) {
            .col-lg{
                max-width: 10vw;
            }
            .excepcion{
                max-width: 11vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .hide-plan{
                display: flex !important;
            }
        }
    }

    
}
.padding{
    padding: 0 11%;
}
    


</style>