<template>
    <div class="row justify-content-center row__width inputs__carrito pb-5">
        <div class="col-12 d-flex justify-content-start align-items-center">
            <h2>Datos para revisar</h2>
        </div>
        <div class="col-12 d-flex justify-content-start align-items-center no-eres">
            <h4>Aun no tengo cuenta </h4> 
            <a href="">Registrarme</a>
        </div>
        <div class="col-12 d-flex justify-content-start align-items-center mb-4">
            <h6>Detalles para facturación</h6>
        </div>
        <inputNombreCheckout/>
        <PasswordInputCheckout/>
        <RecordarCheck/>
        <div class="col-12 d-flex justify-content-center align-items-center mt-4 pt-2">
            <BTNGrisInicio textoBtn="Iniciar Sesión"/>
        </div>
        <IngresoRapidoCheckout class="mt-5 mb-3"/>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <ContinuarComoBTN :titulo="$t('message.loginFacebook')"/>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
            <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')"/>
        </div>
    </div>
</template>

<script setup>
    import BTNGoogle from "../Botones/Google.vue";
    import BTNGrisInicio from "../Botones/GrisInicio.vue";
    import ContinuarComoBTN from "../Botones/ContinuarComo.vue";
    import IngresoRapidoCheckout from "../Titulo/IngresoRapidoCheckout.vue";
    import PasswordInputCheckout from "./PasswordCheckout.vue";
    import RecordarCheck from "./RecordarCheck.vue";
    import inputNombreCheckout from "./NombreCheckout.vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 28px;
    color: var(--colorSecondary);
    padding-bottom: 15px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        background-color: var(--colorSecondary);
        width: 20%;
        height: 5px;
        border-radius: 8px;
        bottom: 0;
    }
}
h6{
    font-weight: 600;
    font-size: 18px;
    color: var(--colorSecondary);
}
.no-eres{
    margin: 2rem 0;
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #7D93B2;
        padding-bottom: 5px;
    } 
    a{
        color: #1877F2;
        font-size: 16px;
        font-weight: 400;
        padding-left: 7px;
    }
}
</style>