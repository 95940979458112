<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center profesor" v-if="professional">
        <!-- <div class="row justify-content-center row__width pt-3 imagenes d-none d-lg-flex">
            <div class="col d-flex justify-content-center align-items-center ps-0 position-relative">
                <img src="../../img/home-icons/cursos-icons/1.png" alt="">
            </div>
            <div class="col d-flex justify-content-center align-items-center px-0 position-relative">
                <img src="../../img/home-icons/cursos-icons/1.png" alt="">
            </div>
            <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center arreglo">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img src="../../img/home-icons/cursos-icons/2.png" alt="">
                    </div> 
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img src="../../img/home-icons/cursos-icons/3.png" alt="">
                    </div> 
                </div>
            </div>
        </div> -->
        <div class="row justify-content-center row__width pt-3 imagenes d-none d-lg-flex">
            <div class="col d-flex justify-content-center align-items-center ps-0 position-relative">
                <img :src="professional.data.banner" alt="" v-if="professional">
            </div>
        </div>
        <div class="row justify-content-center row__width" v-if="professional">
            <div class="col-3 col-lg d-flex justify-content-center align-items-center px-2 max-width">
                <img :src="professional.user.avatar" alt="" class="perfil">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <h2>{{professional.data.full_name}}</h2><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="d-none ps-4" >
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/ubi-negro.svg" alt="" class="pe-2"><h3>{{professional.data.city}}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <template v-if="product.reviews.average">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix" v-for="rate in Math.floor(product.reviews.average)" :key="`rate-${rate}`">
                            <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" class="image-fix" v-for="rateGris in 5 - Math.floor(product.reviews.average)" :key="`rate-gris-${rateGris}`">
                        </template>
                        <template v-else>
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix">
                        </template>
                        <h5>{{product.reviews.average}}</h5>
                        <p >{{ product.reviews.count ? `(${product.reviews.count})` : $t('message.new') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center px-2">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-end align-items-center py-1" v-if="product.professions.length > 0">
                        <h4>{{product.professions[0].name}}</h4>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <a :href="'/perfil-usuario/' + professional.slug" target="_blank" class="ver_perfil py-2">
                            {{ $t('message.viewProfile') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="texto_mas col-12 d-flex justify-content-center align-items-center" id="filtro4">
                <p>{{professional.user.personal_data.about}}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../stores/general"    
import { storeToRefs } from "pinia";
import { inject, ref } from 'vue';
import axios from 'axios';

let product = inject('product')
let professional = inject('professional')

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const mostrarTexto4 = () => {
    document.getElementById('filtro4').classList.remove('d-flex')
}

</script>

<style lang="scss" scoped>
 .profesor{ 
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 20px 5px 20px 15px;
    margin: 20px 0px;
    h2{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 400;
        font-size: 14px;
        color: var(--colorSecondary);
        padding: 0;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        padding: 0;
    }
    h5{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
        padding: 0px 5px;
    }
    p{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #697891;
    }
    .perfil{
        object-fit: cover;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        @media (min-width: 992px) {
            height: 72px;
            width: 72px;
        }
    }
    .max-width{
        max-width: 80px;
    }
    .texto_mas{
        p{  
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
            padding: 20px 0 10px 0;
        }
    }
    .grandes{
        max-width: 100%;
        width: 100%;
        height: 100%;
        height: 170px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        object-fit: cover;
    }
    .chiquitas{
        max-width: 100%;
        width: 100%;
        height: 80px;
        max-width: 92px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        object-fit: cover;
    }
}
.imagenes{
    margin-bottom: 10px;
    .padding{
        padding: 0px 10px 15px 10px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    img{
        max-width: 100%;
        height: 100%;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0px 3px 6px #00000029;
        max-height: 170px;
        object-fit: cover;
        @media (min-width: 1800px) {
            max-height: 250px;
        }
    }
    .arreglo{
        max-width: 33%;
        img{
            max-height: 78px;
            @media (min-width: 1800px) {
                max-height: 115px;
            }
        }
    }
    span{
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        height: 47px;
        width: 47px;
        border-radius: 50%;
        color: var(--colorPrimary);
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 20px;
        top: 6%;
    }
}

.ver_perfil{
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorPrimary);
    border-radius: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorPrimary);
    margin: 10px 0px;
}

.image-fix{
    height: 10px;
    margin-bottom: 3px;
}
</style>