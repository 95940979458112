<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-center align-items-center">
            <span>
                <img src="../../img/roles-icons/estudiante.svg" alt="">
            </span>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>