<template>
    <div class="col-12 d-flex justify-content-center justify-content-lg-between align-items-center mt-3 mt-lg-0">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                <h3>Precio</h3>
            </div>
            <div class="col-12 d-flex justify-content-between align-items-center px-0">
                <button>
                    <img src="../../../img/subir-productos-icons/cursos-icons/precio.svg" alt="">
                </button>
                <input type="number" placeholder="400" id="price" v-model="actualActivity.price" @change="checkPrice()">
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

let actualActivity = inject('actualActivity')

const checkPrice = () => {
    if(actualActivity.value.price < 1 || !actualActivity.value.price){
        actualActivity.value.price = 1
    }
}

</script>

<style lang="scss" scoped>
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 93%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
    @media (max-width: 991px) {
        width: 83%;
    }
}
button{
    background-color: #fff;
    border: 2px solid #E9E3E3;
    outline: 0;
    border-radius: 25px;
    height: 50px;
    width: 50px;
}
</style>