<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex justify-content-start align-items-center" :class="sinBefore == true ? 'justify-content-lg-between' : ''">
            <h4 :class="sinBefore == true ? 'sin__before' : ''">{{titulo}}</h4>
            <div class="div" :class="sinBefore == true ? 'd-lg-flex' : 'd-none'">
                <h2>Master</h2><p>PLUS</p>
            </div>
        </div>
        <div class="col-12 justify-content-start align-items-center" :class="oculto === true ? 'd-none' : 'd-flex'">
            <h5>{{cliente}} <router-link :to="{ name: 'Iniciar', params: {route: 'checkout'} }">{{ingrese}}</router-link></h5>
        </div>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter()

    const props = defineProps({
        titulo: '',
        cliente: '',
        oculto: Boolean,
        ingrese: '',
        sinBefore: Boolean
    })
</script>

<style lang="scss" scoped>
h4{
  font-weight: 700;
  font-size: 28px;
  color: var(--colorSecondary);
  padding-bottom: 15px;
  position: relative;
  text-align: left;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    background-color: var(--colorSecondary);
    width: 20%;
    height: 5px;
    border-radius: 8px;
    bottom: 0;
  }
  @media (min-width: 992px) {
    padding: 30px 0;
    font-size: 32px;
  }
  @media (min-width: 1366px) {
    padding: 15px 0;
  }
}
.sin__before{
    &::before{
        display: none;
    }
    @media (min-width: 992px) {
        padding: 3rem 0 3rem 1.5rem;
        font-size: 32px;
    }
}
h5{
    font-weight: 400;
    font-size: 16px;
    color: #7D93B2;
    padding-top: 40px;
    text-align: left;
}
a{
    font-weight: 400;
    font-size: 16px;
    color: var(--colorPrimary);
    padding-left: 5px;
}
h2{
    font-size: 32px;
    font-weight: 700;
    color: var(--colorSecondary);
}
p{
    font-size: 35px;
    font-weight: 700;
    color: #FEB230;
    text-transform: uppercase;
    padding-left: 5px;
}
.div{
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
        display: none !important;
    }
}
</style>