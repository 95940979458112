<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0 ps-lg-0 pe-lg-3 duration">
        <h3>{{ $t('message.endDate') }}</h3>
        <div class="position">
            <input id="calendar-input-6" placeholder="" />
            <div id="calendar-input-div"></div>
            <i class="calendar"></i>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { inject, ref, computed, onMounted, watch } from "vue";
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';
import 'v-calendar/style.css';
import { useI18n } from 'vue-i18n'

const user = useUsersStore();
const {userData, headers, idUser, beforeInfomation} = storeToRefs(user);
const {} = user;

const general = useGeneralStore();
const {textos, inputs} = storeToRefs(general);
const {} = general;

const information = inject('information')
const validez = inject('validez')
const { t }  = useI18n()

const msg = computed(() => t('message'))

const date = ref(new Date())


const dateConsole = ref(null)

const {locale} = useI18n({ useScope: 'global' })
watch(locale, () => {
    //console.log(locale.value)
    const options = ref({
        input: true,
        actions: {
            changeToInput(e, HTMLInputElement, dates, time, hours, minutes, keeping) {
            if (dates[0]) {
                HTMLInputElement.value = dates[0];
                console.log(calendar.value)
                // if you want to hide the calendar after picking a date
                console.log(calendar.value.HTMLInputElement)
                calendar.value.HTMLElement.classList.add('vanilla-calendar_hidden');
                console.log(calendar.value.HTMLElement, 'es lo que desaparece')
            } else {
                HTMLInputElement.value = '';
            }
            },
        },
        settings: {
            lang: 'define',
        },
        locale: {
            months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
            weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
        }
    })
    loadCalendar()
})

const calendar = ref({})

const options = ref({
  input: true,
  actions: {
    changeToInput(e, HTMLInputElement, dates, time, hours, minutes, keeping) {
      if (dates[0]) {
        HTMLInputElement.value = dates[0];
        //console.log(calendar.value)
        // if you want to hide the calendar after picking a date
        //console.log(calendar.value.HTMLInputElement)
        calendar.value.HTMLElement.classList.add('vanilla-calendar_hidden');
        //console.log(calendar.value.HTMLElement, 'es lo que desaparece')
      } else {
        HTMLInputElement.value = '';
      }
    },
  },
  settings: {
    lang: 'define',
  },
  locale: {
    months: [t('message.january'), t('message.february'), t('message.march'), t('message.april'), t('message.may'), t('message.june'), t('message.july'), t('message.august'), t('message.september'), t('message.october'), t('message.november'), t('message.december')],
    weekday: [t('message.sunday'), t('message.monday'), t('message.tuesday'), t('message.wednesday'), t('message.thursday'), t('message.friday'), t('message.saturday')],
  }
})




const loadCalendar = onMounted(() => {
    calendar.value = new VanillaCalendar('#calendar-input-6', options.value)
    calendar.value.init()
    //console.log(calendar)
    //console.log(calendar.HTMLElement)
})
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: #242134;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    background-color: var(--bgPrincipal);
    border-radius: 10px;
    height: 50px;
    padding-left: 10px;
    outline: 0;
    position: relative;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    padding-left: 35px;
    &:focus, &:hover{
        border: 2px solid #1679FC;
        
    }
    
    &::placeholder{
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        padding-left: 35px;
    }
}
input[type="date"]::-webkit-calendar-picker-indicator{
    background: url('../../../img/panel-icons/calendar.svg');
    background-size: 100% 100%;
    width: 25px;
    position: absolute;
    left: 5px;
}
.position{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .calendar{
        width: 25px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../../img/panel-icons/calendar.svg') ;
        background-size: 100% 100%;
        position: absolute;
        left: 9px;
        top: 15px;
    }
}
input:focus + i, input:hover + i{
    background-image: url('../../../img/panel-icons/calendar-active.svg') ;
}
</style>