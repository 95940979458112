<template>
    <div class="row justify-content-center row__width mb-4">
        <div class="col-3 d-flex justify-content-center align-items-center img">
            <img src="../../img/roles-icons/estudiante.svg" alt="">
        </div>
        <div class="col-8 d-flex flex-column justify-content-center align-items-start">
            <h3>{{ usuariosRegistro.first_name }}</h3>
            <h4>{{$t('message.planFree')}}</h4>
        </div>
    </div>
</template>

<script setup>
import { useUsersStore } from "../../stores/user";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
const users = useUsersStore();
const {cambioHeader, usuariosRegistro} = storeToRefs(users);
const {} = users;
</script>

<style lang="scss" scoped>
.verificado{
    position: absolute;
    top: 10%;
    right: 0;
}
h3{
    font-weight: 600;
    font-size: 22px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
}
h4{
    font-weight: 400;
    font-size: 16px;
    color: #7D93B2;
    border: 2px solid #E4E4E4;
    border-radius: 12px;
    padding: 0px 10px;
    text-transform: uppercase;
}
</style>