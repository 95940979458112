<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.certificates')"/>
        <div class="col ms-3 mt-3 d-flex justify-content-center align-items-start classSpecial">
            <!--- CON CERTIFICADOS --->
            <div class="row justify-content-center justify-content-lg-start row__width " >
                <div class="col-11 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 contenedor">
                    <div class="row justify-content-center row__width padding">
                        <!-- CERTIFICADO -->
                        <TituloNoCertificadosV2 :amount="listCerts ? listCerts.length : '0'"/>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-start align-items-centera scroll" style="max-width: 100% !important; overflow-y: scroll; max-height: 93vh;" v-if="listCerts">
                            <div class="d-flex justify-content-start align-items-center mt-3 d-none d-lg-flex " style="height: auto !important;" v-for="(cert, index) in listCerts">
                                <div class="col-10 col-md-12 d-flex flex-column justify-content-start align-items-center slide px-0">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-lg-3 d-none d-lg-flex">
                                            <img src="../../../img/certificados-icons/certificado.png" alt="" class="certificado">
                                        </div>
                                        <div class="col-12 col-lg-9 d-flex justify-content-center align-items-center">
                                            <div class="row justify-content-center row__width">
                                                <div class="col-10 col-lg-11 d-flex flex-column justify-content-center align-items-start">
                                                    <h2>{{ cert.product_name[0].name }}</h2>
                                                    <!-- <h3>by: MCP</h3> -->
                                                    <h4>Emitido: {{ formattedDate(cert.created_at) }}</h4>
                                                </div>
                                                <div class="col-2 col-lg-1 d-flex justify-content-center align-items-start">
                                                    <img src="../../../img/certificados-icons/certificado-icon.svg" alt="">
                                                </div>
                                                <div class="col-12">
                                                    <button class="btnPrimario" type="button" @click="downloadFile(cert.url.scheme+'://'+cert.url.host+cert.url.path), btnLoader = true">
                                                        <div class="custom-loader" v-if="btnLoader"></div>
                                                        <div v-else>{{$t('message.downloadCertificate')}}</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-11 d-flex flex-column justify-content-center align-items-center px-0 d-lg-none">
                    <!-- CERTIFICADO -->
                    <div class="d-flex justify-content-start align-items-center mt-3" style="height: auto !important;" v-for="(cert, index) in listCerts">
                        <div class="col-10 col-md-12 d-flex flex-column justify-content-start align-items-center slide px-0">
                            <div class="row justify-content-center row__width">
                                <div class="col-lg-3 d-none d-lg-flex">
                                    <img src="../../../img/certificados-icons/certificado.png" alt="" class="certificado">
                                </div>
                                <div class="col-12 col-lg-9 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width">
                                        <div class="col-10 col-lg-11 d-flex flex-column justify-content-center align-items-start">
                                            <h2>{{ cert.product_name[0].name }}</h2>
                                            <!-- <h3>by: MCP</h3> -->
                                            <h4>Emitido: {{ formattedDate(cert.created_at) }}</h4>
                                        </div>
                                        <div class="col-2 col-lg-1 d-flex justify-content-center align-items-start">
                                            <img src="../../../img/certificados-icons/certificado-icon.svg" alt="">
                                        </div>
                                        <div class="col-12">
                                            <button class="btnPrimario" type="button" @click="downloadFile(cert.url.scheme+'://'+cert.url.host+cert.url.path), btnLoader = true">
                                                <div class="custom-loader" v-if="btnLoader"></div>
                                                <div v-else>{{$t('message.downloadCertificate')}}</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--- SIN CERTIFICADOS --->
            <div class="row justify-content-center justify-content-lg-start row__width d-none">
                <div class="col-11 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center align-items-lg-start px-0 contenedor">
                    <div class="row justify-content-center row__width">
                        <div class="col-12 col-lg-11 d-flex px-0 flex-column justify-content-center align-items-center">
                            <TituloNoCertificados/>
                            <NoCertificados/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCertificados :certificates="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import TituloNoCertificadosV2 from "../../../components/Titulo/TituloNoCertificadosV2.vue";
import NoCertificados from "../../../components/Panel/Certificados/NoCertificados.vue";
import TituloNoCertificados from "../../../components/Titulo/TituloNoCertificados.vue";
// Importo mi método
import { storeToRefs } from "pinia"
// Llamo a mi tienda de Pinia en forma de Setup 
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import { onBeforeMount, ref, provide, watch } from "vue"
import axios from 'axios';
// Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
const users = useUsersStore();
// Mis estados los paso a objetos porque en la tienda están en reactivos //
const {access, idUser} = storeToRefs(users);
const { url } = users;
const {validate} = users;

let listCerts = ref()
let btnLoader = ref(false)


const algo = onBeforeMount( () => {
    access.value = [1, 2, 3, 4, 5]
    //console.log(access.value) 
    validate();
}) 

const fetchCerts = async() => {
    let userId = idUser.value.id
    try {
        await axios.get(url+'/api/user/'+userId+'/certificates').then(result => {
            console.log(result)
            if(Array.isArray(result.data.data)){
                listCerts.value = result.data.data
            } else{
                listCerts.value = null
            }
        })
    } catch (error) {
        console.log(error)
    }
}
fetchCerts()

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

const formattedDate = (vdate)=>{
    if (!vdate) return ''
    const date = new Date(vdate)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

const nameDownloadble = (url) => { 
    return url.substring(url.lastIndexOf('/') + 1); 
} 

const downloadFile = async(fileUrl) => {
    // console.log(fileUrl)
    // Obtener el archivo como un blob
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Crear un enlace temporal y simular un clic para descargar el archivo
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = nameDownloadble(fileUrl); // Establece el nombre del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
        btnLoader.value = false
    }, 500)
}

const actual_page = ref(2)

const changePage = (page) => {
    actual_page.value = page
}

provide('actual_page', actual_page)
provide('changePage', changePage)

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
    @media (min-width: 992px) {
        margin-top: 77px;
    }
}
.contenedor{
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding:20px 0 30px 0;
        min-height: 80vh;
    }
    @media (min-width: 992px) {
        max-width: 95vw;
        margin-top: 80px;
    }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}

.classSpecial{
    width: 70vw !important;
}

.mySwiperCertificados{
    padding: 10px 5px 10px 5px;
    margin: 10px 0;
    @media (min-width:992px) {
        padding: 10px;
    }
}
.slide{
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 15px;
    min-height: 25vh;
    padding: 20px;
    h2{
        color: #052651;
        font-weight: 400;
        font-size: 18px;
        padding-bottom: 5px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h3{
        font-weight: 700;
        color: #052651;
        font-size: 16px;
    }
    h4{
        font-size: 16px;
        font-weight: 400;
        color: #B8C1DB;
        padding: 30px 0;
    }
    @media (min-width:992px) {
        margin-bottom: 20px;
    }
    .certificado{
        height: 100%;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
}    
.btnPrimario {
    background-color: var(--colorPrimary);
    border: 2px solid var(--bgPrincipal);
    color: var(--bgPrincipal);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.scroll{
    &::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 6px;
        height: 4px;
        background-color: #29292b1e;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #29292b1e;
    }
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#FFFFFF 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#FFFFFF);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
</style>
