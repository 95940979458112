<template>
    <div class="col-12 d-flex justify-content-center align-items-center slide px-0">
        <div class="row justify-content-center row__width h-100">
            <div class="col d-flex justify-content-center align-items-center position-relative imagenes pe-0">
                <img :src="image" alt="" class="ejemplo w-100">
                <div class="filtro">
                    <div class="col-12 d-flex justify-content-end align-items-center d-lg-none">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6>{{$t('message.rating')}}</h6>
                    </div>
                </div>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <div class="row justify-content-center row__width descripcion">
                    <div class="col-12 d-flex justify-content-start align-items-center pt-3 pb-2 px-0">
                        <h5>{{ name }}</h5><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="ms-2" v-if="verified == true">
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center pb-2 px-0">
                        <img src="../../img/home-icons/mexico-bandera.svg" alt="" class="me-2">
                        <h3>{{ location }}</h3>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center pb-3 pb-lg-1 px-0">
                        <h4>{{ description }}</h4>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center d-none d-lg-flex px-0 py-2">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt=""><h6>{{$t('message.rating')}}</h6>
                    </div>
                    <BTNPrimario url="/perfil-marca/" :slug="slug" :textoBTNPrimario="$t('message.viewProfile')" class="px-0"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    //Recibe los parametros de busqueda del componente padre
    const props = defineProps({
        rName: '',
        rDescription: '',
        rImage: '',
        rLocation: '',
        rSlug: '',
        rRates: '',
        rVerified: ''
    })

    //Setea las variables con los resultados para su uso
    const name = props.rName
    const description = props.rDescription
    const image = props.rImage
    const location = props.rLocation
    const slug = props.rSlug
    const rates = props.rRates
    const verified = props.rVerified
</script>

<style lang="scss" scoped>
.proveedores{
    .slide{
        height: auto;
        min-height: 22vh;
        @media (min-width: 992px) {
            min-height: 210px;
        }
    }
    h6{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
    }
    h5{
        color: #374557;
        font-size: 22px;
        font-weight: 700;
        text-align: left;
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: var(--colorSecondary);
        text-align: left;
    }
    h4{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        text-align: left;
    }
    .filtro{
        position: absolute;
        top: 13%;
        right: 10px;
    }
    .imagenes{
        width: 158px;
        max-width: 158px;
        img{
            box-shadow: 0px 3px 3px #00000014;
            border-radius: 8px;
        }
    }
    @media (min-width: 992px) {
        min-height: 210px;
        .max-width{
            max-width: 35%;
        }
        .ejemplo{
            height: 85%;
        }
    }
    @media (max-width: 375px) {
        h5{
            font-size: 18px;
        }
        h3{
            font-size: 14px;
        }
        h4{
            font-size: 12px;
        }
    }
}
</style>