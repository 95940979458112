<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.uploadProducts')"/>
        <!-- <NavLateralPanel :upload="true" v-if="idUser.team == 2 || idUser.team == 3 || idUser.team == 4"/>
        <NavLateralPanelAdmin :upload="true" v-else-if="idUser.team == 1"/> -->
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start classSpecial">
            <!--- INDEX --->
            <div class="row justify-content-center justify-content-lg-start row__width pt-3 pt-lg-0">
                <div class="col-12 col-lg d-flex flex-column justify-content-center justify-content-lg-start align-items-center contenedor">
                    <div class="row justify-content-center row__width padding h-100">
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center">
                            <TituloSubirProductos/>
                        </div>
                        <div class="col-12 col-lg-11 px-0 d-flex flex-column justify-content-center align-items-center mt-lg-5">
                            <AgregarApartadosPerfil/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCertificados :upload="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../components/Footer/FooterCertificados.vue";
import NavLateralPanel from "../../../components/Navs/NavLateralPanel.vue";
import NavLateralPanelAdmin from "../../../components/Navs/NavLateralPanelAdmin.vue";
import atrasBTNCertificados from "../../../components/Botones/Atras.vue";
import TituloSubirProductos from "../../../components/Titulo/TituloSubirProductos.vue";
import AgregarApartadosPerfil from "../../../components/Panel/Perfil/AgregarApartadosPerfil.vue";
import { onBeforeMount, ref, provide, watch } from "vue"

import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../stores/user"
import { useLanguageStore } from "../../../stores/langs"
import axios from 'axios';

const users = useUsersStore();
const { access, idUser } = storeToRefs(users);
const { url, validate } = users;

//Tipos de producto
const urlFetchTypes = '/api/categories/classes/linked/'

const listTypes = ref([])

provide('listTypes', listTypes)

const algo = onBeforeMount( () => {
    access.value = [1, 4]
    //console.log(access.value)
    validate();
}) 

const isSidebarOpen = ref(true)
provide(isSidebarOpen, 'isSidebarOpen')

const toggleSidebar = () => {
    isSidebarOpen.value = !isSidebarOpen.value
    if(document.getElementById('sidebar').classList.contains('d-none')){
        document.getElementById('sidebar').classList.remove('d-none')
    } else{
        document.getElementById('sidebar').classList.add('d-none')
    }
    if(document.getElementById('div-grey').classList.contains('right')){
        document.getElementById('div-grey').classList.remove('right')
        document.getElementById('div-grey').classList.add('left')
    } else{
        document.getElementById('div-grey').classList.remove('left')
        document.getElementById('div-grey').classList.add('right')
    }
}

//Recibe los tipos de productos existentes
const fetchTypes = async() => {
    try {
        await axios.get(url+urlFetchTypes+'ES').then((result) => {
            console.log(result)
            listTypes.value = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    listTypes.value.push({
                        name: result.data[i].name,
                        description: result.data[i].description,
                        icon: result.data[i].icon,
                        id: result.data[i].id,
                        modalities: result.data[i].types
                    })
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}
fetchTypes()

const actual_page = ref(0)

const changePage = (page) => {
    actual_page.value = page
}

provide('actual_page', actual_page)
provide('changePage', changePage)
</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 100vh;
}
.contenedor{
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 0 30px 0;
        min-height: auto;
       
    }
}
.border-bottom2{
    @media (min-width:992px) {
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 20px;
    }
}

.classSpecial{
    @media (min-width: 992px) {
        max-width: 95vw;
        padding-top: 80px;
    }
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
   padding-right: 0px !important;
}

.proceso{
    padding: 20px 0;
    border-bottom: 2px solid #E4E4E4;
    margin-bottom:30px;
    h3{
        font-weight: 600;
        color: #B8C1DB;
        font-size: 18px;
        text-align: left;
    }
    h4{
        font-weight: 400;
        color: #B8C1DB;
        font-size: 16px;
        text-align: left;
    }
    .borde-right{
        border-right: 2px solid #E4E4E4;
    }
    .opacity{
        h3, h4{
            opacity: 0.5;
        }
    }
    span{
        background-color: #B8C1DB;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active{
        span{
            background-color: var(--colorPrimary);
        }
        h3, h4{
            color: var(--colorSecondary);
        }
    }
}

.border-top{
    border-top: 2px solid #E4E4E4;
    padding-top: 20px;
}

.tablas{  
    background-color: transparent;
    min-height: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid #70707023;
    box-shadow: 0px 3px 6px #00000016;
    border-radius: 10px;
    ul{
        background-color: transparent;
        padding: 8px 0;
    }
    .nav-item{
        padding: 0;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        h3{
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
        }
        @media (min-width: 992px) {
            color: #0E1133;
            h3{
                color: #0E1133;
            }
        }
    }
    .nav-link.active{
        font-weight: 700;
        border-color: transparent;
        color: var(--colorPrimary);
        background-color: transparent;
        border-radius: 0;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: -10px;
            left: 0;
            background-color: var(--colorPrimary);
            border-radius: 10px;
        }
    }
    .scroll{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        ul{
            min-width: 150vw;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
    
}

.contenido{
    .div-gris{
        background-color: #F5F5F5;
        height: auto;
        padding: 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #0000001f;
    }
    h3{
        font-weight: 600;
        color: var(--colorSecondary);
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px !important;
    }
    
    input[type=text], select{
        background-color: #FCFCFC;
        font-weight: 400;
        color: #697891;
        font-size: 16px;
        border-radius: 10px;
        border: 2px solid #E9E3E3;
        height: 50px;
        -webkit-appearance: none;
    }
    .agregar-idioma{
        .col{
            max-width: 40px;
            margin-right: 15px;
        }
        button{
            background-color: var(--bgPrincipal);
            border: 0;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: -7px;
            top: -5px;
        }
    }
    

    .collapses{
        h3{
            margin-bottom: 5px !important;
        }
        h4{
            font-weight: 400;
            font-size: 15px;
            color: #B8BCC8;
            text-align: left;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                margin: 15px 0px;
                border: 0;
                border-radius: 10px;
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                background-color: #F9F9F9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                .accordion-button{
                    border-radius: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: #F9F9F9;
                    &:focus{
                        border-color: inherit;
                        box-shadow: none;
                    }
                    span{
                        border: 2px solid #E9E3E3;
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 5%;
                    background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                    background-size: 100%;
                    background-position: center;
                }
            }
        }
        @media (min-width: 992px) {
            margin: 20px 0 60px 0;
        }
    }
    .bg-gris{
        background-color: #F5F5F5;
        padding: 30px 20px 20px 20px;
        border-radius: 10px;
        h4{
            font-weight: 600;
            color: #343232;
            font-size: 20px;
            text-align: center;
            margin-bottom: 10px !important;
        }
        h5{
            font-weight: 400;
            color: #BEBDBD;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px !important;
            max-width: 54%;
            line-height: 27px;
        }
    }
}
.agregar-cursos-btn, .agregar-cursos-btn-3{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}
.agregar-cursos-btn-3{
    display: flex;
    justify-content: space-between;
    span{
        margin: 0;
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            color: #2E4765;
            font-size: 14px;
            font-weight: 300;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        max-width: 55%;
    }
}
</style>