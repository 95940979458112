<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <div class="row justify-content-center px-lg-4 row__width">
            <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                <div class="row justify-content-start row__width settings">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                        <h2>{{ $t('message.settingCount') }}</h2>
                        <h5>{{ $t('message.approvedCourse') }}</h5>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.giveCertificate') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="certificate" @change="configCertif()" v-model="certificate.sendCertificate">
                            <label class="form-check-label" for="certificate">
                                <p v-if="certificate.sendCertificate">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.minimumGrade') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="qualification" v-model="certificate.requiredGrade" @change="configCertif()">
                            <label class="form-check-label" for="qualification">
                                <p v-if="certificate.requiredGrade">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-start px-0 margin " v-if="certificate.requiredGrade">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center ofertas">
                                <div class="row row__width justify-content-between">
                                    <div class="col-5 col-lg-2 d-flex flex-column justify-content-around align-items-start pe-0">
                                        <h4 class="mb-2">{{ $t('message.percent') }}</h4>
                                        <input type="number" v-model="certificate.minimumGrade" @input="validateNumber(certificate.minimumGrade)" @focusout="configCertif()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.certificateProjectfinal') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="project" @change="createFP(certificate.hasFinalProject)" v-model="certificate.hasFinalProject">
                            <label class="form-check-label" for="project">
                                <p v-if="certificate.hasFinalProject">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    {{ finalProject }}
                    <div class="col-12 d-flex justify-content-center align-items-start px-0 margin " v-if="certificate.hasFinalProject">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center collapses collapses2">
                                <div class="row row__width justify-content-center">
                                    <div class="col-12 d-flex justify-content-center align-items-center">
                                        <div class="row row__width justify-content-center">
                                            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                                <h2>{{ $t('message.finalProject') }}</h2>
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                <h3>Título del proyecto</h3>
                                                <input type="text" v-model="finalProject.title" @focusout="createFP()">
                                            </div>
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-3">
                                                <div class="row justify-content-center row__width width">
                                                    <div class="col-12 d-flex justify-content-between align-items-center px-0">
                                                        <h3>{{ $t('message.description') }}</h3>
                                                    </div>
                                                    <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0">
                                                        <span class="d-flex justify-content-between align-items-center">
                                                            <div class="d-flex justify-content-start align-items-center">
                                                                <h6>B</h6>
                                                            </div>
                                                            <p v-if="finalProject.description">{{ finalProject.description.length }}/400</p>
                                                        </span>
                                                        <textarea name="" id="" cols="30" rows="10" maxlength="400" v-model="finalProject.description" @focusout="createFP()"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center align-items-center px-0  mt-3">
                                                <div class="row row__width justify-content-center">
                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                        <h3>{{ $t('message.studentGuide') }}</h3>
                                                        <h4>{{ $t('message.saveAGiude') }}</h4>
                                                    </div>
                                                    <div class="col-12 d-flex flex-column justify-content-center align-items-start upload">
                                                        <h4 class="mt-3" v-if="finalProject.guide">{{ finalProject.guide.split('/').pop() }}</h4>
                                                        <label for="file-guide">
                                                            <img src="../../../img/subir-productos-icons/cursos-icons/upload.svg" alt="">
                                                            {{ $t('message.uploadAFile') }}
                                                        </label>
                                                        <input type="file" ref="inputFile" name="upload-file-guide" id="file-guide" @change="uploadGuide()">
                                                        <h4 class="mt-3">{{ filename }}</h4>
                                                    </div>
                                                    <div class="col-12">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const urlCreateFP = '/api/final/create'
const urlUploadGuide = '/api/final/upload/'
const urlAddPFinal = '/api/certificate/add/pf/' // cId - fpId
const urlConfigCertif = '/api/certificate/create/'

const filename = ref()

let certificate = inject('certificate')
let finalProject = inject('finalProject')
let product = inject('product')
let listNotes = inject('listNotes')
let inputFile = ref(null)

let owner_id = inject('owner_id')
let product_id = inject('product_id')

provide('inputFile', inputFile)

certificate.value.minimumGrade = listNotes.value.certValue

const uploadGuide = async() => {
    let idData = JSON.parse(localStorage.getItem('idData'))
    const formData = new FormData()
    formData.append('guide', inputFile.value.files[0])
    formData.append('uId', idData.id)
    formData.append('pId', product.value.id)
    let id = finalProject.value.fProjId
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    try {
        await axios.post(url+urlUploadGuide+id, formData, {headers: headers.value}).then((result) => {
            console.log(result)
            let url = result.data.guide
            let parts = url.split("/")
            filename.value = parts[parts.length - 1]
            console.log(filename.value)
            createFP()
        })
    } catch (error) {
        console.log(error)
    }
}

// Crea el proyecto final
const createFP = async() => {
    if(certificate.value.hasFinalProject){
        const token = JSON.parse(localStorage.getItem('token'))
        const headers = ref({'Authorization': `Bearer ${token}`})
        try {
            console.log(finalProject.value)
            await axios.post(url+urlCreateFP, finalProject.value, {headers: headers.value}).then((result) => {
                console.log(result)
                finalProject.value.fProjId = result.data.id
                certificate.value.fProjId = result.data.id
                finalProject.value.title = result.data.title
                finalProject.value.id = product.value.id
                finalProject.value.description = result.data.description
                if(finalProject.value.description == null){
                    finalProject.value.description = ''
                }
                configCertif()
            })
        } catch (error) {
            console.log(error)

        }
    }
}

// Agrega el proyecto final al certificado
const addPfinal = async () => {
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    let fProjId = 0
    certificate.value.uId = idData.id
    if(finalProject.value.fProjId){
        fProjId = finalProject.value.fProjId
    }
    try {
        await axios.post(url + urlAddPFinal + certificate.value.certId + '/' + fProjId, certificate.value, { headers: headers.value }).then((result) => {
            console.log(result)
            certificate.value.certId = result.data.certId
            certificate.value.fProjId = result.data.fProjId
        })
    } catch (error) {
        console.error(error)
    }
}

//Crea el producto luego de elegir tipo de producto y modalidad
const configCertif = async () => {
    console.log(certificate.value)
    const idData = JSON.parse(localStorage.getItem('idData'))
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({ 'Authorization': `Bearer ${token}` })
    let id = product.value.id
    certificate.value.uId = idData.id
    certificate.value.owner_id = owner_id.value
    certificate.value.product_id = product_id.value
    try {
        await axios.post(url + urlConfigCertif + id, certificate.value, { headers: headers.value }).then((result) => {
            console.log(result)
            certificate.value.certId = result.data.certId
            console.log(certificate.value)
            addPfinal(certificate.value.certId, 0)
        })
    } catch (error) {
        console.error(error)
    }
}

const validateNumber = (newValue) => {
    if (newValue < listNotes.value.certValue) {
        certificate.value.minimumGrade = listNotes.value.certValue
    } else if (newValue > 100) {
        certificate.value.minimumGrade = 100
    }
}

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
.collapses, .collapses2{
    background-color: #F5F5F5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    margin: 10px 0;
    padding: 30px 10px;
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
}
.ofertas, .ofertas2{
    background-color: #F5F5F5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    p{
        color: #697891;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
    .guardar{
        button{
            border: 2px solid var(--colorPrimary);
            background-color: var(--bgPrincipal);
            border-radius: 25px;
            height: 50px;
            font-weight: 600;
            font-size: 16px;
            color: var(--colorPrimary);
            width: 100%;
        }
    }
    input,select{
        width:100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        -webkit-appearance: none;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}

.collapses2{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        padding: 0px 0px 10px 0 !important;
    }
    h4{
        color: #BEBDBD;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        padding: 0px 0px 10px 0 !important;
    }
    p{
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
    }
    button{
        border: 2px solid var(--colorPrimary);
        background-color: var(--bgPrincipal);
        border-radius: 25px;
        height: 50px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorPrimary);
    }
    .width{
        width: -webkit-fill-available;
    }
    textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 20vh;
        padding-top: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            
            color: #697891;
        }
        
    }
    span{
        background-color: #E4E4E4;
        height: 42px;
        width: 100%;
        position: absolute;
        max-width: 99.5%;
        top: 1.5px;
        border-radius: 8px 8px 0 0 ;
        padding: 0px 15px;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding-left: 5px;
        }
        img{
            height: 24px;
        }
    }
    .inputs{
        margin: 10px 0px !important;
    }
    input{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: #FCFCFC;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
}
.upload{
    input{
        display: none;
    }
    label{
        background-color: var(--bgPrincipal);
        border-radius: 24px;
        height: 50px;
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-weight: 600;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        cursor: pointer;
        img{
            padding-right: 10px;
        }
    }
}
.settings{
    h2{
        font-size: 22px;
        color: var(--colorSecondary);
        font-weight: 600;
        padding-bottom: 10px;
    }
    h5{
        font-size: 18px;
        color: var(--colorSecondary);
        font-weight: 500;
        padding-bottom: 30px;
        text-align: left;
    }
    .switch{
        .form-switch .form-check-input{
            width: 48px;
            height: 23px;
            background-color: #E4E4E4;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
            border: 0;
            background-position-x: 2px;
        }
        .form-switch .form-check-input:focus{
            outline: 0;
            box-shadow: none;
            border: 0;
            background-image: url('../../../img/panel-icons/check-icon.svg');
            background-size: 40%;
        }
        .form-switch .form-check-input:checked{
            background-color: var(--colorPrimary);
            background-position-x: 27px;
        }
        p{
            font-weight: 500;
            padding-left: 10px;
            width: 25px;
        }
        .sin-padding{
            padding: 0;
            width: auto;
            font-size: 16px;
            font-weight: 400;
        }
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
    
    }
    .col-perso{
        @media (min-width: 992px) {
            min-width: 55% !important;
            max-width: 55%;
        }
    }
}
.margin{
    margin: 10px 0 30px 0;
}
</style>