<template>
    <div class="col d-flex justify-content-center align-items-center input_contenedor">
        <img src="../../img/home-icons/cursos-icons/descuento-icon.svg" alt="">
        <input type="text" :placeholder="$t('message.discountCode')">
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.input_contenedor{
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    height: 50px;
    padding: 10px 0;
    margin-right: 10px;
    img{
        padding: 0 5px;
    }
    input{
        border: 0;
        &:focus{
            outline: 0;
        }
    }
}
</style>