<template>
    <div class="row justify-content-center row__width">
        <div class="col-3 d-flex justify-content-center align-items-center position-relative">
            <img src="../../img/inicio-icons/foto.svg" alt="">
            <img src="../../img/inicio-icons/verificado.svg" alt="" class="verificado">
        </div>
        <div class="col-8 d-flex flex-column justify-content-center align-items-start">
            <h3>Carla Cooper</h3>
            <h4>Ingreso anteriormente con</h4>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.verificado{
    position: absolute;
    top: 10%;
    right: 0;
}
h3{
    font-weight: 600;
    font-size: 22px;
    color: var(--colorSecondary);
    padding-bottom: 5px;
}
h4{
    font-weight: 400;
    font-size: 16px;
    color: #7D93B2;
}
</style>