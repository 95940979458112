<template>
    <div class="row justify-content-start mb-3 px-3 inputs">
        <div class="col-11 d-flex justify-content-start align-items-start recordar px-0" :class="estados.terms">
            <div class="d-flex justify-content-center align-items-center mt-1">
                <input type="checkbox" :placeholder="$t('message.password')" name="terms" id="recordar" v-model="usuarioRegistroInfoExtra.check">
                <label for="recordar" class="d-flex justify-content-start align-items-center"></label>
            </div>
            <p>{{acepto}}<a href="">{{terminos}}</a></p>
        </div>
    </div>
</template>
<script setup>
    // Importo mi método
    import { storeToRefs } from "pinia";
    // Llamo a mi tienda de Pinia en forma de Setup 
    import { useUsersStore } from "../../stores/user";
    import { inject, ref } from 'vue';

    const props = defineProps({
        acepto: String,
        terminos : String
    })

    const estados = inject('estados')
    // Desestructuro mis returns (es para achicar código, en vez de llamar useUsersStore.usuarios.email se asume que ya sale de ahí) //
    const users = useUsersStore();
    // Mis estados los paso a objetos porque en la tienda están en reactivos //
    const {usuarioRegistroInfoExtra} = storeToRefs(users);
    const {} = users;
</script>

<style lang="scss" scoped>
.recordar{
    padding-top: 10px;
    a{
        color: #1679FC;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        text-align: justify;
        padding-left: 5px;
        text-decoration: underline;
    }
    input{
        display: none;
    }
    label{
        height: 15px;
        width: 15px;
        border: 1px solid #697891;
        border-radius: 5px;
        position: relative;
        
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: #697891;
        text-align: left;
        padding-left: 10px;
        margin-bottom: 10px !important;
    }
    input:checked + label::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #1679FC;
        background-image: url('../../img/inicio-icons/tick.svg');
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
    }
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 7px 0px;
        margin-right: 10px;
        margin-top: 0;
    }
}
</style>