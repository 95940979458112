<template>
    <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-0 mt-2">
                <div class="row justify-content-start row__width">
                    <!--- AFILIADOS --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0">
                        <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample">
                            <div class="accordion-item row row__width justify-content-center">
                                <h2 class="accordion-header mb-0 px-0" id="heading">
                                    <p class="accordion-button py-0 px-0 collapsed ps-lg-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
                                        <div class="col-9 col-lg d-flex justify-content-start align-items-center ps-1 pe-0 ">
                                            <div class="row row__width justify-content-center justify-content-lg-start pt-3 pt-lg-0">
                                                <div class="col-4 col-lg-12 d-flex justify-content-center align-items-center img">
                                                    <img src="../../../../img/perfil-icons/img-ejemplo.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col col-lg-10 d-flex justify-content-center align-items-center px-0 seccion d-none d-lg-flex">
                                            <div class="row row__width justify-content-start align-items-center">
                                                <div class="col col-lg-6 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.name') }}</h5>
                                                    <h6>Carlos</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.referredFrom') }}</h5>
                                                    <h6>14/5/2020</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.sales') }}</h5>
                                                    <h6 class="text-bold">$ 400</h6>
                                                </div>
                                                <div class="col d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.status') }}</h5>
                                                    <span class="inactive">INACTIVO</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center eliminar">
                                            <button class="">
                                                <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                            </button>
                                        </div>
                                    </p>
                                </h2>
                                <div id="collapse" class="col-12 accordion-collapse collapse d-lg-none" aria-labelledby="heading" data-bs-parent="#accordionExample">
                                    <div class="accordion-body row justify-content-end py-0 px-0">
                                        <div class="col-6 d-flex flex-column justify-content-center align-items-start ps-1 excepcion">
                                            <div class="row row__width justify-content-center">
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0 excepcion">
                                                    <h5>{{ $t('message.applicationDate') }}</h5>
                                                    <h6>14/5/2020</h6>
                                                </div>
                                                <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                                                    <h5>{{ $t('message.commission') }}</h5>
                                                    <h6 class="text-bold">$ 400</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg- d-flex flex-column justify-content-center align-items-start px-0 py-3 excepcion d-none d-lg-flex">
                                                <h5>{{ $t('message.status') }}</h5>
                                                <span class="inactive">INACTIVO</span>
                                            </div>
                                        </div>
                                        <div class="col-3 d-flex justify-content-center align-items-center eliminar">
                                            <button class="">
                                                <img src="../../../../img/subir-productos-icons/cursos-icons/editar.svg" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-end row__width px-0 d-lg-none">
                                    <div class="col-6 d-flex flex-column justify-content-center align-items-start px-0 py-3 excepcion">
                                        <h5>{{ $t('message.status') }}</h5>
                                        <span class="inactive">INACTIVO</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, onUnmounted } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../../stores/user";
import { useLanguageStore } from "../../../../stores/langs"

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const users = useUsersStore()
const {datosUsuario, professionalData, headers} = storeToRefs(users)
const {url, validarMail, usuarioData, userProfessionalData} = users

const showBanner = () => {
    // Analizar ancho de pantalla
    if(window.innerWidth >= 992) {
        document.querySelectorAll('.accordion-button').addEventListener('click', function(e) {
            e.stopPropagation();
        });
    }
}
onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})

</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}
.collapses{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        padding: 7px 0px;
    }
    h6{
        color:#B8BCC8;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        padding: 5px 0px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
            border-radius: 10px;
            background-color: #F9F9F9;
            margin-bottom: 15px;
            
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .accordion-header{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            .accordion-button{
                border-radius: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: #F9F9F9;
                &:focus{
                    border-color: inherit;
                    box-shadow: none;
                }
                span{
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 5px 10px;
                    min-width: 110px;
                }
                .inactive{
                    background-color: #F5F5F5;
                    color: #707070;
                }
                .active{
                    background-color: #38E6A7;
                    color: #38E6A7;
                }
                .pause{
                    color: #FEB230;
                    background-color: #FEB230;
                }
                h3{
                    text-align: center;
                    font-size: 22px;
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                @media (min-width: 992px) {
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    position: relative;
                    height: 100px;
                }
            }
            .accordion-button:not(.collapsed){
                background-color: inherit;
                box-shadow: none;
                @media (min-width: 992px) {
                    background-color: var(--bgPrincipal);
                    box-shadow: inherit;
                }
            }
            .accordion-button::after{
                position: absolute;
                right: 5%;
                background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                background-size: 50%;
                background-position: center;
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                @media (min-width: 992px) {
                    display: none;
                }
            }
            .accordion-body{
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
            .buttons{
                position: absolute;
                right: 17%;
                @media (min-width: 992px) {
                    right: 7%;
                }
            }
            
        }
        h5{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding-bottom: 10px;
        }
        h6{
            color: #B8C1DB;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            height: 30px;
        }
        .text-bold{
            font-weight: 600;
        }
        .eliminar{
            button{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 0;
            }
        }
        .img{
            img{
                height: 58px;
                width: 58px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .seccion{
            .col{
                max-width: 13%;
            }
            .excepcion{
                max-width: 20%;
            }
        }
    }
    .padding{
        padding: 0 11%;
    }
    
}

</style>