<template>
    <div class="row separador row__width tipo__reunion">
        <div class="col-12 d-flex justify-content-center align-items-center px-0">
            <input type="checkbox" id="hola">
            <label for="hola" class="row">
                <div class="col-6 d-flex justify-content-center align-items-center">
                    <p class="left">{{ $t('message.myCourses') }}</p>
                </div>
                <div class="col-6 d-flex justify-content-center align-items-center px-0">
                    <p class="right">Cursos Inscriptos</p>
                </div>
            </label>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.tipo__reunion{
    label{
        height: 50px;
        width: 100%;
        box-shadow: 0px 0px 6px #00000029;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 20px 0px;
        cursor: pointer;
        &::before{
            content: '';
            position: absolute;
            border: 2px solid var(--colorPrimary);
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--colorPrimary);
            border-radius:  28px;
            left: 1%;
            transition: all 0.5s;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: var(--bgPrincipal);
            z-index: 1;
            transition: all 0.5s;
            &:first-child{
                margin-right: 0%;
            }
            &:last-child{
                margin-left: 0%;
            }
        }
        .left{
            color: var(--bgPrincipal);
            font-weight: 600;
        }
        .right{
            color: var(--colorSecondary);
        }
    }
    input{
        display: none;
    }
    input:checked + label{
        &::before{
            left: 49%;
            width: 50%;
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 600;
            
        }
        .left{
            color: var(--colorSecondary);
            font-weight: 500;
        }
    }
    @media (min-width: 992px) {
        label{
            .right{
                color: var(--colorSecondary);
            }
        }
        input:checked + label{
            .left{
                color: var(--colorSecondary);
            }
        }
    }
}
</style>