<template>
    <div class="container-fluid">
        <NavbarBuscadorBack/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="$t('message.HomePresets')"/>
        <div class="row justify-content-center">
            <div class="col-11 d-flex justify-content-center align-items-center px-0 ">
                <div class="row justify-content-center row__width mt-lg-5">
                    <div class="col-12 col-lg d-flex justify-content-center align-items-center align-items-lg-start px-0 max-width">
                        <div class="row justify-content-center slide row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center row__width">
                                    <div class="col-4 d-flex justify-content-center align-items-center px-0 position-relative">
                                        <div class="foto"><img :src="profesionalData.pic" alt=""></div>
                                        <img src="../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="verificado">
                                    </div>
                                    <div class="col-8 d-flex flex-column justify-content-center align-items-start">
                                        <h2>{{ profesionalData.name }}</h2>
                                        <h3> <img src="../img/home-icons/ubi-azul.svg" alt=""> {{ profesionalData.location.city }}</h3>
                                        <div class="span"><p>150h</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                <h4>{{ $t('message.about') }} {{ profesionalData.name }}</h4>
                                <h5>Asesoría en marketing, negocio y alianzas comerciales.</h5>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                <h4>{{ $t('message.skills') }}</h4>
                                <h5>Experto en Marketing Digital, redes sociales, emprendimiento, negocios digitales y 
                                    especialista en asesoría uno a uno en negocios en línea.</h5>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                <h4>{{ $t('message.schedules') }}</h4>
                                <div class="row justify-content-center row__width before__texto" id="filtroVerMas">
                                    <div class="col-12 d-flex justify-content-start align-items-center desaparecer" :class="horarios[id].active == false ? 'd-none' : 'd-flex'" v-for="(horario , id) in horarios">
                                        <img src="../img/home-icons/cursos-icons/time.svg" alt=""> <h6>{{horarios[id].titulo}}</h6>
                                    </div>
                                </div>
                                <BTNVerMas :funcionTextoVerMas="verMasHorarios" :img="iconoImg"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg d-flex justify-content-center align-items-center align-items-lg-start px-0 margin" style="color: #000;">
                        <div class="row justify-content-center row__width tabs__reseservar">
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                                <ul class="nav nav-tabs row justify-content-start row__width" id="myTab" role="tablist">
                                    <li class="nav-item col-6 col-lg-4 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{{ $t('message.bookYourAdvice') }}</button>
                                    </li>
                                    <li class="col-lg-2 d-none d-lg-flex"></li>
                                    <li class="nav-item col-6 col-lg-4 d-flex justify-content-center align-items-center" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{ $t('message.contactV2') }}</button>
                                    </li>
                                </ul>
                                <div class="tab-content row justify-content-center row__width" id="myTabContent">
                                    <div class="tab-pane fade show active col-12 mt-4 px-lg-0" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <form action="post" @submit.prevent="postReservation">
                                            <TipoReunionAsesor/>
                                            <ReservaSeccionAsesores/>
                                            <InputsCompletarAsesor/>
                                            <FechaYHoraAsesor/>
                                        </form>
                                    </div>
                                    <div class="tab-pane fade col-12 contactar" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div class="row justify-content-center mt-4">
                                            <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                                                <h2>{{ $t('message.contactConsult') }}</h2>
                                                <h3>{{ $t('message.contactSpeaker') }}</h3>
                                                <h4>{{ $t('message.detailTo') }} {{  }} {{ $t('message.questionsFor') }}</h4>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center align-items-center">
                                                <textarea name="" id="" cols="30" rows="10" :placeholder="$t('message.textHere')"></textarea>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center align-items-center mt-4">
                                                <div class="row row__width justify-content-start">
                                                    <div class="col-4 d-flex justify-content-center align-items-center">
                                                        <Primario :textoBTNPrimario="$t('message.sendMessage')"/>
                                                    </div>
                                                    <div class="col-6 d-flex justify-content-center align-items-center">
                                                        <ContactarWpp/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="espacio2"></div>
        <FooterHome/>
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import Footer from "../components/Footer/Footer.vue";
import FooterHome from "../components/Footer/FooterHome.vue";
import NavbarBuscadorBack from "../components/Navs/NavbarBuscadorBack.vue";
import BTNVerMas from "../components/Botones/VerMas.vue";
import HeaderTitulo from "../components/Titulo/HeaderTitulo.vue";
import ReservaSeccionAsesores from "../components/Asesorias/ReservaSeccionAsesores.vue";
import InputsCompletarAsesor from "../components/Inputs/CompletarAsesor.vue";
import TipoReunionAsesor from "../components/Tipos/TipoReunionAsesor.vue";
import FechaYHoraAsesor from "../components/Asesorias/FechaYHoraAsesor.vue";
import Primario from "../components/Botones/Primario.vue";
import ContactarWpp from "../components/Botones/ContactarWpp.vue";
import { useUsersStore } from "../stores/user";
import { storeToRefs } from "pinia";
import { ref, onMounted, computed, onUpdated, watchEffect, watch, onBeforeMount, provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import axios from 'axios';


const iconoImg = ref(true)

const horarios = ref([
    {titulo:'Lun 9.00 am - 6.00 pm', active: true},
    {titulo:'Lun 9.00 am - 6.00 pm', active: false},
    {titulo:'Lun 9.00 am - 6.00 pm', active: false},
])

const users = useUsersStore();
const {headers} = storeToRefs(users);

const router = useRouter();
const route = useRoute();

const idProfessional = ref('')
const url = process.env.VUE_APP_BASE_URL
const urlApi = '/api/professionals/'
const urlReservation = '/api/professionals/add/'
const slug = route.params.id
const slugRef = ref('')
const {locale} = useI18n({ useScope: 'global' })
const lang = ref('')

const information = ref({
    booking: '',
    phone:{},
    client_id: '',
    professional_id:'',
    date: null,
})
provide("information", information)

const profesionalData = ref({
    location:{
        city: ''
    }
})
//-------------------------//
const verMasHorarios = () => {
    let exists = document.getElementsByClassName('desaparecer')
    if (exists){
        for (var i = 0; i < 3; i++) {
            if (exists[i].classList.contains('d-none')) {
                exists[i].classList.toggle('d-none')
                exists[i].classList.add('toggled')
            }else if (exists[i].classList.contains('toggled')){
                exists[i].classList.toggle('toggled')
                exists[i].classList.add('d-none')
            }
        }
    }
    if(iconoImg.value == false){
        iconoImg.value =true
    }else{
        iconoImg.value =false
    }
}
//Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
const setLang = () => {
    lang.value = locale.value
    //console.log(lang.value)
}
setLang()
//Cuando cambio de lang se vuelve a buscar la información necesaria en ese lang
watch(locale, async () => {
    //console.log('Me ejecuto')
    setLang()
    try {
        slugRef.value = product.value.slug[lang.value]
        //console.log(route.params.id)
        await axios.get(url+urlApi+lang.value+`/`+slugRef.value, ).then((result) => {
            profesionalData.value = result.data
            router.push({path: `/reservar-asesor/${slugRef.value}`})
            //console.log(result)
        })
    }
    catch (error) {
    console.log(error)
    }
})
//Hago un get del profesor
const fetchSearch = async () => {
    try {
        await axios.get(url+urlApi+lang.value+`/`+slug, ).then((result) => {
            idProfessional.value = result.data.id
            profesionalData.value = result.data
            console.log(result, idProfessional.value)
        })
    }
    catch (error) {
        console.log(error)
    }
}
fetchSearch()
const postReservation = async ()=>{
    const id = JSON.parse(localStorage.getItem('idUser'))
    information.value.client_id = id.id
    information.value.professional_id = idProfessional.value
    try {
        await axios.post(url+urlReservation+idProfessional.value+'/reservation', information.value,{headers: headers.value} ).then((result) => {
            console.log(result)
            console.log(information.value)
        })
    }
    catch (error) {
        console.log(error)
        console.log(information.value)
    }
}
</script>



<style lang="scss" scoped>
.slide{
    margin: 10px 0px;
    padding: 20px 10px;
    h2{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        color: var(--colorSecondary);
        padding: 5px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            margin-right: 5px;
        }
    }
    .span{
        border: 2px solid var(--colorPrimary);
        background-color: var(--colorPrimary);
        border-radius: 30px;
        height: 35px;
        width: 90px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            color: #FAFAFA;
            font-weight: 600;
            font-size: 18px;
            text-align: center;
        }
    }
    .verificado{
        position: absolute;
        top: 10px;
        right: 5px;
        height: 26px;
    }
    .foto{
        border-radius: 50%;
        border: 4px solid var(--colorPrimary);
        height: 106px;
        width: 106px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 95%;
            height: 95%;
            border-radius: 50%;
        }
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        color: #374557;
        padding: 20px 0px 7px 0px;
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: #697891;
        line-height: 25px;
    }
    @media (min-width: 992px) {
        h5, h4{
            text-align: left;
        }
    }
    .before__texto{
        .col-12{
            padding-bottom: 15px;
            &:first-child{
                padding-top: 10px;
            }
            &:last-child{
                padding-bottom: 0;
            }
            img{
                margin-right: 5px;
            }
            h6{
                font-size: 16px;
                font-weight: 600;
                color: var(--colorSecondary);
            }
        }
    }
}
.tabs__reseservar{
    margin-top: 30px;
    ul{
        border: 0;
        @media (min-width: 992px) {
            border-bottom:1px solid rgba($color: #000000, $alpha: 0.25) ;
        }
        li{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            border: 0;
            font-weight: 600;
            font-size: 22px;
            color: var(--colorSecondary);
            padding: 15px 0;
            position: relative;
            @media (min-width: 992px) {
                text-align: left;
                font-size: 25px;
            }
        }
        .nav-link.active{
            font-weight: 700;
            &:before{
                content: '';
                position: absolute;
                background-color: var(--colorPrimary);
                width: 100%;
                height: 5px;
                border-radius: 8px;
                bottom: 0;
                left: 0;
            }
        }
    }
}
.contactar{
    h2{
        font-weight: 700;
        font-size: 32px;
        color: var(--colorSecondary);
    }
    h3{
        font-weight: 600;
        font-size: 20px;
        color: var(--colorSecondary);
        padding: 10px 0;
    }
    h4{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding: 10px 0;
    }
    textarea{
        border: 1px solid #7070700a;
        background-color: #f5f5f55d;
        box-shadow: 0px 3px 6px #00000016;
        color: #697891;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 10px 10px 15px;
        width: 100%;
        max-height: 90px;
        -webkit-appearance: none;
        border-radius: 10px;
        margin: 20px 0;
        outline: 0;
        &:focus{
            outline: 0;
        }
    }
}
@media (min-width: 992px) {
    .max-width{
        max-width: 28%;
    }
    .margin{
        margin-left: 5%;
        padding: 40px;
        padding-top: 0;
    }
}

</style>