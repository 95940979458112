<template>
    <div class="col-11 d-flex flex-column justify-content-center align-items-center no-eres">
        <h4>¿No eres Carlos Cooper?</h4> 
        <a href="">ingrese con otra cuenta</a>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.no-eres{
    padding: 20px 0px;
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #7D93B2;
        padding-bottom: 5px;
        text-align: left;
        word-wrap: break-word;
    } 
    a{
        color: #1877F2;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
    }
    @media (min-width: 992px) {
        padding: 20px 0 0 0;
    }
}
</style>