<template>
    <div class="row justify-content-center row__width titulo">
        <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0">
            <h2>{{ $t('message.uploadMyProducts') }}</h2>
        </div>
        <div class="col-12 col-lg-4 d-flex justify-content-start justify-content-lg-center align-items-center px-0">
            <h3>{{ $t('message.uploadMyProductsSubtitule') }}</h3>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       
    }
    h3{
        font-size: 16px;
        font-weight: 600;
        color: var(--colorSecondary);
        padding: 0px 0 20px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
}
</style>