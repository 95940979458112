<template>
    <div class="row justify-content-center row__width inputs">
        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
            <h3>{{$t('message.mail')}}</h3>
            <input type="mail" :class="validez.validezMail" :placeholder="$t('message.mail')" v-model="infoMail.email">
        </div>
    </div>
</template>

<script setup>
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";
import { inject } from "vue";

const user = useUsersStore();
const {headers, infoMail} = storeToRefs(user);
const {validarMail} = user;

const validez = inject('validez')
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 15px;
    outline: 0;
    &:focus, &:hover{
        border: 2px solid #1679FC;
    }
    &::placeholder{
        color: #697891;
    }
}
</style>