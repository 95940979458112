<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center opinion px-0">
        <OpinionYRespuestaV2/>
        <div class="row justify-content-center row__width pb-2">
            <OpinionIndividual v-for="(opinion, id) in opiniones" v-if="desaparecer"/>
        </div>
        <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex flex-column justify-content-start align-items-center py-1 px-0">
                <div class="before" id="filtro5">
                    <p>
                        Es impresionante la pasión con la que cuenta Mónica Olvera y el conocimiento que tiene para explicar y resolver las dudas durante el curso, 
                        aprendí demasiado. Lo recomiendo completamente, ideal para comenzar a 
                        prepararte profesionalmente en el mundo de la fotografía de newborn por profesionales reconocidos.
                    </p>
                    <div id="desaparecer5" class="d-none">
                        <p>
                            Lo recomiendo completamente, ideal para comenzar a 
                            prepararte profesionalmente en el mundo de la 
                            fotografía de newborn por profesionales reconocidos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center row__width d-lg-none">
            <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                <h6>¿Te gusto este comentario?</h6>
                <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarTexto5"/>
            </div>
        </div>
        <div class="row justify-content-center row__width d-none d-lg-flex">
            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                <BTNMostrarMas :funcionTexto="mostrarOpiniones" :img="iconoImg"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNMostrarMas from "../Botones/MostrarMas.vue";
    import OpinionIndividual from "./OpinionIndividual.vue";
    import OpinionYRespuestaV2 from "./OpinionYRespuestaV2.vue";
    import { ref } from "vue";

    const opiniones = ref([
        {mostrar: false},
        {mostrar: false},
        {mostrar: false},
    ])

    const desaparecer = ref(false)
    const iconoImg = ref(false)

    const mostrarTexto5 = () => {
        document.getElementById('desaparecer5').classList.toggle('d-none')
        document.getElementById('filtro5').classList.toggle('before')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }

    const mostrarOpiniones = () => {
        document.getElementById('desaparecer5').classList.toggle('d-none')
        document.getElementById('filtro5').classList.toggle('before')
        if(desaparecer.value == false){
            desaparecer.value = true
            iconoImg.value =true
        }else{
            desaparecer.value = false
            iconoImg.value =false
        }
    }
</script>

<style lang="scss" scoped>
.opinion{ 
    h2{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-left: 10px;
    }
    .starts{
        img{
            padding-right: 3px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .perfil-foto{
        height: 85px;
        border-radius: 50%;
        width: 100%;
        object-fit: cover;
        @media (min-width: 425px) {
            height: 110px;
            width: 110px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 12px;
        color: #B8C1DB;
    }
    .botones{
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
    }
    .before_respuesta{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-color: #1C1D1F;
            border-radius: 50px;
            width: 8px;
            height: 110%;
            left: -5%;
        }
    }
    @media (min-width: 992px) {
        margin: 40px 0px;
        .perfil-foto{
            max-height: 70px;
            width: auto;
        }
        .flex-cambio{
            flex-direction: column;
        }
    }
}
</style>