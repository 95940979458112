<template>
    <div class="col-12 d-flex justify-content-center align-items-center mb-4 comparacion">
        <div class="row justify-content-center row__width h-100">
            <div class="col-6 d-flex flex-column justify-content-center align-items-center imagen px-0">
                <img src="http://s3-us-west-2.amazonaws.com/s.cdpn.io/74321/before.jpg"/>
                <div class="div__azul d-flex justify-content-center align-items-center">
                    <h6>{{ $t('message.beforeUppercase') }}</h6>
                </div>
            </div>
            <div class="col-6 d-flex flex-column justify-content-center align-items-center px-0">
                <div id="slider" class="beer-slider beer-slider-wlabels row__width">
                    <img src="http://s3-us-west-2.amazonaws.com/s.cdpn.io/74321/before.jpg"/>
                    <div class="beer-reveal">
                        <img src="http://s3-us-west-2.amazonaws.com/s.cdpn.io/74321/after.jpg" alt="Photo after playing with Lightroom presets">
                    </div>
                </div>
                <div class="div__blanco d-flex justify-content-center align-items-center">
                    <h6>{{ $t('message.afterUppercase') }}</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.beer-slider{
    min-height: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 100%;
        height: 100%;
        border-radius: 0 20px 0 0;
        object-fit: cover;
        @media (max-width: 991px) {
            border-radius:0 11px 0 0;
        }
        
    }
}
.imagen{
    img{
        height: 100%;
        max-width: 100%;
        border-radius:  20px 0 0 0;
        object-fit: cover;
        @media (max-width: 991px) {
            border-radius:11px 0 0 0;
        }
    }
}
.comparacion{
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 0;
    @media (max-width: 991px) {
        height: 30vh;
        margin: 20px 0;
    }
    @media (min-width: 992px) {
        margin: 50px 0;
    }
    .div__azul{
        background-color: var(--colorPrimary);
        width: 100%;
        height: 60px;
        border-radius: 0 0 0 20px;
        h6{
            color: var(--bgPrincipal);
            font-size: 22px;
            font-weight: 600;
        }
        @media (max-width: 991px) {
            border-radius: 0 0  0 11px;
        }
    }
    .div__blanco{
        background-color: var(--bgPrincipal);
        width: 100%;
        height: 60px;
        border-radius: 0 0 20px 0;
        h6{
            color: var(--colorPrimary);
            font-size: 22px;
            font-weight: 600;
        }
        @media (max-width: 991px) {
            border-radius: 0 0 11px 0;
        }
    }
    @media (max-width: 991px) {
        .div__azul,.div__blanco{
            height: 30px;
            h6{
                font-size: 12px;
            }
        }
    }
    
}
</style>