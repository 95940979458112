<template>
   <div class="row justify-content-start row__width">
        <div class="col-12 col-lg max-width d-flex justify-content-center align-items-center ">
            <div class="row justify-content-center row__width modalidad">
                <div class="col-12 px-0 d-flex justify-content-center align-items-start">
                    <input type="radio" value="online" id="online" name="modalities" checked>
                    <label for="online">
                        <div class="span"></div>
                        <div class="row justify-content-center row__width h-100">
                            <div class="col-10 d-flex justify-content-start align-items-center">
                                <h3>E-book</h3>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-1">
                                <h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <img src="../../../img/subir-productos-icons/ebook.svg" alt="">
                            </div>
                        </div>
                    </label>
                    
                </div>
            </div>
        </div>
        <div class="col-12 col-lg max-width d-flex justify-content-center align-items-center ">
            <div class="row justify-content-center row__width modalidad">
                <div class="col-12 px-0 d-flex justify-content-center align-items-start">
                    <input type="radio" value="presencial" id="presencial" name="modalities" checked>
                    <label for="presencial">
                        <div class="span"></div>
                        <div class="row justify-content-center row__width h-100">
                            <div class="col-10 d-flex justify-content-start align-items-center">
                                <h3>Presets</h3>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-start px-1">
                                <h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h4>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center m-3">
                                <img src="../../../img/subir-productos-icons/presets.svg" alt="">
                            </div>
                        </div>
                    </label>
                    
                </div>
            </div>
        </div>
   </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.modalidad{
    box-shadow: 2px 2px 10px #00000026;
    border-radius: 30px;
    height: auto;
    min-height: 280px;
    margin: 20px 0;
    padding: 20px 20px 0 20px;
    h3{
        font-weight: 700;
        font-size: 18px;
        color: #343A40;
        padding-left: 10px;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        margin: 10px 0 !important;
        text-align: left;
    }
    input{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        height: 100%;
        .span{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 20px;
                height: 20px;
                display: none;
            }
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: block;
        }
    }
}
.max-width{
    max-width: 22%;
}
</style>