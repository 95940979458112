<template>
    <nav class="navbar navbar-light fixed-top">
        <div class="container-fluid d-flex justify-content-center align-items-center position-relative">
            <div class="row justify-content-center row__width">
                <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center justify-content-lg-start align-items-center row__width">
                        <div class="col-12 col-lg-3 d-flex justify-content-start align-items-center px-0 ">
                            <!-- <button class="navbar-toggler me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar5" aria-controls="offcanvasNavbar5">
                                <span class="navbar-toggler-icon"></span>
                            </button> -->
                            <router-link to="/" class="navbar-brand ms-3 mx-lg-0 d-flex justify-content-center align-items-center"><img src="../../img/Logo.png" alt=""></router-link>
                        </div>
                        <div class="col-lg-9 d-flex justify-content-center align-items-center row__lg__nav px-0 d-none d-lg-flex">
                            <div class="row justify-content-end row__width">
                                <SeccionNavHome/>
                                <div class="col-5 d-flex justify-content-center align-items-center botones">
                                    <BTNPrimarioV2 @click="$router.push('/registro-terminar')" :textoBTNPrimarioV2="$t('message.titleRegister')"/>
                                    <BTNPrimario @click="$router.push('/iniciar')" :textoBTNPrimario="$t('message.login')" class="ms-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MenuHome/>
            <Menu/>
        </div>
    </nav>
</template>

<script setup>
import BTNPrimario from "../Botones/Primario.vue";
import BTNPrimarioV2 from "../Botones/PrimarioV2.vue";
import Menu from "../Carrito/Menu.vue";
import MenuHome from "./MenuHome.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import SeccionNavHome from "./SeccionNavHome.vue";
import { RouterLink } from 'vue-router';

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const users = useUsersStore();
const {cambioHeader, idUser} = storeToRefs(users);

const buscadorDiv = ref(false)

const botonBuscador = ref(true)

const funcionBuscador = () => {
    if(buscadorDiv == false){
        buscadorDiv = true
        botonBuscador = false
    }else{
        buscadorDiv = false
        botonBuscador = true
    }
}

const validarHeader = () => {
    idUser.value = JSON.parse(localStorage.getItem('idUser'))
    if (idUser.value != null){
        console.log(idUser.value)
        if (idUser.value.team){
            cambioHeader.value = false
        }
        else {
            cambioHeader.value = true
        }
    }
    console.log(`validarHeader${cambioHeader.value}`)
}
validarHeader()

</script>

<style lang="scss" scoped>
nav{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 68px;
    a{
        img{
            max-height: 50px;
            @media (min-width: 992px) {
                max-height: 50px;
            }
        }
    }
    .container-fluid{
        height: 100%;
    }
    .navbar-toggler{
        border: 0;
        padding: 0;
        outline: 0;
        .navbar-toggler-icon{
            background-image: url('../../img/menu-icons/menu-icon.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            height: 25px;
            width: 29px;
        }
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;
        position: relative;
        img{
            margin-right: 20px;
        }
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 23px;
            min-width: 23px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .cursos{
            background-image: url('../../img/menu-icons/cursos.svg');
        }
        .categorias{
            background-image: url('../../img/menu-icons/categorias.svg');
        }
        .escuela{
            background-image: url('../../img/menu-icons/escuela.svg');
        }
        .concursos{
            background-image: url('../../img/menu-icons/concursos.svg');
        }
        .nosotros{
            background-image: url('../../img/menu-icons/nosotros.svg');
        }
        .contacto{
            background-image: url('../../img/menu-icons/contacto.svg');
        }
        .premium{
            background-image: url('../../img/menu-icons/premium.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -8.5%;
                top: 0;
            }
            .cursos{
                background-image: url('../../img/menu-icons/cursos_active.svg');
            }
            .categorias{
                background-image: url('../../img/menu-icons/categorias_active.svg');
            }
            .escuela{
                background-image: url('../../img/menu-icons/escuela_active.svg');
            }
            .concursos{
                background-image: url('../../img/menu-icons/concursos_actives.svg');
            }
            .nosotros{
                background-image: url('../../img/menu-icons/nosotros_active.svg');
            }
            .contacto{
                background-image: url('../../img/menu-icons/contacto_active.svg');
            }
            .premium{
                background-image: url('../../img/menu-icons/premium-active.svg');
            }
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        p{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorPrimary);
        }
        @media (min-width: 992px) {
            font-size: 30px;
            p{
                font-size: 30px;
            }
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li{
            padding: 0;
        }
    }
    .paises{
        border-radius: 20px;
        background-color: var(--bgPrincipal);
        right: 10%;
        padding: 10px 20px;
        width: 250px;
        li{
            width: 100%;
            padding: 10px 0px 10px 0px;
            img{
                margin-left: 10px;
            }
            .nav-link{
                &:focus, &:hover{
                    color: var(--enlacesNav);
                    &::before{
                        display: none;
                    }
                }
            }
        }
        .borde{
            border-top: 1px solid #c6bacc7c;
            padding: 15px 0px;
            img{
                margin-right: 10px;
                margin-left: 0;
            }
        }
    }
    .btns{
        margin: 10px 0px;
    }
    .ul_precios{
        padding-left: 0;
        list-style: none;
        padding-bottom: 40px;
        li{
            a{
                color: var(--colorSecondary);
                font-size: 16px;
                font-weight: 500;
                text-align: left;
            }
            
        }
        .nav-link{
            &:focus, &:hover{
                color: var(--colorSecondary);
                font-weight: 500;
                &::before{
                    display: none;
                }
            }
        }
    }
    .boder-top{
        border-top: 1px solid rgba(184, 193, 219, 0.5);
        h6{
            color: var(--grisSecundario);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }

    }
    @media (min-width: 992px) {
        height: 95px;
    }
    .offcanvas{
        padding: 10px 10px;
        max-width: 85%;
        border-radius: 0px 0px 15px 0px;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 100vh;
        flex-direction: row;
        .offcanvas-header{
            align-items: flex-start;
            padding: 10px 25px;
        }
        .btn-close{
            background-image: url('../../img/menu-icons/cerrar.svg');
        }
        .offcanvas-body{
            padding: 10px 25px;
        }
        @media (min-width: 992px) {
            background-color: var(--bgPrincipal) !important;
            box-shadow: 0px 3px 6px #00000029;
            top: 95px;
        }
    }

    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .row__lg__nav{
        .btn_none{
            border: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }
    .offcanvas-carrito{
        min-width: 436px;
        padding: 20px;
        padding-bottom: 0;
        .offcanvas-title{
            font-size: 20px;
            font-weight: 700;
            color: var(--colorSecondary);
        }
        .div{
            min-height: 80%;
            padding: 20px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: #FCFCFC;
            h2{
                font-size: 20px;
                font-weight: 700;
                color: var(--colorSecondary);
            }
            img{
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                margin-bottom: 20px;
            }
            h3{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                text-align: center;
                padding: 10px 0px;
            }
        }
        .offcanvas .btn-close{
            background-image: url('../../img/carrito-icons/cerrar-modal.svg');
            opacity: 1;
        }
        .borde{
            border-bottom: 1px solid rgba(184, 193, 219, 0.5);
            height: 1px;
        }
        .modal-carrito{
            .offcanvas-header{
                margin-bottom: 10px !important;
            }
            h4{
                font-weight: 700;
                font-size: 22px;
                color: var(--colorSecondary);
            }
            p{
                font-weight: 500;
                font-size: 16px;
                color: #697891;
                padding: 10px 0px;
                padding-bottom: 0;
            }
        }
    }
    .offcanvas-backdrop{
        backdrop-filter: blur(3px);
        @media (min-width: 992px) {
            top: 95px !important;
            backdrop-filter: blur(10px);
            background-color: var(--colorSecondary);
        }
    }
    .buscador{
        border: 2px solid #37405B;
        border-radius: 23px;
        min-height: 45px;
        min-width: 70%;
        padding: 0 7px 0 15px;
        margin-right: 5px;
        transition: all ease 3s;
        input{
            border: 0;
            outline: 0;
            font-weight: 400;
            color: #B8C1DB;
            font-size: 16px;
        }
    }
    
}
.navbar-expand-lg .navbar-toggler {
    @media (min-width: 992px){
        display: flex !important;
    }
}
</style>