<template>
    <div class="row justify-content-center row__width titulo">
        <div class="col-12 d-flex justify-content-start align-items-center ">
            <h2>Editar cuestionario</h2>
        </div>
    </div>
</template>

<script setup>

    
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
}
</style>