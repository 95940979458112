<template>
    <div class="row justify-content-center proveedores mt-4 row__width" v-if="sliderLength >= 1">
        <swiper
            :slidesPerView="3"
            :navigation="{
                nextEl: '#nextSlider7boton',
                prevEl: '#prevSlider7boton'
            }"
            :pagination="{
                el: '#pagMarcas',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :spaceBetween="20"
            :loop="true"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                992: {
                    slidesPerView: 2,
                },
                1366: {
                    slidesPerView: 3,
                },
                1920: {
                    slidesPerView: 4,
                },
            }"
            class="mySwiper" >
            <swiper-slide v-for="(slide, id) in sliderLength" class="">
                <ProveedoresSlide   :rName="childProducts[id].names[lang]"
                                    :rDescription="childProducts[id].description[lang]"
                                    :rImage="childProducts[id].image"
                                    :rLocation="childProducts[id].location"
                                    :rSlug="childProducts[id].slug[lang]"
                                    :rRates="childProducts[id].rates"
                                    :rVerified="childProducts[id].verified"/>
            </swiper-slide>
        </swiper>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>


    import ProveedoresSlide from "./ProveedoresSlide.vue";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import { ref, watch } from "vue";
    import { useI18n } from "vue-i18n";
    import axios from 'axios';
    
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Navigation, Grid, Pagination, column } from "swiper";

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
    const {} = general;

    const {locale} = useI18n({ useScope: 'global' })
    const lang = ref()
    const X = ref('')

    const url = process.env.VUE_APP_MEILISEARCH_URL
    const urlApi = '/multi-search'

    //Setea parametros de busqueda para el post
    const search = ref({
        queries:[{
            indexUid: 'brands',
            q:''
        }]
    })

    //Precarga parametros de resultados de busqueda
    const childProducts = ref(0)
    const sliderLength = ref(0)

    //Setea las variables con los resultados para su uso
    // const name = childProducts X
    // const description = childProducts X
    // const image = childProducts X
    // const location = childProducts X
    // const slug = childProducts X
    // const rates = childProducts X
    // const verified = childProducts X

    //INTENTO PASAR LA FECHA
    // const date = new Date(props.rCreatedAt)
    // const createdAt = () => {
    //     const options = { day: 'numeric', month: 'long' };
    //     return new Date(date).toLocaleDateString('es-ES', options);
    // }
    // createdAt()
    // console.log(createdAt.value)

    //Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
    const setLang = () => {
        lang.value = locale.value
        //console.log(lang.value)
    }
    setLang()

    //Hago un post del usuario x
    const fetchSearch = async () => {
        if (sessionStorage.getItem('searchValue')){
            //console.log(sessionStorage.getItem('searchValue'))
            X.value = JSON.parse(sessionStorage.getItem('searchValue'))
            search.value.queries[0].q = X.value
        }
        setLang()
        try {
            await axios.post(url+urlApi, search.value).then((result) => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                //console.log(result.data.results)
                sessionStorage.removeItem('searchValue')
            })
        }
        catch (error) {
        console.log(error)
        }
    }
    fetchSearch()

    watch( reSearch, async () => {
        if (sessionStorage.getItem('searchValue')){
            //console.log(sessionStorage.getItem('searchValue'))
            X.value = JSON.parse(sessionStorage.getItem('searchValue'))
            search.value.queries[0].q = X.value
        }
        setLang()
        try {
            await axios.post(url+urlApi, search.value).then((result) => {
                childProducts.value = result.data.results[0].hits
                sliderLength.value = result.data.results[0].hits.length
                //console.log(result.data.results)
                sessionStorage.removeItem('searchValue')
            })
        }
        catch (error) {
        console.log(error)
        }
    })
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 5px;
}

</style>