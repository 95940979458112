<template>
    <!--- MOBILE --->
    <div class="offcanvas offcanvas-start show p-0 d-none " tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel" data-bs-backdrop="true" data-bs-scroll="true" v-if="backdrop">
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center">
            <h5>{{ $t('message.modeAdminUppercase') }}</h5>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll">
            <ul class="navbar-nav justify-content-end row row__width">
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/dashboard" :class="props.dashboard == true ? 'active' : ''">
                        <i class="dashboard"></i>
                        {{ $t('message.dashboard') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <button class="nav-link btn-upload" role="button" id="professionalUpload" data-bs-toggle="dropdown" aria-expanded="false" :class="props.stats == true ? 'active' : ''" >
                        <i class="stats"></i>
                        {{ $t('message.stats') }}
                        <i class="arrow"></i>
                    </button>
                    <ul class="ul_updload dropdown-menu" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-academicas/'+idUser.id" :class="props.academic == true ? 'active' : ''">
                            <i class="mas"></i> Académicas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-financieras/'+idUser.id" :class="props.financial == true ? 'active' : ''">
                            <i class="mas"></i> Financieras
                            </router-link>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/productos" :class="props.products == true ? 'active' : ''">
                        <i class="products"></i>
                        Productos
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.uploadProducts') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/usuarios" :class="props.users == true ? 'active' : ''"  >
                        <i class="users"></i>
                        {{ $t('message.users') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/concursos" :class="props.contests == true ? 'active' : ''"  >
                        <i class="contests"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/referidos" :class="props.referrals == true ? 'active' : ''">
                        <i class="referrals"></i>
                        {{ $t('message.referrals') }}
                    </router-link>
                </li> -->
                <div class="px-0">
                    <h5 class="py-3">{{ $t('message.other') }}</h5>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" to="/ajustes-admin" :class="props.settings == true ? 'active' : ''">
                            <i class="ajustes"></i>
                            {{ $t('message.settings') }}
                        </router-link>
                        <ul class="ul_updload" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;">
                            <li>
                                <div class="nav-link">
                                    Algo
                                </div>
                            </li>
                        </ul>
                    </li>
                </div>
            </ul>
            <!-- <div class="row justify-content-center row__width position padding">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top">
                    <h6> {{$t('message.mode') }} <strong>{{ $t('message.light') }}</strong></h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
        <router-link to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </router-link>
    </div>
    <!--- ESCRITORIO --->
    <div class="offcanvas offcanvas-start show p-0 d-none" tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel" data-bs-backdrop="false" data-bs-scroll="true" v-else>
        <div class="offcanvas-header col-12 d-flex justify-content-between align-items-center">
            <h5>{{ $t('message.modeAdminUppercase') }}</h5>
        </div>
        <div class="offcanvas-body col-12 d-flex flex-column justify-content-start align-items-center position-relative scroll">
            <ul class="navbar-nav justify-content-end row row__width">
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/dashboard" :class="props.dashboard == true ? 'active' : ''">
                        <i class="dashboard"></i>
                        {{ $t('message.dashboard') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <button class="nav-link btn-upload" role="button" id="professionalUpload" data-bs-toggle="dropdown" aria-expanded="false" :class="props.stats == true ? 'active' : ''" >
                        <i class="stats"></i>
                        {{ $t('message.stats') }}
                        <i class="arrow"></i>
                    </button>
                    <ul class="ul_updload dropdown-menu" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-academicas/'+idUser.id" :class="props.academic == true ? 'active' : ''">
                            <i class="mas"></i> Académicas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to="'/estadisticas-financieras/'+idUser.id" :class="props.financial == true ? 'active' : ''">
                            <i class="mas"></i> Financieras
                            </router-link>
                        </li>
                    </ul>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/productos" :class="props.products == true ? 'active' : ''">
                        <i class="products"></i>
                        Productos
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link btn-upload" to="/subir-producto" :class="props.upload == true ? 'active' : ''" >
                        <i class="upload-products"></i>
                        {{ $t('message.uploadProducts') }}
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/usuarios" :class="props.users == true ? 'active' : ''"  >
                        <i class="users"></i>
                        {{ $t('message.users') }}
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/concursos" :class="props.contests == true ? 'active' : ''"  >
                        <i class="contests"></i>
                        {{ $t('message.contests') }}
                    </router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" to="/referidos" :class="props.referrals == true ? 'active' : ''">
                        <i class="referrals"></i>
                        {{ $t('message.referrals') }}
                    </router-link>
                </li> -->
                <div class="px-0">
                    <h5 class="py-3">{{ $t('message.other') }}</h5>
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" to="/ajustes-admin" :class="submenu == true ? 'active' : ''" @click="submenu = true">
                            <i class="ajustes"></i>
                            {{ $t('message.settings') }}
                        </router-link>
                        <ul class="ul_updload ps-4" aria-labelledby="professionalUpload" style="inset: 0 -20px !important;"  v-show="submenu">
                            <li class="nav-link" @click="changePage(1); professionPage = false" :class="actual_page == 1 ? 'active' : ''">
                                {{ $t('message.FAQ') }}
                            </li>
                            <li class="nav-link" @click="changePage(2); professionPage = false" :class="actual_page == 2 ? 'active' : ''">
                                {{ $t('message.benefits') }}
                            </li>
                            <li class="nav-link" @click="changePage(3); professionPage = false" :class="actual_page == 3 ? 'active' : ''">
                                {{ $t('message.values') }}
                            </li>
                            <li class="nav-link" @click="changePage(4); professionPage = false" :class="actual_page == 4 ? 'active' : ''">
                                {{ $t('message.academicSetup') }}
                            </li>
                            <li class="nav-link" @click="changePage(5); professionPage = false" :class="actual_page == 5 ? 'active' : ''">
                                {{ $t('message.categories') }}
                            </li>
                            <li class="nav-link" @click="changePage(6); professionPage = false" :class="actual_page == 6 ? 'active' : ''">
                                {{ $t('message.professions') }}
                            </li>
                        </ul>
                    </li>
                </div>
            </ul>
            <!-- <div class="row justify-content-center row__width position padding">
                <div class="col-12 d-flex justify-content-between align-items-center boder-top">
                    <h6> {{$t('message.mode') }} <strong>{{ $t('message.light') }}</strong></h6>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                    </div>
                </div>
            </div> -->
        </div>
        <router-link to="/" class="nav-link boder-box" @click="logout">
            <i class="log-out"></i>
            {{ $t('message.logOut') }}
        </router-link>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, onMounted, watch, inject, onUnmounted } from "vue"
const router = useRouter()
const user = useUsersStore();
const {datosUsuario, headers, idUser, userInformation} = storeToRefs(user);
const {} = user;

const url = process.env.VUE_APP_BASE_URL
const urlLogout = '/api/auth/logout'

const props = defineProps({
    dashboard: Boolean,
    products: Boolean,
    stats: Boolean,
    users: Boolean,
    contests: Boolean,
    referrals: Boolean,
    settings: Boolean,
    financial: Boolean,
    academic: Boolean,
    upload: Boolean,
})

const logout = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    try {
        await axios.post(url+urlLogout, {

        }, {headers: {
            'Authorization': `Bearer ${token}`
            }
        }).then((result) => {
            localStorage.clear()
            router.go(0)
        })
    }
    catch (error) {
        console.log(error)
    }
}

const backdrop = ref(true)


// watch( sizeWidth , () => {
//     console.log(sizeWidth.value)
// })

const showBanner = () => {
    const sizeWidth = ref(window.innerWidth)
    //console.log(sizeWidth.value)
    if(sizeWidth.value < 992){
        backdrop.value = true
        console.log(backdrop.value)
    }else{
        backdrop.value = false
        console.log(backdrop.value)
    }
}
showBanner()
onMounted(() => {
    window.addEventListener("resize", showBanner)
})

onUnmounted(() => {
    window.removeEventListener("resize", showBanner)
})
const actual_page = inject('actual_page')
const changePage = inject('changePage')
const submenu = inject('submenu')
const professionPage = inject('professionPage')
</script>

<style lang="scss" scoped>
.offcanvas {
  @media (min-width: 992px) {
    min-height: 100vh;
    z-index: 1;
  }
  @media (max-width: 991px) {
    max-width: 80vw;
  }
}

.offcanvas-start {
  transform: translateX(-100%);
  visibility: visible;
  transition: transform 0.3s ease-in-out;
  border-right: 0 !important;
  box-shadow: 0px 3px 6px #00000029;
    .offcanvas-header{
        padding: 30px 30px 0 30px;
    }
    .offcanvas-body{
        animation: none;
        padding: 0;
    }
    h5{
        color: #B8C1DB;
        font-weight: 700;
        font-size: 16px;
        padding-top: 10px;
        text-transform: uppercase;
        text-align: left;
    }
    ul, .padding{
        padding: 30px 0 0 30px;
    }
    .nav-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--enlacesNav);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        margin: 5px 0px;
        position: relative;
        .position{
            position: absolute;
            right: 0;
        }
        i{
            margin-right: 15px;
            min-height: 25px;
            min-width: 27px;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow.svg');
            transform: rotate(-180deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products.svg');
        }
        .products{
            background-image: url('../../img/certificados-icons/productos.svg');
        }
        &:focus, &:hover{
            color: var(--colorPrimary);
            font-weight: 700;
            &::before{
                content: '';
                background-color: var(--colorPrimary);
                height: 100%;
                position: absolute;
                width: 5px;
                border-radius: 10px;
                left: -12%;
                top: 0;
            }
            .dashboard{
                background-image: url('../../img/menu-icons/dashboard-active.svg');
            }
            .stats{
                background-image: url('../../img/menu-icons/stats-active.svg');
            }
            .users{
                background-image: url('../../img/menu-icons/users-active.svg');
            }
            .contests{
                background-image: url('../../img/menu-icons/contest-active.svg');
            }
            .referrals{
                background-image: url('../../img/menu-icons/refrerrals-active.svg');
            }
            .arrow{
                background-image: url('../../img/menu-icons/arrow-azul.svg');
                transform: rotate(0deg);
                transition: all ease 0.2s;
                margin-left: 20px;
            }
            .ajustes{
                background-image: url('../../img/certificados-icons/ajustes-active.svg');
            }
            .log-out{
                background-image: url('../../img/certificados-icons/log-out-active.svg');
            }
            .upload-products{
                background-image: url('../../img/menu-icons/upload-products-active.svg');
            }
            .products{
                background-image: url('../../img/certificados-icons/productos-active.svg');
            }
        }
        
    }
    .active{
        color: var(--colorPrimary) !important;
        font-weight: 700;
        &::before{
            content: '';
            background-color: var(--colorPrimary);
            height: 100%;
            position: absolute;
            width: 5px;
            border-radius: 10px;
            left: -12%;
            top: 0;
        }
        .dashboard{
            background-image: url('../../img/menu-icons/dashboard-active.svg');
        }
        .stats{
            background-image: url('../../img/menu-icons/stats-active.svg');
        }
        .users{
            background-image: url('../../img/menu-icons/users-active.svg');
        }
        .contests{
            background-image: url('../../img/menu-icons/contest-active.svg');
        }
        .referrals{
            background-image: url('../../img/menu-icons/refrerrals-active.svg');
        }
        .arrow{
            background-image: url('../../img/menu-icons/arrow-azul.svg');
            transform: rotate(0deg);
            transition: all ease 0.2s;
            margin-left: 20px;
        }
        .ajustes{
            background-image: url('../../img/certificados-icons/ajustes-active.svg');
        }
        .log-out{
            background-image: url('../../img/certificados-icons/log-out-active.svg');
        }
        .upload-products{
            background-image: url('../../img/menu-icons/upload-products-active.svg');
        }
        .products{
            background-image: url('../../img/certificados-icons/productos-active.svg');
        }
    }
    .navbar-brand{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    .dropdown-toggle::after{
        border: 0;
    }
    ul{
        h4{
            color: var(--grisPrimario);
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 0 0 10px 0;
        }
        li, .nav-item{
            padding: 0;
            // ul{
            //     display: none;
            // }
            // &:hover, &:focus{
            //     ul{
            //         display: block;
            //     }
            // }
        }
    }
    .btn-upload{
        background-color: transparent;
        border: 0;
    }
    .ul_updload{
        list-style: none;
        position: relative !important;
        padding: 0;
        border: 0;
        outline: none;
        transform: none !important;
        inset: 0 -20px !important;
        .nav-item{
            padding-left: 15px;
            color: var(--enlacesNav);
            font-size: 15px;
            font-weight: 500;
            &:hover, &:focus{
                background-color: #F8F8F9;
                border-radius: 10px;
            }
            a, .nav-link{
                &:hover, &:focus{
                    &::before{
                        display: none !important;
                    }
                    
                }
            }
            .nav-link.active{
                &::before{
                    display: none !important;
                }
                .mas{
                    background-image: url('../../img/menu-icons/mas-active.svg');
                }
            }
            i{
                margin-right: 15px;
                min-height: 25px;
                min-width: 27px;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }
            .mas{
                background-image: url('../../img/menu-icons/mas.svg');
            }
        }
    }
    .ul_updload.show{
        inset: 0 -20px !important;
    }
    .btns{
        margin: 10px 0px;
    }
    .boder-top{
        padding-top: 35%;
        h6{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            strong{
                font-size: inherit;
            }
        }
        .form-check-input{
            border-color: var(--colorPrimary);
            position: relative;
            display: flex;
            align-items: center;
        }
        .form-switch .form-check-input {
            height: 25px;
            width: 48px;
            background-image: url('../../img/menu-icons/switch_normal.svg');
            background-size: 42% 100%;
            background-position-x: 2px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                right: 5px;
            }
            &::after{
                display: none;
            }
        }
        .form-switch .form-check-input:checked{
            background-image: url('../../img/menu-icons/switch_active.svg');
            background-position-x: 25px;
            &::before{
                content: '';
                position: absolute;
                background-image: url('../../img/menu-icons/modo-nocturno-azul.svg');
                filter: brightness(10);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 15px;
                width: 15px;
                left: 5px;
            }
        }
        .form-switch .form-check-input:focus{
            box-shadow: none;
        }
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
    }
    .boder-box{
        box-shadow: 0px 3px 10px #00000033;
        background-color: #fff;
        padding: 10px 10px 15px 30px;
        position: absolute;
        z-index: 1449;
        left: 0;
        bottom: 55px;
        width: 100%;
        height: 60px;
        .nav-link{
            &::before{
                display: none !important;
            }
        }
    }
    .scroll{
        overflow-y: scroll;
        padding-bottom: 90px;
        max-height: 95vh;
        &::-webkit-scrollbar{
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
            width: 5px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #02020262;
            width: 5px;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            max-height: 85vh;
        }
    }
}

@media (min-width: 992px) {
    @keyframes slideInLeft {
    from {
        transform: translateX(-50px);
    }
    to {
        transform: translateX(0);
    }
    }

    @keyframes slideInUp {
    from {
        transform: translateY(-50px);
    }
    to {
        transform: translateY(0);
    }
    }

    @keyframes slideOutLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50px);
    }
    }

    @keyframes slideOutDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-50px);
    }
    }

    @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    }

    @keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
    }
        
    .offcanvas.offcanvas-start {
    top: 60px;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    }

    .offcanvas.offcanvas-start.show {
    width: 280px;
    }

    .section {
    width: 100%;
    flex-grow: 1;
    transition: width 0.3s ease-in-out;
    }

    /* Animaciones */
    // .offcanvas-header,

    // .offcanvas-header h5 {
    // transform: translateX(-50px);
    // animation: slideInLeft 0.5s ease-in-out forwards;
    // }

    // .offcanvas-start:not(.show) .offcanvas-header,
    // .offcanvas-start:not(.show) .offcanvas-body {
    // animation: fadeOut 0.5s ease-in-out forwards;
    // }

    // .offcanvas-start:not(.show) .offcanvas-header h5 {
    // animation: slideOutLeft 0.5s ease-in-out forwards;
    // }
    // .offcanvas-start.active {
    // transform: translateX(0);
    // }
}
</style>