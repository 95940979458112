<template>
    <div>
        <img src="../../img/home-icons/home-decoracion.png" alt="">
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
img{
    position: absolute;
    right: 0;
    z-index: -1;
    @media (min-width: 992px) {
        top: 20%;
        max-width: 40%;
    }
    @media (min-width: 1200px) {
        top: 24%;
        max-width: 40%;
    }
    @media (min-width: 1300px) {
        top: 28%;
        max-width: 44%;
    }
    @media (min-width: 1400px) {
        top: 28%;
        max-width: 47%;
    }
    @media (min-width: 1500px) {
        top: 26%;
        max-width: 47%;
    }
    @media (min-width: 1700px) {
        top: 26%;
        max-width: 47%;
    }
}
</style>