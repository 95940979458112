<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center align-items-lg-start contactar">
        <h2> {{ $t('message.moreInformationCourse') }} </h2>
        <a href="https://wa.me/5546490297?text=Hola!%20quiero%20contactarme%20con%20un%20asesor" class="mt-3"><img src="../../img/home-icons/cursos-icons/whatsapp-contactar.svg" alt=""> {{ $t('message.contactAadvisor') }} </a>
        <img src="../../img/home-icons/cursos-icons/freelancer.png" alt="" class="ejemplo d-none d-lg-flex">
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.contactar{
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    min-height: 200px;
    padding: 20px 30px;
    position: relative;
    margin: 50px 0 30px 0;
    @media (max-width: 991px) {
        padding: 0px;
    }
    h2{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        line-height: 37px;
        
        @media (max-width: 425px) {
            font-size: 20px;
            line-height: 25px;
        }
        @media (min-width: 992px) {
            text-align: left;
            max-width: 45%;
        }
    }
    a{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        border-radius: 28px;
        background-color: transparent;
        border: 2px solid var(--colorSecondary);
        height: 50px;
        width: 300px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0px;
        img{
            padding-right: 10px;
            
        }
    }
    .ejemplo{
        position: absolute;
        right: 7%;
        bottom: 0;
        @media (min-width: 992px) {
            right: 4%;
            height: 33vh;
        }
    }
    @media (min-width: 992px) {
        padding-left: 60px;
    }
}
</style>