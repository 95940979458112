<template>
    <div class="row justify-content-center my-3 row__width" v-if="sliderLength >= 1">
        
        <swiper 
            :navigation="{
                nextEl: '#nextSliderPresetsboton',
                prevEl: '#prevSliderPresetsboton'
            }"
            :pagination="{
                el: '#pagPresets',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :modules="modules"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1920: {
                    slidesPerView: 4,
                },
            }"
            class="mySwiper" :class="filtrosDesplegados == true ? 'd-lg-none' : '' || mapaDesplegado == true ? 'd-lg-none' : '' ">
            <swiper-slide v-for="(slide, id) in sliderLength" class="d-flex justify-content-center align-items-center">
                <div class="col-11 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center row__width caption">
                        <div class="col-12 d-flex justify-content-center align-items-center filtro  px-0">
                            <img :src="childProducts[id].imagePreset" alt="" class="ejemplo">
                            <div class="row justify-content-center row__width position">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width row__slide">
                                        <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <h6><img src="../../img/home-icons/cursos-icons/rate.svg" alt="">{{$t('message.rating')}}</h6><p>{{$t('message.opinions')}}</p>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center creador">
                            <img :src="childProducts[id].image" alt="">
                            <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                                <h3>{{$t('message.creator')}}</h3>
                                <h4>{{ childProducts[id].name }} {{ childProducts[id].lastName }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt=""></h4>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h2>{{ childProducts[id].title }}</h2>
                            <h5>{{ childProducts[id].description }}</h5>
                            <span class="border-bottom mt-4 w-100"></span>
                        </div>
                    </div>
                    <div class="row justify-content-center comprar row__width">
                        <div class="col-5 d-flex flex-column justify-content-center align-items-start padding">
                            <h2>{{ childProducts[id].pricePromo }}</h2>
                            <h5>{{ childProducts[id].price }}</h5>
                        </div>
                        <div class="col-7 d-flex justify-content-center align-items-center ps-0">
                            <BTNPrimario url="/preset/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.buy')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <swiper 
            :navigation="{
                nextEl: '#nextSliderPresetsboton',
                prevEl: '#prevSliderPresetsboton'
            }"
            :pagination="{
                el: '#pagPresets',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 2,
                },
                1920: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            }"
            class="mySwiper d-none px-2" :class="filtrosDesplegados == true ? 'd-lg-flex mt-0' : '' || mapaDesplegado == true ? 'd-lg-flex mt-0' : '' ">
            <swiper-slide v-for="(slide, id) in sliderLength" class="d-flex justify-content-center align-items-center">
                <div class="col-11 col-xxl-12 d-flex flex-column justify-content-start align-items-center slide px-0">
                    <div class="row justify-content-center row__width caption">
                        <div class="col-12 d-flex justify-content-center align-items-center filtro  px-0">
                            <img :src="childProducts[id].imagePreset" alt="" class="ejemplo">
                            <div class="row justify-content-center row__width position">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="row justify-content-center row__width row__slide">
                                        <div class="col-12 d-flex justify-content-center align-items-center abajo px-0">
                                            <div class="row justify-content-center row__width">
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <h6><img src="../../img/home-icons/cursos-icons/rate.svg" alt="">{{$t('message.rating')}}</h6><p>{{$t('message.opinions')}}</p>
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center descripcion row__width">
                        <div class="col-12 d-flex justify-content-start align-items-center creador">
                            <img :src="childProducts[id].image" alt="">
                            <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                                <h3>{{$t('message.creator')}}</h3>
                                <h4>{{ childProducts[id].name }} {{ childProducts[id].lastName }}<img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt=""></h4>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column justify-content-center align-items-start">
                            <h2>{{ childProducts[id].title }}</h2>
                            <h5>{{ childProducts[id].description }}</h5>
                            <span class="border-bottom mt-4 w-100"></span>
                        </div>
                    </div>
                    <div class="row justify-content-center comprar row__width">
                        <div class="col-5 d-flex flex-column justify-content-center align-items-start padding">
                            <h2>{{ childProducts[id].pricePromo }}</h2>
                            <h5>{{ childProducts[id].price }}</h5>
                        </div>
                        <div class="col-7 d-flex justify-content-center align-items-center ps-0">
                            <BTNPrimario url="/preset/" :slug="childProducts[id].slug[lang]" :textoBTNPrimario="$t('message.buy')"/>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
    <div v-else class="pt-5 pb-5">
        <h1>{{ $t('message.withoutResults') }}</h1>
    </div>
</template>

<script setup>
    import BTNPrimario from "../Botones/PrimarioRutas.vue";
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Navigation } from "swiper";
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";
    import { ref, watch } from 'vue';
    import { useI18n } from "vue-i18n";
    import axios from 'axios';

    const general = useGeneralStore();
    const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
    const {} = general;

    const {locale} = useI18n({ useScope: 'global' })
    const lang = ref()
    const X = ref('')

    const url = process.env.VUE_APP_MEILISEARCH_URL
    const urlApi = '/multi-search'

    //Setea parametros de busqueda para el post
    const search = ref({
        queries:[{
            indexUid: 'preset_products',
            q:''
        }]
    })

    //Precarga parametros de resultados de busqueda
    const childProducts = ref(0)
    const sliderLength = ref(0)

    //Setea las variables con los resultados para su uso
    // const title = childProducts X
    // const name = childProducts X
    // const lastName = childProducts X
    // const description = childProducts X
    // const image = childProducts X
    // const imagePreset = childProducts X
    // const slug = childProducts 
    // const rates = childProducts 
    // const verified = childProducts 
    // const pricePromo = childProducts X
    // const price = childProducts X

    //INTENTO PASAR LA FECHA
    // const date = new Date(props.rCreatedAt)
    // const createdAt = () => {
    //     const options = { day: 'numeric', month: 'long' };
    //     return new Date(date).toLocaleDateString('es-ES', options);
    // }
    // createdAt()
    // console.log(createdAt.value)

    //Setea valores segun el lenguaje -- POSIBLEMENTE NO SE USE EN EL FUTURO --
    const setLang = () => {
        lang.value = locale.value
        //console.log(lang.value)
    }
    setLang()

    //Hago un post del usuario x
    // const fetchSearch = async () => {
    //     if (sessionStorage.getItem('searchValue')){
    //         //console.log(sessionStorage.getItem('searchValue'))
    //         X.value = JSON.parse(sessionStorage.getItem('searchValue'))
    //         search.value.queries[0].q = X.value
    //     }
    //     setLang()
    //     try {
    //         await axios.post(url+urlApi, search.value).then((result) => {
    //             childProducts.value = result.data.results[0].hits
    //             sliderLength.value = result.data.results[0].hits.length
    //             //console.log(result.data.results)
    //             sessionStorage.removeItem('searchValue')
    //         })
    //     }
    //     catch (error) {
    //     console.log(error)
    //     }
    // }
    // fetchSearch()

    // watch( reSearch, async () => {
    //     if (sessionStorage.getItem('searchValue')){
    //         //console.log(sessionStorage.getItem('searchValue'))
    //         X.value = JSON.parse(sessionStorage.getItem('searchValue'))
    //         search.value.queries[0].q = X.value
    //     }
    //     setLang()
    //     try {
    //         await axios.post(url+urlApi, search.value).then((result) => {
    //             childProducts.value = result.data.results[0].hits
    //             sliderLength.value = result.data.results[0].hits.length
    //             //console.log(result.data.results)
    //             sessionStorage.removeItem('searchValue')
    //         })
    //     }
    //     catch (error) {
    //     console.log(error)
    //     }
    // })
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 0px 0;
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    min-height: 500px;
    max-height: 70vh;
    .ejemplo{
        width: 100%;
    }
    .caption{
        position: relative;
        margin: 10px 0px;
        img{
            max-width: 100%;
        }
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            z-index: 3;
            h6{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
            }
            p{  font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 3px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                .arriba{
                    position: absolute;
                    top: 10%;
                }
                .abajo{
                    position: absolute;
                    bottom: 10%;
                }
            }
            
        }
        .filtro{
            &::before{
                content: '';
                position: absolute;
                bottom: 10px;
                width: 96%;
                height: 40%;
                border-radius: 0px 0px 15px 15px;
                background: linear-gradient(to top,  #081026 , #252E4800);
                filter: opacity(1);
                z-index: 2;
            }
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin-left: 5px;
            }
        }
        h5{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            padding-top: 10px;
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            padding-bottom: 10px;
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .comprar{
        margin: 15px 0px;
        h2{
            font-weight: 700;
            font-size: 23px;
            color: var(--colorSecondary);
            text-align: left;
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
        }
    }
}
</style>