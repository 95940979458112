<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start texto_mas ql-editor-viendo">
        <div class="ql-editor" v-html="response.description"></div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, computed, onBeforeMount } from 'vue';
const response = inject('response')
</script>


<style lang="scss" scoped>
.texto_mas{
    p, li{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    ul{
        padding: 0;
        max-width: 100%;
        position: relative;
        li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 30px;
            min-height: 40px;
            width: 100%;
            margin-bottom: 10px;
            &::before{
                content: url('../../img/home-icons/cursos-icons/tick.png');
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
    .seccion{
        padding: 10px 10px;
        img{
            padding-right: 10px;
        }
    }
    h6{
        font-weight: 500;
        font-size: 18px;
        color: var(--colorSecondary);
        padding: 15px 0px;
        display: none;
        text-align: left;
        @media (min-width: 992px) {
            display: flex;
        }
    }
}

</style>