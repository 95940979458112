<template>
    <div class="row justify-content-center row__width titulo">
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start ">
            <h2>{{ $t('message.myCertificates') }}</h2>
            <h3>{{ $t('message.youDontHaveCertificates') }}</h3>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0">
            <div class="row justify-content-center justify-content-lg-end  row__width">
                <div class="col-10 col-lg-9 d-flex justify-content-center align-items-center px-0 pe-1">
                    <div class="contenedor row d-flex justify-content-center align-items-between">
                        <input type="text" :placeholder="$t('message.searchCertificate')">
                        <button class="lens">
                            <img src="../../img/home-icons/lupa.svg" alt="">
                        </button>
                    </div>
                </div>
                <div class="col-2 d-flex justify-content-center align-items-center">
                    <button class="calendar">
                        <img src="../../img/certificados-icons/calendar.svg" alt="" class="d-lg-none">
                        <img src="../../img/certificados-icons/calendar-blanco.svg" alt="" class="d-none d-lg-flex">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    
</script>

<style lang="scss" scoped>
.titulo{
    h2{
        font-weight: 700;
        color: var(--colorSecondary);
        font-size: 25px;
       
    }
    
    h3{
        font-size: 16px;
        font-weight: 600;
        color: var(--colorSecondary);
        padding: 0px 0 30px 0;
        @media (min-width: 992px) {
            padding-bottom: 0;
        }
    }
    .contenedor{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 33px;
        background-color: var(--bgPrincipal);
        height: 55px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
        @media (min-width: 992px) {
            border-radius: 50px;
            padding: 22px 15px;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
        .img{
            padding: 0 20px;
        }
        h3{
            color: var(--colorSecondary);
            font-size: 18px;
            font-weight: 600;
            padding: 5px 0px;
            @media (min-width: 992px) {
                font-weight: 500;
                padding-top: 0;
                padding-bottom: 5px;
            }
        }
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 14px;
                width: 80%;
            }
        }
        
        .border-left{
            border-left: 1px solid rgba(184, 193, 219, 0.4);
        }
        
    }
    .calendar{
        outline: 0;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: #F5F5F5;
        min-height: 47px;
        min-width: 47px;
        border: 0;
        max-width: 47px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 992px) {
            background-color: var(--colorPrimary);
        }
    }
    .lens{
        outline: 0;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        min-height: 47px;
        min-width: 47px;
        border: 0;
        max-width: 47px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
            position: relative;
            right: 42px;
        }
    }
}
</style>