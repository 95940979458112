<template>
    <div class="row justify-content-center row__width asesorias__profesionales pb-5">
        <div class="col-12 d-flex justify-content-center align-items-center px-0 pb-3 d-lg-none">
            <img src="../../img/home-icons/cursos-icons/podras-conseguir.png" alt="">
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center pt-4">
            <h2 class="v2">{{$t('message.learnConsultancies')}}</h2>
            <ul class="nav nav-tabs row__width mt-3" id="myTab" role="tablist">
                <li class="nav-item col-6 d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{{$t('message.oneAndOne')}}</button>
                </li>
                <li class="nav-item col-6 d-flex justify-content-center align-items-center" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">{{$t('message.coachingCalls')}}</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row justify-content-center pt-3">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <h4>{{$t('message.oneAndOneSubtitle')}}</h4>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center pt-2">
                            <div class="row justify-content-start row__width">
                                <div class="col-6 d-flex justify-content-start align-items-center px-0 spanV2" v-for="(horario, id) in oneAndOneHorarios">
                                    <img src="../../img/home-icons/cursos-icons/time.svg" alt="">
                                    <p>{{oneAndOneHorarios[id].horario}}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row justify-content-center justify-content-lg-start row__width">
                        <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center">
                            <Violeta :BTNVioletaTexto="$t('message.wantAdvice')"/>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="row justify-content-center pt-3">
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <h4>{{$t('message.oneAndOneSubtitle')}}</h4>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center pt-2">
                            <div class="row justify-content-start row__width">
                                <div class="col-6 d-flex justify-content-start align-items-center px-0 spanV2" v-for="(horario, id) in oneAndOneHorarios">
                                    <img src="../../img/home-icons/cursos-icons/time.svg" alt="">
                                    <p>{{oneAndOneHorarios[id].horario}}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row justify-content-center justify-content-lg-start row__width">
                        <div class="col-12 col-lg-8 d-flex justify-content-center align-items-center">
                            <Violeta :BTNVioletaTexto="$t('message.wantAdvice')"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center px-0 pb-3 d-none d-lg-flex">
            <img src="../../img/home-icons/cursos-icons/podras-conseguir.png" alt="">
        </div>
    </div>
</template>

<script setup>
    import { mapState } from "vuex";
    import Violeta from "../Botones/Violeta.vue";
    import { ref } from "vue";
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    
    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        titulo: "",
        subtitulo: "",
        src: "",
    })

    const oneAndOneHorarios = ref([
        { horario: "15 Minutos." },
        { horario: "35 Minutos." },
        { horario: "60 Minutos." },
        { horario: "60 Minutos." },
    ])
</script>

<style lang="scss" scoped>
.asesorias__profesionales{
    h2{
        font-weight: 700;
        font-size: 28px;
        color: var(--colorSecondary);
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        &::before{
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            border-radius: 5px;
            width: 15%;
            background-color: var(--colorSecondary);
        }
    }
    .v2, .v3{
        font-size: 26px;
        text-align: left;
        &::before{
            display: none;
        }
    }
    h4{
        font-size: 16px;
        font-weight: 400;
        color: #697891;
        text-align: left;
        padding: 10px 0;
        line-height: 27px;
        strong{
            color: inherit;
            font-size: inherit;
        }
    }
    h5{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary); 
        padding-bottom: 10px;
    }
    .lista{
        ul{
            padding: 0;
            li{
                font-size: 16px;
                font-weight: 400;
                color: #697891;
                position: relative;
                padding-left: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 10px;
                &::before{
                    content: '';
                    position: absolute;
                    background-image: url('../../img/home-icons/cursos-icons/tick-listado-asesorias.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 15px;
                    width: 15px;
                    left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
    .nav-tabs{
        height: 60px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 28px;
        button{
            border: 0;
            border-radius: 50%;
            font-weight: 500;
            font-size: 16px;
            color: var(--colorSecondary);
            height: 85%;
            width: 99%;
            transition: color .1s ease-in-out;
            &:focus{
                outline: 0;
            }
        }
        .active{
            background-color: var(--colorPrimary);
            color: var(--bgPrincipal);
            font-weight: 700;
            border-radius: 28px;
        }
    }
    .spanV2{
        margin-bottom: 10px;
    }
    p{
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-left: 10px;
    }
}
</style>