
<template>
    <div class="col-lg d-none d-lg-flex justify-content-center align-items-center max-width-icon px-0">
        <button type="button" class="btn_none px-0" @click="editQuestFunction">
            <img src="../../img/home-icons/arrow-left-negra.svg" alt="">
        </button>
    </div>
</template>

<script setup>
import { inject } from 'vue';

let editQuest = inject('editQuest')
const editQuestFunction = () => {
    editQuest.value = false
}
</script>

<style lang="scss" scoped>
.max-width-icon{
    max-width: 2vw;
    
    .btn-none{
        border: 0;
        background-color: transparent;
        outline: 0;
        img{
            width: 1vw;
        }
    }
}
</style>