<template>
  <router-view/>
  <!-- <nav id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/asesorias">Asesorias | </router-link>
    <router-link to="/carrito/1">Carrito | </router-link>
    <router-link to="/home-cursos-resultados">Home Cursos Resultados | </router-link>
    <router-link to="/home-cursos/:id">Home Cursos | </router-link>
    <router-link to="/ingresar-codigo">Ingresar Código | </router-link>
    <router-link to="/ingresar-facebook">Ingresar Facebook | </router-link>
    <router-link to="/iniciar">Iniciar | </router-link>
    <router-link to="/recuperar"> Recuperar | </router-link>
    <router-link to="/reestablecer"> Reestablecer | </router-link>
    <router-link to="/registrarse-telefono"> Registrarse Teléfono | </router-link>
    <router-link to="/registrarse-usuario"> Registrarse Usuario | </router-link>
    <router-link to="/registrarse"> Registrarse | </router-link>
    <router-link to="/registro-terminar"> Registro Terminar | </router-link>
    <router-link to="/reservar-asesor/:id"> Reservar Asesor | </router-link>
    <router-link to="/resultados-cursos"> Resultados Cursos | </router-link>
    <router-link to="/resultados-marcas"> Resultados Marcas | </router-link>
    <router-link to="/resultados-presets"> Resultados Presets | </router-link>
    <router-link to="/resultados-profesores"> Resultados Profesores | </router-link>
    <router-link to="/resultados-generales"> Resultados generales| </router-link>
    <router-link to="/roles"> Roles | </router-link>
    <router-link to="/splash"> Splash | </router-link>
    <router-link to="/page-not-found"> Page Not Found | </router-link>
    <router-link to="/sin-resultados"> Sin Resultados | </router-link>
    <router-link to="/pagos-plus"> Pagos Plus | </router-link>
    <router-link to="/planes-plus"> Planes Plus | </router-link>
    <router-link to="/certificados"> Certificados | </router-link>
    <router-link to="/pedido-aprobado"> Pedido Aprobado | </router-link>
    <router-link to="/mis-productos"> Mis Productos | </router-link>
    <router-link to="/ajustes"> Ajustes | </router-link>
    <router-link to="/cuenta"> Cuenta | </router-link>
    <router-link to="/perfil-marca/:id"> Perfil Marca | </router-link>
    <router-link to="/perfil-usuario/:id"> Perfil Usuario | </router-link>
    <router-link to="/viendo-curso/:slug"> Viendo Curso | </router-link>
    <router-link to="/preset/:id"> Preset | </router-link>
    <router-link to="/subir-producto"> Subir Productos | </router-link>
    <router-link to="/referidos"> Referido | </router-link>
    <router-link to="/usuarios"> Usuarios | </router-link>
  </nav> -->
</template>

<script setup>
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { onMounted } from "vue";

onMounted(() => {
    polyfillCountryFlagEmojis();
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 20vh 0;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
