
<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="row d-flex flex-column justify-content-center align-items-center">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center max__width__title">
                    <TituloYSubtituloRegistro :titulo="$t('message.recoverPassword')" :subtitulo="$t('message.recoverPasswordSubtitle')" desaparecerSubtitulo="false"/>
                </div>
                <div class="col-12 d-flex flex-column justify-content-center align-items-center max__width__mail">
                    <form class="w-100" action="">
                        <Password :titulo="$t('message.newPassword')"/>
                        <NuevaPassword :titulo="$t('message.repeatPassword')"/>
                        <BTNPrimario :textoBTNPrimario="$t('message.sendLink')" @click="fetchNewPassword()" class="mt-5"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Password from "../../components/Inputs/Reestablecer/Password.vue";
import NuevaPassword from "../../components/Inputs/Reestablecer/NuevaPassword.vue";
import BTNPrimario from "../../components/Botones/Primario.vue";
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';

import { useUsersStore } from "../../stores/user"    
import { storeToRefs } from "pinia";
import { provide, ref,onMounted, computed, onUnmounted } from "vue";
import axios from "axios"
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import Navbar from "../../components/Navs/Navbar.vue";

const { t }  = useI18n()
const msg = computed(() => t('message'))
//-------------//
const router = useRouter();
const route = useRoute();
//-------------//
const user = useUsersStore();
const {datosUsuario, headers, idUser} = storeToRefs(user);
const {} = user;
//-------------//
const infoPassword = ref({
    newPassword: ''
})
provide('infoPassword', infoPassword)
//-------------//
const validez = ref({
    validezPassword : false,
    validezNewPassword : false,
})
provide("validez", validez)
//-------------//
const infoReset = ref({
    password: '',
    token:''
})
provide('infoReset', infoReset)
//-------------//
const url = process.env.VUE_APP_BASE_URL
const urlReset = '/api/auth/reset/action'
//-------------//

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})




const validatePass = () => {
    if(infoReset.value.password != '' &&  infoReset.value.password.length >= 6){
        validez.value.validezPassword = 'input_valido'
    }else{
        validez.value.validezPassword = 'input_invalido'
    }
}
const validateNewPass = () => {
    if(infoPassword.value.newPassword != '' &&  infoPassword.value.newPassword.length >= 6  && infoPassword.value.newPassword == infoReset.value.password){
        validez.value.validezNewPassword = 'input_valido'
    }else{
        validez.value.validezNewPassword = 'input_invalido'
    }
}
onMounted(() => {
    //console.log(route.query)
    infoReset.value.token = route.query.token
})

//Hago un post del usuario 
async function fetchNewPassword() {
    console.log(validez.value, infoReset.value)
    validatePass(), validateNewPass()
    if( validez.value.validezPassword == 'input_valido' &&  validez.value.validezNewPassword == 'input_valido'){
        try {
            const response = await axios.post(url+urlReset, infoReset.value)
            console.log(response)
            if (response.data.status == 'Success') {
                Swal.fire({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: 'OK'
                })
                setTimeout(() => {
                    router.push('/iniciar')
                }, 4000);
            }
            
        }
        catch (error) {
            console.log(error)
            Swal.fire({
                text: error.response.data.message,
                icon: "error",
                confirmButtonText: 'OK'
            })
        }
    }else{
        //console.log('Campos inválidos')
        //console.log(infoReset.value)
        Swal.fire({
            text: t('message.titleResetPasswordFailed'),
            icon: "error",
            confirmButtonText: 'OK'
        })
        setTimeout(() => {
        }, 4000);
    }
}
</script>

<style lang="scss" scoped>

.max__width__title{
    max-width: 520px;
}
.max__width__mail{
    max-width: 420px;
}
.max__width__button{
    max-width: 420px;
}

</style>