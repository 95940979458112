<template>
  <ul class="nav nav-tabs row justify-content-center row__width" id="myTab" role="tablist">
    <div class="col d-flex justify-content-start align-items-center">
      <linksTabsHomeCursos
        v-for="(link, index) in filteredTabs"
        :key="id"
        :idTab="'tab-'+link.id"
        :titulo="link.titulo"
        :active="link.active"
        :display="link.displayM"
        :src="link.src"
      />
    </div>
    <div class="tab-content justify-content-center row__width px-0" id="myTabContent">
      <tabpaneHomeCursos />
    </div>
  </ul>
</template>

<script setup>
import linksTabsHomeCursos from "./LinksTabsHomeCursos.vue";
import tabpaneHomeCursos from "./TabpaneHomeCursos.vue";
import { useGeneralStore } from "../../stores/general";
import { storeToRefs } from "pinia";
import { ref, inject, computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n(); // Usamos el hook `t` para traducciones
const general = useGeneralStore();
const { tabHomecursos } = storeToRefs(general);
const product = inject("product");

// Establecemos las traducciones de las tabs usando `t()`
const tabTitles = [
  t('message.description'),
  t('message.dates'),
  t('message.content'),
  t('message.speaker'),
  t('message.benefits')
];

// Llamamos a la función de la store para establecer los títulos traducidos
general.setTabTitles(tabTitles);

// Filtramos las tabs en base a los datos del producto
const filteredTabs = computed(() => {
  return tabHomecursos.value.filter((tab) => {
    if (product) {
      if (tab.titulo === t('message.description') && product.value.description) {
        return true;
      }
      if (tab.titulo === t('message.dates') && product.value.dates) {
        return true;
      }
      if (tab.titulo === t('message.content') && product.value.intro?.information) {
        return true;
      }
      if (tab.titulo === t('message.speaker') && product.value.owner) {
        return true;
      }
      if (tab.titulo === t('message.benefits') && product.value.benefits?.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  });
});
</script>

<style lang="scss" scoped>
.tabs {
  .tab-pane {
    padding: 0;
  }
  .detalle-tabs {
    .descripcion {
      padding: 0px 0px;
    }
  }
}
</style>
