<template>
    <div class="row justify-content-center detalle row__width" v-if="arrayCheckout">
        <div class="col-12 d-flex justify-content-between align-items-center pt-4 px-lg-0">
            <h6>{{$t('message.subtotal')}}</h6>
            <p>${{ arrayCheckout.total }} {{ type }} </p>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center pt-3 pb-4 border_bottom px-lg-0" v-if="arrayCheckout.promoTotal">
            <h4>Descuentos </h4>
            <h5>- {{ type }} ${{ arrayCheckout.promoTotal }}</h5>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center pt-3 px-lg-0">
            <h2>{{$t('message.total')}}</h2>
            <h3> ${{ arrayCheckout.precioTotal }} {{ type }}</h3>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, provide } from 'vue';
import axios from 'axios';
import { storeToRefs } from "pinia"
import { useCartStore } from "../../stores/cart"

let type = 'MXN'

const carts = useCartStore();
const { arrayCheckout } = storeToRefs(carts);
const {  } = carts

</script>


<style lang="scss" scoped>
.detalle{
    h2{
        font-weight: 600;
        font-size: 28px;
        color: var(--colorSecondary);
        padding-top: 15px;
        padding-bottom: 10px;
        @media (min-width: 992px) {
            font-weight: 700;
        }
    }
    h3{
        font-weight: 700;
        font-size: 25px;
        color: var(--colorSecondary);
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    h5{
        color: #707070;
        font-weight: 500;
        font-size: 20px;
    }
    h4, h6{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h6{
        font-size: 22px;
    }
    p{
        font-size: 20px;
        font-weight: 500;
        color: var(--colorSecondary);
    }
    .border_bottom{
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
</style>