<template>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start mt-3 px-0">
        <h3>{{ $t('message.time') }}</h3>
         <div class="position">
            <input :type="type" @focus="cambio" :placeholder="userData.birthday !== null ? userData.birthday : $t('message.date')" v-model="information.time" >
            <i class="calendar" :class="type == 'date' ? 'd-none' : ''"></i>
        </div>
    </div>
</template>

<script setup>
import { useGeneralStore } from "../../../stores/general"
import { inject, ref } from "vue";
import { useUsersStore } from "../../../stores/user"    
import { storeToRefs } from "pinia";

const user = useUsersStore();
const {userData, headers, idUser, beforeInfomation} = storeToRefs(user);
const {} = user;

const general = useGeneralStore();
const {textos, inputs} = storeToRefs(general);
const {} = general;

const information = inject('information')
const validez = inject('validez')


const props = defineProps({
    titulo: '',
    responsive: '',
    
})
const type = ref('text')

const cambio = () =>{
    type.value = 'time'
}
</script>

<style lang="scss" scoped>
.inputs{
    margin: 10px 0px !important;
}
h3{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    padding: 7px 0px;
}
input{
    width: 100%;
    border: 2px solid #E9E3E3;
    border-radius: 10px;
    background-color: transparent;
    height: 50px;
    padding-left: 35px;
    outline: 0;
    position: relative;
    color: #697891;
    font-size: 15px;
    font-weight: 400;
    &:focus, &:hover{
        border: 2px solid #1679FC;
        
    }
    &::placeholder{
        color: #697891;
        font-size: 15px;
        font-weight: 400;
    }
}
input[type="time"]::-webkit-calendar-picker-indicator{
    background: url('../../../img/panel-icons/time2.svg');
    background-size: 100% 100%;
    width: 25px;
    position: absolute;
    left: -5px;
}
input[type="time"]:hover::-webkit-calendar-picker-indicator, input[type="time"]:focus::-webkit-calendar-picker-indicator{
    background: url('../../../img/panel-icons/time-active.svg');
    background-size: 100% 100%;
    width: 25px;
    position: absolute;
    left: -5px;
}
.position{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .calendar{
        width: 25px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-image: url('../../../img/panel-icons/time2.svg') ;
        background-size: 100% 100%;
        position: absolute;
        left: 8px;
    }
}
</style>