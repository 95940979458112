<template>
    <div class="col-12 d-flex justify-content-center align-items-center px-0 content">
        <div class="row justify-content-center row__width">
            <div class="col-12 d-flex justify-content-center align-items-center px-lg-0 mt-4">
                <div class="row justify-content-start row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-start px-0">
                        <h2>{{ $t('message.addQuestion') }} </h2>
                    </div>
                    <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.showDefaultQuestion') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="default" v-model="faq.showDefault" @change="setFaqs()">
                            <label class="form-check-label" for="default">
                                <p v-if="faq.showDefault">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-lg col-perso d-flex justify-content-between align-items-center mb-3 switch px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <p class="sin-padding">{{ $t('message.showMyQuestions') }}</p>
                        </div>
                        <div class="form-check form-switch d-flex justify-content-start align-items-center" >
                            <input class="form-check-input" type="checkbox" role="switch" id="perso" v-model="faq.showQuestions" @change="setFaqs()">
                            <label class="form-check-label" for="perso">
                                <p v-if="faq.showQuestions">{{ $t('message.yes') }}</p>
                                <p v-else>{{ $t('message.no') }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center px-0" v-if="faq.showQuestions">
                        <div class="row justify-content-center justify-content-lg-start row__width">
                            <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start px-0">
                                <h3>{{ $t('message.myQuestions') }}</h3>
                                <p>{{ $t('message.addQA') }}</p>
                            </div>
                            <div class="col-12 col-lg-4 d-flex justify-content-center align-items-start px-0">
                                <button type="button" class="agregar-cursos-btn" @click="addQuestion">
                                    <span>
                                        <img src="../../../img/panel-icons/mas.svg" alt="">
                                    </span>
                                    <p>{{ $t('message.addNewQuestion') }}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--- PREGUNTAS FRECUENTES --->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center collapses px-0" v-if="faq.showQuestions">
                        <div class="accordion d-flex flex-column justify-content-center align-items-center" id="accordionExample" v-for="(question, id) in faq.questions">
                            <div class="accordion-item row row__width">
                                <h2 class="accordion-header px-0 justify-content-center" :id="'heading'+id">
                                    <div class="col-12 d-flex justify-content-center align-items-center px-0 py-3">
                                        <div class="row row__width justify-content-center">
                                            <div class="col-11 d-flex justify-content-center align-items-center ps-0 me-3">
                                                <span class="me-3">
                                                    <h3>{{ id+1 }}</h3>
                                                </span>
                                                <h3 v-show="question.question == ''">{{ $t('message.productInformation') }}</h3>
                                                <h3 v-show="question.question != ''">{{ question.question }}</h3>
                                            </div>
                                            <div class="col-1 d-flex justify-content-end align-items-center pe-0 position">
                                                <button type="button" @click="deleteFaq(question.faq_id, faq.questions, id)">
                                                    <img src="../../../img/subir-productos-icons/cursos-icons/eliminar.svg" alt="">
                                                </button>
                                            </div>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+id" aria-expanded="false" aria-controls="collapse"></button>
                                        </div>
                                    </div>
                                    <div :id="'collapse'+id" class="col-12 accordion-collapse collapse px-0" :aria-labelledby="'heading'+id" data-bs-parent="#accordionExample">
                                        <div class="accordion-body row justify-content-center">
                                            <div class="col-12 d-flex justify-content-center align-items-center">
                                                <div class="row  row__width justify-content-end">
                                                    <span class="col-1">
                                                        <h3>{{ id+1 }}</h3>
                                                    </span>
                                                    <div class="col d-flex flex-column justify-content-center align-items-starts pe-0">
                                                        <input type="text" name="" id="" :placeholder="$t('message.questionPlaceholder')" v-model="question.question">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-center align-items-center mt-3 px-0">
                                                <div class="row  row__width justify-content-center">
                                                    <div class="col d-flex justify-content-center align-items-center pe-0  ms-4">
                                                        <div class="row row__width justify-content-center ">
                                                            <div class="col d-flex flex-column justify-content-center align-items-start ps-3 pe-0 pe-lg-auto">
                                                                <input type="text" name="" id="" :placeholder="$t('message.answerPlaceholder')" v-model="question.response">
                                                            </div>
                                                            <button type="button" class="col-1 button d-flex justify-content-center align-items-center btn-save" @click="addFaq(question.faq_id, question.question, question.response, '#collapse'+id)">
                                                                <i class="save"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"

const users = useUsersStore();
const {  } = storeToRefs(users);
const { url } = users;

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const urlSetFaqs = '/api/product/creating/set/faq'
const urlFetchFaqs = '/api/product/personal/faqs/'
const urlAddFaq = '/api/product/creating/add/faq'
const urlDeleteFaq = '/api/product/creating/delete/faq/'

let faq = inject('faq')
let product = inject('product')
let information = inject('information')

let owner_id = inject('owner_id')
let product_id = inject('product_id')

const addQuestion = () => {
    console.log(faq.value)
    faq.value.questions.push(
        {
            question: '',
            response: '',
            faq_id: null,
            trans_id: null
        }
    )
}

const setFaqs = async() => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let hasFaq = faq.value.showDefault
    let hasPFaq = faq.value.showQuestions
    let id = product.value.id
    try {
        await axios.post(url+urlSetFaqs, {id: id, hasFaq: hasFaq, hasPFaq: hasPFaq, owner_id: owner_id.value, product_id: product_id.value}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 200){
                if(hasPFaq == true){
                    fetchFaqs()
                }
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const fetchFaqs = async() => {
    let id = product.value.id
    try {
        await axios.get(url+urlFetchFaqs+id).then((result) => {
            console.log(result)
            faq.value.questions = []
            if (result.status == 200){
                for(let i = 0; i < result.data.length; i++){
                    faq.value.questions.push( result.data[i] )
                }
            }
        })
    } catch (error) {
        console.error(error)
    }
}

const saveQuestionsIcon = (x, questionId) => {
    let button = document.querySelector(`${questionId} .btn-save`);
    if(x == 1){
        button.classList.add('done')
        setTimeout(() => {
            button.classList.remove('done')
        }, 1500)
    }else{
        button.classList.add('undone')
        setTimeout(() => {
            button.classList.remove('undone')
        }, 1500)
    }
}

const addFaq = async(faq_id, question, response, questionId) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    let id = product.value.id
    let lang = information.value.lang
    try {
        await axios.post(url+urlAddFaq, {id: id, pFaq_id: faq_id, language: lang, question: question, response: response}, {headers: headers.value}).then((result) => {
            console.log(result)
            if (result.status == 201){
                fetchFaqs()
                saveQuestionsIcon(1, questionId)
            }
            else if(result.status == 422){
                console.log('Error de validacion')
            }
            else {
                console.log('Roldan lo rompio todo')
            }
        })
    } catch (error) {
        console.error(error)
        saveQuestionsIcon(2, questionId)
    }
}

const deleteFaq = async(faq_id, item, id) => {
    const token = JSON.parse(localStorage.getItem('token'))
    const headers = ref({'Authorization': `Bearer ${token}`})
    if(faq_id){
        try {
            await axios.delete(url+urlDeleteFaq+faq_id, {headers: headers.value}).then((result) => {
                console.log(result)
                if (result.status == 200){
                    fetchFaqs()
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    else {
        item.splice(id, 1);
    }
}
</script>

<style lang="scss" scoped>
h2{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%; 
}
h3{
    font-weight: 600;
    color: var(--colorSecondary);
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px !important;
    width: 100%;
}
h4{
    color: var(--colorSecondary);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 7px 0px;
}
p{
    color: var(--colorSecondary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    padding: 2px 0px;
}
.switch{
    .form-switch .form-check-input{
        width: 48px;
        height: 23px;
        background-color: #E4E4E4;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
        border: 0;
        background-position-x: 2px;
    }
    .form-switch .form-check-input:focus{
        outline: 0;
        box-shadow: none;
        border: 0;
        background-image: url('../../../img/panel-icons/check-icon.svg');
        background-size: 40%;
    }
    .form-switch .form-check-input:checked{
        background-color: var(--colorPrimary);
        background-position-x: 27px;
    }
    p{
        font-weight: 500;
        padding-left: 10px;
        width: 25px;
    }
    .sin-padding{
        padding: 0;
        width: auto;
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-left: 10px;
    }
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
    }
   
}
.col-perso{
    @media (min-width: 992px) {
        min-width: 55% !important;
        max-width: 55%;
    }
}
.agregar-cursos-btn{
    box-shadow: 0 3px 6px #00000016;
    border-radius: 43px;
    height: 60px;
    margin: 20px 0;
    border: 0;
    background-color: var(--bgPrincipal);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    position: relative;
    p{
        padding-left: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    span{
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        min-height: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0px;
    }
}
.buttons{
    .btn__primario{
        height: 45px;
    }
}
.collapses{
    h3{
        margin-bottom: 0px !important;
        font-size: 16px;
    }
    .accordion{
        width: 100%;
        .accordion-item{
            margin: 15px 0px;
            border: 0;
            border-radius: 10px;
            min-height: 70px;
        }
        .accordion-item:last-of-type .accordion-button.collapsed{
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        span{
            border: 2px solid #E9E3E3;
            min-height: 40px;
            min-width: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            h3{
                text-align: center;
            }
        }
        .accordion-button{
            border-radius: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--bgPrincipal);
            width: auto;
            &:focus{
                border-color: inherit;
                box-shadow: none;
            }
           
        }
        .accordion-button:not(.collapsed){
            background-color: inherit;
            box-shadow: none;
        }
        .accordion-button::after{
            position: absolute;
            right: 2.5%;
            background-image: url('../../../img/subir-productos-icons/arrow-gris-abajo.svg');
            background-size: 100%;
            background-position: center;
        }
        .accordion-header{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            background-color: var(--bgPrincipal);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .accordion-body{
                input,select{
                    width: 100%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: #FCFCFC;
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    -webkit-appearance: none;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                }
                span{
                    border: 2px solid #E9E3E3;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h3{
                        text-align: center;
                    }
                }
                button{
                    background-color: transparent;
                    border: 2px solid #E4E4E4;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin-left: 10px;
                }
            }
    }
}
.ofertas{
    border: 1px solid #E4E4E4;
    background-color: var(--bgPrincipal);
    border-radius: 15px;
    height: 110px;
    h4{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
    }
    h5{
        color: #2E4765;
        font-weight: 300;
        font-size: 14px;
    }
    .eliminar{
        border-left: 2px solid #E4E4E4;
        button{
            background-color: transparent;
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 10px;
        }
    }
    .ejemplo{
        border-radius: 5px;
        height: 83px;
        img{
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    
}
.dropdown{
    .dropdown-toggle{
        background-color: var(--bgPrincipal);
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 600;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 0 20px;
        border: 0 !important;
        margin-left: 0 !important;
        padding: 0;
        width: 20px;
        min-width: 0 !important;
        h3{
            padding: 20px 0;
        }
        .dropdown-menu{
            background-color: var(--bgPrincipal);
            width: 100%;
            border: 0;
            padding: 20px 20px 0 20px;
            height: auto;
            border-radius: 0 0 10px 10px ;
            a:hover, a:focus{
                background-color: inherit;
            }
            a{
                padding: 0 0 10px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #2E4765;
                font-size: 15px;
                font-weight: 400;
            }
            li{
                width: 100%;
            }
        }
    }
    .dropdown-toggle::after{
        display: none;
    }
    .arrow{
        width: 25px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.2s;
        background-size: 75%;
        background-position: center;
    }
}
.upperCase {
    text-transform: uppercase;
}

.position{
    position: absolute;
    right: 8%;
    @media (max-width: 750px) {
        right: 3%;
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        min-height: 40px;
        min-width: 40px;
    }
}

.btn-save{
    i{
        min-width: 23px;
        min-height: 23px;
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('../../../img/subir-productos-icons/cursos-icons/save.svg');

    }
    transition: all 0.2s;
}
.done{
    i{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/correct.svg');
    }
    border: 2px solid #66DF4A !important;
}
.undone{
    i{
        background-image: url('../../../img/subir-productos-icons/cursos-icons/incorrect.svg');
    }
    border: 2px solid #FF576E !important;
}
</style>